import React from 'react';
import { LoadingSpinner } from './LoadingSpinner';

interface IProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  isLoading?: boolean;
}

export const SpringButton = ({ isLoading, children, ...props }: IProps) => {
  return (
    <button style={{ display: 'flex', flexDirection: 'row' }} disabled={isLoading} {...props}>
      {isLoading && (
        <div className={'mr-2'}>
          <LoadingSpinner color={'inherit'} size={12} />
        </div>
      )}
      {children}
    </button>
  );
};
