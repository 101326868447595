import { Schema } from 'boaz-bikes-types';
import React from 'react';
import { DateInfo } from '../components/DateInfo';
import { Table } from '../components/Table';
import { useSpringFetch } from '../firebase';
import { useNavigate } from '../Router';
import { Card } from '../components/Card';
import { PageContainer } from '../components/PageContainer';
import { Link } from 'react-router-dom';

function PromoCodeList() {
  const navigate = useNavigate();
  const { data, isLoading } = useSpringFetch<{
    promoCodes: Schema.PromoCode.Admin[];
  }>('get', '/admin/promos', {});

  if (!isLoading && data == null) {
    return <div>No Promos Available</div>;
  }

  const promoCodes = data ? data.promoCodes : [];

  return (
    <div className="row">
      <div className="col">
        <Card.Container>
          <Table
            title={'All Promo Codes'}
            onAddItem={() => navigate('/promos/create')}
            // getRowUrl={(promoCode) => `/promos/${promoCode.id}`}
            isLoading={isLoading}
            columnRenderInfos={[
              { columnName: 'Promo Code', attribute: 'promoCode' },
              { columnName: 'Discount Cents', attribute: 'discountCents' },
              { columnName: 'Number of uses per account', attribute: 'initialUseCount' },
              {
                columnName: 'Active',
                attribute: 'isActive',
                shouldJsonifyValue: true,
              },
              {
                columnName: 'Expiration Date',
                attribute: 'expirationDate'
              },
              {
                columnName: 'Created At',
                attribute: 'createdAt',
                renderFunc: ({ createdAt }) => <DateInfo date={createdAt} />,
              },
              {
                columnName: 'Edit',
                renderFunc: ({ id }) => (
                  <Link to={`/promos/${id}`}>Edit</Link>
                ),
              },
            ]}
            rows={promoCodes}
          />
        </Card.Container>
      </div>
    </div>
  );
}

export function PromosHome() {
  return (
    <PageContainer>
      <PromoCodeList />
    </PageContainer>
  );
}
