import React, { useState } from 'react';
import { IFormField, INPUT_TYPE } from 'boaz-bikes-forms';
import { SpringClient, Schema } from 'boaz-bikes-types';
import { useNavigate } from '../Router';
import { showMessage } from '../ToastProvider';
import { SimpleForm } from 'boaz-bikes-forms';
import { LoadingView } from '../components/LoadingSpinner';
import { PageContainer } from '../components/PageContainer';

export const CREATE_HOME_CITY_FIELDS: IFormField[] = [
  {
    label: 'Name of City',
    name: 'name',
    inputType: INPUT_TYPE.TEXT,
  },
  {
    label: 'Zip Codes (separated with commas)',
    name: 'zipCode',
    inputType: INPUT_TYPE.TEXT,
  },
  {
    label: 'Services Available',
    name: 'services',
    inputType: INPUT_TYPE.SELECT,
    options: Object.values(Schema.HomeCity.Services),
  },
  {
    label: 'Latitude',
    name: 'latitude',
    inputType: INPUT_TYPE.TEXT,
  },
  {
    label: 'Longitude',
    name: 'longitude',
    inputType: INPUT_TYPE.TEXT,
  },
];

export const CreateHomeCity = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const submitFormAsync = async (formState: any) => {
    setIsLoading(true);

    try {
      const { message, homeCityId } = await SpringClient.post('/admin/homecity', formState);
      showMessage(message);
      navigate(`/`);
      window.location.reload(false);
    } catch (e) {
      console.error('Error creating home city', e);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <PageContainer>
      <div className="row">
        <div className="col-md-6 col-12 mx-auto">
          <SimpleForm
            title={'Create Home City'}
            formFields={CREATE_HOME_CITY_FIELDS}
            onSubmit={submitFormAsync}
          />
        </div>
      </div>
    </PageContainer>
  );
};
