import React, { useState } from 'react';
import { SpringClient } from 'boaz-bikes-types/dist/SpringClient';
import { Select } from '../components/Select';

export const AdminStatusDropdown = ({
  id,
  isAdmin,
  refreshData,
}: {
  id: string;
  isAdmin: boolean;
  refreshData: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const updateStatusAsync = async (isAdmin: boolean) => {
    if (window.confirm("Do you want to change this person's admin status?")) {
      setIsLoading(true);
      await SpringClient.post(`/admin/users/${id}/update-admin`, { isAdmin });
      await refreshData();
      setIsLoading(false);
    }
  };
  return (
    <Select
      isLoading={isLoading}
      value={`${isAdmin}`}
      options={['true', 'false'].map((option) => ({ label: option, value: option }))}
      onChange={(value) => updateStatusAsync(value === 'true')}
    />
  );
};
