import { displayPrice, Schema, SpringClient } from 'boaz-bikes-types';
import classNames from 'classnames';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router';
import { DateInfo } from '../components/DateInfo';
import { DatePeriodProvider } from '../components/DatePeriodProvider';
import { DisplayData } from '../components/DisplayData';
import { LoadingSpinner, LoadingView } from '../components/LoadingSpinner';
import { UrlLink } from '../components/UrlLink';
import { useSpringFetch } from '../firebase';
import { PingsMap } from '../map/PingsMap';
import { FirebaseStorageImage } from '../support/SupportHome';
import { VehicleFeed } from '../vehicles/VehicleFeed';
import { UserSummary, VehicleSummary } from './RentalList';
import { Card } from '../components/Card';
import { PageContainer } from '../components/PageContainer';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { SpringButton } from '../components/SpringButton';
import { CompanyContext } from '../auth/CompanyContext';
import { TransactionsList } from '../users/TransactionsList';

const EndRentalButton = ({
  shouldCaptureCharge,
  ignoreIotFailure,
  title,
  rentalId,
  refreshData,
}: {
  shouldCaptureCharge: boolean;
  ignoreIotFailure: boolean;
  rentalId: string;
  title: string;
  refreshData: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <SpringButton
      isLoading={isLoading}
      className={classNames('btn mr-2 cursor-pointer', {
        'btn-danger': shouldCaptureCharge,
        'btn-outline-danger': !shouldCaptureCharge,
      })}
      onClick={async () => {
        setIsLoading(true);
        await SpringClient.post(`/admin/rentals/${rentalId}/end`, {
          shouldCaptureCharge,
          ignoreIotFailure,
        });
        setIsLoading(false);
        refreshData();
      }}
    >
      {title}
    </SpringButton>
  );
};

const GroupRentalData = ({
  currentRentalId,
  groupId,
}: {
  currentRentalId: string;
  groupId: string;
}) => {
  const { data, isLoading } = useSpringFetch<{
    rentals: Schema.Rental.Admin[];
  }>('get', `/admin/rentals`, { groupId });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const rentals = data?.rentals || [];
  const associatedRentals = rentals.filter((rental) => rental.id !== currentRentalId);

  if (associatedRentals.length) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>{`${rentals.length} rentals`}</div>
        {associatedRentals.map((rental, index) => (
          <Link key={rental.id} to={`/rentals/${rental.id}`}>
            {`rental ${_.truncate(rental.id, { length: 10 })}`}
          </Link>
        ))}
      </div>
    );
  }

  return <div>Individual Rental</div>;
};

const UserInput = ({ rental }: { rental: Schema.Rental.Admin }) => {
  return (
    <Card.Container>
      <Card.Header title="User Input" />
      <Card.Body>
        {rental.photoGcloudUrl ? (
          <FirebaseStorageImage
            firebaseUrl={rental.photoGcloudUrl}
            className={'w-100 mb-4'}
            style={{ maxWidth: 500 }}
          />
        ) : (
          <div className="alert alert-warning" role="alert">
            No photo available
          </div>
        )}
        <DisplayData
          data={[
            { label: 'Rating', value: rental.rating },
            { label: 'Review', value: rental.review },
          ]}
        />
      </Card.Body>
    </Card.Container>
  );
};

const Actions = ({
  rental,
  refreshData,
}: {
  rental: Schema.Rental.Admin;
  refreshData: () => void;
}) => {
  return (
    <Card.Container>
      <Card.Header title="Actions" />
      <Card.Body>
        <EndRentalButton
          shouldCaptureCharge={true}
          ignoreIotFailure={false}
          rentalId={rental.id}
          title={'End Rental'}
          refreshData={refreshData}
        />
        <EndRentalButton
          shouldCaptureCharge={false}
          ignoreIotFailure={true}
          rentalId={rental.id}
          title={'End Rental Without Charge'}
          refreshData={refreshData}
        />
      </Card.Body>
    </Card.Container>
  );
};

const StripeLinks = ({ rental }: { rental: Schema.Rental.Admin }) => {
  if (rental.stripeChargeId) {
    const stripeChargeUrl = `https://dashboard.stripe.com/payments/${rental.stripeChargeId}`;
    return <UrlLink href={stripeChargeUrl} label={'View in Stripe'} />;
  }

  const stripeUrls = rental.stripeCapturedPaymentIntentIds.map(
    (id) => `https://dashboard.stripe.com/payments/${id}`
  );

  return (
    <>
      {stripeUrls.map((url, idx) => (
        <UrlLink label={`Stripe ${idx + 1}`} href={url} key={idx} className={'mx-1'} />
      ))}
    </>
  );
};

const PromoCodeInfo = ({ rental }: { rental: Schema.Rental.Admin }) => {
  const { company } = useContext(CompanyContext);
  if (!rental.promoCodeId) {
    return null;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Link to={`/promos/${rental.promoCodeId}`}>{rental.promoCode}</Link>
      <div>{`discount: ${displayPrice({
        amountCents: rental.amountCentsDiscounted,
        numDigits: 2,
        currency: company.currency,
      })}`}</div>
    </div>
  );
};

const RentalDuration = ({ rental }: { rental: Schema.Rental.Admin }) => {
  const { startAt, endAt } = rental;

  let durationMinutesString = 'Ongoing...';

  if (startAt && endAt) {
    const startMoment = moment(startAt);
    const endMoment = moment(endAt);
    const minutes = endMoment.diff(startMoment, 'minutes');
    const seconds = endMoment.diff(startMoment, 'seconds') % 60;
    durationMinutesString = `${minutes} Minutes, ${seconds} Seconds`;
  }

  return <div>{durationMinutesString}</div>;
};

const RentalPrice = ({ rental }: { rental: Schema.Rental.Admin }) => {
  const { company } = useContext(CompanyContext);
  const { amountCentsCharged } = rental;

  const rentalAmount = displayPrice({
    amountCents: amountCentsCharged,
    currency: company.currency,
    numDigits: 2,
  });

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="mr-2">{rentalAmount}</div>
        <StripeLinks rental={rental} />
      </div>

      <PromoCodeInfo rental={rental} />

      {rental.isShortRental && <div>Grace Period - No Charge</div>}
    </div>
  );
};

export function RentalInfo() {
  const { rentalId } = useParams();

  const { data, isLoading, refreshData } = useSpringFetch<{
    rental: Schema.Rental.Admin;
    vehicle: Schema.Vehicle.Admin;
    user: Schema.User.Admin;
  }>('get', `/admin/rentals/${rentalId}`, {});

  if (isLoading) {
    return <LoadingView />;
  }

  if (data == null) {
    return <div className="alert alert-warning">Rental not found</div>;
  }

  const { rental, vehicle, user } = data;

  const { id, startAt, endAt, tripDistance } = rental;
  let distanceMiles: string | number = (tripDistance || 0) / 1609.34;
  distanceMiles = distanceMiles.toFixed(2);

  return (
    <PageContainer>
      <div className="row mb-4">
        <div className="col-lg-6 col-xs-12 mb-lg-0 mb-2">
          <Card.Container>
            <Card.Header title="Details" />
            <Card.Body>
              <DisplayData
                data={[
                  { label: 'User', value: <UserSummary user={user} /> },
                  { label: 'Vehicle', value: <VehicleSummary vehicle={vehicle} /> },
                  {
                    label: 'Price',
                    value: <RentalPrice rental={rental} />,
                  },
                  { label: 'Start', value: <DateInfo date={startAt} /> },
                  { label: 'End', value: <DateInfo date={endAt} /> },
                  { label: 'Duration', value: <RentalDuration rental={rental} /> },
                  { label: 'Distance', value: `${distanceMiles} miles` },
                  { label: 'ID', value: id },
                  {
                    label: 'Group',
                    value: <GroupRentalData currentRentalId={rental.id} groupId={rental.groupId} />,
                  },
                ]}
              />
            </Card.Body>
          </Card.Container>
        </div>

        <div className="col-lg-6 col-xs-12">
          {endAt ? (
            <UserInput rental={rental} />
          ) : (
            <Actions rental={rental} refreshData={refreshData} />
          )}
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12">
          <Card.Container>
            <Card.Header title="Transactions" />
            <TransactionsList rentalId={rental.id} />
          </Card.Container>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12">
          <Card.Container>
            <Card.Header title="Rental Pings" />
            <Card.Body>
              <DatePeriodProvider
                initialStart={rental.startAt}
                initialEnd={rental.endAt}
                render={({ start, end }) => {
                  return (
                    <PingsMap
                      vehicleId={rental.vehicleId}
                      startAt={start}
                      endAt={end}
                      userId={rental.userId}
                      startCoordinate={rental.startUserCoordinate}
                      endCoordinate={rental.endUserCoordinate}
                    />
                  );
                }}
              />
            </Card.Body>
          </Card.Container>
          <div />
        </div>
      </div>
      <VehicleFeed initialStart={rental.startAt} initialEnd={rental.endAt} vehicle={vehicle} />
    </PageContainer>
  );
}
