import React, { useState, useContext } from 'react';
import { useSpringFetch } from '../firebase';
import { Schema, SpringClient } from 'boaz-bikes-types';
import { IFormField, INPUT_TYPE } from 'boaz-bikes-forms';
import { showMessage } from '../ToastProvider';
import { LoadingView } from '../components/LoadingSpinner';
import { SimpleForm } from 'boaz-bikes-forms';
import { useNavigate } from '../Router';
import { useParams } from 'react-router';
import { PageContainer } from '../components/PageContainer';
import { HomeCityContext } from '../home-city/HomeCityContext';

const UpdateDockForm = ({ dock }: { dock: Schema.Dock.Admin }) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const {getHomeCities} = useContext(HomeCityContext);
  const navigate = useNavigate();

  const submitFormAsync = async (formState: any) => {
    setIsUpdating(true);

    try {
      const { message } = await SpringClient.post(`/admin/docks/${dock.id}`, formState);
      navigate(`/docks/${dock.id}`);
      showMessage(message);
    } catch (e) {
      console.error('Error creating dock', e);
    }

    setIsUpdating(false);
  };

  if (isUpdating) {
    return <LoadingView />;
  }

  const allHomeCity = getHomeCities();
  let homeCityOptions = [{label: "", value: ""}];
  allHomeCity?.forEach((homecity) =>{
    homeCityOptions.push({
      label: homecity.name,
      value: homecity.id
    })
  })

  const DOCK_FIELDS: IFormField[] = [
    {
      label: 'Display Name',
      name: 'displayName',
      inputType: INPUT_TYPE.TEXT,
    },
    {
      label: 'Home City',
      name: 'homeCityId',
      inputType: INPUT_TYPE.SELECT,
      options: homeCityOptions,
    },
    {
      label: 'Manufacturer',
      name: 'manufacturer',
      inputType: INPUT_TYPE.SELECT,
      options: Object.values(Schema.Dock.Manufacturer),
    },
    {
      label: 'Manufacturer Id',
      name: 'manufacturerId',
      inputType: INPUT_TYPE.TEXT,
    },
    {
      label: 'QR Code',
      name: 'qrCode',
      inputType: INPUT_TYPE.TEXT,
    },
    {
      label: 'Total Bays',
      name: 'numTotalBays',
      inputType: INPUT_TYPE.NUMBER,
    },
    {
      label: 'Available Bays',
      name: 'numAvailableBays',
      inputType: INPUT_TYPE.NUMBER,
    },
    {
      label: 'Available Vehicles',
      name: 'numAvailableVehicles',
      inputType: INPUT_TYPE.NUMBER,
    },
    {
      label: 'Latitude',
      name: 'latitude',
      inputType: INPUT_TYPE.NUMBER,
    },
    {
      label: 'Longitude',
      name: 'longitude',
      inputType: INPUT_TYPE.NUMBER,
    },
    {
      label: 'Is Active',
      name: 'isActive',
      inputType: INPUT_TYPE.BOOLEAN,
    },
    {
      label: 'Is Operator',
      name: 'isOperator',
      inputType: INPUT_TYPE.BOOLEAN,
      description: 'Operator hubs show up as drop off locations on the operations map.',
    },
    {
      label: 'Is High Priority',
      name: 'highPriority',
      inputType: INPUT_TYPE.BOOLEAN,
    },
    {
      label: 'User Parking Allowed',
      name: 'userParkingAllowed',
      inputType: INPUT_TYPE.BOOLEAN,
    },
  ]

  return (
    <SimpleForm
      title={'Update Dock'}
      formFields={DOCK_FIELDS}
      initialState={dock}
      onSubmit={submitFormAsync}
      isLoading={isUpdating}
    />
  );
};

export const UpdateDock = () => {
  const { dockId } = useParams();

  const { data, isLoading } = useSpringFetch<{ dock: Schema.Dock.Admin }>(
    'get',
    `/admin/docks/${dockId}`
  );

  if (isLoading) {
    return <LoadingView />;
  }

  if (data == null) {
    return <div className="alert alert-warning">Dock not found</div>;
  }

  const { dock } = data;

  return (
    <PageContainer>
      <div className="row">
        <div className="col-md-6 col-12 mx-auto">
          <UpdateDockForm dock={dock} />
        </div>
      </div>
    </PageContainer>
  );
};
