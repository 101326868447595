import { displayPrice, displayTime, Schema } from 'boaz-bikes-types';
import React from 'react';
import { Card } from '../components/Card';
import { DisplayData } from '../components/DisplayData';
import { Link } from 'react-router-dom';

const PriceOptions = ({ priceOptions }: { priceOptions: Schema.PriceOption.Client[] }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      {priceOptions.map((option) => {
        const unlockPrice = displayPrice({
          amountCents: option.priceUnlockCents,
          currency: option.currency,
          numDigits: 2,
        });
        const chunckPrice = displayPrice({
          amountCents: option.priceCentsPerChunk,
          currency: option.currency,
          numDigits: 2,
        });
        const chunkTime = displayTime({
          seconds: option.minuteChunkSize * 60,
        });
        return (
          <div
            key={option.id}
            className="alert alert-dark"
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginRight: 10,
              marginBottom: 10,
            }}
          >
            {/*Wrapping div maintains spaces between words*/}
            <div>
              <strong>{option.name}: {unlockPrice}</strong> unlock + <strong>{chunckPrice}</strong> / {chunkTime}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const TopUpOptions = ({ topUpOptions }: { topUpOptions: Schema.TopUpOption.Client[] }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      {topUpOptions.map(({ id, amountCents, currency }) => {
        const price = displayPrice({
          amountCents: amountCents,
          currency,
          numDigits: 2,
        });

        return (
          <div
            key={id}
            className="alert alert-dark"
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginRight: 10,
              marginBottom: 10,
            }}
          >
            {price}
          </div>
        );
      })}
    </div>
  );
};
export const PricingConfig = ({
  company,
  priceOptions,
  topUpOptions,
}: {
  company: Schema.Company.Client;
  priceOptions: Schema.PriceOption.Client[];
  topUpOptions: Schema.TopUpOption.Client[];
}) => {
  return (
    <div className="col-lg-6 col-xs-12 mb-lg-0 mb-2">
      <Card.Container>
        <Card.Header title="Pricing" actions={<Link to={'/config/pricing/edit'}>Edit</Link>} />
        <Card.Body>
          <DisplayData
            data={[
              { label: 'Currency', value: company.currency },
              { label: 'Wallet Enabled', value: company.walletEnabled, jsonify: true },
              company.walletEnabled
                ? { label: 'Minimum Balance Cents', value: company.minBalanceCents }
                : {
                    label: 'Authorization Amount Cents',
                    value: company.priceAuthorizationAmountCents,
                  },
              { label: 'Grace Period Seconds', value: company.gracePeriodSeconds },
              { label: 'Referral Bonus', value: company.referralBonus },
              { label: 'Payment Processor', value: company.paymentProcessor },
              { label: 'Stripe Public Key', value: company.stripePublicKey },
              { label: 'Price Options', value: <PriceOptions priceOptions={priceOptions} /> },
              { label: 'Top Up Options', value: <TopUpOptions topUpOptions={topUpOptions} /> },
            ]}
          />
        </Card.Body>
      </Card.Container>
    </div>
  );
};
