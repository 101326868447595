import React, { useState } from 'react';
import { Select } from '../components/Select';
import { useSpringFetch } from '../firebase';
import { SpringClient, Schema, Message } from 'boaz-bikes-types';
import { showMessage } from '../ToastProvider';

export const HomeCityDropdown = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const updatehomeCityIdAsync = async (homeCityId: string) => {

    if (props.updateUser) {
      if (window.confirm("Do you want to change this person's home city?")) {
        setIsLoading(true);
        await SpringClient.post(`/admin/users/${props.id}/update-homecity`, { homeCityId });
        props.refreshData();
        setIsLoading(false);
      }
    }
    else if (props.updateVehicle) {
      setIsLoading(true);
      await SpringClient.post(`/admin/vehicles/${props.id}`, { homeCityId }, { showGoodMessages: false });
      showMessage(Message.create('Vehicles Updated', Message.Type.Success));
      props.refreshData();
      setIsLoading(false);
    }
    else {
      setIsLoading(true);
      if (props.refreshData) await props.refreshData(homeCityId);
      // if (props.getSelected) props.getSelected(homeCityId)
      // await Promise.all(
      //   ids.map(async (id) => {
      //     await SpringClient.post(`/admin/vehicles/${id}`, { homeCityId }, { showGoodMessages: false });
      //   })
      // );
      // showMessage(Message.create('Vehicles Updated', Message.Type.Success));
      // await refreshData();
      setIsLoading(false);
    }
  };

  const { data: homeCityData, isLoading: isLoadingUsers } = useSpringFetch<{
    homeCities: Schema.HomeCity.Admin[];
  }>('get', '/admin/homecities');

  // let options: { label: string; value: string; }[] = [];
  const options = [{ label: '', value: '' }];

  homeCityData?.homeCities.forEach((homeCity) => {
    options.push({
      label: homeCity.name,
      value: homeCity.id,
    });
  });

  return (
    <Select
      isLoading={isLoading}
      value={props.homeCityId || ""}
      options={options}
      onChange={(value) => updatehomeCityIdAsync(value as string)}
    />
  );
};