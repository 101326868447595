import React from 'react';
import { Colors } from './Colors';

interface ITabProps {
  title: string;
  isSelected: boolean;
  onClick: () => void;
}

export const Tab = ({ title, isSelected, onClick }: ITabProps) => {
  return (
    <div
      className="col"
      onClick={onClick}
      style={{
        cursor: 'pointer',
        display: 'flex',
        height: 64,
        justifyContent: 'center',
        alignItems: 'center',
        borderBottomStyle: 'solid',
        borderBottomWidth: isSelected ? 2 : 0,
        borderBottomColor: Colors.PRIMARY,
      }}
    >
      <div>{title}</div>
    </div>
  );
};

interface ITabRowProps {
  tabs: ITabProps[];
  includeShadow?: boolean;
  includeBorder?: boolean;
}

export const TabRow = ({ tabs, includeShadow = true, includeBorder = true }: ITabRowProps) => {
  return (
    <div className="row mb-2">
      <div className="col">
        <div className="card" style={{ boxShadow: includeShadow ? undefined : 'none', borderWidth: includeBorder ? undefined : 0 }}>
          <div className="container">
            <div className="row">
              {tabs.map((tab) => {
                return <Tab key={tab.title} {...tab} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
