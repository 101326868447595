import { FormField, useForm } from 'boaz-bikes-forms';
import { ICoordinate, Message, SpringClient } from 'boaz-bikes-types';
import * as turf from '@turf/turf';
import React, { useContext, useState } from 'react';
import Marker from 'react-google-maps/lib/components/Marker';
import Polyline from 'react-google-maps/lib/components/Polyline';
import ReactJson from 'react-json-view';
import { MapComponent } from '../components/MapComponent';
import { GEOFENCE_FORM_FIELDS, MapOverlay, useAdminGeofences } from './GeofenceHome';
import { useNavigate } from '../Router';
import { SpringButton } from '../components/SpringButton';
import { useDefaultCenter } from '../map/MapPage';
import { Geofence } from '../components/Geofence';
import { HomeCityContext } from '../home-city/HomeCityContext';

enum MODE {
  ADDING_SHAPE,
  ADDING_HOLE,
}

interface IInstructions {
  title: string;
  instructions: string[];
}

const ADDING_SHAPE_INSTRUCTIONS: IInstructions = {
  title: 'Creating outer perimeter:',
  instructions: ['Click on map to add points', 'Close shape by clicking on starting point'],
};

const ADDING_HOLE_INSTRUCTIONS: IInstructions = {
  title: 'Creating hole:',
  instructions: [
    'Click on map to add points',
    'Keep points within outer perimeter',
    'Close shape by clicking on starting point',
    'You can add multiple holes',
  ],
};

const Instructions = ({ mode }: { mode: MODE }) => {
  const alertStyle = mode === MODE.ADDING_SHAPE ? 'alert-primary' : 'alert-interesting';
  const instructions =
    mode === MODE.ADDING_SHAPE ? ADDING_SHAPE_INSTRUCTIONS : ADDING_HOLE_INSTRUCTIONS;
  return (
    <div className={`alert ${alertStyle}`}>
      {instructions.title}
      <ul>
        {instructions.instructions.map((instruction) => (
          <li>{instruction}</li>
        ))}
      </ul>
    </div>
  );
};

const CreateOverlay = ({
  onUndo,
  onStartOver,
  onClearCurrentSelection,
  geoJson,
  mode,
  todayDateServer,
}: {
  onUndo(): void;
  onStartOver(): void;
  onClearCurrentSelection(): void;
  geoJson: any;
  mode: MODE;
  todayDateServer:Date|undefined;
}) => {
  const navigate = useNavigate();
  const { formState, updateForm } = useForm(GEOFENCE_FORM_FIELDS);
  const [isCreatingFence, setIsCreatingFence] = useState(false);
  

  const onComplete = () => navigate('/geofence');

  const submitCreateFormAsync = async (e: any) => {
    e.preventDefault();
    setIsCreatingFence(true);
    try {
      const { message } = await SpringClient.post(`/admin/geofences`, {
        ...formState,
        geometry: geoJson,
      });
      if (message?.messageType === Message.Type.Success) {
        onComplete();
      }
    } catch (e) {
      console.error('Error creating geofence', e);
    }
    setIsCreatingFence(false);
  };

  return (
    <MapOverlay>
      <div className="border-bottom mb-4">
        <Instructions mode={mode} />
        <div>
          <div className={'btn btn-secondary btn-sm mr-2 mb-2'} onClick={onUndo}>
            Undo
          </div>
          <div className={'btn btn-warning btn-sm mr-2 mb-2'} onClick={onClearCurrentSelection}>
            Clear Current Selection
          </div>
          <div className={'btn btn-info btn-sm mr-2 mb-2'} onClick={onStartOver}>
            Start Over
          </div>
          <div className={'btn btn-danger btn-sm mb-2'} onClick={onComplete}>
            Cancel
          </div>
        </div>
      </div>

      <form onSubmit={(e) => submitCreateFormAsync(e)}>
        <h5>New Geofence</h5>
        {GEOFENCE_FORM_FIELDS.map((formField) => {
          if (formField.name == 'discount' && formState.type != 'parking discount'){
            return;
          }
          if ((formField.name == 'daysActive'|| 
                formField.name == 'startActive' ||
                formField.name == 'endActive'  )&& formState.isActive != 'custom'){
              return;
            }
        return (
          <FormField
            key={formField.name}
            disabled={isCreatingFence}
            formField={formField}
            updateForm={updateForm}
            formState={formState}
          />
        )})}
        <label>GeoJSON</label>
        <div className="bg-light">
          <ReactJson collapsed={true} src={geoJson} />
        </div>
        <SpringButton type="submit" className="btn btn-primary my-4" isLoading={isCreatingFence}>
          Create
        </SpringButton>
        <div style={{marginBottom:5}}>
          <div>Current Server time is:</div>
            {todayDateServer}
        </div>
      </form>
    </MapOverlay>
  );
};

const transformCoordinatesToGeojsonShape = (coordinates: ICoordinate[][]) => {
  if (coordinates[0].length === 0) {
    return [];
  }

  let points = coordinates.map((shape) =>
    shape.map(({ latitude, longitude }) => [longitude, latitude])
  );

  const polygon = turf.polygon(points);
  return turf.rewind(polygon).geometry?.coordinates;
};

export const CreateGeofence = () => {
  const defaultCenter = useDefaultCenter();
  const { geofences,todayDateServer } = useAdminGeofences();

  const [mode, setMode] = useState<MODE>(MODE.ADDING_SHAPE);
  const [points, setPoints] = useState<ICoordinate[]>([]);
  const { getHomeCities, selectedHomeCityId } = useContext(HomeCityContext);

  const [shape, setShape] = useState<ICoordinate[]>([]);
  const [holes, setHoles] = useState<ICoordinate[][]>([]);

  const currentColor = mode === MODE.ADDING_SHAPE ? 'blue' : 'purple';

  let defaultCenterCity:any =defaultCenter;
  let allHomecities = getHomeCities()
  allHomecities?.map((city)=>{
    if (city.id==selectedHomeCityId){
      defaultCenterCity = {
        lat:city.latitude,
        lng:city.longitude
      }
    }
  })
  const geoJson = {
    type: 'Polygon',
    coordinates: transformCoordinatesToGeojsonShape([shape, ...holes]),
  };

  const startOver = () => {
    setPoints([]);
    setShape([]);
    setHoles([]);
    setMode(MODE.ADDING_SHAPE);
  };

  const closeShape = () => {
    if (points.length < 3) {
      window.alert('must select at least 3 points');
      return;
    }
    if (mode === MODE.ADDING_SHAPE) {
      setPoints([]);
      setShape([...points, points[0]]);
      setMode(MODE.ADDING_HOLE);
    } else if (mode === MODE.ADDING_HOLE) {
      setPoints([]);
      setHoles([...holes, [...points, points[0]]]);
    }
  };

  const undo = () => {
    setPoints(points.slice(0, -1));
  };

  const clearCurrentSelection = () => {
    setPoints([]);
  };

  return (
    <div className="w-100 h-100" style={{ position: 'relative' }}>
      <MapComponent
        containerHeight={'95vh'}
        defaultZoom={13}
        defaultCenter={defaultCenterCity}
        clickableIcons={false}
        onClick={({ latLng }: any) => {
          const latitude = latLng.lat();
          const longitude = latLng.lng();
          setPoints([...points, { latitude, longitude }]);
        }}
      >
        {geofences.map(({ id, geometry, type }) => {
          return <Geofence key={id} geometry={geometry} type={type} />;
        })}

        {points.map(({ latitude, longitude }, index) => {
          const isStartingPoint = index === 0;
          return (
            <Marker
              icon={`http://maps.google.com/mapfiles/ms/icons/${
                isStartingPoint ? 'green' : currentColor
              }-dot.png`}
              position={{ lat: latitude, lng: longitude }}
              onClick={isStartingPoint ? closeShape : undefined}
            />
          );
        })}

        <Polyline
          options={{ strokeColor: currentColor }}
          path={points.map(({ latitude, longitude }) => ({
            lat: latitude,
            lng: longitude,
          }))}
        />

        {shape && shape.length > 0 && (
          <Polyline
            options={{ strokeColor: 'blue' }}
            path={[...shape, shape[0]].map(({ latitude, longitude }) => ({
              lat: latitude,
              lng: longitude,
            }))}
          />
        )}

        {holes &&
          holes.length > 0 &&
          holes.map((hole) => (
            <Polyline
              options={{ strokeColor: 'purple' }}
              path={[...hole, hole[0]].map(({ latitude, longitude }) => ({
                lat: latitude,
                lng: longitude,
              }))}
            />
          ))}
      </MapComponent>
      <CreateOverlay
        mode={mode}
        onUndo={undo}
        onStartOver={startOver}
        onClearCurrentSelection={clearCurrentSelection}
        geoJson={geoJson}
        todayDateServer={todayDateServer}
      />
    </div>
  );
};
