import React, { useContext, useEffect, useState } from 'react';
import { IFormField, INPUT_TYPE } from 'boaz-bikes-forms';
import { SpringClient, Schema } from 'boaz-bikes-types';
import { useNavigate } from '../Router';
import { showMessage } from '../ToastProvider';
import { SimpleForm } from 'boaz-bikes-forms';
import { LoadingView } from '../components/LoadingSpinner';
import { PageContainer } from '../components/PageContainer';
import { useParams } from 'react-router';
import { useSpringFetch } from '../firebase';
import { HomeCityContext } from '../home-city/HomeCityContext';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title:{
        width:'100%',
        textAlign:'center',
        fontSize:'30px'
    },
    labels:{
        marginLeft:9
    },
    column:{
        width:'50%',
        margin:'0 auto', 
        marginBottom:10
    },
    inputs:{
        width:'100%',
        borderWidth:0.2,
        margin:7,
        borderRadius:5,
        marginBottom:7,
    },
    submit:{
        width:'20%',
        height:'100%',
        backgroundColor:'#007bff',
        borderRadius:5,
        color:'white',
        borderWidth:0.2,
        borderColor:'white',
        marginLeft:10,
    }
})
) 
export const CREATE_HOME_CITY_FIELDS: IFormField[] = [
    {
        label: 'Name of City',
        name: 'name',
        inputType: INPUT_TYPE.TEXT,
    },
    {
        label: 'Zip Codes (separated with commas)',
        name: 'zipCode',
        inputType: INPUT_TYPE.TEXT,
    },
    {
        label: 'Services Available',
        name: 'services',
        inputType: INPUT_TYPE.SELECT,
        options: Object.values(Schema.HomeCity.Services),
    },
    {
        label: 'Is Drop Off Open',
        name: 'isDropOffOpen',
        inputType: INPUT_TYPE.SELECT,
        options: [ 
            {label: 'False', value: false},
            {label: 'True', value: true}
        ]
    },
    {
        label: 'Latitude',
        name: 'latitude',
        inputType: INPUT_TYPE.TEXT,
      },
      {
        label: 'Longitude',
        name: 'longitude',
        inputType: INPUT_TYPE.TEXT,
      },
      {
        label: 'Is Parking Photo Active',
        name: 'parkingPhoto',
        inputType: INPUT_TYPE.SELECT,
        options: [
            {label: 'False', value: false},
            {label: 'True', value: true}
        ],
      },
      {
        label: 'Is Geozone Active',
        name: 'isGeozone',
        inputType: INPUT_TYPE.SELECT,
        options: [ 
            {label: 'False', value: false},
            {label: 'True', value: true}
        ]
    },
    {
        label: 'Is Verification Id Active',
        name: 'isVerificationId',
        inputType: INPUT_TYPE.SELECT,
        options: [ 
            {label: 'False', value: false},
            {label: 'True', value: true}
        ]
    },
    {
        label: 'New Stripe Active',
        name: 'newStripeActive',
        inputType: INPUT_TYPE.SELECT,
        options: [
            {label: 'No', value: false},
            {label: 'Yes', value: true}
        ]
    },
    {
        label: 'Stripe Public Key',
        name: 'stripePublicKey',
        inputType: INPUT_TYPE.TEXT,
    },
    {
        label: 'Stripe Private Key',
        name: 'stripePrivateKey',
        inputType: INPUT_TYPE.TEXT,
    },
    {
        label: 'Active',
        name: 'isActive',
        inputType: INPUT_TYPE.SELECT,
        options: [
            {label: 'No', value: false},
            {label: 'Yes', value: true}
        ]
    },
];

export const EditHomeCityForm = (props: any) => {
    const { refreshHomeCities} = useContext(HomeCityContext);
    const classes = useStyles();
    const [ nameCity, setNameCity ] = useState(props.homeCity.name)
    const [ zipCode, setZipCode ] = useState(props.homeCity.zipCode)
    const [ services, setServices ] = useState(props.homeCity.services)
    const [ isGeozone, setIsGeozone ] = useState(props.homeCity.isGeozone)
    const [ isDropOffOpen, setIsDropOffOpen ] = useState(props.homeCity.isDropOffOpen)
    const [ latitude, setLatitude ] = useState(props.homeCity.latitude)
    const [ longitude, setLongitude ] = useState(props.homeCity.longitude)
    const [ isActive, setIsActive ] = useState(props.homeCity.isActive)
    const [ parkingPhoto, setParkingPhoto ] = useState(props.homeCity.parkingPhoto)
    const [ newStripeActive, setNewStripeActive ] = useState(props.homeCity.newStripeActive)
    const [ stripePrivateKey, setStripePrivateKey ] = useState(props.homeCity.stripePrivateKey)
    const [ stripePublicKey, setStripePublicKey ] = useState(props.homeCity.stripePublicKey)
    const [ isVerificationId,setIsVerificationID] = useState(props.homeCity.isVerificationId)
    const [isSubscriptionActive, setIsSubscriptionActive] = useState(props.homeCity.isSubscriptionActive)
    const navigate = useNavigate();
    const submitFormAsync = async () => {
        try {
            const formState = {
                nameCity,
                zipCode,
                services,
                isGeozone,
                isDropOffOpen,
                latitude,
                longitude,
                isActive,
                parkingPhoto,    
                newStripeActive,
                stripePrivateKey,
                stripePublicKey,
                isVerificationId,
                isSubscriptionActive
            }
            if ((newStripeActive == true || newStripeActive == 'true' )&& (!stripePrivateKey || !stripePublicKey || stripePrivateKey==''||stripePublicKey=='')){
                alert('Private key or/and public key are empty')
                throw Error('Private key or/and public key are empty')
            }
            const { message, promoCode } = await SpringClient.post(`/admin/homecity/${props.homeCity.id}`, formState);
            refreshHomeCities(promoCode);
            showMessage(message);

        } catch (e) {
            console.error('Error creating home city', e);
        }
    };
   
    return (
        
        <PageContainer>
            <div className={classes.title} >Update Home City</div>     
                <div className={classes.column}>
                    <div>
                        <label className={classes.labels} >  Name of City </label>
                    </div>
                    <div >
                        <input className={classes.inputs} value={nameCity} onChange={e => setNameCity(e.target.value)} />
                    </div>
                    <div>
                        <label className={classes.labels}>  Zip Codes (separated with commas)</label>
                    </div>
                    <div>
                        <input className={classes.inputs} value={zipCode} onChange={e => setZipCode(e.target.value)} />
                    </div>
                    <div>
                        <label className={classes.labels}> Services Availables</label>
                    </div>
                    <div>
                    <select className={classes.inputs} value={services} onChange={e => setServices(e.target.value)} >
                            <option value={Schema.HomeCity.Services.ALL}>All</option>
                            <option value={Schema.HomeCity.Services.STREET}>Street</option>
                            <option value={Schema.HomeCity.Services.DROPOFF}>Drop Off</option>
                    </select>
                    </div>
                    <div className={classes.inputs} >
                        <label className={classes.labels}> Is Drop Off Open</label>
                    </div>
                    <div>
                        <select className={classes.inputs} value={isDropOffOpen} onChange={e => setIsDropOffOpen(e.target.value)} >
                            <option value={'true'}>Yes</option>
                            <option value={'false'}>No</option>
                        </select>
                    </div>
                    <div>
                        <label className={classes.labels}>  Latitude </label>
                    </div>
                    <div>
                        <input className={classes.inputs} value={latitude} onChange={e => setLatitude(e.target.value)} />
                    </div>
                    <div>
                        <label className={classes.labels}>
                            Longitude
                        </label>
                    </div>
                    <div>
                        <input className={classes.inputs} value={longitude} onChange={e => setLongitude(e.target.value)} />
                    </div>
                    <div>
                        <label className={classes.labels}>
                            Is Parking Photo Active
                        </label>
                    </div>
                    <div>
                    <select className={classes.inputs} value={parkingPhoto} onChange={e => setParkingPhoto(e.target.value)} >
                            <option value={'true'}>Yes</option>
                            <option value={'false'}>No</option>
                    </select>
                    </div>
                    <div>
                        <label className={classes.labels}>
                            Is Geozone Active
                        </label>
                    </div>
                    <div>
                        <select className={classes.inputs} value={isGeozone} onChange={e => setIsGeozone(e.target.value)} >
                            <option value={'true'}>Yes</option>
                            <option value={'false'}>No</option>
                        </select>
                    </div>
                    <div>
                        <label className={classes.labels}>
                            Is Verification Id Active
                        </label>
                    </div>
                    <div>
                        <select className={classes.inputs} value={isVerificationId} onChange={e => setIsVerificationID(e.target.value)} >
                            <option value={'true'}>Yes</option>
                            <option value={'false'}>No</option>
                        </select>
                    </div>
                    <div>
                        <label className={classes.labels}>
                            Is Subscription Active
                        </label>
                    </div>
                    <div>
                        <select className={classes.inputs} value={isSubscriptionActive} onChange={e => setIsSubscriptionActive(e.target.value)} >
                            <option value={'false'}>No</option>
                            <option value={'true'}>Yes</option>
                        </select>
                    </div>
                    <div>
                        <label className={classes.labels}>
                            New Stripe Active
                        </label>
                    </div>
                    <div>
                        <select className={classes.inputs} value={newStripeActive} onChange={e => setNewStripeActive(e.target.value)} >
                            <option value={'false'}>No</option>
                            <option value={'true'}>Yes</option>
                        </select>
                    </div>
                    <div className={classes.inputs} hidden={newStripeActive=='false'} >
                        <label className={classes.labels}>
                            Stripe Public Key
                        </label>
                    </div>
                    <div hidden={newStripeActive=='false'}>
                        <input className={classes.inputs} value={stripePublicKey} onChange={e => setStripePublicKey(e.target.value)} />
                    </div>
                    <div hidden={newStripeActive=='false'} >
                        <label className={classes.labels}>
                            Stripe Private Key
                        </label>
                    </div>
                    <div hidden={newStripeActive=='false'}>
                        <input className={classes.inputs} value={stripePrivateKey} onChange={e => setStripePrivateKey(e.target.value)} />
                    </div>
                    <div >
                        <label className={classes.labels}>
                                Active
                        </label>
                    </div>
                    <div>
                        <select className={classes.inputs} value={isActive} onChange={e => setIsActive(e.target.value)} >
                            <option value={'true'}>Yes</option>
                            <option value={'false'}>No</option>
                        </select>
                    </div>
                    <div style={{width:'100%',height:'100%'}}>
                        <button className={classes.submit} onClick={submitFormAsync} >
                            Submit
                        </button>
                    </div>
                </div>
        </PageContainer>
    );
};





export const EditHomeCity = () => {
    const { homeCityId } = useParams();

    const { data, isLoading } = useSpringFetch<{ promoCode: Schema.HomeCity.Admin}>(
        'get',
        `/admin/homecity/${homeCityId}`,
        {}
    );
    // console.log("==232",data);

    if (isLoading) {
        return <LoadingView />;
    }

    if (data == null) {
        return <div className="alert alert-warning">Home City not found</div>;
    }

    const { promoCode } = data;
    console.log('promoCode', promoCode);
    return <EditHomeCityForm homeCity={promoCode} />;
};