import { Schema } from 'boaz-bikes-types';
import React, {useContext} from 'react';
import { DateInfo } from '../components/DateInfo';
import { Table } from '../components/Table';
import { useSpringFetch } from '../firebase';
import { useNavigate } from '../Router';
import { Card } from '../components/Card';
import { PageContainer } from '../components/PageContainer';
import { Link } from 'react-router-dom';
import { HomeCityContext } from '../home-city/HomeCityContext';
import { FilterAdminAreas } from '../utils/FilterAdminAreas';
import { LoadingSpinner } from '../components/LoadingSpinner';

function DockList(
    { 
      adminArea,
      CityAdminId,
      adminAreaCity
    }:{
      adminArea:boolean,
      CityAdminId:string|null|undefined,
      adminAreaCity:string,
    } ) {
  const navigate = useNavigate();
  const { selectedHomeCityId, getHomeCities } = useContext(HomeCityContext);
  
  const { data, isLoading } = useSpringFetch<{
    docks: Schema.Dock.Admin[];
  }>('get', '/admin/docks', {
    homeCityId: adminArea? adminAreaCity : selectedHomeCityId,
  });

  if (!isLoading && data == null) {
    return <div>No Docks Available</div>;
  }
  const docks = data &&selectedHomeCityId && !adminArea &&  selectedHomeCityId!= 'All Home City' 
       ? data.docks.filter((dock)=>dock.homeCityId == selectedHomeCityId) :
       data &&selectedHomeCityId && !adminArea && selectedHomeCityId!= 'All Home City' && CityAdminId
       ?data.docks.filter((dock)=>dock.homeCityId == CityAdminId) :
       data ? data.docks : [];
  // mapping homeCities
  const allHomeCity = getHomeCities();
  const homeCityMap = new Map(allHomeCity?.map((homecity: any) => [homecity.id, homecity.name]))

  return (
    <div className="row">
      <div className="col">
        <Card.Container>
          <Table
            title={'All Docks'}
            rowsPerPage={100}
            isLoading={isLoading}
            // getRowUrl={(dock) => `/docks/${dock.id}`}
            onAddItem={() => navigate('/docks/create')}
            onAddCsv={() =>{ navigate('/docks/import-docks') }}
            columnRenderInfos={[
              { columnName: 'Display Name', attribute: 'displayName' },
              {
                columnName: 'Home City',
                attribute: 'homeCityId',
                renderFunc: ({ homeCityId }) => {
                  let homeName = homeCityMap.get(homeCityId);
                  return homeName || "";
                }
              },
              { columnName: 'Manufacturer ID', attribute: 'manufacturerId' },
              { columnName: 'Manufacturer', attribute: 'manufacturer' },
              { columnName: 'Total bays', attribute: 'numTotalBays' },
              { columnName: 'Available bays', attribute: 'numAvailableBays' },
              { columnName: 'Available vehicles', attribute: 'numAvailableVehicles' },
              {
                columnName: 'Active',
                attribute: 'isActive',
                shouldJsonifyValue: true,
              },
              {
                columnName: 'Operator',
                attribute: 'isOperator',
                shouldJsonifyValue: true,
              },
              {
                columnName: 'Created At',
                attribute: 'createdAt',
                renderFunc: ({ createdAt }) => <DateInfo date={createdAt} />,
              },
              {
                columnName: 'Edit',
                renderFunc: ({ id }) => (
                  <Link to={`/docks/${id}`}>Edit</Link>
                ),
              },
              {
                columnName: 'Is High Priority',
                attribute: 'highPriority',
                renderFunc: ({highPriority}) => highPriority? `${highPriority}`: 'false'
              },
              {
                columnName: 'User Parking Allowed',
                attribute: 'userParkingAllowed',
                renderFunc: ({userParkingAllowed}) => userParkingAllowed? `${userParkingAllowed}`: 'false'
              },
            ]}
            rows={docks}
          />
        </Card.Container>
      </div>
    </div>
  );
}

export function DockHome() {
  const {adminArea, homeCityAdmin:CityAdminId,adminAreaCity, isLoading} = FilterAdminAreas();

  if (isLoading) return <LoadingSpinner></LoadingSpinner>  
  return (
    <PageContainer>
      <DockList  adminArea={adminArea} CityAdminId={CityAdminId} adminAreaCity={adminAreaCity} />
    </PageContainer>
  );
}
