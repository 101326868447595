import { IconButton, Toolbar, Tooltip } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import { Schema } from 'boaz-bikes-types';
import moment from 'moment';
import React, { useState } from 'react';
import { Marker, Polyline } from 'react-google-maps';
import { MapComponent } from '../components/MapComponent';
import { Select } from '../components/Select';
import { useSpringFetch } from '../firebase';
import { Card } from '../components/Card';

function dateToString(daysBack: number = 0) {
  return moment()
    .subtract(daysBack * 24, 'hours')
    .toISOString();
}

export const UserPingMap = ({ userId }: { userId: string }) => {
  const [startAt, setStartAt] = useState<{ daysAgo: number; dateString: string }>({
    daysAgo: 1,
    dateString: dateToString(1),
  });
  const { data, isLoading, refreshData } = useSpringFetch<{
    userPings: Schema.UserPing.Admin[];
  }>('get', `/admin/user-pings/${userId}`, {
    startAt: startAt.dateString,
  });

  if (!isLoading && data == null) {
    return <div>Error fetching user pings</div>;
  }

  if (!data) {
    return <div>No user pings</div>;
  }

  const { userPings = [] } = data;

  const finishPing = userPings[0];
  const startPing = userPings[userPings.length - 1];
  const defaultCenter = finishPing
    ? { lat: finishPing.latitude, lng: finishPing.longitude }
    : { lat: 0, lng: 0 };

  return (
    <div>
      <Card.Container>
        <Card.Header title="User Pings" />
        <div className="card-body">
          <Toolbar>
            <div style={{ flex: 1 }} />
            <Select
              value={`${startAt.daysAgo}`}
              options={[
                { label: 'Today', value: '1' },
                { label: 'This Week', value: '7' },
                { label: 'This Month', value: '30' },
              ]}
              onChange={(value) => {
                const daysAgo = Number(value);
                setStartAt({ daysAgo, dateString: dateToString(daysAgo) });
              }}
            />
            <Tooltip title="refresh">
              <IconButton aria-label="refresh" onClick={refreshData}>
                <Refresh />
              </IconButton>
            </Tooltip>
          </Toolbar>
          <MapComponent defaultZoom={15} defaultCenter={defaultCenter}>
            {finishPing && (
              <Marker
                position={{ lat: finishPing.latitude, lng: finishPing.longitude }}
                icon={`http://maps.google.com/mapfiles/ms/icons/red-dot.png`}
              />
            )}
            {startPing && (
              <Marker
                position={{ lat: startPing.latitude, lng: startPing.longitude }}
                icon={`http://maps.google.com/mapfiles/ms/icons/green-dot.png`}
              />
            )}
            <Polyline
              path={userPings.map(({ latitude, longitude }) => ({
                lat: latitude,
                lng: longitude,
              }))}
            />
          </MapComponent>
          <div>{'Green = Starting position'}</div>
        </div>
      </Card.Container>
    </div>
  );
};
