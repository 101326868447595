import React, { useState, useContext, useEffect } from 'react';
import XLSX from 'xlsx';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import MaterialTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { LoadingView } from '../components/LoadingSpinner';
import { Schema, SpringClient } from 'boaz-bikes-types';
import { useNavigate } from '../Router';
import { v4 as uuid } from 'uuid';
import { HomeCityContext } from '../home-city/HomeCityContext';
import { Message } from 'boaz-types';
import { showMessage } from '../ToastProvider';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      overflowX: 'auto',
    },
    table: {
      minWidth: 650,
    },
    row: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
      '&:hover': {
        textDecoration: 'none',
      },
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    toolbar: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    spacer: {
      flex: '1 1 100%',
    },
    actions: {
      color: theme.palette.text.secondary,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    title: {
      flex: '0 0 auto',
    },
  })
);

interface NewVehicles {
  Controller: string;
  QrCode: string;
  IotDeviceId: string;
  HomeCity: string;
  FleetId: string;
  Status: string;
  Longitude: string;
  Latitude: string;
  Battery: string;
}


export const CreateMultiples = () => {
  const [newVehicles, setNewVehicles] = useState<Schema.Vehicle.Admin[]>([])
  const classes = useStyles();
  const vehicleFields = ["Controller", "QRCODE", "	IOT Device ID (IMEI)", "Home City", "Type", "Fleet ID"]
  const [isEmpty, setIsEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();
  const { getHomeCities, selectedHomeCityId, setSelectedHomeCityId } = useContext(HomeCityContext);
  const availableHomeCityIds = getHomeCities();

  const checkHomeCitiesId = (homeCityId: string) => {
    if (availableHomeCityIds?.some((elem) => elem.name == homeCityId)) {
      return true
    } else return false
  }
  const validateFields = (currentFields: any) => {
    let res = true;
    //check City id
    res = checkHomeCitiesId(currentFields[3])
    if (!res) return "City id invalid"

    //iotController
    res = Object.values(Schema.Vehicle.IotController).includes(currentFields[0]);
    if (!res) return "IotController invalid"
    //qrcode
    res = (currentFields[1].toString().length == 7)
    if (!res) return "QrCode invalid"
    //IotDeviceID
    // res = (currentFields[2].toString().length == 14) 
    // if (!res) return "IotDeviceId invalid"

    return res
  }

  const getCSVData = async (file: any) => {
    setIsEmpty(true);
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = e => {
      /* Parse data */
      const bstr = e.target!.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array", raw: true });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      /* Update state */
      const d = new Date();
      let date = d.getHours() + ":" + d.getMinutes() + ", " + d.toDateString();
      let today = date.toString()
      try {
        const jsonParser = (data: string) => JSON.parse(data);
        let CSVReady = jsonParser(JSON.stringify(data));
        let newVehicle: Schema.Vehicle.Admin;
        let ok;
        for (let i = 1; i < CSVReady.length; i++) {
          ok = validateFields(CSVReady[i]);
          if (ok == true) {
            newVehicle = {
              id: uuid(),
              companyId: "boaz-bikes",
              createdAt: today,
              lastPingedAt: null,
              lastRentalAt: null,
              lastMaintenanceReportAt: null,
              iotController: CSVReady[i][0],
              qrCode: CSVReady[i][1].toString(),
              iotDeviceId: CSVReady[i][2].toString(),
              homeCityId: CSVReady[i][3],
              fleetId: CSVReady[i][5] ? CSVReady[i][5].toString() : null,
              status: Schema.Vehicle.Status.ACTIVE,
              longitude: 0,
              latitude: 0,
              batteryPercent: 0,
              updatedAt: today,
              currentRentalId: null,
              isCharging: null,
              isUnlocked: null,
              deliveryStatus: Schema.Vehicle.DeliveryStatus.AVAILABLE,
              dockId: null,
              currentDriverId: null,
              geofenceSpeedMode: null,
              bikeOwnerId: null,
              eventLocation: null,
              eventTime: null,
              eventTypes: 'trip_end',
              vehicleTypes: CSVReady[i][4] ? CSVReady[i][4] : 'scooter-seated',
              propulsionTypes: 'electric',
              accuracy: '5',
              isFallen: false,
              isFallenTime: null,
              unlockedBy: null,
              alert: null,
            }
          } else {
            setIsEmpty(true)
            console.error(ok)
            const err = ok.toString();
            showMessage(Message.create(err, Message.Type.Danger));
          }
          // setItems(items => [...items, 'New Item']);
          setNewVehicles(newVehicles => [...newVehicles, newVehicle])
          // newVehicles.push(newVehicle)
        }
      } catch (e) {
        console.log(e)
      }
      // let newVehicle
    };
    if (rABS) reader.readAsBinaryString(file[0]);
    else reader.readAsArrayBuffer(file[0]);
    setIsEmpty(false)

  }
  console.log(newVehicles)
  const postVehicles = async () => {

    setIsLoading(true);
    try {
      await SpringClient.post('/admin/create-multiple-vehicles', newVehicles);
      navigate('/vehicles');
    } catch (e) {
      console.error('Error creating vehicle', e);
      setIsLoading(false);
    }
  }


  if (isLoading) return <LoadingView />


  return (
    <div>
      <div>
        <input
          type="file"
          onChange={(e) => getCSVData(e.target.files)} />
      </div>
      {!isEmpty ? <div className={classes.tableContainer}>
        <MaterialTable className={classes.table}>
          <TableHead>
            <TableRow>
              {vehicleFields.map((field) => (
                <TableCell key={field} >{field}</TableCell>

              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {(newVehicles && newVehicles[0] != undefined) ? newVehicles.map((currentVehicle, index) => (
              <TableRow key={index}>
                <TableCell> {currentVehicle.iotController} </TableCell>
                <TableCell>{currentVehicle.qrCode}</TableCell>
                <TableCell>{currentVehicle.iotDeviceId}</TableCell>
                <TableCell>{currentVehicle.homeCityId}</TableCell>
                <TableCell>{currentVehicle.vehicleTypes}</TableCell>
                <TableCell>{currentVehicle.fleetId}</TableCell>
              </TableRow>
            ))
              : <></>}
          </TableBody>
        </MaterialTable>
      </div>
        : <div></div>}
      {newVehicles.length > 0 && !isEmpty ?
        <div style={{ flexDirection: "row", width: 600 }}>
          <button
            type="button"
            className="btn btn-primary"
            style={{ flexDirection: 'row', marginRight: 24, marginTop: 10 }}
            onClick={() => {
              postVehicles()

            }}
          >
            Confirm
          </button>
          <button
            type="button"
            className="btn btn-primary"
            style={{ flexDirection: 'row', marginRight: 24, marginTop: 10 }}
            onClick={() => {
              setNewVehicles([])
              setIsEmpty(true)
            }}
          >
            Remove
          </button>
        </div>
        : <div></div>}

    </div>
  );
};
