import { Schema } from 'boaz-bikes-types';
import _ from 'lodash';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { DatePeriodProvider } from '../components/DatePeriodProvider';
import { LoadingSpinner, LoadingView } from '../components/LoadingSpinner';
import { useSpringFetch } from '../firebase';
import { FleetContext } from '../vehicles/FleetContext';
import { IDataPoint, LineGraph } from "../components/LineGraph";
import { Colors } from '../components/Colors';
import { BarGraph } from '../components/BarGraph';
import { ViewStream as Row, ViewModule as Grid, Subscriptions } from '@material-ui/icons';
import { CSVLink, CSVDownload } from "react-csv";
import { HomeCityContext } from '../home-city/HomeCityContext';
import { Card } from "../components/Card";
import { getRentalStats } from "../utils/rentals";
import { FilterAdminAreas } from '../utils/FilterAdminAreas';


const GraphContainer = ({ title, children }: { title: string; children: any }) => {
  return (
    <div className="card my-2">
      <div className="card-header">{title}</div>
      <div className="card-body">{children}</div>
    </div>
  );
};

const AnalyticsRow = ({
  summaryLabel,
  summaryValue,
  data,
}: {
  summaryLabel: string;
  summaryValue: string;
  data: IDataPoint[];
}) => {
  return (
    <div className="row mb-2 border-bottom">
      <div className="col-lg-2 col-12">
        <div className="text-muted">{summaryLabel}</div>
        <h4 className="text-primary">{summaryValue}</h4>
      </div>
      <div className="col-lg-10 col-12" style={{ overflow: "visible" }}>
        <LineGraph
          data={data}
          height={80}
          includeXAxis={false}
          includeYAxis={false}
          includeXTicks={false}
          includeYTicks={false}
        />
      </div>
    </div>
  );
};

const RentalAnalyticsRows = ({
  startAt,
  endAt,
  adminArea,
  adminAreaCity,
}: {
  startAt: Date;
  endAt: Date;
  adminArea: boolean;
  adminAreaCity: string;

}) => {
  const { selectedFleetId } = useContext(FleetContext);
  const { selectedHomeCityId } = useContext(HomeCityContext);

  const { data: rentalData } = useSpringFetch<{
    rentals: Schema.Rental.Admin[];
  }>("get", "/admin/rentals", {
    homeCityId: adminArea ? adminAreaCity : selectedHomeCityId,
    startAt,
    endAt,
  });


  const { data: dataPasses, isLoading: isLoadingPasses } = useSpringFetch<{
    userPasses: any[];
  }>('get', '/admin/get-all-users-passes');


  const rentals = rentalData
    ? filterByDateAndFleet(
      rentalData.rentals.reverse(),
      startAt,
      endAt,
      selectedFleetId,
      selectedHomeCityId
    )
    : [];
  const { data: userData } = useSpringFetch<{
    users: Schema.User.Admin[];
  }>("get", "/admin/users", {
    startAt: startAt,
    endAt: endAt,
  });

  const users = userData
    ? filterByDateAndFleet(
      userData.users,
      startAt,
      endAt,
      selectedFleetId,
      selectedHomeCityId
    )
    : [];
  const passes = filterByDateAndFleetPasses(dataPasses?.userPasses || [], startAt, endAt, selectedFleetId);
  const rentalsAux = rentals.concat(passes)
  const { dollarsSpent } = getRentalStats(rentalsAux);
  const newUsersByDay = getNewUserCountByDay(users, startAt, endAt);

  const newUserCount = _.sumBy(newUsersByDay, (dataPoint) => dataPoint.y);
  let passesFiltered = filterPassesByCitySelected(passes, selectedHomeCityId);

  if (isLoadingPasses) return <LoadingSpinner></LoadingSpinner>
  return (
    <>
      <AnalyticsRow
        summaryLabel="Total Rentals"
        summaryValue={`${rentals.length}`}
        data={getRidesByDay(rentals, startAt, endAt)}
      />
      <AnalyticsRow
        summaryLabel="Net Revenue"
        summaryValue={`$${dollarsSpent}`}
        data={getRevenueByDay(rentals, startAt, endAt, passesFiltered)}
      />
      <AnalyticsRow
        summaryLabel="New Customers"
        summaryValue={`${newUserCount}`}
        data={getNewUserCountByDay(users, startAt, endAt)}
      />
    </>
  );
};

const RentalAnalyticsCard = ({
  adminArea,
  adminAreaCity,
}: {
  adminArea: boolean,
  adminAreaCity: string,
}) => {
  const defaultStart = moment().subtract(7, "day").toDate();
  const defaultEnd = moment().toDate();


  return (
    <div className="row mb-4">
      <div className="col">
        <Card.Container>
          <Card.Header title="Rental Analytics" />
          <div className="card-body">
            <DatePeriodProvider
              initialStart={defaultStart.toISOString()}
              initialEnd={defaultEnd.toISOString()}
              render={({ start, end }) => {
                return (
                  <RentalAnalyticsRows
                    adminArea={adminArea}
                    adminAreaCity={adminAreaCity}
                    startAt={start || defaultStart}
                    endAt={end || defaultEnd}
                  />
                );
              }}
            />
          </div>
        </Card.Container>
      </div>
    </div>
  );
};

const getAllDatesWithin = (startAt: Date, endAt: Date) => {
  const dates = [];
  const endDate = moment(endAt).startOf('day');
  const currentDate = moment(startAt).startOf('day');
  while (currentDate.isSameOrBefore(endDate)) {
    dates.push(currentDate.format('YYYY-MM-DD'));
    currentDate.add(1, 'day');
  }
  return dates;
};

const getDateToRentals = (startAt: Date, endAt: Date, rentals?: Schema.Rental.Admin[], passesFiltered?: any) => {


  const dateToRentals: { [date: string]: any } = {};
  getAllDatesWithin(startAt, endAt).forEach((date) => {
    dateToRentals[date] = [];
  });
  let dateToString;
  rentals && rentals.forEach((rental) => {
    dateToString = moment(rental.startTime).format('YYYY-MM-DD');

    const rentalList = dateToRentals[dateToString];

    if (rentalList) {
      rentalList.push(rental);
    } else {
      dateToRentals[dateToString] = [rental];
    }
  });
  passesFiltered && passesFiltered.forEach((pass: any) => {
    const timestamp = pass.current_period_start * 1000;
    dateToString = moment(timestamp).format('YYYY-MM-DD');
    const pasesList = dateToRentals[dateToString];

    if (pasesList) {
      pasesList.push(pass);
    } else {
      dateToRentals[dateToString] = [pass];
    }
  });

  const dateRentalPairs = Object.entries(dateToRentals);
  dateRentalPairs.sort((a, b) => {
    const dateA = new Date(a[0]);
    const dateB = new Date(b[0]);

    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  });
  const sortedDateToRentals: { [date: string]: any } = {};
  dateRentalPairs.forEach(([date, rental]) => {
    sortedDateToRentals[date] = rental;
  });

  return sortedDateToRentals;
};

export const getRevenueByDay = (rentals: any, startAt: Date, endAt: Date, passesFiltered?: any) => {

  const dateToRentals = getDateToRentals(startAt, endAt, rentals, passesFiltered);

  return _.map(dateToRentals, (rentals, dateString) => {
    // check if it's a rental or a sub
    return {
      x: moment(dateString).toDate(),
      y: rentals.reduce((totalRev: any, pass: any) => totalRev + (pass.durationInMonths == 4 ? 100 : pass.durationInMonths == 1 ? 30 : pass.amountCentsCharged / 100 || 0), 0),
    };

    // return {
    //   x: moment(dateString).toDate(),
    //   y: rentals.reduce((totalRev:any, rental:any) => totalRev + (rental.amountCentsCharged || 0), 0) / 100,
    // };
  });

};

// const getDateToPasses = (passes:any, startAt: Date, endAt: Date) => {
//   const dateToPasses: { [date: string]: Schema.Rental.Admin[] } = {};
//   getAllDatesWithin(startAt, endAt).forEach((date) => {
//     dateToPasses[date] = [];
//   });

//   passes.forEach((pass:any) => {
//     const dateToString = moment(pass.current_period_start).format('YYYY-MM-DD');

//     const passList = dateToPasses[dateToString];

//     if (passList) {
//       passList.push(pass);
//     } else {
//       dateToPasses[dateToString] = [pass];
//     }
//   });

//   return dateToPasses;
// };

// export const getRevenueByDayPasses = (passes:any, startAt: Date, endAt: Date) => {
//   const dateToPasses = getDateToPasses(passes, startAt, endAt);

//   return _.map(dateToPasses, (passes, dateString) => {
//     return {
//       x: moment(dateString).toDate(),
//       y: passes.reduce((totalRev, pass:any) => totalRev + (pass.durationInMonths == 4 ? 100 : pass.durationInMonths == 1 ? 30 : 0), 0) / 100,
//     };
//   });
// };

const getDateToSubscriptionsCreatedAt = (startAt: Date, endAt: Date, subscriptions?: any) => {


  const dateToSubscription: { [date: string]: any } = {};
  getAllDatesWithin(startAt, endAt).forEach((date) => {
    dateToSubscription[date] = [];
  });
  let dateToString;
  subscriptions && subscriptions.forEach((pass: any) => {
    const timestamp = pass.created_at * 1000;
    dateToString = moment(timestamp).format('YYYY-MM-DD');
    const pasesList = dateToSubscription[dateToString];

    if (pasesList) {
      pasesList.push(pass);
    } else {
      dateToSubscription[dateToString] = [pass];
    }
  });
  const dateSubscriptionPairs = Object.entries(dateToSubscription);
  dateSubscriptionPairs.sort((a, b) => {
    const dateA = new Date(a[0]);
    const dateB = new Date(b[0]);

    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  });
  const sortedDateToRentals: { [date: string]: any } = {};
  dateSubscriptionPairs.forEach(([date, rental]) => {
    sortedDateToRentals[date] = rental;
  });

  return sortedDateToRentals;
}

const getDateToSubscriptionsCurrentPeriod = (startAt: Date, endAt: Date, subscriptions?: any) => {


  const dateToSubscription: { [date: string]: any } = {};
  getAllDatesWithin(startAt, endAt).forEach((date) => {
    dateToSubscription[date] = [];
  });
  let dateToString;
  subscriptions && subscriptions.forEach((pass: any) => {
    const timestamp = pass.current_period_start * 1000;
    dateToString = moment(timestamp).format('YYYY-MM-DD');
    const pasesList = dateToSubscription[dateToString];

    if (pasesList) {
      pasesList.push(pass);
    } else {
      dateToSubscription[dateToString] = [pass];
    }
  });

  const dateSubscriptionPairs = Object.entries(dateToSubscription);
  dateSubscriptionPairs.sort((a, b) => {
    const dateA = new Date(a[0]);
    const dateB = new Date(b[0]);

    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  });
  const sortedDateToRentals: { [date: string]: any } = {};
  dateSubscriptionPairs.forEach(([date, rental]) => {
    sortedDateToRentals[date] = rental;
  });

  return sortedDateToRentals;
};

export const getRevenueByDaySubscriptions = (startAt: Date, endAt: Date, subscriptions?: any) => {

  const dateToRentals = getDateToSubscriptionsCurrentPeriod(startAt, endAt, subscriptions);
  return _.map(dateToRentals, (rentals, dateString) => {

    return {
      x: moment(dateString).toDate(),
      y: rentals.reduce((totalRev: any, pass: any) => totalRev + (pass.passType == 'monthly' || pass.durationInMonths == 1 ? 30 : pass.passType == 'four-monthly' || pass.durationInMonths == 4 ? 100 : pass.amountCentsCharged / 100 || 0), 0),
    };
  });

};

export const DailyRevenueSubscriptions = ({
  subscriptions,
  startAt,
  endAt,
}: {
  subscriptions: any
  startAt: Date;
  endAt: Date;
}) => {
  const { selectedHomeCityId } = useContext(HomeCityContext);
  let passesFiltered = filterPassesByCitySelected(subscriptions, selectedHomeCityId);
  const chartData = getRevenueByDaySubscriptions(startAt, endAt, passesFiltered);
  return (
    <>
      <GraphContainer title={'Daily Revenue Subscriptions'}>
        <LineGraph data={chartData} color={Colors.SUCCESS} />
      </GraphContainer>
      <CSVLink
        data={chartData}
        filename={"daily-revenue-subscriptions.csv"}
        className="btn btn-primary"
        target="_blank"
      >
        Export Daily Revenue Subscriptions
      </CSVLink>
    </>
  );
};

export const DailyRevenue = ({
  rentals,
  startAt,
  endAt,
  passes
}: {
  rentals: Schema.Rental.Admin[];
  startAt: Date;
  endAt: Date;
  passes: any
}) => {
  const { selectedHomeCityId } = useContext(HomeCityContext);
  let rentalsFiltered = filterRentalsByCitySelected(rentals, selectedHomeCityId);
  let passesFiltered = filterPassesByCitySelected(passes, selectedHomeCityId);
  const chartData = getRevenueByDay(rentalsFiltered, startAt, endAt, passesFiltered);
  return (
    <>
      <GraphContainer title={'Daily Revenue'}>
        <LineGraph data={chartData} color={Colors.SUCCESS} />
      </GraphContainer>
      <CSVLink
        data={chartData}
        filename={"daily-revenue.csv"}
        className="btn btn-primary"
        target="_blank"
      >
        Export Daily Revenue
      </CSVLink>
    </>
  );
};

export const getRidesByDay = (rentals: Schema.Rental.Admin[], startAt: Date, endAt: Date) => {
  const dateToRentals = getDateToRentals(startAt, endAt, rentals);
  return _.map(dateToRentals, (rentals, dateString) => {
    return {
      x: moment(dateString).format('YYYY-MM-DD'),
      y: rentals.length,
    };
  });
};

export const getSubscriptionsByDay = (subscriptions: any[], startAt: Date, endAt: Date) => {
  const dateToRentals = getDateToSubscriptionsCreatedAt(startAt, endAt, subscriptions);
  return _.map(dateToRentals, (rentals, dateString) => {
    return {
      x: moment(dateString).format('YYYY-MM-DD'),
      y: rentals.length,
    };
  });
};



export const DailySubscriptions = ({
  subscriptions,
  startAt,
  endAt,
}: {
  subscriptions: any[];
  startAt: Date;
  endAt: Date;
}) => {
  const { selectedHomeCityId } = useContext(HomeCityContext);
  let subscriptionsFiltered = filterPassesByCitySelected(subscriptions, selectedHomeCityId)
  const chartData = getSubscriptionsByDay(subscriptionsFiltered, startAt, endAt,);
  return (
    <>
      <GraphContainer title={'Daily New Subscriptions'}>
        <LineGraph data={chartData} />
      </GraphContainer>
      <CSVLink
        data={chartData}
        filename={"daily-subscriptions.csv"}
        className="btn btn-primary"
        target="_blank"
      >
        Export Daily Subscriptions
      </CSVLink>
    </>

  );
};
export const DailyRides = ({
  rentals,
  startAt,
  endAt,
}: {
  rentals: Schema.Rental.Admin[];
  startAt: Date;
  endAt: Date;
}) => {
  const { selectedHomeCityId } = useContext(HomeCityContext);
  let rentalsFiltered = filterRentalsByCitySelected(rentals, selectedHomeCityId)
  const chartData = getRidesByDay(rentalsFiltered, startAt, endAt);
  return (
    <>
      <GraphContainer title={'Daily Rides'}>
        <LineGraph data={chartData} />
      </GraphContainer>
      <CSVLink
        data={chartData}
        filename={"daily-rides.csv"}
        className="btn btn-primary"
        target="_blank"
      >
        Export Daily Rides
      </CSVLink>
    </>

  );
};

const getAverageRatingByDay = (rentals: Schema.Rental.Admin[], startAt: Date, endAt: Date) => {
  const dateToRentals = getDateToRentals(startAt, endAt, rentals);

  return _.map(dateToRentals, (rentals, dateString) => {
    const rentalsWithRatings = rentals.filter(({ rating }: { rating: any }) => Boolean(rating));

    let avgRating: number;
    if (rentalsWithRatings.length) {
      avgRating = _.sum(rentalsWithRatings.map(({ rating }: { rating: any }) => rating)) / rentalsWithRatings.length;
    } else {
      avgRating = 5;
    }

    return {
      x: moment(dateString).toDate(),
      y: avgRating,
    };
  });
};

export const AverageRideRatingByDay = ({
  rentals,
  startAt,
  endAt,
}: {
  rentals: Schema.Rental.Admin[];
  startAt: Date;
  endAt: Date;
}) => {
  const { selectedHomeCityId } = useContext(HomeCityContext);
  let rentalsFiltered = filterRentalsByCitySelected(rentals, selectedHomeCityId)
  const chartData = getAverageRatingByDay(rentalsFiltered, startAt, endAt);

  return (
    <>
      <GraphContainer title={'Daily Rating (avg)'}>
        <LineGraph data={chartData} />
      </GraphContainer>
      <CSVLink
        data={chartData}
        filename={"daily-rides.csv"}
        className="btn btn-primary"
        target="_blank"
      >
        Export Daily Rating (avg)
      </CSVLink>
    </>
  );
};

const getAverageRentalDurationByDay = (
  rentals: Schema.Rental.Admin[],
  startAt: Date,
  endAt: Date
) => {
  const dateToRentals = getDateToRentals(startAt, endAt, rentals);

  return _.map(dateToRentals, (rentals, dateString) => {
    const rentalsWithStartAndEnd = rentals.filter(({ startAt, endAt }: { startAt: any, endAt: any }) => startAt && endAt);

    let avgRentalDuration: number;
    if (rentalsWithStartAndEnd.length) {
      const durations = rentalsWithStartAndEnd
        .map(({ startAt, endAt }: { startAt: any, endAt: any }) => {
          return moment(endAt as string).diff(moment(startAt), 'minutes');
        })
        .filter((duration: any) => duration < 60 * 12);

      const totalDuration = _.sum(durations);

      avgRentalDuration = totalDuration / durations.length;
    } else {
      avgRentalDuration = 0;
    }

    return {
      x: moment(dateString).format('YYYY-MM-DD'),
      y: avgRentalDuration,
    };
  });
};

export const AverageRentalDurationByDay = ({
  rentals,
  startAt,
  endAt,
}: {
  rentals: Schema.Rental.Admin[];
  startAt: Date;
  endAt: Date;
}) => {
  const { selectedHomeCityId } = useContext(HomeCityContext);
  let rentalsFiltered = filterRentalsByCitySelected(rentals, selectedHomeCityId)
  const chartData = getAverageRentalDurationByDay(rentalsFiltered, startAt, endAt);

  return (
    <>
      <GraphContainer title={'Rental Duration Minutes (avg)'}>
        <LineGraph data={chartData} />
      </GraphContainer>
      <CSVLink
        data={chartData}
        filename={"rental-duration-minutes.csv"}
        className="btn btn-primary"
        target="_blank"
      >
        Export Daily Rental Duration
      </CSVLink>
    </>
  );
};

const getUsersRentingByDay = (rentals: Schema.Rental.Admin[], startAt: Date, endAt: Date) => {
  const dateToRentals = getDateToRentals(startAt, endAt, rentals);

  return _.map(dateToRentals, (rentals, dateString) => {
    const userIds = new Set();
    rentals.forEach(({ userId }: { userId: string }) => {
      userIds.add(userId);
    });

    return {
      x: moment(dateString).format('YYYY-MM-DD'),
      y: userIds.size,
    };
  });
};

const UsersRentingByDay = ({
  rentals,
  startAt,
  endAt,
}: {
  rentals: Schema.Rental.Admin[];
  startAt: Date;
  endAt: Date;
}) => {
  const { selectedHomeCityId } = useContext(HomeCityContext);
  let rentalsFiltered = filterRentalsByCitySelected(rentals, selectedHomeCityId)
  const chartData = getUsersRentingByDay(rentalsFiltered, startAt, endAt);
  const getTime = (date?: string) => {
    return date != null ? new Date(date).getTime() : 0;
  }
  const charDataShorted = chartData.sort((a, b) => getTime(a.x).valueOf() - getTime(b.x).valueOf())

  return (
    <>
      <GraphContainer title={'Daily Customers'}>
        <LineGraph data={charDataShorted} />
      </GraphContainer>
      <CSVLink
        data={chartData}
        filename={"daily-customer.csv"}
        className="btn btn-primary"
        target="_blank">
        Export Daily Customers
      </CSVLink>
    </>
  );
};

export const getNewUserCountByDay = (users: Schema.User.Admin[], startAt: Date, endAt: Date) => {
  const dateToUserCreated: { [date: string]: number } = {};
  getAllDatesWithin(startAt, endAt).forEach((date) => {
    dateToUserCreated[date] = 0;
  });

  users.forEach((user) => {
    const dateToString = moment(user.createdAt).format('YYYY-MM-DD');
    dateToUserCreated[dateToString] = (dateToUserCreated[dateToString] || 0) + 1;
  });

  return Object.entries(dateToUserCreated).map(([dateString, count]) => {
    return {
      x: moment(dateString).toDate(),
      y: count,
    };
  });
};
const filterUserByHomeCity = (users: Schema.User.Admin[], selectedHomeCityId: string | undefined) => {
  let res = users;
  if (selectedHomeCityId && selectedHomeCityId != 'All Home City') {
    res = users.filter((rental) => rental.homeCityId == selectedHomeCityId)
  }
  return res;

}

export const NewUserCountByDay = ({
  users,
  startAt,
  endAt,
}: {
  users: Schema.User.Admin[];
  startAt: Date;
  endAt: Date;
}) => {
  const { selectedHomeCityId } = useContext(HomeCityContext);
  let usersFiltered = filterUserByHomeCity(users, selectedHomeCityId)
  const chartData = getNewUserCountByDay(usersFiltered, startAt, endAt);
  const charDataShorted = chartData.sort((a, b) => a.x.valueOf() - b.x.valueOf())
  return (
    <>
      <GraphContainer title={'New Users'}>
        <LineGraph data={charDataShorted} />
      </GraphContainer>
      <CSVLink
        data={chartData}
        filename={"new-users.csv"}
        className="btn btn-primary"
        target="_blank"
      >
        Export New Users
      </CSVLink>
    </>
  );
};

const getRentalsPerScooterPerDay = (
  rentals: Schema.Rental.Admin[],
  vehicles: Schema.Vehicle.Admin[],
  startAt: Date,
  endAt: Date
) => {
  const dateToRentals = getDateToRentals(startAt, endAt, rentals);

  return _.map(dateToRentals, (rentals, dateString) => {
    // TODO: use vehicle pings to get a more accurate count
    const activeVehicleCount = vehicles.filter(
      (vehicle) => vehicle.lastPingedAt && moment(vehicle.lastPingedAt).isAfter(dateString)
    ).length;

    return {
      x: moment(dateString).format('YYYY-MM-DD'),
      y: rentals.length / activeVehicleCount,
      rentalCount: rentals.length,
      vehicleCount: activeVehicleCount,
    };
  });
};

export const RentalsPerScooterPerDay = ({
  rentals,
  vehicles,
  startAt,
  endAt,
}: {
  rentals: Schema.Rental.Admin[];
  vehicles: Schema.Vehicle.Admin[];
  startAt: Date;
  endAt: Date;
}) => {
  const { selectedHomeCityId } = useContext(HomeCityContext);
  let rentalsFiltered = filterRentalsByCitySelected(rentals, selectedHomeCityId)
  const chartData = getRentalsPerScooterPerDay(rentalsFiltered, vehicles, startAt, endAt);

  return (
    <>
      <GraphContainer title={'Rides Per Scooter'}>
        <LineGraph data={chartData} />
      </GraphContainer>
      <CSVLink
        data={chartData}
        filename={"rides-per-scooter.csv"}
        className="btn btn-primary"
        target="_blank"
      >
        Export Rides Per Scooter CSV
      </CSVLink>
    </>
  );
};

const getRentalCountByHour = (rentals: Schema.Rental.Admin[]) => {
  const rentalCountByHour: { [key: number]: number } = {};
  rentals.forEach((rental) => {
    const hour = moment(rental.startAt).hour();
    rentalCountByHour[hour] = (rentalCountByHour[hour] || 0) + 1;
  });
  return _.map(rentalCountByHour, (count, hour) => {
    return {
      x: hour,
      y: count,
    };
  });
};

export const RentalCountByHour = ({ rentals }: { rentals: Schema.Rental.Admin[] }) => {
  const { selectedHomeCityId } = useContext(HomeCityContext);
  let rentalsFiltered = filterRentalsByCitySelected(rentals, selectedHomeCityId)
  const chartData = getRentalCountByHour(rentalsFiltered);

  return (
    <>
      <GraphContainer title={'Rental Count by Hour'}>
        <BarGraph data={chartData} />
      </GraphContainer>
      <CSVLink
        data={chartData}
        filename={"rent-count-by-hour.csv"}
        className="btn btn-primary"
        target="_blank"
      >
        Export Rental Count by Hour
      </CSVLink>
    </>
  );
};

const containsPromo = (promoCodes: any[], promoCode: string | null) => {
  var i;
  for (i = 0; i < promoCodes.length; i++) {
    if (promoCodes[i].x == promoCode) {
      return i;
    }
  }
  return -1;
}
const getUsesByName = (userPromos: Schema.UserPromoCode.Admin[] | undefined, activePromoCode: Schema.PromoCode.Admin[] | undefined) => {
  let promoCodes: any[] = [];
  if (activePromoCode) {
    activePromoCode.forEach((code) => {
      if (code.isActive) {
        promoCodes.push({
          x: code.promoCode,
          y: 0
        })
      }
    })
  }
  if (userPromos) {
    userPromos.forEach((promo) => {
      if (promo.promoCode != null) {
        let index = containsPromo(promoCodes, promo.promoCode);
        if (index != -1) {
          promoCodes[index].y = promoCodes[index].y + 1
        }
      }
    })
  }
  return promoCodes;
};

const NumberOfUsesPromobyName = ({ userPromoCode, activePromoCode }: { userPromoCode: Schema.UserPromoCode.Admin[] | undefined, activePromoCode: Schema.PromoCode.Admin[] | undefined }) => {

  const { selectedHomeCityId } = useContext(HomeCityContext);
  const chartData = getUsesByName(userPromoCode, activePromoCode)

  return (
    <>
      <GraphContainer title={'Number of Uses for Promos'}>
        <BarGraph data={chartData} />
      </GraphContainer>
      <CSVLink
        data={chartData}
        filename={"rent-count-by-hour.csv"}
        className="btn btn-primary"
        target="_blank"
      >
        Export Number of Uses for Promos
      </CSVLink>
    </>
  );
};

const getSupportIssueCountByDay = (supportIssues: Schema.SupportIssue.Admin[]) => {
  const dateToIssueCount: { [date: string]: number } = {};

  supportIssues.forEach((issue) => {
    const dateToString = moment(issue.createdAt).format('YYYY-MM-DD');

    dateToIssueCount[dateToString] = (dateToIssueCount[dateToString] || 0) + 1;
  });

  return _.map(dateToIssueCount, (count, dateString) => {
    return {
      x: moment(dateString).toDate(),
      y: count,
    };
  });
};
const filterIssuesByHomeCity = (issues: Schema.SupportIssue.Admin[], selectedHomeCityId: string | undefined) => {
  let res = issues;
  if (selectedHomeCityId && selectedHomeCityId != 'All Home City') {
    res = issues.filter((issue) => issue.homeCityId == selectedHomeCityId)
  }
  return res;

}

export const SupportIssueCountByDay = ({ issues }: { issues: Schema.SupportIssue.Admin[] }) => {
  const { selectedHomeCityId } = useContext(HomeCityContext);
  let issuesFiltered = filterIssuesByHomeCity(issues, selectedHomeCityId)
  const chartData = getSupportIssueCountByDay(issuesFiltered);
  const charDataShorted = chartData.sort((a, b) => a.x.valueOf() - b.x.valueOf())
  return (
    <>
      <GraphContainer title={'Support Issue Count'}>
        <BarGraph data={charDataShorted} color={Colors.DANGER} />
      </GraphContainer>
      <CSVLink
        data={chartData}
        filename={"support-issue-count.csv"}
        className="btn btn-primary"
        target="_blank"
      >
        Export Support Issue Count
      </CSVLink>
    </>
  );
};
const getCityName = (homeCityId: string, cities: Schema.HomeCity.Admin[] | null) => {
  let res = '';
  cities?.map((city) => {
    if (homeCityId == city.id) {
      res = city.name
    }
  })
  return res;
}

export const filterRentalsByCitySelected = (rentals: Schema.Rental.Admin[], selectedHomeCityId: string | undefined) => {

  let res = rentals;
  if (selectedHomeCityId && selectedHomeCityId != 'All Home City') {
    res = rentals.filter((rental) => rental.homeCityId == selectedHomeCityId)
  }
  return res;
}

export const filterPassesByCitySelected = (passes: any, selectedHomeCityId: string | undefined) => {

  let res = passes;
  if (selectedHomeCityId && selectedHomeCityId != 'All Home City') {
    res = passes.filter((pass: any) => pass.homeCityId == selectedHomeCityId)
  }
  return res;

}

const getRentalByCity = (rentals: Schema.Rental.Admin[], cities: Schema.HomeCity.Admin[] | null) => {
  const dateToRentalCount: { [key: string]: number } = {};


  rentals.forEach((rental) => {
    if (rental?.homeCityId) {
      dateToRentalCount[getCityName(rental?.homeCityId, cities)] = (dateToRentalCount[getCityName(rental?.homeCityId, cities)] || 0) + 1;
    }
  });
  return _.map(dateToRentalCount, (count, city) => {
    return {
      x: city,
      y: count,
    };
  });
}
const getSubscriptionByType = (subscriptions: any[]) => {
  const dateToRentalCount: { [key: string]: number } = {};


  subscriptions.forEach((subscription) => {
    if (subscription?.passType) {
      dateToRentalCount[subscription.passType] = (dateToRentalCount[subscription.passType] || 0) + 1;
    } else if (subscription?.durationInMonths == 1) {
      dateToRentalCount['monthly'] = (dateToRentalCount['monthly'] || 0) + 1;
    } else if (subscription?.durationInMonths == 4) {
      dateToRentalCount['four-monthly'] = (dateToRentalCount['four-monthly'] || 0) + 1;
    }
  });

  return _.map(dateToRentalCount, (count, passType) => {
    return {
      x: passType,
      y: count,
    };
  });
}


export const SubscriptionsByType = (
  { subscriptions }: { subscriptions: any[] }) => {
  const chartData = getSubscriptionByType(subscriptions);

  return (
    <>
      <GraphContainer title={'Subscriptions Count by Type (total)'}>
        <BarGraph data={chartData} color={Colors.DANGER} />
      </GraphContainer>
      <CSVLink
        data={chartData}
        filename={"Subscription-Count-by-Type.csv"}
        className="btn btn-primary"
        target="_blank"
      >
        Export Subscription Count by Type
      </CSVLink>
    </>
  );
};

export const RentalsByCity = (
  { rentals }: { rentals: Schema.Rental.Admin[] }) => {

  const { getHomeCities } = useContext(HomeCityContext);
  const availableHomeCity = getHomeCities();

  const chartData = getRentalByCity(rentals, availableHomeCity);

  return (
    <>
      <GraphContainer title={'Rental Count by City'}>
        <BarGraph data={chartData} color={Colors.DANGER} />
      </GraphContainer>
      <CSVLink
        data={chartData}
        filename={"Rental-Count-by-City.csv"}
        className="btn btn-primary"
        target="_blank"
      >
        Export Rental Count by City
      </CSVLink>
    </>
  );
};

const getRevenueByCity = (rentals: Schema.Rental.Admin[], cities: Schema.HomeCity.Admin[] | null, passes: any) => {
  const RevenueToRental: { [key: string]: Schema.Rental.Admin[] } = {};

  rentals = rentals.concat(passes);
  rentals.forEach((rental) => {
    if (rental?.homeCityId) {
      const rentalList = RevenueToRental[getCityName(rental.homeCityId, cities)]
      if (rentalList) {
        RevenueToRental[getCityName(rental.homeCityId, cities)].push(rental);
      } else {
        RevenueToRental[getCityName(rental.homeCityId, cities)] = [rental];
      }
    }
  });
  return _.map(RevenueToRental, (rentals, city) => {
    return {
      x: city,
      y: rentals.reduce((totalRev, rental: any) => totalRev + (rental.durationInMonths == 4 ? 100 : rental.durationInMonths == 1 ? 30 : rental.amountCentsCharged / 100 || 0), 0),
    };
  });

}

export const RevenueByCity = (
  { rentals, passes }: { rentals: Schema.Rental.Admin[], passes: any }) => {

  const { getHomeCities } = useContext(HomeCityContext);
  const availableHomeCity = getHomeCities();
  const chartData = getRevenueByCity(rentals, availableHomeCity, passes);

  return (
    <>
      <GraphContainer title={'Rental Revenue by City'}>
        <LineGraph data={chartData} />
      </GraphContainer>
      <CSVLink
        data={chartData}
        filename={"Rental-Revenue-by-City.csv"}
        className="btn btn-primary"
        target="_blank"
      >
        Export Rental Count by City
      </CSVLink>
    </>
  );
};

export const getSupportIssueCountByVehicle = (
  supportIssues: Schema.SupportIssue.Admin[],
  vehicles: Schema.Vehicle.Admin[]
) => {
  const vehicleIdToVehicle: { [id: string]: Schema.Vehicle.Admin } = {};
  vehicles.forEach((vehicle) => {
    vehicleIdToVehicle[vehicle.id] = vehicle;
  });

  const vehicleIdToIssueCount: { [id: string]: number } = {};

  supportIssues
    .filter((issue) => issue.vehicleIdEnteredByUser)
    .forEach((issue) => {
      vehicleIdToIssueCount[issue.vehicleIdEnteredByUser] =
        (vehicleIdToIssueCount[issue.vehicleIdEnteredByUser] || 0) + 1;
    });
  return _.map(vehicleIdToIssueCount, (count, vehicleId) => {
    const vehicle = vehicleIdToVehicle[vehicleId];
    return {
      x: vehicle ? vehicle.qrCode : vehicleId,
      y: count,
    };
  });
};
export const filterByDateAndFleetNewUsers = (
  users: Schema.User.Admin[],
  startAt?: Date | null,
  endAt?: Date | null,
  homeCityId?: string | null,
) => {
  const getTime = (date?: string) => {
    return date != null ? new Date(date).getTime() : 0;
  }
  return users.filter(({ createdAt, homeCityId: _objectHomeCityId, }) => {

    if (startAt && startAt.valueOf() > getTime(createdAt).valueOf()) {
      return false;
    }

    if (endAt && endAt.valueOf() < getTime(createdAt).valueOf()) {
      return false;
    }

    if (homeCityId && homeCityId != 'All Home City' && _objectHomeCityId !== homeCityId) {
      return false;
    }

    return true;
  });
};

export const filterByDateAndFleetPasses = <T extends any>(
  objects: any[],
  startAt?: Date | null,
  endAt?: Date | null,
  fleetId?: string | null,
  homeCityId?: string | null,
) => {
  return objects.filter(({ current_period_start, homeCityId: _objectHomeCityId }) => {
    if (startAt && (Number(startAt.getTime() / 1000).toFixed(0)) > current_period_start) {
      return false;
    }
    if (endAt && (Number(endAt.getTime() / 1000).toFixed(0)) < current_period_start) {
      return false;
    }

    if (homeCityId && homeCityId != 'All Home City' && _objectHomeCityId !== homeCityId) {
      return false;
    }

    return true;
  });
};


export const filterByDateAndFleet = <T extends any>(
  objects: T[],
  startAt?: Date | null,
  endAt?: Date | null,
  fleetId?: string | null,
  homeCityId?: string | null,
) => {
  return objects.filter(({ createdAt, fleetId: _objectFleetId, homeCityId: _objectHomeCityId }) => {
    if (startAt && startAt.toISOString() > createdAt) {
      return false;
    }

    if (endAt && endAt.toISOString() < createdAt) {
      return false;
    }

    if (fleetId && _objectFleetId !== fleetId) {
      return false;
    }

    if (homeCityId && homeCityId != 'All Home City' && _objectHomeCityId !== homeCityId) {
      return false;
    }

    return true;
  });
};

export const Statistics = ({
  startAt: _startAt,
  endAt: _endAt,
  fleet,
  adminArea,
  adminAreaCity,
  selectedHomeCityId
}: {
  startAt?: Date | null;
  endAt?: Date | null;
  fleet?: string | null;
  adminArea: boolean;
  adminAreaCity: string;
  selectedHomeCityId: string | undefined;
}) => {
  const [isGrid, setIsGrid] = useState(false);
  const { data: subscriptionsData, isLoading: isLoadingSubscriptions } = useSpringFetch<{
    subscriptions: any[];
  }>('get', '/admin/subcriptions-stats', {
    homeCityId: selectedHomeCityId != 'All Home City' ? selectedHomeCityId : undefined,
    fleetId: fleet,
    startAt: _startAt,
    endAt: _endAt,
  });
  console.log(subscriptionsData)
  const { data: rentalData, isLoading: isLoadingRentals } = useSpringFetch<{
    rentals: Schema.Rental.Admin[];
  }>('get', '/admin/rentals', {
    homeCityId: adminArea ? adminAreaCity : selectedHomeCityId,
    fleetId: fleet,
    startAt: _startAt,
    endAt: _endAt,
  });
  const { data: userData, isLoading: isLoadingUsers } = useSpringFetch<{
    users: Schema.User.Admin[];
  }>('get', '/admin/users', {
    startAt: _startAt,
    endAt: _endAt,
  });
  const { data: vehicleData, isLoading: isLoadingVehicles } = useSpringFetch<{
    vehicles: Schema.Vehicle.Admin[];
  }>('get', '/admin/vehicles');

  const { data: supportIssueData, isLoading: isLoadingSupportIssues } = useSpringFetch<{
    supportIssues: Schema.SupportIssue.Admin[];
  }>('get', '/admin/support-issues');


  const { data: userPromosData, isLoading: isLoadingPromoCodeUses } = useSpringFetch<{
    userPromoCodes: Schema.UserPromoCode.Admin[];
  }>('get', `/admin/user-promos-statics`);

  const { data: codePromos, isLoading: isLoadingPromoCode } = useSpringFetch<{
    promoCodes: Schema.PromoCode.Admin[];
  }>('get', `/admin/promos`);

  const { data: dataPasses, isLoading: isLoadingPasses } = useSpringFetch<{
    userPasses: any[];
  }>('get', '/admin/get-all-users-passes');

  const passes = filterByDateAndFleetPasses(dataPasses?.userPasses || [], _startAt, _endAt, fleet);
  const startAt = _startAt || moment().subtract(30, 'days').toDate();
  const subscriptions = subscriptionsData ? subscriptionsData.subscriptions : [];
  const rentals = rentalData
    ? filterByDateAndFleet(rentalData.rentals.slice().reverse(), startAt, _endAt, fleet)
    : [];

  const endAt = _endAt || moment(_.last(rentals)?.startAt).toDate();

  const users = userData ? filterByDateAndFleetNewUsers(userData.users, startAt, _endAt) : [];

  const userPromos = userPromosData?.userPromoCodes ?
    filterByDateAndFleet(userPromosData.userPromoCodes, _startAt, _endAt, fleet) : undefined;

  const vehicles = vehicleData ? filterByDateAndFleet(vehicleData.vehicles, null, null, fleet) : [];
  const supportIssues = supportIssueData
    ? filterByDateAndFleet(supportIssueData.supportIssues, startAt, _endAt, fleet)
    : [];

  const isLoading =
    isLoadingRentals || isLoadingSubscriptions || isLoadingUsers || isLoadingVehicles || isLoadingSupportIssues || isLoadingPromoCodeUses || isLoadingPromoCode || isLoadingPasses;

  if (isLoading) {
    return <LoadingView />;
  }
  console.log(subscriptions)
  const graphColClass = isGrid ? 'col-md-4 col-xs-12' : 'col-md-12 col-xs-12';

  return (
    <div>
      <div>
        <div className="btn-group  d-md-inline d-none" role="group">
          <button
            onClick={() => setIsGrid(false)}
            type="button"
            className={`btn ${isGrid ? 'btn-outline-secondary' : 'btn-secondary'}`}
          >
            <Row />
          </button>
          <button
            onClick={() => setIsGrid(true)}
            type="button"
            className={`btn ${isGrid ? 'btn-secondary' : 'btn-outline-secondary'}`}
          >
            <Grid />
          </button>
        </div>
      </div>
      <div className="row">
        <div className={graphColClass}>
          <DailyRevenue rentals={rentals} startAt={startAt} endAt={endAt} passes={passes} />
        </div>
        <div className={graphColClass}>
          <DailyRides rentals={rentals} startAt={startAt} endAt={endAt} />
        </div>
        <div className={graphColClass}>
          <DailyRevenueSubscriptions subscriptions={subscriptions} startAt={startAt} endAt={endAt} />
        </div>
        <div className={graphColClass}>
          <DailySubscriptions subscriptions={subscriptions} startAt={startAt} endAt={endAt} />
        </div>
        <div className={graphColClass}>
          <SubscriptionsByType subscriptions={subscriptions} />
        </div>
        <div className={graphColClass}>
          <RentalsPerScooterPerDay
            rentals={rentals}
            vehicles={vehicles}
            startAt={startAt}
            endAt={endAt}
          />
        </div>
        <div className={graphColClass}>
          <AverageRideRatingByDay rentals={rentals} startAt={startAt} endAt={endAt} />
        </div>
        <div className={graphColClass}>
          <RentalCountByHour rentals={rentals} />
        </div>
        <div className={graphColClass}>
          <AverageRentalDurationByDay rentals={rentals} startAt={startAt} endAt={endAt} />
        </div>
        <div className={graphColClass}>
          <NewUserCountByDay users={users} startAt={startAt} endAt={endAt} />
        </div>
        <div className={graphColClass}>
          <NumberOfUsesPromobyName userPromoCode={userPromos} activePromoCode={codePromos?.promoCodes} />
        </div>
        <div className={graphColClass}>
          <SupportIssueCountByDay issues={supportIssues} />
        </div>
        <div className={graphColClass}>
          <UsersRentingByDay rentals={rentals} startAt={startAt} endAt={endAt} />
        </div>
        <div className={graphColClass}>
          <RentalsByCity rentals={rentals} />
        </div>
        <div className={graphColClass}>
          <RevenueByCity rentals={rentals} passes={passes} />
        </div>
      </div>
    </div>
  );
};

export const StatisticsHome = () => {
  const { selectedFleetId } = useContext(FleetContext);
  const { selectedHomeCityId, isLoading: isLoadingHomeCity } = useContext(HomeCityContext);
  const { adminArea, adminAreaCity, isLoading: isLoadingAdminArea } = FilterAdminAreas();
  const defaultStart = moment().subtract(30, "day").toDate();
  const defaultEnd = moment().toDate();

  if (isLoadingAdminArea || isLoadingHomeCity) return <LoadingSpinner></LoadingSpinner>
  return (
    <div className={'container'}>

      <h2>Breakdown</h2>

      <DatePeriodProvider
        render={({ start, end }) => (
          <Statistics
            selectedHomeCityId={selectedHomeCityId}
            adminArea={adminArea}
            adminAreaCity={adminAreaCity}
            fleet={selectedFleetId}
            startAt={start || defaultStart} endAt={end || defaultEnd} />
        )}
      />
      <div style={{ padding: 10 }}></div>
      <h2>Summary</h2>

      <div style={{}}>
        <RentalAnalyticsCard
          adminArea={adminArea} adminAreaCity={adminAreaCity} />
      </div>

    </div>
  );
};
