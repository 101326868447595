import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';

type IProps = TextFieldProps & {
  icon?: React.ReactNode;
  onNewValue: (value: string) => void;
  value: string;
};

export const TextInput = ({ icon, value, onNewValue, ...otherProps }: IProps) => (
  <TextField
    id="input-with-icon-textfield"
    value={value}
    onChange={(e: any) => onNewValue(e.target.value)}
    {...otherProps}
    InputProps={{
      startAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
    }}
  />
);
