import React from 'react';
import { MoreHoriz } from '@material-ui/icons';

interface IDropDownItemProps {
  label: string;
  href?: string;
  onClick?: () => void;
}

export const DropDownItem = ({ label, href, onClick }: IDropDownItemProps) => {
  return (
    <a
      className="dropdown-item"
      onClick={(event) => {
        event.stopPropagation();
        onClick && onClick();
      }}
      target="_blank"
      rel="noopener noreferrer"
      href={href}
    >
      {label}
    </a>
  );
};

export const MoreDropdown = ({ options }: { options: IDropDownItemProps[] }) => {
  return (
    <div className="dropdown">
      <button
        onClick={(event) => event.stopPropagation()}
        className="btn bg-transparent"
        id="dropdownMenuButton"
        data-toggle="dropdown"
      >
        <MoreHoriz />
      </button>
      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        {options.map((option, idx) => (
          <DropDownItem key={idx} {...option} />
        ))}
      </div>
    </div>
  );
};
