import React from 'react';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import { Colors } from './Colors';

export interface IDataPoint {
  x: string | Date;
  y: number;
}

export type ColorOption = Colors.PRIMARY | Colors.SUCCESS | Colors.DANGER;

export const COLOR_TO_GRAPH_COLOR: Record<
  ColorOption,
  { borderColor: string; backgroundColor: string }
> = {
  [Colors.PRIMARY]: { borderColor: 'rgba(47,128,237,.8)', backgroundColor: 'rgba(47,128,237,.4)' },
  [Colors.SUCCESS]: { borderColor: 'rgba(40,167,69,.8)', backgroundColor: 'rgba(40,167,69,.4)' },
  [Colors.DANGER]: { borderColor: 'rgba(220,53,69,.8)', backgroundColor: 'rgba(220,53,69,.4)' },
};

export const getLabel = (label: string | Date) => {
  if (typeof label === 'string') {
    return label;
  }
  return moment(label).format('MM/DD');
};

export const LineGraph = ({
  data,
  height,
  color = Colors.PRIMARY,
  includeXAxis = true,
  includeYAxis = true,
  includeXTicks = true,
  includeYTicks = true,
}: {
  data: IDataPoint[];
  height?: number;
  color?: ColorOption;
  includeXAxis?: boolean;
  includeYAxis?: boolean;
  includeXTicks?: boolean;
  includeYTicks?: boolean;
}) => {
  return (
    <Line
      height={height}
      data={{
        labels: data.map((pt) => getLabel(pt.x)),
        datasets: [
          {
            data: data.map((pt) => pt.y),
            fill: true,
            ...COLOR_TO_GRAPH_COLOR[color],
          },
        ],
      }}
      options={{
        maintainAspectRatio: false,
        resposive: true,
        layout: {
          padding: 5,
        },
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              display: includeXAxis,
              ticks: {
                display: includeXTicks,
              },
            },
          ],
          yAxes: [
            {
              display: includeYAxis,
              ticks: {
                display: includeYTicks,
              },
            },
          ],
        },
        tooltips: {
          mode: 'point',
        },
      }}
    />
  );
};
