import React, { useEffect, useState } from 'react';
import moment from 'moment';
import DateTimePicker from 'react-datetime-picker';

type DateState = [Date | null, (date: Date | null) => void];

const DateTimeInput = ({
  date,
  setDate,
}: {
  date: null | Date;
  setDate: (date: Date | null) => void;
}) => {
  return <DateTimePicker onChange={setDate} value={date} disableClock={true} />;
};

enum Timezone {
  UTC = 'UTC',
  LOCAL = 'Local',
}

const TimezoneSwitch = ({
  timezone,
  setTimezone,
}: {
  timezone: Timezone;
  setTimezone: (timezone: Timezone) => void;
}) => {
  const toggleTimezone = () => {
    if (timezone === Timezone.UTC) {
      setTimezone(Timezone.LOCAL);
    } else {
      setTimezone(Timezone.UTC);
    }
  };

  const checked = timezone === Timezone.UTC;

  return (
    <div className="custom-control custom-switch mr-2">
      <label className="custom-control-label">
        <input
          type="checkbox"
          className="custom-control-input"
          onChange={toggleTimezone}
          checked={checked}
        />
        {timezone}
      </label>
    </div>
  );
};

const SelectDateTimeRange = ({
  startState,
  endState,
}: {
  startState: DateState;
  endState: DateState;
}) => {
  const [start, setStart] = startState;
  const [end, setEnd] = endState;

  const [tempStart, setTempStart] = useState(start);
  const [tempEnd, setTempEnd] = useState(end);
  const [timezone, setTimezone] = useState(Timezone.LOCAL);

  return (
    <form
      className="form-inline my-2"
      onSubmit={(e) => {
        e.preventDefault();

        /**
         * Naively, we see the local time in the DateTime selector. So if we want to be specify a UTC time
         * then we need to modify the date we chose by the UTC offset.
         */
        const transformDate = (date: Date | null) => {
          if (!date) {
            return null;
          }

          if (timezone !== Timezone.UTC) {
            return date;
          }

          const utcOffset = moment().utcOffset();
          return moment(date)
            .add(utcOffset, 'minutes')
            .toDate();
        };

        setStart(transformDate(tempStart));
        setEnd(transformDate(tempEnd));
      }}
    >
      <div className="form-check mb-2 mr-sm-2">
        <DateTimeInput date={tempStart} setDate={setTempStart} />
        {' to '}
        <DateTimeInput date={tempEnd} setDate={setTempEnd} />
      </div>

      <TimezoneSwitch timezone={timezone} setTimezone={setTimezone} />

      <button type="submit" className="btn btn-primary mb-2">
        Submit
      </button>
    </form>
  );
};

export const DatePeriodProvider = ({
  initialStart,
  initialEnd,
  render,
}: {
  initialStart?: string | null;
  initialEnd?: string | null;
  render: ({ start, end }: { start: Date | null; end: Date | null }) => React.ReactNode;
}) => {
  const initialStartDate = initialStart ? moment(initialStart).toDate() : null;
  const initialEndDate = initialEnd ? moment(initialEnd).toDate() : null;

  const startState = useState<Date | null>(initialStartDate);
  const endState = useState<Date | null>(initialEndDate);

  return (
    <div>
      <SelectDateTimeRange startState={startState} endState={endState} />

      {render({ start: startState[0], end: endState[0] })}
    </div>
  );
};

export const DatePeriodPicker = ({
  initialStart,
  initialEnd,
  setDates,
}: {
  initialStart?: string | null;
  initialEnd?: string | null;
  setDates: ({ start, end }: { start: Date | null; end: Date | null }) => void;
}) => {
  const initialStartDate = initialStart ? moment(initialStart).toDate() : null;
  const initialEndDate = initialEnd ? moment(initialEnd).toDate() : null;

  const startState = useState<Date | null>(initialStartDate);
  const endState = useState<Date | null>(initialEndDate);

  const startDate = startState[0];
  const endDate = endState[0];

  useEffect(() => {
    setDates({ start: startDate, end: endDate });
  }, [startDate, endDate, setDates]);

  return <SelectDateTimeRange startState={startState} endState={endState} />;
};
