import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { FEED_ITEM_CATEGORIES } from './VehicleFeed';

const FilterCheckbox = ({
  selected,
  setSelected,
  label,
}: {
  selected: boolean;
  setSelected: (selected: boolean) => void;
  label: string;
}) => {
  return (
    <FormControlLabel
      control={<Checkbox checked={selected} onChange={() => setSelected(!selected)} />}
      label={label}
    />
  );
};

export const FeedFilters = ({
  activeCategories,
  setActiveCategories,
  categoryToCount,
}: {
  activeCategories: string[];
  setActiveCategories: (categories: string[]) => void;
  categoryToCount: Record<string, number>;
}) => {
  return (
    <FormGroup row>
      {FEED_ITEM_CATEGORIES.map((category) => (
        <FilterCheckbox
          key={category}
          selected={activeCategories.includes(category)}
          setSelected={(selected) => {
            if (selected) {
              const newCategories = activeCategories.concat([category]);
              setActiveCategories(newCategories);
            } else {
              const newCategories = activeCategories.filter((c) => c !== category);
              setActiveCategories(newCategories);
            }
          }}
          label={`${category} - ${categoryToCount[category]}`}
        />
      ))}
    </FormGroup>
  );
};
