import React, { createContext, useContext, useState } from 'react';
import { useAnimation } from '../components/useAnimation';
import _ from 'lodash';
import moment from 'moment';

export const getPingsBeforeDate = (
  snapshotDate: Date,
  pings: any[],
  dateKey: string,
  numPings: number
): any[] => {
  const dates = _.sortBy(pings.map((ping) => ping[dateKey]));
  const pingIndex = _.sortedLastIndex(dates, snapshotDate.toISOString());

  const startIndex = Math.max(0, pingIndex - numPings);
  return pings.slice(startIndex, pingIndex);
};

export const getDateInRange = (startAt: Date, endAt: Date, ratio: number): Date => {
  const difference = endAt.getTime() - startAt.getTime();
  return new Date(startAt.getTime() + ratio * difference);
};

const TimeAnimatorContext = createContext<{
  startAnimation: () => void;
  snapshotDate: Date;
  ratio: number;
  startAt: Date;
  endAt: Date;
}>({
  startAnimation: () => null,
  snapshotDate: new Date(),
  ratio: 0,
  startAt: new Date(),
  endAt: new Date(),
});

const ControlBar = () => {
  const { snapshotDate, startAnimation, ratio, startAt, endAt } = useContext(TimeAnimatorContext);

  return (
    <div className={'m-2'}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>{moment(startAt).format('MMMM Do, hh:mm a')}</div>
        <div>{moment(snapshotDate).format('hh:mm a')}</div>
        <div>{moment(endAt).format('MMMM Do, hh:mm a')}</div>
      </div>

      <div>
        <input
          type="range"
          className="custom-range"
          min={0}
          max={1}
          step={0.01}
          value={ratio}
          readOnly={true}
        />
      </div>

      <div>
        <button onClick={() => startAnimation()} className={'btn btn-sm btn-primary'}>
          Start Animation
        </button>
      </div>
    </div>
  );
};

const Consumer = ({ render }: { render: ({ snapshotDate }: { snapshotDate: Date }) => any }) => {
  const { snapshotDate } = useContext(TimeAnimatorContext);

  return render({ snapshotDate });
};

const TimeAnimatorProvider = ({
  startAt,
  endAt,
  children,
}: {
  startAt: Date;
  endAt: Date;
  children: React.ReactNode;
}) => {
  const [resetVal, setResetVal] = useState(0);

  const ratio = useAnimation(20 * 1000, resetVal);

  const snapshotDate = getDateInRange(startAt, endAt, ratio);

  return (
    <TimeAnimatorContext.Provider
      value={{
        startAnimation: () => setResetVal(resetVal + 1),
        snapshotDate,
        ratio,
        startAt,
        endAt,
      }}
    >
      {children}
    </TimeAnimatorContext.Provider>
  );
};

export const TimeAnimator = {
  Provider: TimeAnimatorProvider,
  ControlBar,
  Consumer,
  Context: TimeAnimatorContext,
};
