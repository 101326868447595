import React from 'react';
import { Bar } from 'react-chartjs-2';
import { IDataPoint, getLabel, ColorOption, COLOR_TO_GRAPH_COLOR } from './LineGraph';
import { Colors } from './Colors';

export const BarGraph = ({
  data,
  height,
  color = Colors.PRIMARY,
  includeXAxis = true,
  includeYAxis = true,
  includeXTicks = true,
  includeYTicks = true,
}: {
  data: IDataPoint[];
  height?: number;
  color?: ColorOption;
  includeXAxis?: boolean;
  includeYAxis?: boolean;
  includeXTicks?: boolean;
  includeYTicks?: boolean;
}) => {
  return (
    <Bar
      height={height}
      data={{
        labels: data.map((pt) => getLabel(pt.x)),
        datasets: [
          {
            data: data.map((pt) => pt.y),
            borderWidth: 1,
            ...COLOR_TO_GRAPH_COLOR[color],
          },
        ],
      }}
      options={{
        maintainAspectRatio: false,
        resposive: true,
        layout: {
          padding: 5,
        },
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              display: includeXAxis,
              ticks: {
                display: includeXTicks,
              },
            },
          ],
          yAxes: [
            {
              display: includeYAxis,
              ticks: {
                display: includeYTicks,
              },
            },
          ],
        },
      }}
    />
  );
};
