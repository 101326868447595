import { Schema, SpringClient } from 'boaz-bikes-types';
import React, { useContext, useState } from 'react';
import { DateInfo } from '../components/DateInfo';
import { TabRow } from '../components/Tab';
import { Table } from '../components/Table';
import { useSpring, useSpringFetch } from '../firebase';
import { AdminStatusDropdown } from './AdminStatusDropdown';
import { DeliveryStatusDropdown } from './DeliveryStatusDropdown';
import { BlockUserDropdown } from './BlockUserDropdown';
import { SendPushNotificationForm } from './SendPushNotificationForm';
import { Card } from '../components/Card';
import { PageContainer } from '../components/PageContainer';
import { Link } from 'react-router-dom';
import { HomeCityDropdown } from '../home-city/HomeCityDropdown';
import { HomeCityContext } from '../home-city/HomeCityContext';
import { Button } from '@material-ui/core';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { FilterAdminAreas, FilterAdminAreaUsers } from '../utils/FilterAdminAreas';
import { AdminAreaStatusDropdown } from './AdminAreaStatusDropdown';
import { useNavigate } from '../Router';
import { LoadingSpinner } from '../components/LoadingSpinner';
import { useServerFetch } from 'boaz-bikes-client-utils';

enum UserTab {
  ALL = 'All',
  ADMIN = 'Admin',
  AREAADMIN = 'AreaAdmin',
  DELIVERY = 'Delivery',
  BLOCKED = 'Blocked',
}

type UserFilter = (user: Schema.User.Admin) => boolean;

const TAB_TO_FILTER: Partial<Record<UserTab, UserFilter>> = {
  [UserTab.ADMIN]: (user) => user.isAdmin,
  [UserTab.DELIVERY]: (user) => user.isDelivery,
  [UserTab.BLOCKED]: (user) => user.isBlocked,
};



export const UserList = ({
  title,
  filter,
  limit,
  pageSize,
  adminArea,
  adminAreaCity
}: {
  title?: string;
  filter?: UserFilter;
  limit?: number;
  pageSize: number;
  adminArea: boolean;
  adminAreaCity: string;
}) => {
  const { selectedHomeCityId, getHomeCities } = useContext(HomeCityContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [textSearch, setTextSearch] = useState('');
  const [rowsState, setRowsState] = useState<any>([]);
  const navigate = useNavigate();
  const [exportAll, setExportAll] = useState(false);
  const { data, isLoading, refreshData: refershInitialData } = useSpringFetch<{
    users: Schema.User.Admin[];
    usersLength: number;
  }>('get', '/admin/users', {
    homeCityId: adminArea ? adminAreaCity : selectedHomeCityId,
    limit,
    pageSize: !exportAll ? pageSize : undefined,
    page: !exportAll ? currentPage - 1 : undefined,
  });
  const { data: dataSearch, isLoading: isLoadingSearch, refreshData: refreshSearchApi } = useSpringFetch<{
    users: Schema.User.Admin[];
  }>('get', '/admin/search-users', {
    homeCityId: adminArea ? adminAreaCity : selectedHomeCityId,
    textSearch
  });
  const refreshData = () => {
    refershInitialData();
    refreshSearchApi();
  }

  const users = textSearch != '' && dataSearch ? dataSearch?.users : data?.users || [];
  const usersLength = data?.usersLength || 0;
  if (!isLoading && data == null) {
    return <div className="alert alert-warning">Users not found</div>;
  }

  const deleteUser = async (id: string) => {
    if (window.confirm("Are you sure?")) {
      await SpringClient.post(`/admin/users/${id}`);
      refreshData();
    }
  }

  const filteredUsers = filter ? users.filter(filter) : users;
  let allHomeCity = getHomeCities();
  const homeCityMap = new Map(allHomeCity?.map((homecity: any) => [homecity.id, homecity.name]))
  const exportUsers = filteredUsers.length > 0 ? filteredUsers.map((user) => {
    let homeCityCurrent = allHomeCity?.find((homecity: any) => homecity.id === user.homeCityId);
    return {
      'Phone Number': user.phoneNumber,
      'Name': user.name,
      'Email': user.email,
      'Signed Up': moment.utc(user.createdAt).fromNow(),
      'Home City': homeCityCurrent?.name,
      'Admin': user.isAdmin ? 'true' : 'false',
      'AreaAdmin': user.areaAdmin,
      'Delivery': user.isDelivery ? 'true' : 'false',
      'Blocked': user.isBlocked ? 'true' : 'false',
      'Referral': user.referral,
    };
  }
  ) : [];
  const exportAllFunction = () => {
    setExportAll(true)
  }

  return (
    <>
      <Table
        title={title}
        isLoading={isLoading || isLoadingSearch}
        autoFocusSearch={true}
        rowsPerPage={100}
        onAddCsv={() => { navigate('/users/import-user') }}
        // getRowUrl={(user: Schema.User.Admin) => `/users/${user.id}`}
        columnRenderInfos={[
          {
            columnName: 'Phone Number',
            attribute: 'phoneNumber',
          },
          {
            columnName: 'Name',
            attribute: 'name',
          },
          {
            columnName: 'Email',
            attribute: 'email',
          },
          {
            columnName: 'Signed Up',
            attribute: 'createdAt',
            renderFunc: ({ createdAt }) => <DateInfo date={createdAt} />,
          },
          {
            columnName: 'Home City',
            attribute: 'homeCityId',
            // renderFunc: ({ id, homeCityId }) => {
            //   return <HomeCityDropdown id={id} homeCityId={homeCityId} refreshData={refreshData} updateUser={true} />
            // }
            renderFunc: ({ homeCityId }) => {
              let homeName = homeCityMap.get(homeCityId);
              return homeName || "";
            }
          },
          {
            columnName: 'Pass Type',
            attribute: 'passType',
            renderFunc: ({ passType }) => {
              if (passType) {
                const passTypeStr = passType === 'monthly' ? 'Monthly' :
                  passType === 'four-monthly' ? 'Four Monthly' : '';
                return passTypeStr;
              }
              return '';
            }
          },
          {
            columnName: 'Admin',
            attribute: 'isAdmin',
            renderFunc: ({ id, isAdmin }) => (
              <AdminStatusDropdown id={id} isAdmin={isAdmin} refreshData={refreshData} />
            ),
          },
          {
            columnName: 'Admin Area',
            attribute: 'areaAdmin',
            renderFunc: ({ id, areaAdmin }) => (
              <AdminAreaStatusDropdown id={id} areaAdmin={areaAdmin} refreshData={refreshData} />
            ),
          },
          {
            columnName: 'Delivery',
            attribute: 'isDelivery',
            renderFunc: ({ id, isDelivery }) => (
              <DeliveryStatusDropdown id={id} isDelivery={isDelivery} refreshData={refreshData} />
            ),
          },
          {
            columnName: 'Blocked',
            attribute: 'isBlocked',
            renderFunc: ({ id, isBlocked }) => (
              <BlockUserDropdown id={id} isBlocked={isBlocked} refreshData={refreshData} />
            ),
          },
          {
            columnName: 'Referral',
            attribute: 'referral',
          },
          {
            columnName: 'Edit',
            attribute: 'email',
            renderFunc: ({ id, isBlocked }) => (
              <Link to={`/users/${id}`}>Edit</Link>
            ),
          },
          {
            columnName: 'Delete',
            attribute: 'email',
            renderFunc: ({ id, isBlocked }) => (
              <Button onClick={() => deleteUser(id)}>Delete</Button>
            ),
          },
        ]}
        rows={filteredUsers}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        rowsState={rowsState}
        setRowsState={setRowsState}
        dataLength={usersLength}
        searchInDb={(text) => {
          setTextSearch(text)
        }
        }
      />

      <div className="row mb-4">
        <div className="col">
          <CSVLink
            style={{ marginLeft: 20 }}
            data={exportUsers}
            filename={"users.csv"}
            className="btn btn-primary"
            target="_blank"
          >
            Export
          </CSVLink>
        </div>
      </div>
      <button
        type="button"
        onClick={exportAllFunction}
        className="btn btn-primary"
        style={{ marginBottom: 20, marginLeft: 20, display: 'flex', flexDirection: 'row', width: "15%" }}
      >Load All Users
      </button>

    </>
  );
};

export const UsersHome = () => {
  const [selectedTab, setSelectedTab] = useState<UserTab>(UserTab.ALL);
  const { adminArea, adminAreaCity, isLoading: isLoadingAdminArea } = FilterAdminAreas();
  const { isLoading: isLoadingHomeCities } = useContext(HomeCityContext);

  if (isLoadingAdminArea || isLoadingHomeCities) return <LoadingSpinner></LoadingSpinner>

  return (
    <PageContainer fullWidth>
      <TabRow
        tabs={Object.values(UserTab).map((tab) => ({
          title: tab,
          onClick: () => setSelectedTab(tab),
          isSelected: selectedTab === tab,
        }))}
      />
      <div className="row mb-4">
        <div className="col">
          <Card.Container>
            <UserList adminArea={adminArea} adminAreaCity={adminAreaCity} pageSize={100} title={'All Users'} filter={TAB_TO_FILTER[selectedTab]} />
          </Card.Container>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col">
          <Card.Container>
            <Card.Body>
              <SendPushNotificationForm sendToAllUsers={true} />
            </Card.Body>
          </Card.Container>
        </div>
      </div>

    </PageContainer>
  );
};
