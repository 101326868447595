import { Lock, VerifiedUser } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { SignInForm } from "./auth/SignInForm";
import { UserContext } from "./auth/UserContext";
import { Navbar } from "./components/Navbar";
import { CreateDock } from "./docks/CreateDock";
import { DockHome } from "./docks/DockHome";
import { DockInfo } from "./docks/DockInfo";
import { UpdateDock } from "./docks/UpdateDock";
import { GeofenceMap } from "./geofence/GeofenceHome";
import { ImportGeofence } from "./geofence/ImportGeofence";
import { HomePage } from "./home/HomePage";
import { HistoricalMap } from "./map/HistoricalMap";
import { MapPage } from "./map/MapPage";
import { CreatePromoCode } from "./promo-codes/CreatePromoCode";
import { PromosHome } from "./promo-codes/PromoCodeHome";
import { PromoCodeInfo } from "./promo-codes/PromoCodeInfo";
import { UpdatePromoCode } from "./promo-codes/UpdatePromoCode";
import { RentalInfo } from "./rentals/RentalInfo";
import { RentalsHome } from "./rentals/RentalsHome";
import { CurrentRentals } from "./rentals/CurrentRentals";
import { StatisticsHome } from "./statistics/StatisticsHome";
import { SupportHome } from "./support/SupportHome";
import { TaskForm } from "./tasks/TaskForm";
import { TasksHome } from "./tasks/TasksHome";
import { UpdateTask } from "./tasks/UpdateTask";
import { UserProfile } from "./users/UserProfile";
import { UsersHome } from "./users/UsersHome";
import { ImportUser } from "./users/ImportUser";
import { CreateVehicle } from "./vehicles/CreateVehicle";
import { CreateMultiples } from "./vehicles/CreateMultiples";
import { FleetProvider } from "./vehicles/FleetContext";
import { UpdateVehicle } from "./vehicles/UpdateVehicle";
import { VehicleInfo } from "./vehicles/VehicleInfo";
import { VehiclesHome } from "./vehicles/VehiclesHome";
import { CreateGeofence } from "./geofence/CreateGeofence";
import { ConfigPage } from "./config/ConfigPage";
import { EditCompanyConfig } from "./config/EditCompanyConfig";
import { EditPricingConfig } from "./config/EditPricingConfig";
import { CreateHomeCity } from "./home-city/CreateHomeCity";
import { HomeCityProvider } from "./home-city/HomeCityContext";
import { EditHomeCity } from "./home-city/EditHomeCity";
import { DropOffHome } from "./drop-off/DropOffHome";
import { DropOffDetail } from "./drop-off/DropOffDetail";
import { DropOffEdit } from "./drop-off/DropOffEdit";
import { DeliveryHome } from "./delivery/DeliveryHome";
import { DeliveryDetail } from "./delivery/DeliveryDetail";
import { DeliveryEdit } from "./delivery/DeliveryEdit";
import { LeftBar } from "./components/LeftBar";
import { HeatMapSection } from "./heat-map/HeatMapSection";
import { VehicleAlertSection } from "./vehicle-alerts/VehicleAlertSection";
import { VehicleListStopPing } from "./vehicle-alerts/VehicleListStopPing";
import { VehicleListNotRented } from "./vehicle-alerts/VehicleListNotRented";
import { EventsHome } from "./events/EventsHome";
import { useIdleTimer } from 'react-idle-timer';
import { ImportDock } from "./docks/ImportDock";
import { UsersVerificationHome } from "./user-verification/UsersVerificationHome";
import { UsersPassHome } from "./user-pass/UsersPassHome";


export const useNavigate = () => {
  const history = useHistory();
  const navigate = (url: string) => history.push(url);
  return navigate;
};

const trackPageInSegment = () => {
  if ((window as any).analytics && (window as any).analytics.page) {
    (window as any).analytics.page();
  }
};

const TrackPageChanges = () => {
  const history = useHistory();

  useEffect(() => {
    trackPageInSegment();
  }, [history.location.pathname]);

  return null;
};

export function AppRouter() {
  const { user, isAdmin, isLoadingUser, logout } = useContext(UserContext);

  const onIdle = () => {
    window.location.href = "https://boazbikes.com";
  }
  const idleTimer = useIdleTimer({ onIdle, timeout: 600000, })

  if (isLoadingUser) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 20,
        }}
      >
        <VerifiedUser fontSize={"large"} />
        Verifying your identity...
      </div>
    );
  }

  if (!user) {
    return <SignInForm />;
  }

  if (!isAdmin) {
    window.location.href = "https://boazbikes.com";
  }

  idleTimer.start()
  // if (!isAdmin) {
  //   return (
  //     <div
  //       style={{
  //         display: 'flex',
  //         flexDirection: 'column',
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //         paddingTop: 20,
  //       }}
  //     >
  //       <Lock fontSize={'large'} />
  //       You don't have admin privileges, please contact a member of the Spring team.
  //       <button style={{ marginTop: 20 }} type="button" className="btn btn-danger" onClick={logout}>
  //         Logout
  //       </button>
  //     </div>
  //   );
  // }

  const router = (
    <Router>
      <FleetProvider>
        <HomeCityProvider>
          <Navbar />
          <div
            className="row"
            style={{
              marginLeft: 0,
              marginRight: 0,
              minHeight: "calc(100vh - 56px)",
            }}
          >
            <div className="col-2 bg-light d-none d-md-block">
              <LeftBar />
            </div>
            <div className="col col-xs-12 col-md-10">
              <TrackPageChanges />
              <Switch>
                <Route path="/users/import-user" component={ImportUser} />
                <Route path="/users/:userId" component={UserProfile} />
                <Route path="/users" component={UsersHome} />
                <Route path="/users-verification" component={UsersVerificationHome} />
                <Route path="/vehicles/create" component={CreateVehicle} />
                <Route
                  path="/vehicles/multiple-create"
                  component={CreateMultiples}
                />
                <Route
                  path="/vehicles/:vehicleId/edit"
                  component={UpdateVehicle}
                />
                <Route path="/vehicles/:vehicleId" component={VehicleInfo} />
                <Route path="/vehicles" component={VehiclesHome} />
                <Route path="/vehicle-alerts/list-not-rented" component={VehicleListNotRented} />
                <Route path="/vehicle-alerts/list-stop-ping" component={VehicleListStopPing} />
                <Route path="/vehicle-alerts" component={VehicleAlertSection} />
                <Route path="/rentals/:rentalId" component={RentalInfo} />
                <Route path="/rentals" component={RentalsHome} />
                <Route path="/users-passes" component={UsersPassHome} />
                <Route path="/current-rentals" component={CurrentRentals} />
                <Route path="/tasks/create" component={TaskForm} />
                <Route path="/tasks/:taskId/edit" component={UpdateTask} />
                <Route path="/tasks" component={TasksHome} />
                <Route path='/events' component={EventsHome} />
                <Route path="/promos/create" component={CreatePromoCode} />
                <Route
                  path="/promos/:promoCodeId/edit"
                  component={UpdatePromoCode}
                />
                <Route path="/promos/:promoCodeId" component={PromoCodeInfo} />
                <Route path="/promos" component={PromosHome} />
                <Route path="/docks/import-docks" component={ImportDock} />
                <Route path="/docks/create" component={CreateDock} />
                <Route path="/docks/:dockId/edit" component={UpdateDock} />
                <Route path="/docks/:dockId" component={DockInfo} />
                <Route path="/docks" component={DockHome} />
                <Route path="/support" component={SupportHome} />
                <Route path="/map" exact component={MapPage} />
                <Route path="/mapheat" exact component={HeatMapSection} />
                <Route path="/history" exact component={HistoricalMap} />
                <Route path="/geofence" exact component={GeofenceMap} />
                <Route
                  path="/geofence/create"
                  exact
                  component={CreateGeofence}
                />
                <Route
                  path="/geofence/import"
                  exact
                  component={ImportGeofence}
                />
                <Route path="/statistics" exact component={StatisticsHome} />
                <Route path="/config" exact component={ConfigPage} />
                <Route
                  path="/config/edit"
                  exact
                  component={EditCompanyConfig}
                />
                <Route
                  path="/config/pricing/edit"
                  exact
                  component={EditPricingConfig}
                />
                <Route path="/home-city/create" component={CreateHomeCity} />
                <Route
                  path="/home-city/:homeCityId/edit"
                  component={EditHomeCity}
                />
                <Route path="/dropoffs" exact component={DropOffHome} />
                <Route
                  path="/dropoffs/:dropOffId"
                  exact
                  component={DropOffDetail}
                />
                <Route
                  path="/dropoffs/:dropOffId/edit"
                  component={DropOffEdit}
                />
                <Route path="/delivery" exact component={DeliveryHome} />
                <Route
                  path="/delivery/:deliveryId"
                  exact
                  component={DeliveryDetail}
                />
                <Route
                  path="/delivery/:deliveryId/edit"
                  component={DeliveryEdit}
                />
                <Route path="/" exact component={HomePage} />
              </Switch>
            </div>
          </div>
        </HomeCityProvider>
      </FleetProvider>
    </Router>
  );
  return router;
}
