import React from 'react';
import { IFormField, INPUT_TYPE } from 'boaz-bikes-forms';
import { SpringClient, Schema } from 'boaz-bikes-types';
import { useNavigate } from '../Router';
import { showMessage } from '../ToastProvider';
import { SimpleForm } from 'boaz-bikes-forms';
import { LoadingView } from '../components/LoadingSpinner';
import { PageContainer } from '../components/PageContainer';
import { useParams } from 'react-router';
import { useSpringFetch } from '../firebase';

export const DROP_OFF_FIELDS: IFormField[] = [
    {
        label: 'Status',
        name: 'status',
        inputType: INPUT_TYPE.SELECT,
        options: Object.values(Schema.Dropoff.Status),
    },
];


export function DropOffEdit() {
    const navigate = useNavigate();
    const { dropOffId } = useParams();

    const { data, isLoading } = useSpringFetch<{ dropOff: Schema.Dropoff.Admin }>(
        'get',
        `/admin/dropoff/${dropOffId}`,
        {}
    );

    if (isLoading) {
        return <LoadingView />;
    }

    if (data == null) {
        return <div className="alert alert-warning">DropOff not found</div>;
    }

    const submitFormAsync = async (formState: any) => {
        try {
            const { message, dropOff } = await SpringClient.post(`/admin/dropOff/${dropOffId}`, formState);
            showMessage(message);
            navigate(`/dropoffs/${dropOffId}`);
        } catch (e) {
            console.error('Error update DropOff', e);
        }
    };

    return (
        <PageContainer>
            <div className="row">
                <div className="col-md-6 col-12 mx-auto">
                    <SimpleForm
                        title={'Update DropOff'}
                        formFields={DROP_OFF_FIELDS}
                        initialState={data?.dropOff}
                        onSubmit={submitFormAsync}
                    />
                </div>
            </div>
        </PageContainer>
    )
};