import React, { createContext, useState } from 'react';
import { useServerFetch } from 'boaz-bikes-client-utils';

interface IFleetContext {
  isLoading: boolean;
  availableFleetIds: string[];
  selectedFleetId?: string;
  setSelectedFleetId(fleetId: string | undefined): void;
  refreshFleets(): void;
}

const INITIAL_FLEET_CONTEXT: IFleetContext = {
  isLoading: true,
  availableFleetIds: [],
  selectedFleetId: undefined,
  setSelectedFleetId: () => null,
  refreshFleets: () => null,
};

export const FleetContext = createContext<IFleetContext>(INITIAL_FLEET_CONTEXT);

export const FleetProvider = ({ children }: { children: any }) => {
  const [selectedFleetId, setSelectedFleetId] = useState();

  const { data, isLoading, refreshData: refreshFleets } = useServerFetch<{
    fleets: Array<string | null>;
  }>('get', '/admin/fleets');
  
  const availableFleetIds =
    data?.fleets.filter((fleetId) => fleetId).map((fleetId) => fleetId || '') || [];

  return (
    <FleetContext.Provider
      value={{
        isLoading,
        availableFleetIds,
        selectedFleetId,
        setSelectedFleetId: (fleetId: string) => setSelectedFleetId(fleetId || undefined),
        refreshFleets,
      }}
    >
      {children}
    </FleetContext.Provider>
  );
};
