import React from 'react';
import _ from 'lodash';

export const DisplayJson = ({ data }: { data: any }) => {
  return (
    <dl className={'row'}>
      {_.map(data, (value, key) => [
        <dt key={'dt'} className={'col-md-6'}>
          {key}
        </dt>,
        <dd key={'dd'} className={'col-md-6'}>
          {JSON.stringify(value)}
        </dd>,
      ])}
    </dl>
  );
};

interface IDataRow {
  label: string;
  value: React.ReactNode;
  jsonify?: boolean;
}

export const DisplayData = ({ data }: { data: Array<IDataRow | null> }) => {
  return (
    <div className={'row'}>
      {data.map((row, idx) => {
        if (row == null) {
          return null;
        }

        const { label, value, jsonify } = row;
        return (
          <React.Fragment key={idx}>
            <div className="col-4 mb-2" key={'label'}>
              <p className="text-muted">{label}</p>
            </div>
            <div className="col-8 mb-2" key={'value'}>
              {jsonify ? JSON.stringify(value) : value}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};
