import { Schema } from 'boaz-bikes-types';
import React, { useContext, useState } from 'react';
import { DateInfo } from '../components/DateInfo';
import { Table } from '../components/Table';
import { useSpringFetch } from '../firebase';
import { Card } from '../components/Card';
import { PageContainer } from '../components/PageContainer';
import { Link } from 'react-router-dom';
import { HomeCityContext } from '../home-city/HomeCityContext';

export const RenderDropOffIds = ({ dropOffIds }: { dropOffIds: string[] }) => {
  return (
    <span>
      {dropOffIds?.map((dropOffId) =>
        <>
          <Link to={`/dropoffs/${dropOffId}`}>{dropOffId}</Link>,<br/>
        </>
      )}
    </span>
  )
}

const FilterDateRange = (props: any) => {
  const dateRangeList = [
    { value: 1, label: '1 day' },
    { value: 3, label: '3 days' },
    { value: 7, label: '7 days' },
    { value: 15, label: '15 days' },
    { value: 30, label: '30 days' },
    { value: 90, label: '90 days' },
    { value: 120, label: '120 days' },
    { value: 365, label: '365 days' },
    { value: 0, label: 'All' },
  ];

  const handleChange = (elm: any) => {
    props.setDateRange(elm.target.value);
  }

  return (
    <div>
      Date Range: {' '}
      <select
        // className="form-control"
        value={props.dateRange}
        onChange={handleChange}
      >
        {dateRangeList.map((range) => (
          <option key={range.value} value={range.value}>{range.label}</option>
        ))}
      </select>
    </div>
  )
}

const getDataByDateRange = (data: any[], range: number) => {
  if (range > 0) {
    let dateCompare = new Date();
    dateCompare.setHours(0, 0, 0, 0);
    dateCompare.setDate(dateCompare.getDate() - range + 1);
    data = data.filter(item => {
      let dateOfItem = new Date(item.createdAt);
      if (dateOfItem.getTime() > dateCompare.getTime()) return true;
      return false;
    })
  }
  return data;
}

export function DeliveryHome(){
  const { selectedHomeCityId, getHomeCities } = useContext(HomeCityContext);
  const [dateRange, setDateRange] = useState(0);

  const { data, isLoading, refreshData } = useSpringFetch<{
    deliveries: Schema.Delivery.Admin[];
    users: Schema.User.Admin[];
    dropoffs: Schema.Dropoff.Admin[];
  }>('get', '/admin/deliveries', {
    homeCityId: selectedHomeCityId,
    expand: ['user', 'dropoff'],
  });

  if (!isLoading && data == null) {
    return <div>No Deliveries Available</div>;
  }

  // filter dropOffs
  const deliveries = data ? data.deliveries : [];
  const deliveriesFilterDate = getDataByDateRange(deliveries, dateRange);

  // mapping users
  const userIdMap = new Map(data?.users?.map((user) => [user.id, user]));

  // mapping homeCities
  const allHomeCity = getHomeCities();
  const homeCityMap = new Map(allHomeCity?.map((homecity: any) => [homecity.id, homecity.name]))

  const refreshDateRange = (range: number) => {
    setDateRange(Number(range));
  }

  return (
    <PageContainer>
    <div className="row">
      <div className="col">
        <Card.Container>
          <Card.Header
            title={`Delivery`}
            actions={<FilterDateRange dateRange={dateRange} setDateRange={refreshDateRange} />}
          />
          <Table
            // title={`Drop Offs`}
            // onAddItem={()=>{console.log("hello")}}
            // getRowUrl={(delivery) => `/delivery/${delivery?.id}`}
            isLoading={isLoading}
            rows={deliveriesFilterDate}
            columnRenderInfos={[
              {
                columnName: 'Home City',
                attribute: 'homeCityId',
                renderFunc: ({ homeCityId }) => {
                  let homeName = homeCityMap.get(homeCityId);
                  return homeName || "";
                }
              },
              {
                columnName: 'User ID',
                attribute: 'userId',
                renderFunc: ({ userId }) => {
                  let user = userIdMap.get(userId);
                  return <Link to={`/users/${user?.id}`}>{user?.phoneNumber}</Link>
                }
              },
              {
                columnName: 'Name',
                attribute: 'name'
              },
              {
                columnName: 'Total Inventory',
                attribute: 'totalInventory'
              },
              {
                columnName: 'Tagged for Delivery',
                attribute: 'taggedForDelivery'
              },
              {
                columnName: 'Available Bikes',
                attribute: 'availableBikes'
              },
              
              {
                columnName: 'Delivery Queue',
                attribute: 'dropOffIds',
                renderFunc: ({dropOffIds}) => dropOffIds?.length || 0,
              },
              {
                columnName: 'Drop Off IDs',
                attribute: 'dropOffIds',
                renderFunc: ({dropOffIds}) => <RenderDropOffIds dropOffIds={dropOffIds} />
              },
              {
                columnName: 'Active',
                attribute: 'isActive',
                renderFunc: ({ updatedAt }) => <DateInfo date={updatedAt} />,
              },

              {
                columnName: 'Edit',
                renderFunc: ({ id }) => (
                  <Link to={`/delivery/${id}`}>Edit</Link>
                ),
              },
            ]}
          />
        </Card.Container>
      </div>
    </div>
    </PageContainer>
  );
};
