import React from 'react';
import { SpringButton } from './SpringButton';
import _ from 'lodash';

export const ArrayEditor = <EntryType extends any>({
  arrayEntries,
  defaultEntry,
  label,
  removeRowEnabled = true,
  addRowEnabled = true,
  onUpdateArrayEntries,
  entryRenderFunction,
}: {
  arrayEntries: Array<EntryType>;
  defaultEntry: EntryType;
  label?: string;
  removeRowEnabled?: boolean;
  addRowEnabled?: boolean;
  onUpdateArrayEntries: (updatedEntries: Array<EntryType>) => void;
  entryRenderFunction: (entry: EntryType, onChange: (entry: EntryType) => void) => JSX.Element;
}) => {
  const addEntry = () => onUpdateArrayEntries([...arrayEntries, _.cloneDeep(defaultEntry)]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {label && <label>{label}</label>}
      {arrayEntries.map((entry, index) => {
        const isLastEntry = index === arrayEntries.length - 1;
        return (
          <div className="mb-1" style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
            {entryRenderFunction(entry, (entry) => {
              onUpdateArrayEntries([
                ...arrayEntries.slice(0, index),
                entry,
                ...arrayEntries.slice(index + 1, arrayEntries.length),
              ]);
            })}
            {removeRowEnabled && (
              <SpringButton
                className="btn btn-danger mx-1"
                onClick={() =>
                  onUpdateArrayEntries([
                    ...arrayEntries.slice(0, index),
                    ...arrayEntries.slice(index + 1, arrayEntries.length),
                  ])
                }
              >
                Remove
              </SpringButton>
            )}
            {addRowEnabled && isLastEntry && (
              <SpringButton className="btn btn-success" onClick={addEntry}>
                Add Another
              </SpringButton>
            )}
          </div>
        );
      })}
      {arrayEntries.length < 1 && (
        <SpringButton className="btn btn-success mb-1" onClick={addEntry}>
          Add
        </SpringButton>
      )}
    </div>
  );
};
