import React, { useState, useContext } from 'react';
import { IFormField, INPUT_TYPE } from 'boaz-bikes-forms';
import { SpringClient, Schema } from 'boaz-bikes-types';
import { showMessage } from '../ToastProvider';
import { SimpleForm } from 'boaz-bikes-forms';
import { LoadingView } from '../components/LoadingSpinner';
import { useNavigate } from '../Router';
import { PageContainer } from '../components/PageContainer';
import { HomeCityContext } from '../home-city/HomeCityContext';

export const CreateDock = () => {
  const navigate = useNavigate();
  const { getHomeCities } = useContext(HomeCityContext);

  const [isLoading, setIsLoading] = useState(false);

  const submitFormAsync = async (formState: any) => {
    setIsLoading(true);

    try {
      const { message, dockId } = await SpringClient.post('/admin/docks', formState);
      showMessage(message);
      navigate(`/docks/${dockId}`);
    } catch (e) {
      console.error('Error creating dock', e);
      setIsLoading(false);
    }
  };


  const allHomeCity = getHomeCities();
  let homeCityOptions = [{label: "", value: ""}];
  allHomeCity?.forEach((homecity) =>{
    homeCityOptions.push({
      label: homecity.name,
      value: homecity.id
    })
  })

  const DOCK_FIELDS: IFormField[] = [
    {
      label: 'Display Name',
      name: 'displayName',
      inputType: INPUT_TYPE.TEXT,
    },
    {
      label: 'Home City',
      name: 'homeCityId',
      inputType: INPUT_TYPE.SELECT,
      options: homeCityOptions,
    },
    {
      label: 'Manufacturer',
      name: 'manufacturer',
      inputType: INPUT_TYPE.SELECT,
      options: Object.values(Schema.Dock.Manufacturer),
    },
    {
      label: 'Manufacturer Id',
      name: 'manufacturerId',
      inputType: INPUT_TYPE.TEXT,
    },
    {
      label: 'QR Code',
      name: 'qrCode',
      inputType: INPUT_TYPE.TEXT,
    },
    {
      label: 'Total Bays',
      name: 'numTotalBays',
      inputType: INPUT_TYPE.NUMBER,
    },
    {
      label: 'Available Bays',
      name: 'numAvailableBays',
      inputType: INPUT_TYPE.NUMBER,
    },
    {
      label: 'Available Vehicles',
      name: 'numAvailableVehicles',
      inputType: INPUT_TYPE.NUMBER,
    },
    {
      label: 'Latitude',
      name: 'latitude',
      inputType: INPUT_TYPE.NUMBER,
    },
    {
      label: 'Longitude',
      name: 'longitude',
      inputType: INPUT_TYPE.NUMBER,
    },
    {
      label: 'Is Active',
      name: 'isActive',
      inputType: INPUT_TYPE.BOOLEAN,
    },
    {
      label: 'Is Operator',
      name: 'isOperator',
      inputType: INPUT_TYPE.BOOLEAN,
      description: 'Operator hubs show up as drop off locations on the operations map.',
    },
    {
      label: 'Is High Priority',
      name: 'highPriority',
      inputType: INPUT_TYPE.BOOLEAN,
    },
    {
      label: 'User Parking Allowed',
      name: 'userParkingAllowed',
      inputType: INPUT_TYPE.BOOLEAN,
    },
  ]

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <PageContainer>
      <div className="row">
        <div className="col-md-6 col-12 mx-auto">
          <SimpleForm
            title={'Create Dock'}
            formFields={DOCK_FIELDS}
            onSubmit={submitFormAsync}
          />
        </div>
      </div>
    </PageContainer>
  );
};
