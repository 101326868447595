import React, { useState, useContext } from 'react';
import { useSpringFetch } from '../firebase';
import { FormField, useForm, INPUT_TYPE, IFormField } from 'boaz-bikes-forms';
import { Schema, SpringClient } from 'boaz-bikes-types';
import { CREATE_VEHICLE_FIELDS } from './CreateVehicle';
import { useNavigate } from '../Router';
import { useParams } from 'react-router';
import { LoadingView } from '../components/LoadingSpinner';
import { SpringButton } from '../components/SpringButton';
import { HomeCityContext } from '../home-city/HomeCityContext';
import { Link } from 'react-router-dom';


export const RenderDeliveryStatus = ({ status, driver, customer }: { status: string, driver: Schema.User.Admin, customer: Schema.User.Admin }) => {
  const { WITH_DRIVER, WAITING_CUSTOMER, LATE_CUSTOMER } = Schema.Vehicle.DeliveryStatus;

  if (driver) {
    return (
      <>
        With driver: <Link to={`/users/${driver.id}`}>{driver.phoneNumber}</Link>
      </>
    )
  }

  switch (status) {
    // case WITH_DRIVER: {
    //   return (
    //     <>
    //       With driver: <Link to={`/users/${driver.id}`}>{driver.phoneNumber}</Link>
    //     </>
    //   )
    // }
    case WAITING_CUSTOMER: {
      return (
        <>
          Waiting customer: <Link to={`/users/${customer?.id}`}>{customer?.phoneNumber}</Link>
        </>
      )
    }
    case LATE_CUSTOMER: {
      return (
        <>
          Late customer: <Link to={`/users/${customer?.id}`}>{customer?.phoneNumber}</Link>
        </>
      )
    }
    default: return <>Available</>;
  }
}

const UpdateVehicleForm = ({ vehicle, driver, customer }: { vehicle: Schema.Vehicle.Admin, driver: Schema.User.Admin, customer: Schema.User.Admin }) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const navigate = useNavigate();

  // get options for HomeCity
  const { getHomeCities } = useContext(HomeCityContext);
  const allHomeCity = getHomeCities();
  let homeCityOptions = [{ label: "", value: "" }];
  allHomeCity?.forEach((homecity) => {
    homeCityOptions.push({
      label: homecity.name,
      value: homecity.id
    })
  })

  const VEHICLE_FIELDS: IFormField[] = [
    {
      label: 'Controller',
      name: 'iotController',
      inputType: INPUT_TYPE.SELECT,
      options: Object.values(Schema.Vehicle.IotController),
    },
    {
      label: 'QR Code',
      name: 'qrCode',
      inputType: INPUT_TYPE.TEXT,
      description: 'Must be a unique value across all of your vehicles.',
    },
    {
      label: 'IOT Device ID',
      name: 'iotDeviceId',
      inputType: INPUT_TYPE.TEXT,
      description: (
        <>
          <div>
            This is often the IMEI number corresponding with your SIM card which is a big number.
          </div>

          <div>
            For Segway scooters, you can find the Serial Number on the IOT device. Then you can look
            up the IMEI number from the Serial Number.
          </div>
        </>
      ),
    },
    {
      label: 'Home City',
      name: 'homeCityId',
      inputType: INPUT_TYPE.SELECT,
      options: homeCityOptions,
    },
    {
      label: 'Fleet ID (optional)',
      name: 'fleetId',
      inputType: INPUT_TYPE.TEXT,
      description:
        "You will use fleets when you expand to multiple cities to keep track of rides in each city. It's ok to leave this blank for now.",
    },
    {
      label: 'Status',
      name: 'status',
      inputType: INPUT_TYPE.SELECT,
      options: Object.values(Schema.Vehicle.Status),
    },
    {
      label: 'Type',
      name: 'vehicleTypes',
      inputType: INPUT_TYPE.SELECT,
      options: [{ label: 'scooter-seated', value: 'scooter-seated' }, { label: 'scooter-standing', value: 'scooter-standing' }],
    },
    {
      label: 'Delivery Status',
      name: 'deliveryStatus',
      inputType: INPUT_TYPE.TEXT,
    },
    {
      label: 'Latitude',
      name: 'latitude',
      inputType: INPUT_TYPE.NUMBER,
      max: 90,
      min: -90,
    },
    {
      label: 'Longitude',
      name: 'longitude',
      inputType: INPUT_TYPE.NUMBER,
      max: 180,
      min: -180,
    },
    {
      label: 'Battery Percent',
      name: 'batteryPercent',
      inputType: INPUT_TYPE.NUMBER,
    },
    {
      label: 'Owner ID',
      name: 'bikeOwnerId',
      inputType: INPUT_TYPE.TEXT,
    },
  ];

  const { formState, updateForm } = useForm(VEHICLE_FIELDS, vehicle);

  const updateDeliveryStatus = () => {
    updateForm({
      newValue: Schema.Vehicle.DeliveryStatus.AVAILABLE,
      name: 'deliveryStatus'
    })
  }
  const checkIdOwnerNull = (formState: any) => {
    if ((formState) && (formState != null) && (formState.bikeOwnerId != null)) {
      if (((formState.bikeOwnerId == '') || (formState.bikeOwnerId.replace(/ /g, "") == ''))) {
        formState.bikeOwnerId = null
      }
    }
  };

  const submitFormAsync = async (e: any) => {
    e.preventDefault();
    setIsUpdating(true);

    try {
      checkIdOwnerNull(formState)
      await SpringClient.post(`/admin/vehicles/${vehicle.id}`, formState);
      navigate(`/vehicles/${vehicle.id}`);
    } catch (e) {
      console.error('Error creating vehicle', e);
      setIsUpdating(false);
    }
  };

  if (isUpdating) {
    return <div>Updating...</div>;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-md-6 mx-auto">
          <div className="my-4">
            <h4>Update Vehicle</h4>

            <form onSubmit={submitFormAsync}>
              {VEHICLE_FIELDS.map((formField) => (
                formField.name === 'deliveryStatus'
                  ?
                  <div style={{ marginBottom: '5px' }}>
                    <p>Delivery Status</p>
                    <RenderDeliveryStatus status={formState?.deliveryStatus} customer={customer} driver={driver} />{' '}
                    <button type="button" onClick={updateDeliveryStatus} className='btn btn-sm btn-secondary'>RESET</button>
                  </div>
                  :
                  <FormField
                    key={formField.name}
                    disabled={isUpdating}
                    formField={formField}
                    updateForm={updateForm}
                    formState={formState}
                  />
              ))}

              <SpringButton type="submit" className="btn btn-primary" isLoading={isUpdating}>
                Update
              </SpringButton>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export const UpdateVehicle = () => {
  const { vehicleId } = useParams();

  const { data, isLoading } = useSpringFetch<{ vehicle: Schema.Vehicle.Admin, driver: Schema.User.Admin, customer: Schema.User.Admin }>(
    'get',
    `/admin/vehicles/${vehicleId}`,
    { expand: ['user'] }
  );

  if (isLoading) {
    return <LoadingView />;
  }

  if (data == null) {
    return <div className="alert alert-warning">Vehicle not found</div>;
  }

  const { vehicle, driver, customer } = data;

  return <UpdateVehicleForm vehicle={vehicle} driver={driver} customer={customer} />;
};
