import React from 'react';
import { AppRouter } from './Router';
import { UserProvider } from './auth/UserContext';
import { ToastProvider } from './ToastProvider';

import 'jquery';
import 'popper.js';
import 'bootstrap';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { CompanyProvider } from './auth/CompanyContext';

library.add(fas);

const App: React.FC = () => {
  return (
    <CompanyProvider>
      <UserProvider>
        <ToastProvider>           
            <AppRouter />          
        </ToastProvider>
      </UserProvider>
    </CompanyProvider>
  );
};

export default App;
