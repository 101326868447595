import React from 'react';

interface IParams {
  isSuccess: boolean;
  children: any;
}

export const BadgePill = ({ isSuccess, children }: IParams) => {
  return (
    <h5>
      <span className={`badge badge-pill ${isSuccess ? 'badge-success' : 'badge-danger'}`}>
        {children}
      </span>
    </h5>
  );
};
