import React, { useContext, useState } from 'react';
import { RentalList } from './RentalList';
import { Schema } from 'boaz-bikes-types';
import { TabRow } from '../components/Tab';
import { Card } from '../components/Card';
import { PageContainer } from '../components/PageContainer';
import { FilterAdminAreas } from '../utils/FilterAdminAreas';
import { LoadingSpinner } from '../components/LoadingSpinner';
import { HomeCityContext } from '../home-city/HomeCityContext';
import moment from 'moment';

enum RentalTab {
  PAST_RENTAL = 'Past Rentals',
  LOW_RATING = 'Low Rating',
}

type RentalFilter = (user: Schema.Rental.Admin) => boolean;

const TAB_TO_FILTER: Partial<Record<RentalTab, RentalFilter>> = {
  [RentalTab.PAST_RENTAL]: (rental) => (rental.endAt? true : false),
  [RentalTab.LOW_RATING]: (rental) => (rental.rating ? rental.rating < 5 : false),
};

export function RentalsHome() {
  const [selectedTab, setSelectedTab] = React.useState<RentalTab>(RentalTab.PAST_RENTAL);
  const {adminArea,adminAreaCity,isLoading:isLoadingAdminArea }  = FilterAdminAreas();
  const {isLoading:isLoadingHomeCities } = useContext(HomeCityContext);
  const defaultStart = moment().subtract(10, "day").toDate();
  const [ textSearch, setTextSearch ] = useState('');

  if (isLoadingAdminArea || isLoadingHomeCities) return <LoadingSpinner></LoadingSpinner>  
  return (
    <PageContainer>
      <TabRow
        tabs={Object.values(RentalTab).map((tab) => ({
          title: tab,
          onClick: () => setSelectedTab(tab),
          isSelected: selectedTab === tab,
        }))}
      />
      <div className="row mb-4">
        <div className="col">
          <Card.Container>
            <RentalList title={'Past Rentals'} filter={TAB_TO_FILTER[selectedTab]} 
                  startAt={defaultStart}
                  adminArea={adminArea} 
                  adminAreaCity={adminAreaCity}
                  pageSize={100}
                  onGoing={false}
                  setTextSearch={setTextSearch}
                  textSearch={textSearch}
              />
          </Card.Container>
        </div>
      </div>
    </PageContainer>
  );
}
