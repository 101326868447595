import React, { useContext } from 'react';
import { useSpringFetch } from '../firebase';
import { Schema } from 'boaz-bikes-types';
import { DisplayData } from '../components/DisplayData';
import { Link } from 'react-router-dom';
import { LoadingView } from '../components/LoadingSpinner';
import { SpringQRCode } from '../components/SpringQRCode';
import { Location } from '../components/Location';
import { MapComponent } from '../components/MapComponent';
import { Marker } from 'react-google-maps';
import { Card } from '../components/Card';
import { PageContainer } from '../components/PageContainer';
import { HomeCityContext } from '../home-city/HomeCityContext';


export function DockInfo({ match }: any) {
  const { dockId } = match.params;
  const { selectedHomeCityId, getHomeCities } = useContext(HomeCityContext);

  const { data, isLoading } = useSpringFetch<{
    dock: Schema.Dock.Admin;
  }>('get', `/admin/docks/${dockId}`);

  if (isLoading) {
    return <LoadingView />;
  }
  
  if (data == null || data?.dock === null) {
    return <div className="alert alert-warning">Dock not found</div>;
  }

  const { dock } = data;
  // mapping homeCities
  const allHomeCity = getHomeCities();
  const homeSelected = allHomeCity?.find((homecity) => homecity.id === dock.homeCityId);

  return (
    <PageContainer>
      <div className="row mb-4">
        <div className="col-12">
          <Card.Container>
            <Card.Header title="Details" actions={<Link to={`/docks/${dockId}/edit`}>Edit</Link>} />
            <Card.Body>
              <div className="row">
                <div className="col-lg-6 col-xs-12">
                  <DisplayData
                    data={[
                      {
                        label: 'Home City',
                        value: homeSelected?.name || ""
                      },
                      {
                        label: 'Display Name',
                        value: dock.displayName
                      },
                      {
                        label: 'Active',
                        value: `${dock.isActive}`
                      },
                      {
                        label: 'Total bays',
                        value: dock.numTotalBays
                      },
                      {
                        label: 'Available bays',
                        value: dock.numAvailableBays
                      },
                      {
                        label: 'Available Vehicles',
                        value: dock.numAvailableVehicles
                      },
                      {
                        label: 'QR Code',
                        value: dock.qrCode ? <SpringQRCode qrCode={dock.qrCode} /> : null,
                      },
                      {
                        label: 'Is High Priority',
                        value: dock.highPriority? `${dock.highPriority}` : 'false',
                      },
                      {
                        label: 'User Parking Allowed',
                        value: dock.userParkingAllowed? `${dock.userParkingAllowed}` : 'false',
                      },
                      {
                        label: 'Location',
                        value: <Location coordinate={dock} />,
                      },
                      {
                        label: 'ID',
                        value: dock.id,
                      },
                    ]}
                  />
                </div>

                <div className="col-lg-6 col-xs-12">
                  <MapComponent
                    defaultZoom={13}
                    defaultCenter={{ lat: dock.latitude, lng: dock.longitude }}
                  >
                    <Marker
                      icon={'http://maps.google.com/mapfiles/ms/icons/red-dot.png'}
                      position={{ lat: dock.latitude, lng: dock.longitude }}
                    />
                  </MapComponent>
                </div>
              </div>
            </Card.Body>
          </Card.Container>
        </div>
      </div>
    </PageContainer>
  );
}
