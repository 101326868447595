import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Message } from 'boaz-bikes-types';

import 'react-toastify/dist/ReactToastify.css';

export const showMessage = (message: Message.IMessage) => {
  const { messageType } = message;
  const toastType = messageType === Message.Type.Danger ? 'error' : messageType;

  toast(message.messageText, {
    type: toastType,
  });
};

export const ToastProvider = ({ children }: { children: React.ReactNode }) => (
  <>
    {children}
    <ToastContainer />
  </>
);
