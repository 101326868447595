import React, { useContext, useState } from 'react';
import { RentalList } from './RentalList';
import { Schema } from 'boaz-bikes-types';
import { Card } from '../components/Card';
import { PageContainer } from '../components/PageContainer';
import { FilterAdminAreas } from '../utils/FilterAdminAreas';
import { LoadingSpinner } from '../components/LoadingSpinner';
import { HomeCityContext } from '../home-city/HomeCityContext';
import moment from 'moment';

enum RentalTab {
  CURRENT = 'Current',
}

type RentalFilter = (user: Schema.Rental.Admin) => boolean;

const TAB_TO_FILTER: Partial<Record<RentalTab, RentalFilter>> = {
  [RentalTab.CURRENT]: (rental) => (!rental.endAt),
};

export function CurrentRentals() {
  const [selectedTab] = React.useState<RentalTab>(RentalTab.CURRENT);
  const {adminArea,adminAreaCity,isLoading:isLoadingAdminArea }  = FilterAdminAreas();
  const {isLoading:isLoadingHomeCities } = useContext(HomeCityContext);
  const defaultStart = moment().subtract(1, "day").toDate();
  const [ textSearch, setTextSearch ] = useState('');

  if (isLoadingAdminArea || isLoadingHomeCities) return <LoadingSpinner></LoadingSpinner>  
  return (
    <PageContainer>
      <div className="row mb-4">
        <div className="col">
          <Card.Container>
            <RentalList title={'Current Rentals'} filter={TAB_TO_FILTER[selectedTab]} 
                  startAt={defaultStart}
                  adminArea={adminArea} 
                  adminAreaCity={adminAreaCity}
                  pageSize={100}
                  onGoing={true}
                  setTextSearch={setTextSearch}
                  textSearch={textSearch}
              />
          </Card.Container>
        </div>
      </div>
    </PageContainer>
  );
}
