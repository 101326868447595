import React, { useContext, useState } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import MaterialTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TablePagination from '@material-ui/core/TablePagination';
import { Tooltip, IconButton } from '@material-ui/core';
import { Refresh, Add, Search } from '@material-ui/icons';
import { TextInput } from './TextInput';
import Checkbox from '@material-ui/core/Checkbox';
import _ from 'lodash';
import { LoadingView } from './LoadingSpinner';
import { Link } from 'react-router-dom';
import { FILE } from 'dns';
import { createNull } from 'typescript';
import { HomeCityContext } from '../home-city/HomeCityContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      overflowX: 'auto',
    },
    table: {
      minWidth: 650,
    },
    row: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
      '&:hover': {
        textDecoration: 'none',
      },
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    toolbar: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    spacer: {
      flex: '1 1 100%',
    },
    actions: {
      color: theme.palette.text.secondary,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      width: '40%'
    },
    title: {
      flex: '0 0 auto',
    },
  })
);

function desc<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] === null) {
    return -1;
  }
  if (a[orderBy] === null) {
    return 1;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort<T>(array: T[], cmp: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting<T>(order: Order, orderBy: keyof T): (a: T, b: T) => number {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function containsString(object: any, valueToMatch: string): boolean {
  return Object.values(object).some((objValue) =>
    String(objValue)
      .toLowerCase()
      .includes(valueToMatch.toLowerCase())
  );
}

type Order = 'asc' | 'desc';

type IFunctionToRenderColumn<T> = (row: T) => React.ReactNode;

interface IColumnRenderAttribute<T> {
  columnName: string;
  attribute: keyof T;
  shouldJsonifyValue?: boolean;
}

interface IColumnRenderFunc<T> {
  columnName: string;
  renderFunc: IFunctionToRenderColumn<T>;
}

interface IColumnRenderFuncSortable<T> extends IColumnRenderFunc<T> {
  attribute: keyof T;
}

export type IColumnRenderInfo<T> =
  | IColumnRenderAttribute<T>
  | IColumnRenderFunc<T>
  | IColumnRenderFuncSortable<T>;

interface ITableProps<T> {
  title?: string | React.ReactNode;
  rows: T[];
  columnRenderInfos: IColumnRenderInfo<T>[];
  rowsPerPage?: number;
  isLoading?: boolean;
  autoFocusSearch?: boolean;
  selectedItems?: T[];
  selectionTools?: React.ReactNode[];
  setSelectedItems?: (items: T[]) => void;
  getRowUrl?: (item: T) => string;
  refreshData?: () => void;
  onAddItem?: () => void;
  onAddCsv?: () => void;
  currentPage?: number,
  setCurrentPage?: (page: number) => void,
  dataLength?: number,
  rowsState?: any[]
  setRowsState?: (state: any) => void,
  setPageSize?: (size: any) => void,
  filterTab?: (obj: T) => boolean,
  searchInDb?: (text: string) => void,
  showCount?: boolean;
}

const TableToolbar = ({
  title,
  searchText,
  autoFocusSearch,
  setSearchText,
  refreshData,
  onAddItem,
  classes,
  onAddCsv,
  searchInDb,
}: {
  title?: string | React.ReactNode;
  searchText: string;
  autoFocusSearch: boolean;
  setSearchText: (text: string) => void;
  refreshData?: () => void;
  onAddItem?: () => void;
  classes: any;
  onAddCsv?: () => void;
  searchInDb?: (text: string) => void;
}) => {

  return (
    <Toolbar>
      <div className={classes.title}>
        {typeof title == 'string' ? (
          <Typography variant="h6" id="tableTitle">
            {title}
          </Typography>
        ) : (
          title && title
        )}
      </div>
      <div className={classes.spacer} />
      {onAddCsv && (
        <div style={{ width: 300, marginRight: 10, alignItems: 'center' }}>
          <button
            type="button"
            onClick={onAddCsv}
            className="btn btn-primary"
            style={{ display: 'flex', flexDirection: 'row', width: "100%" }}
          >+ CSV Import</button>
        </div>
      )}
      <div className={classes.actions}>
        {onAddItem && (
          <button
            type="button"
            onClick={onAddItem}
            className="btn btn-primary"
            style={{ display: 'flex', flexDirection: 'row', marginRight: 24 }}
          >
            <Add />
            <div>Add</div>
          </button>
        )}
        <TextInput
          style={{ width: 200, marginLeft: 10 }}
          autoFocus={autoFocusSearch}
          value={searchText}
          onNewValue={(text) => setSearchText(text)}
          icon={<Search />}
        />
        <button
          type="button"
          onClick={() => {
            if (searchInDb) {
              searchInDb(searchText)
              setSearchText('')
            }
          }}
          className="btn btn-primary"
          style={{ display: 'flex', flexDirection: 'row', marginLeft: 10, marginRight: 10 }}
        >
          <div>Search</div>
        </button>
        {refreshData && (
          <Tooltip title="refresh">
            <IconButton aria-label="refresh" onClick={refreshData}>
              <Refresh />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

const SelectionControls = ({
  selectedItemCount,
  selectionTools,
}: {
  selectedItemCount: number;
  selectionTools: React.ReactNode[];
}) => {
  return (
    <div
      hidden={selectedItemCount < 1}
      style={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        backgroundColor: 'rgb(253, 224, 234)',
        color: '#f50057',
        padding: 20,
      }}
    >
      <div style={{ display: 'flex', flex: 1 }}>{`${selectedItemCount} selected`}</div>
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        {selectionTools.map((tool, idx) => (
          <div key={idx} style={{ paddingLeft: 10 }}>
            {tool}
          </div>
        ))}
      </div>
    </div>
  );
};

export function Table<T extends any>({
  title,
  rows,
  columnRenderInfos,
  rowsPerPage: initialRowsPerPage = 100,
  isLoading,
  refreshData,
  onAddItem,
  onAddCsv,
  autoFocusSearch = false,
  selectionTools = [],
  selectedItems = [],
  setSelectedItems,
  getRowUrl,
  currentPage,
  setCurrentPage,
  dataLength,
  rowsState,
  setRowsState,
  filterTab,
  searchInDb,
  showCount,
}: ITableProps<T>) {
  const classes = useStyles();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>();
  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [searchText, setSearchText] = useState('');
  const { selectedHomeCityId, getHomeCities } = useContext(HomeCityContext);

  function handleSort(property: string) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }


  function handleChangePage(event: unknown, newPage: number) {
    if (setCurrentPage && currentPage && ((page * rowsPerPage + rowsPerPage + 1) >= (currentPage + rowsPerPage)) && newPage > lastPage) {
      setCurrentPage(currentPage + rowsPerPage)
    }
    if (newPage > lastPage) setLastPage(newPage)
    setPage(newPage);
    if (currentPage && setRowsState && rowsState && ((page * rowsPerPage + rowsPerPage + 1) >= (currentPage + rowsPerPage)) && newPage > lastPage) {
      const aux: T[] = rowsState.concat(rows)
      setRowsState(aux)
    }
  }
  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleRowsSelected(event: React.ChangeEvent<HTMLInputElement>, rows: T[]) {
    if (!setSelectedItems) {
      return;
    }
    if (event.target.checked) {
      setSelectedItems(_.union(selectedItems, rows));
    } else {
      setSelectedItems(_.difference(selectedItems, rows));
    }
  }

  // filteredRows = rows.filter((row) => (searchText ? containsString(row, searchText) : true));

  let filteredRowsPre = rowsState ? rowsState.concat(rows) : rows;
  let filteredBycity = selectedHomeCityId && selectedHomeCityId != 'All Home City' ? filteredRowsPre.filter((obj) => obj.homeCityId == selectedHomeCityId) : filteredRowsPre;
  let filteredByTab = filterTab ? filteredBycity.filter(filterTab) : filteredBycity;
  const filteredRows = filteredByTab.filter((preSearchRow) => (searchText ? containsString(preSearchRow, searchText) : true));
  const rowsPerPageOptions = [100];
  // if (filteredRows.length > 100) {
  //   rowsPerPageOptions.push(filteredRows.length);
  // }
  return (
    <div>
      <TableToolbar
        searchText={searchText}
        autoFocusSearch={autoFocusSearch}
        classes={classes}
        refreshData={refreshData}
        onAddItem={onAddItem}
        title={title}
        setSearchText={setSearchText}
        onAddCsv={onAddCsv}
        searchInDb={searchInDb}
      />

      {isLoading ? (
        <LoadingView />
      ) : (
        <>
          <SelectionControls
            selectedItemCount={selectedItems.length}
            selectionTools={selectionTools}
          />
          <div className={classes.tableContainer}>
            <MaterialTable className={classes.table}>
              <TableHead>
                <TableRow>
                  {setSelectedItems && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={_.difference(filteredRows, selectedItems).length === 0}
                        onChange={(event) => filteredRows ? handleRowsSelected(event, filteredRows) : false}
                      />
                    </TableCell>
                  )}
                  {showCount && <TableCell>#</TableCell>}
                  {//
                    // @ts-ignore
                    columnRenderInfos.map(({ columnName, attribute }, idx) => (
                      <TableCell key={idx}>
                        {attribute ? (
                          <TableSortLabel
                            active={orderBy === attribute}
                            direction={order}
                            onClick={() => handleSort(attribute)}
                          >
                            {columnName}
                            {orderBy === attribute ? (
                              <span className={classes.visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                              </span>
                            ) : null}
                          </TableSortLabel>
                        ) : (
                          columnName
                        )}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {(orderBy ? stableSort(filteredRows, getSorting(order, orderBy)) : filteredRows)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, idx) => (
                    <TableRow
                      // @ts-ignore
                      component={getRowUrl ? Link : 'tr'}
                      to={getRowUrl && getRowUrl(row)}
                      hover
                      key={idx}
                      className={classes.row}
                    >
                      {setSelectedItems && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            onClick={(event) => event.stopPropagation()}
                            checked={selectedItems.includes(row)}
                            onChange={(event) => handleRowsSelected(event, [row])}
                          />
                        </TableCell>
                      )}
                      {showCount &&
                        <TableCell>
                          {(page * rowsPerPage) + (idx + 1)}
                        </TableCell>
                      }
                      {columnRenderInfos.map((columnRenderInfo, idx) => {
                        // @ts-ignore
                        const { renderFunc } = columnRenderInfo;

                        if (renderFunc) {
                          const { renderFunc } = columnRenderInfo as IColumnRenderFunc<T>;
                          return (
                            <TableCell align="left" key={idx}>
                              {renderFunc(row)}
                            </TableCell>
                          );
                        }

                        const {
                          attribute,
                          shouldJsonifyValue,
                        } = columnRenderInfo as IColumnRenderAttribute<T>;
                        let value = row[attribute];
                        if (shouldJsonifyValue) {
                          value = JSON.stringify(value);
                        }

                        return (
                          <TableCell align="left" key={idx}>
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </MaterialTable>
          </div>
        </>
      )}

      {!isLoading && filteredRows.length < 1 && (
        <Typography
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            padding: 20,
          }}
          variant="body1"
        >
          No Matching Data
        </Typography>
      )}
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={dataLength ? dataLength : filteredRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}
