import React, { useEffect, useState } from 'react';
import { FormField, IFormField, INPUT_TYPE, useForm } from 'boaz-bikes-forms';
import { CREATE_VEHICLE_FIELDS } from './CreateVehicle';
import { Spacer } from './VehicleInfo';
import { showMessage } from '../ToastProvider';
import { Message, SpringClient } from 'boaz-bikes-types';
import ReactJson from 'react-json-view';
import { SpringButton } from '../components/SpringButton';

export const SEND_COMMAND_FIELDS: IFormField[] = [
  {
    label: 'Path',
    name: 'path',
    inputType: INPUT_TYPE.TEXT,
  },
  {
    label: 'Params',
    name: 'params',
    inputType: INPUT_TYPE.TEXTAREA,
  },
  {
    label: 'Data',
    name: 'data',
    inputType: INPUT_TYPE.TEXTAREA,
  },
  {
    label: 'Method',
    name: 'method',
    inputType: INPUT_TYPE.SELECT,
    options: ['get', 'post'],
  },
];

export const SendIotCommand = ({
  vehicleId,
  iotDeviceId,
}: {
  vehicleId: string;
  iotDeviceId?: string | null;
}) => {
  const { formState, updateForm } = useForm(CREATE_VEHICLE_FIELDS);
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState<object | null>(null);

  useEffect(() => {
    updateForm({
      name: 'params',
      newValue: JSON.stringify({ iotCode: iotDeviceId, vehicleCode: iotDeviceId }),
    });
  }, [iotDeviceId, updateForm]);

  const submitFormAsync = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const dataToSend = {
        vehicleId,
        method: formState.method,
        path: formState.path,
        data: formState.data ? JSON.parse(formState.data) : null,
        params: formState.params ? JSON.parse(formState.params) : null,
      };
      const response = await SpringClient.post<any, object>(
        `/admin/iot/${vehicleId}/command`,
        dataToSend
      );

      setResult(response);
      setIsLoading(false);
    } catch (e) {
      console.error('Error sending command', e);
      showMessage({
        messageText: 'Error sending request.',
        messageType: Message.Type.Warning,
      });
      setIsLoading(false);
    }
  };

  return (
    <div className="row">
      <div className="col-12 col-md-6 mx-auto">
        <div className="my-4">
          <p>
            <a
              href="https://api.segway.pt/doc/index.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Segway Documentation
            </a>
          </p>

          <form onSubmit={submitFormAsync}>
            {SEND_COMMAND_FIELDS.map((formField) => (
              <FormField
                key={formField.name}
                disabled={isLoading}
                formField={formField}
                updateForm={updateForm}
                formState={formState}
              />
            ))}

            <SpringButton type="submit" className="btn btn-primary" isLoading={isLoading}>
              Submit
            </SpringButton>
          </form>

          <Spacer />

          {result && <ReactJson theme="apathy:inverted" src={result} />}
        </div>
      </div>
    </div>
  );
};
