import { Schema } from 'boaz-bikes-types';
import moment from 'moment';
import React, { useState, useContext } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Card } from '../components/Card';
import { DateInfo } from '../components/DateInfo';
import { DatePeriodProvider } from '../components/DatePeriodProvider';
import { DisplayData } from '../components/DisplayData';
import { LoadingView } from '../components/LoadingSpinner';
import { Location } from '../components/Location';
import { PageContainer } from '../components/PageContainer';
import { SpringButton } from '../components/SpringButton';
import { SpringQRCode } from '../components/SpringQRCode';
import { useSpring, useSpringFetch } from '../firebase';
import { PingsMap } from '../map/PingsMap';
import { RentalList } from '../rentals/RentalList';
import { DocumentTitle } from '../utils/ApiClient';
import { getRentalStats } from '../utils/rentals';
import { SendIotCommand } from './SendIotCommand';
import { SendOmniMessage } from './SendOmniMessage';
import { VehicleFeed } from './VehicleFeed';
import { LastRentalDisplay, VehicleStatusDropdown } from './VehiclesHome';
import { SendFitriderMessage } from './SendFitriderMessage';
import { Modal } from '@material-ui/core';
import ReactJson from 'react-json-view';
import { HomeCityContext } from '../home-city/HomeCityContext';
import {RenderDeliveryStatus} from './UpdateVehicle';
import { FilterAdminAreas } from '../utils/FilterAdminAreas';

export const Spacer = () => <hr className="my-5" />;

const ControlButton = ({
  url,
  buttonText,
  isOutline,
}: {
  url: string;
  buttonText: string;
  isOutline: boolean;
}) => {
  const { isLoading, callFunction } = useSpring('post', url);

  return (
    <SpringButton
      isLoading={isLoading}
      className={`btn  mr-2 ${isOutline ? 'btn-outline-danger' : 'btn-danger'}`}
      onClick={() => {
        callFunction({});
      }}
    >
      {buttonText}
    </SpringButton>
  );
};

export const VehicleControlButtons = ({ vehicleId }: { vehicleId: string }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }} className="my-2">
      <ControlButton
        url={`/admin/vehicles/${vehicleId}/iot/lock`}
        buttonText={'Lock'}
        isOutline={false}
      />
      <ControlButton
        url={`/admin/vehicles/${vehicleId}/iot/unlock`}
        buttonText={'Unlock'}
        isOutline={true}
      />
    </div>
  );
};

const ShowPhoneUserUnlocked = ({unlockedBy}:{unlockedBy:string|null}) =>{

  return (
    <div><Link   to={`/users/${unlockedBy}`} > {unlockedBy} </Link></div>
  )
}

const VehicleDetails = ({
  vehicle,
  driver,
  customer,
  refreshData,
}: {
  vehicle: Schema.Vehicle.Admin;
  driver: Schema.User.Admin;
  customer: Schema.User.Admin;
  refreshData: () => void;
}) => {
  const [open, setOpen] = useState(false);
  const { selectedHomeCityId, getHomeCities } = useContext(HomeCityContext);

  // mapping homeCities
  const allHomeCity = getHomeCities();
  const homeCity = allHomeCity?.find(homecity => homecity.id === vehicle.homeCityId);

  return (
    <Card.Container>
      <Card.Header
        title={'Details'}
        actions={<Link to={`/vehicles/${vehicle.id}/edit`}>Edit</Link>}
      />
      <Card.Body>
        <DisplayData
          data={[
            {
              label: 'QR Code',
              value: vehicle?.qrCode ? <SpringQRCode qrCode={vehicle?.qrCode} /> : null,
            },
            {
              label: 'Status',
              value: (
                <VehicleStatusDropdown
                  ids={[vehicle.id]}
                  status={vehicle.status}
                  refreshData={refreshData}
                />
              ),
            },
            {
              label: 'Locked',
              value: `${!vehicle.isUnlocked}`
            },
            {
              label: 'Unlocked By',
              value: vehicle.unlockedBy?  < ShowPhoneUserUnlocked unlockedBy={vehicle.unlockedBy} /> : <></>
            },
            {
              label: 'Battery',
              value: `${vehicle.batteryPercent}%`
            },
            {
              label: 'IOT Controller',
              value: vehicle.iotController
            },
            {
              label: 'Home City',
              value: `${homeCity?.name || ""}`
            },
            {
              label: 'Type',
              value: vehicle.vehicleTypes
            },
            {
              label: 'Delivery Status',
              value: <RenderDeliveryStatus driver={driver} customer={customer} status={vehicle?.deliveryStatus} />
            },
            {
              label: 'Location',
              value: <Location coordinate={vehicle} />
            },
            {
              label: 'ID',
              value: vehicle.id
            },
            {
              label: 'Actions',
              value: <VehicleControlButtons vehicleId={vehicle.id} />
            },
          ]}
        />

        <div className={'btn btn-sm btn-link'} onClick={() => setOpen(true)}>
          More Info
        </div>

        <Modal open={open} onClose={() => setOpen(false)}>
          <div
            style={{
              maxWidth: 600,
              borderRadius: 10,
              boxShadow: '1px 5px 15px rgba(0, 0, 0, .2)',
              background: 'white',
            }}
            className={'p-4 mx-auto mt-5'}
          >
            <ReactJson src={vehicle} />
          </div>
        </Modal>
      </Card.Body>
    </Card.Container>
  );
};

export const VehicleStats = ({
  vehicle,
  rentals,
}: {
  vehicle: Schema.Vehicle.Admin;
  rentals: Schema.Rental.Admin[];
}) => {
  const { dollarsSpent, avgRentalRating } = getRentalStats(rentals);
  return (
    <Card.Container>
      <Card.Header title={'Stats'} />
      <Card.Body>
        <DisplayData
          data={[
            { label: 'Activated', value: <DateInfo date={vehicle.createdAt} /> },
            { label: 'Last Ping', value: <DateInfo date={vehicle.lastPingedAt} /> },
            {
              label: 'Last Rental',
              value: (
                <LastRentalDisplay
                  currentRentalIds={vehicle.currentRentalId}
                  lastRentalAt={vehicle.lastRentalAt}
                />
              ),
            },
            {
              label: 'Rentals',
              value: `${rentals.length}`,
            },
            {
              label: 'Revenue Generated',
              value: `$${dollarsSpent}`,
            },
            {
              label: 'Average Rating',
              value: avgRentalRating,
            },
          ]}
        />
      </Card.Body>
    </Card.Container>
  );
};

export function VehicleInfo() {
  const { vehicleId } = useParams();

  if (!vehicleId) {
    throw new Error('Vehicle ID not set');
  }
  const {adminArea,adminAreaCity,isLoading:isLoadingAdminArea }  = FilterAdminAreas();

  const {
    data: vehicleData,
    isLoading: isLoadingVehicleData,
    refreshData: refreshVehicleData,
  } = useSpringFetch<{
    vehicle: Schema.Vehicle.Admin;
    driver: Schema.User.Admin;
    customer: Schema.User.Admin;
  }>('get', `/admin/vehicles/${vehicleId}`, {expand: ['user']}, { pollingInterval: 10_000 });  

  const { data: rentalData } = useSpringFetch<{
    rentals: Schema.Rental.Admin[];
  }>('get', `/admin/rentals`, { vehicleId });

  if (isLoadingVehicleData && !vehicleData && isLoadingAdminArea) {
    return <LoadingView />;
  }

  if (vehicleData == null || vehicleData?.vehicle === null) {
    return <div className="alert alert-warning">Vehicle not found</div>;
  }
  
  const vehicle = vehicleData?.vehicle;
  const driver = vehicleData?.driver;
  const customer = vehicleData?.customer;
  const rentals = rentalData?.rentals || [];

  return (
    <PageContainer>
      <DocumentTitle title={`Vehicle ${vehicle?.qrCode}`} />

      <div className="row mb-4">
        <div className="col-lg-6 col-xs-sm mb-lg-0 mb-2">
          <VehicleDetails vehicle={vehicle} driver={driver} customer={customer} refreshData={refreshVehicleData} />
        </div>
        <div className="col-lg-6 col-xs-sm mb-lg-0 mb-2">
          <VehicleStats vehicle={vehicle} rentals={rentals} />
        </div>
      </div>

      <div className="row mb-4">
        <div className="col">
          <Card.Container>
            <Card.Header title="Ping Map" />
            <Card.Body>
              <DatePeriodProvider
                initialStart={moment()
                  .subtract(2, 'hours')
                  .format()}
                initialEnd={moment().format()}
                render={({ start, end }) => {
                  return (
                    <PingsMap
                      vehicleId={vehicleId}
                      startAt={start}
                      endAt={end}
                      startCoordinate={{ latitude: vehicle.latitude, longitude: vehicle.longitude }}
                    />
                  );
                }}
              />
            </Card.Body>
          </Card.Container>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col">
          <Card.Container>
            <Card.Header title="Rentals" />
            <RentalList vehicleId={vehicle.id} 
            adminArea={adminArea} 
            adminAreaCity={adminAreaCity}
            pageSize={100}
             />
          </Card.Container>
        </div>
      </div>

      <VehicleFeed
        initialStart={moment()
          .subtract(2, 'hours')
          .format()}
        initialEnd={moment()
          .add(1, 'hours')
          .format()}
        vehicle={vehicle}
      />

      <div className="row mb-4">
        <div className="col">
          <Card.Container>
            <Card.Header title="Send IOT Command" />
            <Card.Body>
              <SendCommand vehicle={vehicle} />
            </Card.Body>
          </Card.Container>
        </div>
      </div>
    </PageContainer>
  );
}

const SendCommand = ({ vehicle }: { vehicle: Schema.Vehicle.Admin }) => {
  if (vehicle.iotController === Schema.Vehicle.IotController.OMNI) {
    return <SendOmniMessage vehicleId={vehicle.id} iotDeviceId={vehicle.iotDeviceId} />;
  }

  if (vehicle.iotController === Schema.Vehicle.IotController.FITRIDER) {
    return <SendFitriderMessage vehicleId={vehicle.id} iotDeviceId={vehicle.iotDeviceId} />;
  }

  return <SendIotCommand vehicleId={vehicle.id} iotDeviceId={vehicle.iotDeviceId} />;
};
