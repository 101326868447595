import React, { useContext, useState } from 'react';
import { FleetContext } from '../vehicles/FleetContext';
import { HomeCityContext } from '../home-city/HomeCityContext';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { COMPANY_ID } from '../utils/ApiClient';
import { Add } from '@material-ui/icons';
import { useNavigate } from '../Router';
import { FilterAdminAreas } from '../utils/FilterAdminAreas';

const ALL_FLEETS = 'All Fleets';
const ALL_HOME_CITY = 'All Home City';


export const NavMenuList = () => {

  let { adminAreaCity: adminAreaCity } = FilterAdminAreas();

  return (
    <>
      <NavItem label={'Users'} path={'/users/'} />
      <NavItem label={'Users Verification'} path={'/users-verification/'} />
      <NavItem label={'Users Passes'} path={'/users-passes/'} />
      <NavItem label={'Vehicles'} path={'/vehicles/'} />
      <NavItem label={'Past Rentals'} path={'/rentals/'} />
      <NavItem label={'Current Rentals'} path={'/current-rentals/'} />
      <NavItem label={'Drop Offs'} path={'/dropoffs/'} />
      <NavItem label={'Tasks'} path={'/tasks/'} />
      <NavItem label={'Events'} path={'/events/'} />
      <NavItem label={'Docks'} path={'/docks/'} />
      <NavItem label={'Promos'} path={'/promos/'} />
      <NavItem label={'Support'} path={'/support/'} />
      <NavItem label={'Vehicle Alerts'} path={'/vehicle-alerts/'} />
      <NavItem label={'Map'} path={'/map/'} />
      <NavItem label={'IRIS Map'} path={'/mapheat/'} />
      <NavItem label={'Geofence'} path={'/geofence/'} />
      <NavItem label={'Stats'} path={'/statistics/'} />
      {adminAreaCity ? <NavItem label={'Edit City'} path={`/home-city/${adminAreaCity}/edit`} />
        : <NavItem label={'Config'} path={'/config'} />}
    </>
  )
}

const FleetSelector = () => {
  const { availableFleetIds, selectedFleetId, setSelectedFleetId } = useContext(FleetContext);
  const { isLoading } = useContext(HomeCityContext);
  return (
    <select
      className="form-control col-2"
      value={selectedFleetId || ALL_FLEETS}
      onChange={(e) => setSelectedFleetId(e.target.value)}
      disabled={isLoading}
    >
      <option key={'all'} value={''}>
        {ALL_FLEETS}
      </option>
      {availableFleetIds.map((fleetId) => (
        <option key={fleetId}>{fleetId}</option>
      ))}
    </select>
  );
};

const HomeCitySelector = () => {

  const { getHomeCities, selectedHomeCityId, setSelectedHomeCityId, isLoading } = useContext(HomeCityContext);
  const availableHomeCityIds = getHomeCities();
  // const homeCityAdminId = FilterAdminAreas()
  return (
    <select
      className="form-control col-2"
      value={selectedHomeCityId || ALL_FLEETS}
      onChange={(e) => setSelectedHomeCityId(e.target.value)}
      disabled={isLoading}
    >
      <option key={'all'} value={ALL_HOME_CITY}>
        {ALL_HOME_CITY}
      </option>
      {availableHomeCityIds?.map((homecity) => (
        <option key={homecity.id} value={homecity.id}>{homecity.name}</option>
      ))}
    </select>
  );
};

const NavItem = ({ label, path }: { label: string; path: string }) => {
  return (
    <li className="nav-item">
      <Link className="nav-link" to={path}>
        {label}
      </Link>
    </li>
  );
};

export const Navbar = () => {
  const navigate = useNavigate();
  const { isLoading } = useContext(HomeCityContext);
  return (
    <nav className="navbar navbar-expand-md navbar-light bg-light">
      <Link className="navbar-brand" to="/">
        {_.capitalize(COMPANY_ID)}
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav mr-auto d-none d-md-block"></ul>
        <ul className="navbar-nav mr-auto d-block d-md-none">
          <NavMenuList />
        </ul>
        <HomeCitySelector />
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => navigate('/home-city/create')}
          style={{ display: 'flex', flexDirection: 'row', marginLeft: 24, marginRight: 24 }}
          disabled={isLoading}
        >
          <Add />
        </button>
        <FleetSelector />
      </div>
    </nav>
  );
};
