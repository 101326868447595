import { FormField, IFormField, INPUT_TYPE, useForm } from 'boaz-bikes-forms';
import { Schema, SpringClient, UnitSystem, Message } from 'boaz-bikes-types';
import React, { useState } from 'react';
import { ArrayEditor } from '../components/ArrayEditor';
import { LoadingView } from '../components/LoadingSpinner';
import { SpringButton } from '../components/SpringButton';
import { useSpringFetch } from '../firebase';
import { useNavigate } from '../Router';
import { showMessage } from '../ToastProvider';

const COMPANY_CONFIG_FIELDS: IFormField[] = [
  {
    label: 'Latitude',
    name: 'latitude',
    inputType: INPUT_TYPE.NUMBER,
  },
  {
    label: 'Longitude',
    name: 'longitude',
    inputType: INPUT_TYPE.NUMBER,
  },
  {
    label: 'Unit System',
    name: 'unitSystem',
    inputType: INPUT_TYPE.SELECT,
    options: Object.values(UnitSystem),
  },
  {
    label: 'Auto Lock Timer',
    name: 'autoLockTimer',
    description: 'Timer to lock the bikes',
    inputType: INPUT_TYPE.NUMBER,
  },
  {
    label: 'Status',
    name: 'status',
    description: 'Status displayed to all users on application map',
    inputType: INPUT_TYPE.TEXTAREA,
  },
  {
    label: 'Is Status Visible',
    name: 'isStatusVisible',
    description: 'Toggle to display/hide status from application map',
    inputType: INPUT_TYPE.BOOLEAN,
  },
  {
    label: 'Apple App ID',
    name: 'appleAppId',
    inputType: INPUT_TYPE.TEXT,
  },
  {
    label: 'Android Package Name',
    name: 'androidPackageName',
    inputType: INPUT_TYPE.TEXT,
  },
  {
    label: 'Contact: Email',
    name: 'contactEmail',
    inputType: INPUT_TYPE.TEXT,
  },
  {
    label: 'Contact: Phone',
    name: 'contactPhoneNumber',
    inputType: INPUT_TYPE.TEXT,
  },
  {
    label: 'Theme: Color Primary',
    name: 'themeColorPrimary',
    inputType: INPUT_TYPE.TEXT,
  },
];

const EditTermsConfig = ({
  termsDescription,
  setTermsDescription,
  termsLinks,
  setTermsLinks,
  isUpdating,
}: {
  termsDescription?: string;
  setTermsDescription: (value: string) => void;
  termsLinks: Schema.TermsConfig.Link[];
  setTermsLinks: (links: Schema.TermsConfig.Link[]) => void;
  isUpdating: boolean;
}) => {
  return (
    <>
      <label>Terms Description</label>
      <input
        disabled={isUpdating}
        type="text"
        className="form-control mb-2"
        value={termsDescription}
        onChange={(e: any) => setTermsDescription(e.target.value)}
      />

      <ArrayEditor
        arrayEntries={termsLinks}
        defaultEntry={['', '']}
        label={'Terms Links'}
        onUpdateArrayEntries={setTermsLinks}
        entryRenderFunction={([label, link], onChange) => {
          return (
            <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
              <input
                disabled={isUpdating}
                type="text"
                placeholder="label"
                className="form-control mr-1"
                value={label}
                onChange={(e: any) => onChange([e.target.value, link])}
              />
              <input
                disabled={isUpdating}
                type="text"
                placeholder="url"
                className="form-control mr-1"
                value={link}
                onChange={(e: any) => onChange([label, e.target.value])}
              />
            </div>
          );
        }}
      />
    </>
  );
};

const EditCompanyConfigForm = ({
  company,
  termsConfig,
}: {
  company: Schema.Company.Admin;
  termsConfig?: Schema.TermsConfig.Client;
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [termsDescription, setTermsDescription] = useState<string | undefined>(
    termsConfig?.description
  );
  const [termsLinks, setTermsLinks] = useState<Schema.TermsConfig.Link[]>(termsConfig?.links || []);

  const navigate = useNavigate();

  const { formState: companyFormState, updateForm: updateCompanyFormState } = useForm(
    COMPANY_CONFIG_FIELDS,
    company
  );

  const submitFormAsync = async (e: any) => {
    e.preventDefault();
    setIsUpdating(true);

    try {
      await SpringClient.post('/admin/company-config', companyFormState, {
        showGoodMessages: false,
      });
      await SpringClient.post(
        '/admin/terms-config',
        {
          links: termsLinks,
          description: termsDescription,
        },
        { showGoodMessages: false }
      );
      showMessage({ messageType: Message.Type.Success, messageText: 'Updated Successfully' });
      navigate('/config');
    } catch (e) {
      setIsUpdating(false);
    }
  };

  if (isUpdating) {
    return <div>Updating...</div>;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-md-6 mx-auto">
          <div className="my-4">
            <h4>Update Company Config</h4>

            <div className="alert alert-warning">
              Please reach out to us before editing this page so that we make sure that your apps
              are updated.
            </div>

            {COMPANY_CONFIG_FIELDS.map((formField) => (
              <FormField
                key={formField.name}
                disabled={isUpdating}
                formField={formField}
                updateForm={updateCompanyFormState}
                formState={companyFormState}
              />
            ))}

            <EditTermsConfig
              termsDescription={termsDescription}
              setTermsDescription={setTermsDescription}
              termsLinks={termsLinks}
              setTermsLinks={setTermsLinks}
              isUpdating={isUpdating}
            />

            <SpringButton
              className="btn btn-primary mt-2 mb-4"
              isLoading={isUpdating}
              onClick={(e) => submitFormAsync(e)}
            >
              Update
            </SpringButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export const EditCompanyConfig = () => {
  const { data } = useSpringFetch<{
    company: Schema.Company.Admin;
    termsConfig: Schema.TermsConfig.Client;
  }>('get', `/company-config-admin`, {});

  if (!data) {
    return <LoadingView />;
  }

  return <EditCompanyConfigForm company={data.company} termsConfig={data.termsConfig} />;
};
