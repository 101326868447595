import React from 'react';

interface IOption {
  label: string;
  value: string;
}

interface ISelectProps {
  value: string;
  options: IOption[];
  isLoading?: boolean;
  onChange(value: string): void;
}

export const Select = ({ value, options, isLoading, onChange }: ISelectProps) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
      {isLoading ? (
        <span
          className="spinner-grow spinner-grow-sm text-primary"
          role="status"
          aria-hidden="true"
        />
      ) : (
        <select
          className="form-control"
          value={value}
          onClick={(event) => {
            event.stopPropagation();
          }}
          onChange={(event) => {
            onChange(event.target.value);
          }}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};
