import { FormField, IFormField, INPUT_TYPE, useForm } from 'boaz-bikes-forms';
import { Currency, Message, Schema, SpringClient } from 'boaz-bikes-types';
import React, { useState } from 'react';
import { ArrayEditor } from '../components/ArrayEditor';
import { LoadingView } from '../components/LoadingSpinner';
import { SpringButton } from '../components/SpringButton';
import { useSpringFetch } from '../firebase';
import { useNavigate } from '../Router';
import { showMessage } from '../ToastProvider';
import { PageContainer } from '../components/PageContainer';

const PRICE_CONFIG_FIELDS: IFormField[] = [
  {
    label: 'Minimum Balance Cents',
    name: 'minBalanceCents',
    inputType: INPUT_TYPE.NUMBER,
    description: 'This only applies if the wallet is enabled.',
  },
  {
    label: 'Price per Minute Cents',
    name: 'priceCentsPerChunk',
    inputType: INPUT_TYPE.NUMBER,
  },
  {
    label: 'Price Unlock Cents',
    name: 'priceUnlockCents',
    inputType: INPUT_TYPE.NUMBER,
  },
  {
    label: 'Price Unlock Cents Dropoff',
    name: 'priceUnlockCentsDropOff',
    inputType: INPUT_TYPE.NUMBER,
  },
  {
    label: 'Price per Minute Cents Dropoff',
    name: 'priceCentsPerChunkDropOff',
    inputType: INPUT_TYPE.NUMBER,
  },
  {
    label: 'Grace Period in Seconds',
    name: 'gracePeriodSeconds',
    inputType: INPUT_TYPE.NUMBER,
    description: 'The app will not be charge the rider for rides under this many seconds',
  },
  {
    label: 'Referral Bonus',
    name: 'referralBonus',
    inputType: INPUT_TYPE.NUMBER,
    description: '',
  },
  {
    label: 'Currency',
    name: 'currency',
    inputType: INPUT_TYPE.SELECT,
    options: Object.values(Currency),
  },
];

const EditTopUpOptions = ({
  topUpOptions,
  setTopUpOptions,
  isUpdating,
}: {
  topUpOptions: Schema.TopUpOption.Client[];
  setTopUpOptions: (value: Schema.TopUpOption.Client[]) => void;
  isUpdating: boolean;
}) => {
  return (
    <ArrayEditor
      arrayEntries={topUpOptions}
      defaultEntry={{
        // @ts-ignore because this top up option isn't filled out
        currency: '',
        amountCents: 0,
      }}
      removeRowEnabled={false}
      addRowEnabled={false}
      label={'Top Up Options'}
      onUpdateArrayEntries={setTopUpOptions}
      entryRenderFunction={(topUpOption, onChange) => {
        const { amountCents } = topUpOption;
        return (
          <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
            <input
              disabled={isUpdating}
              type="number"
              placeholder="amount-cents"
              className="form-control mr-1"
              value={amountCents}
              onChange={(e: any) =>
                onChange(Object.assign(topUpOption, { amountCents: e.target.value }))
              }
            />
          </div>
        );
      }}
    />
  );
};

const EditPriceConfigForm = ({
  company,
  topUpOptions: _topUpOptions,
  priceOptions,
  priceCentsPerChunkDropOff,
  priceUnlockCentsDropOff
}: {
  company: Schema.Company.Admin;
  topUpOptions: Schema.TopUpOption.Client[];
  priceOptions:Schema.PriceOption.Admin;
  priceCentsPerChunkDropOff:any;
  priceUnlockCentsDropOff:any
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [topUpOptions, setTopUpOptions] = useState<Schema.TopUpOption.Client[]>(_topUpOptions);

  const navigate = useNavigate();
  const { formState: companyFormState, updateForm: updateCompanyFormState } = useForm(
    PRICE_CONFIG_FIELDS,
    {
      minBalanceCents:company.minBalanceCents,
      priceCentsPerChunk:priceOptions.priceCentsPerChunk,
      priceUnlockCents:priceOptions.priceUnlockCents,
      priceCentsPerChunkDropOff:priceCentsPerChunkDropOff,
      priceUnlockCentsDropOff:priceUnlockCentsDropOff,
      gracePeriodSeconds:company.gracePeriodSeconds,
      referralBonus:company.referralBonus,
      currency:company.currency
    },
    
  );

  const submitFormAsync = async (e: any) => {
    e.preventDefault();
    setIsUpdating(true);

    try {
      await SpringClient.post('/admin/company-config', companyFormState, {
        showGoodMessages: false,
      });

      for (const topUpOption of topUpOptions) {
        await SpringClient.post(`/admin/top-up-options/${topUpOption.id}`, topUpOption, {
          showGoodMessages: false,
        });
      }

      showMessage({ messageType: Message.Type.Success, messageText: 'Updated successfully' });
      navigate('/config');
    } catch (e) {
      setIsUpdating(false);
    }
  };

  if (isUpdating) {
    return <div>Updating...</div>;
  }

  return (
    <PageContainer>
      <div className="row">
        <div className="col-12 col-md-6 mx-auto">
          <h4 className={'my-4'}>Update Pricing Config</h4>

          {PRICE_CONFIG_FIELDS.map((formField) => (
            <FormField
              key={formField.name}
              disabled={isUpdating}
              formField={formField}
              updateForm={updateCompanyFormState}
              formState={companyFormState}
            />
          ))}

          <EditTopUpOptions
            topUpOptions={topUpOptions}
            setTopUpOptions={setTopUpOptions}
            isUpdating={isUpdating}
          />

          <SpringButton
            className="btn btn-primary mt-2 mb-4"
            isLoading={isUpdating}
            onClick={(e) => submitFormAsync(e)}
          >
            Update
          </SpringButton>
        </div>
      </div>
    </PageContainer>
  );
};

export const EditPricingConfig = () => {
  const { data } = useSpringFetch<{
    company: Schema.Company.Admin;
    topUpOptions: Schema.TopUpOption.Client[];
    priceOptions:Schema.PriceOption.Admin[];
  }>('get', `/company-config-admin`, {});

  if (!data) {
    return <LoadingView />;
  }
  return <EditPriceConfigForm priceOptions={data.priceOptions[0]} priceCentsPerChunkDropOff={data.priceOptions[1].priceCentsPerChunk} priceUnlockCentsDropOff={data.priceOptions[1].priceUnlockCents} company={data.company} topUpOptions={data.topUpOptions} />;
};
