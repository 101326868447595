import { Schema, displayPrice } from 'boaz-bikes-types';
import React, { useContext } from 'react';
import { DateInfo } from '../components/DateInfo';
import { Table, IColumnRenderInfo } from '../components/Table';
import { useSpringFetch } from '../firebase';
import { MoreDropdown } from '../components/MoreDropdown';
import { BadgePill } from '../components/BadgePill';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { CompanyContext } from '../auth/CompanyContext';

export const TransactionsList = ({ userId, rentalId }: { userId?: string; rentalId?: string }) => {
  const { company } = useContext(CompanyContext);

  const { data, isLoading } = useSpringFetch<{
    transactions: Schema.Transaction.Admin[];
  }>('get', '/admin/transactions', { userId, rentalId });

  if (!isLoading && data == null) {
    return <div className="alert alert-warning">No transactions found</div>;
  }

  const transactions = data ? data.transactions : [];

  const columnRenderInfos: IColumnRenderInfo<Schema.Transaction.Admin>[] = [
    {
      columnName: 'Created At',
      attribute: 'createdAt',
      renderFunc: ({ createdAt }) => <DateInfo date={createdAt} />,
    },
    {
      columnName: 'Reason',
      attribute: 'reason',
    },
    {
      columnName: 'Amount Captured',
      attribute: 'amountCents',
      renderFunc: ({ amountCents }) => {
        return (
          <BadgePill isSuccess={amountCents > 0}>
            {displayPrice({
              amountCents,
              numDigits: 2,
              currency: company.currency,
            })}
          </BadgePill>
        );
      },
    },
    {
      columnName: 'Stripe Status',
      attribute: 'paymentStatus',
      renderFunc: ({ paymentStatus, stripeErrorCode }) => {
        if (!paymentStatus) {
          return null;
        }
        return (
          <BadgePill isSuccess={paymentStatus === Schema.Transaction.PaymentStatus.SUCCESS}>
            {stripeErrorCode || paymentStatus}
          </BadgePill>
        );
      },
    },
    {
      columnName: 'Creator',
      attribute: 'creatorId',
      renderFunc: ({ creatorId }) => {
        if (!creatorId) {
          return null;
        }
        return <Link to={`/users/${creatorId}`}>{_.truncate(creatorId, { length: 10 })}</Link>;
      },
    },
    {
      columnName: 'Notes',
      attribute: 'notes',
    },
  ];

  if (!userId) {
    columnRenderInfos.push({
      columnName: 'User',
      attribute: 'userId',
      renderFunc: ({ userId }) =>
        userId ? <Link to={`/users/${userId}`}>{_.truncate(userId, { length: 10 })}</Link> : null,
    });
  }

  if (!rentalId) {
    columnRenderInfos.push({
      columnName: 'Rental',
      attribute: 'rentalId',
      renderFunc: ({ rentalId }) =>
        rentalId ? (
          <Link to={`/rentals/${rentalId}`}>{_.truncate(rentalId, { length: 10 })}</Link>
        ) : null,
    });
  }

  columnRenderInfos.push({
    columnName: '',
    attribute: 'stripePaymentId',
    renderFunc: ({ stripePaymentId }) => {
      if (!stripePaymentId) {
        return null;
      }
      return (
        <MoreDropdown
          options={[
            {
              label: 'View Stripe Charge',
              href: `https://dashboard.stripe.com/payments/${stripePaymentId}`,
            },
          ]}
        />
      );
    },
  });

  return (
    <Table
      isLoading={isLoading && transactions.length < 1}
      rowsPerPage={100}
      columnRenderInfos={columnRenderInfos}
      rows={transactions}
    />
  );
};
