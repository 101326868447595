import React, { useState } from 'react';
import { IFormField, INPUT_TYPE } from 'boaz-bikes-forms';
import { SpringClient } from 'boaz-bikes-types';
import { useNavigate } from '../Router';
import { showMessage } from '../ToastProvider';
import { SimpleForm } from 'boaz-bikes-forms';
import { LoadingView } from '../components/LoadingSpinner';
import { PageContainer } from '../components/PageContainer';

export const CREATE_PROMO_CODE_FIELDS: IFormField[] = [
  {
    label: 'Code',
    name: 'promoCode',
    inputType: INPUT_TYPE.TEXT,
  },
  {
    label: 'Number of uses per account',
    name: 'initialUseCount',
    inputType: INPUT_TYPE.NUMBER,
  },
  {
    label: 'Discount Amount (in cents)',
    name: 'discountCents',
    inputType: INPUT_TYPE.NUMBER,
  },
  {
    label: 'Active',
    name: 'isActive',
    inputType: INPUT_TYPE.BOOLEAN,
  },
  {
    label: 'Expiration Date (YYYY-MM-DD)',
    name: 'expirationDate',
    inputType: INPUT_TYPE.TEXT,
  },
];

export const CreatePromoCode = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const submitFormAsync = async (formState: any) => {
    setIsLoading(true);

    try {
      const { message, promoCodeId } = await SpringClient.post('/admin/promos', formState);
      showMessage(message);
      navigate(`/promos/${promoCodeId}`);
    } catch (e) {
      console.error('Error creating promo code', e);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <PageContainer>
      <div className="row">
        <div className="col-md-6 col-12 mx-auto">
          <SimpleForm
            title={'Create Promo Code'}
            formFields={CREATE_PROMO_CODE_FIELDS}
            onSubmit={submitFormAsync}
          />
        </div>
      </div>
    </PageContainer>
  );
};
