import React from 'react';
import { NavMenuList } from './Navbar';

export function LeftBar() {
    
    return (
        <nav className="navbar navbar-light bg-light">
            <ul className="navbar-nav">
                <NavMenuList />
            </ul>
        </nav>
    )
}