import React, { useState, useContext } from 'react';
import { FormField, IFormField, INPUT_TYPE, useForm } from 'boaz-bikes-forms';
import { Schema, SpringClient } from 'boaz-bikes-types';
import { useNavigate } from '../Router';
import { SpringButton } from '../components/SpringButton';
import { HomeCityContext } from '../home-city/HomeCityContext';

export const CREATE_VEHICLE_FIELDS: IFormField[] = [
  {
    label: 'Controller',
    name: 'iotController',
    inputType: INPUT_TYPE.SELECT,
    options: Object.values(Schema.Vehicle.IotController),
  },
  {
    label: 'QR Code',
    name: 'qrCode',
    inputType: INPUT_TYPE.TEXT,
    description: 'Must be a unique value across all of your vehicles.',
  },
  {
    label: 'IOT Device ID',
    name: 'iotDeviceId',
    inputType: INPUT_TYPE.TEXT,
    description: (
      <>
        <div>
          This is often the IMEI number corresponding with your SIM card which is a big number.
        </div>

        <div>
          For Segway scooters, you can find the Serial Number on the IOT device. Then you can look
          up the IMEI number from the Serial Number.
        </div>
      </>
    ),
  },
  {
    label: 'Fleet ID (optional)',
    name: 'fleetId',
    inputType: INPUT_TYPE.TEXT,
    description:
      "You will use fleets when you expand to multiple cities to keep track of rides in each city. It's ok to leave this blank for now.",
  },
  {
    label: 'Status',
    name: 'status',
    inputType: INPUT_TYPE.SELECT,
    options: Object.values(Schema.Vehicle.Status),
  },
  {
    label: 'Type',
    name: 'vehicleTypes',
    inputType: INPUT_TYPE.SELECT,
    options: [{ label: 'scooter-seated', value: 'scooter-seated' }, { label: 'scooter-standing', value: 'scooter-standing' }],
  },
  {
    label: 'Latitude',
    name: 'latitude',
    inputType: INPUT_TYPE.NUMBER,
    max: 90,
    min: -90,
  },
  {
    label: 'Longitude',
    name: 'longitude',
    inputType: INPUT_TYPE.NUMBER,
    max: 180,
    min: -180,
  },
  {
    label: 'Battery Percent',
    name: 'batteryPercent',
    inputType: INPUT_TYPE.NUMBER,
  },
];

export const CreateVehicle = () => {
  const { getHomeCities } = useContext(HomeCityContext);
  const allHomeCity = getHomeCities();
  let homeCityOptions = [{ label: "", value: "" }];
  allHomeCity?.forEach((homecity) => {
    homeCityOptions.push({
      label: homecity.name,
      value: homecity.id
    })
  })

  const VEHICLE_FIELDS: IFormField[] = [
    {
      label: 'Controller',
      name: 'iotController',
      inputType: INPUT_TYPE.SELECT,
      options: Object.values(Schema.Vehicle.IotController),
    },
    {
      label: 'QR Code',
      name: 'qrCode',
      inputType: INPUT_TYPE.TEXT,
      description: 'Must be a unique value across all of your vehicles.',
    },
    {
      label: 'IOT Device ID',
      name: 'iotDeviceId',
      inputType: INPUT_TYPE.TEXT,
      description: (
        <>
          <div>
            This is often the IMEI number corresponding with your SIM card which is a big number.
          </div>

          <div>
            For Segway scooters, you can find the Serial Number on the IOT device. Then you can look
            up the IMEI number from the Serial Number.
          </div>
        </>
      ),
    },
    {
      label: 'Home City',
      name: 'homeCityId',
      inputType: INPUT_TYPE.SELECT,
      options: homeCityOptions,
    },
    {
      label: 'Fleet ID (optional)',
      name: 'fleetId',
      inputType: INPUT_TYPE.TEXT,
      description:
        "You will use fleets when you expand to multiple cities to keep track of rides in each city. It's ok to leave this blank for now.",
    },
    {
      label: 'Status',
      name: 'status',
      inputType: INPUT_TYPE.SELECT,
      options: Object.values(Schema.Vehicle.Status),
    },
    {
      label: 'Type',
      name: 'vehicleTypes',
      inputType: INPUT_TYPE.SELECT,
      options: [{ label: 'scooter-seated', value: 'scooter-seated' }, { label: 'scooter-standing', value: 'scooter-standing' }],
    },
    {
      label: 'Latitude',
      name: 'latitude',
      inputType: INPUT_TYPE.NUMBER,
      max: 90,
      min: -90,
    },
    {
      label: 'Longitude',
      name: 'longitude',
      inputType: INPUT_TYPE.NUMBER,
      max: 180,
      min: -180,
    },
    {
      label: 'Battery Percent',
      name: 'batteryPercent',
      inputType: INPUT_TYPE.NUMBER,
    },
  ];

  const { formState, updateForm } = useForm(VEHICLE_FIELDS);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const submitFormAsync = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await SpringClient.post('/admin/vehicles', formState);
      navigate('/vehicles');
    } catch (e) {
      console.error('Error creating vehicle', e);
      setIsLoading(false);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-md-6 mx-auto">
          <div className="my-4">
            <h4>Create Vehicle</h4>

            <form onSubmit={submitFormAsync}>
              {VEHICLE_FIELDS.map((formField) => (
                <FormField
                  key={formField.name}
                  disabled={isLoading}
                  formField={formField}
                  updateForm={updateForm}
                  formState={formState}
                />
              ))}

              <SpringButton type="submit" className="btn btn-primary" isLoading={isLoading}>
                Submit
              </SpringButton>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
