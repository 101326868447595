import React, { useState, useContext, useEffect } from 'react';
import XLSX from 'xlsx';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import MaterialTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { LoadingView } from '../components/LoadingSpinner';
import { Schema, SpringClient } from 'boaz-bikes-types';
import { useNavigate } from '../Router';
import { v4 as uuid } from 'uuid';
import { HomeCityContext } from '../home-city/HomeCityContext';
import { Message } from 'boaz-types';
import { showMessage } from '../ToastProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      overflowX: 'auto',
    },
    table: {
      minWidth: 650,
    },
    row: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
      '&:hover': {
        textDecoration: 'none',
      },
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    toolbar: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    spacer: {
      flex: '1 1 100%',
    },
    actions: {
      color: theme.palette.text.secondary,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    title: {
      flex: '0 0 auto',
    },
  })
);


export const ImportUser = () => {
  const [newUsers, setNewUsers] = useState<Schema.User.Admin[]>([])
  const userFields = ["Company id", "Phone number", "Email", "Name", "Is admin", "Is delivery", "Admin area", "Is Blocked", "Credit amount", "Home City"]
  const [isEmpty, setIsEmpty] = useState(true);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();
  const { getHomeCities, isLoading: isLoadingHomeCities } = useContext(HomeCityContext);
  const availableHomeCityIds = getHomeCities();

  const checkHomeCitiesId = (homeCityId: string) => {
    if (availableHomeCityIds?.some((elem) => elem.id == homeCityId)) {
      return true
    } else {
      return false
    }
  }
  const checkPhoneNumber = (phoneNumber: string) => {
    if (phoneNumber.length == 11 && !isNaN(Number(phoneNumber))) {
      return true
    } else {
      return false
    }
  }

  const checkAdminArea = (homeCity: string) => {
    if (availableHomeCityIds?.some((elem) => (
      elem.name.toLowerCase() == homeCity.toLowerCase() || 'all' == homeCity.toLowerCase()))) {
      return true
    } else {
      return false
    }
  }
  const validateFields = (currentFields: any) => {
    let res = true;

    res = checkHomeCitiesId(currentFields[9])
    if (!res) return "City id invalid"

    res = checkPhoneNumber(currentFields[1])
    if (!res) return "invalid phone number"

    res = (currentFields[4] == 'true' || currentFields[4] == 'false')
    res = (currentFields[5] == 'true' || currentFields[5] == 'false')
    res = (currentFields[7] == 'true' || currentFields[7] == 'false')
    if (!res) return "Invalid boolean fields"

    res = checkAdminArea(currentFields[6])
    if (!res) return "Invalid Admin Area"

    return res
  }

  const getCSVData = async (file: any) => {
    setIsEmpty(true);
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = e => {
      /* Parse data */
      const bstr = e.target!.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array", raw: true });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      /* Update state */
      const d = new Date();
      let date = d.getHours() + ":" + d.getMinutes() + ", " + d.toDateString();
      let today = date.toString()
      try {
        const jsonParser = (data: string) => JSON.parse(data);
        let CSVReady = jsonParser(JSON.stringify(data));
        let newUser: Schema.User.Admin;
        let ok;
        for (let i = 1; i < CSVReady.length; i++) {
          ok = validateFields(CSVReady[i]);
          if (ok == true) {
            newUser = {
              id: '',
              companyId: CSVReady[i][0],
              createdAt: today,
              phoneNumber: '+' + CSVReady[i][1],
              name: CSVReady[i][3],
              email: CSVReady[i][2],
              homeCityId: CSVReady[i][9],
              isAdmin: CSVReady[i][4],
              areaAdmin: CSVReady[i][6].toLowerCase(),
              isDelivery: CSVReady[i][5],
              isBlocked: CSVReady[i][7],
              balanceAmountCents: CSVReady[i][8],
              updatedAt: today,
              currentRentalIds: [],
              availableBikeIds: [],
              isActiveCampaign: false,
              isTurnoffNotification: false,
              referral: '',
              agreedToTermsAt: today,
              canonicalId: '',
              totalLifeTime: 0,
              deviceId: '',
              verification: '',
              rentalCountRemain: 0,
              passType: '',
              isDeleted:false
            }
          } else {
            setIsEmpty(true)
            console.error(ok)
            const err = ok.toString();
            showMessage(Message.create(err, Message.Type.Danger));
          }
          setNewUsers(newUsers => [...newUsers, newUser])
        }
      } catch (e) {
        console.log(e)
      }
    };
    if (rABS) reader.readAsBinaryString(file[0]);
    else reader.readAsArrayBuffer(file[0]);
    setIsEmpty(false)
  }

  const postUsers = async () => {
    setIsLoading(true);
    try {
      await SpringClient.post('/admin/create-multiple-users', newUsers);
      navigate('/users');
    } catch (e) {
      console.error('Error creating users', e);
      setIsLoading(false);
    }
  }


  if (isLoading || isLoadingHomeCities) return <LoadingView />


  return (
    <div>
      <div>
        <input
          type="file"
          onChange={(e) => getCSVData(e.target.files)} />
      </div>
      {!isEmpty ? <div className={classes.tableContainer}>
        <MaterialTable className={classes.table}>
          <TableHead>
            <TableRow>
              {userFields.map((field) => (
                <TableCell key={field} >{field}</TableCell>

              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {(newUsers && newUsers[0] != undefined) ? newUsers.map((currentUser, index) => (
              <TableRow key={index}>
                <TableCell>{currentUser.companyId}</TableCell>
                <TableCell>{currentUser.phoneNumber}</TableCell>
                <TableCell>{currentUser.email}</TableCell>
                <TableCell>{currentUser.name}</TableCell>
                <TableCell>{currentUser.isAdmin}</TableCell>
                <TableCell>{currentUser.isDelivery}</TableCell>
                <TableCell>{currentUser.areaAdmin}</TableCell>
                <TableCell>{currentUser.isBlocked}</TableCell>
                <TableCell>{currentUser.balanceAmountCents}</TableCell>
                <TableCell>{currentUser.homeCityId}</TableCell>
              </TableRow>
            ))
              : <></>}
          </TableBody>
        </MaterialTable>
      </div>
        : <div></div>}
      {newUsers.length > 0 && !isEmpty ?
        <div style={{ flexDirection: "row", width: 600 }}>
          <button
            type="button"
            className="btn btn-primary"
            style={{ flexDirection: 'row', marginRight: 24, marginTop: 10 }}
            onClick={() => {
              postUsers()
            }}
          >
            Confirm
          </button>
          <button
            type="button"
            className="btn btn-primary"
            style={{ flexDirection: 'row', marginRight: 24, marginTop: 10 }}
            onClick={() => {
              setNewUsers([])
              setIsEmpty(true)
            }}
          >
            Remove
          </button>
        </div>
        : <div></div>}

    </div>
  );
};

