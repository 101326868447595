import React, { useState } from 'react';
import moment from 'moment';

export const DateInfo = ({
  date,
  containerStyle,
  shouldStartWithRelative = true,
}: {
  date: string | null;
  containerStyle?: React.CSSProperties;
  shouldStartWithRelative?: boolean;
}) => {
  const [showRelative, setShowRelative] = useState(shouldStartWithRelative);

  if (!date) {
    return <div style={containerStyle}>No date provided</div>;
  }

  const relativeDate = moment.utc(date).fromNow();

  if (showRelative) {
    return (
      <div style={containerStyle}>
        {relativeDate}
        <button
          className={'btn btn-sm btn-link d-inline'}
          onClick={(event) => {
            event.stopPropagation();
            setShowRelative(false);
          }}
        >
          abs
        </button>
      </div>
    );
  }

  const absoluteDate = moment.utc(date).format();

  return (
    <div style={containerStyle}>
      {absoluteDate}
      <button
        className={'btn btn-sm btn-link d-span'}
        onClick={(event) => {
          event.stopPropagation();
          setShowRelative(true);
        }}
      >
        rel
      </button>
    </div>
  );
};
