import React from 'react';
import { useSpringFetch } from '../firebase';
import { Schema } from 'boaz-bikes-types';
import { LoadingView } from '../components/LoadingSpinner';
import { TaskForm } from './TaskForm';

export const UpdateTask = ({ match }: { match: any }) => {
  const { taskId } = match.params;

  const { data, isLoading } = useSpringFetch<{ task: Schema.Task.Admin }>(
    'get',
    `/admin/tasks/${taskId}`
  );

  if (isLoading) {
    return <LoadingView />;
  }

  if (data == null) {
    return <div className="alert alert-warning">Task not found</div>;
  }

  const { task } = data;

  return <TaskForm existingTask={task} />;
};
