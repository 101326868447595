import React from 'react';

const Container = ({
  children,
  style,
  className,
}: {
  children: any;
  style?: any;
  className?: string;
}) => {
  return (
    <div className={`card ${className}`} style={style}>
      {children}
    </div>
  );
};

const Header = ({ title, actions }: { title: string; actions?: React.ReactNode }) => {
  return (
    <div className="card-header" style={{ display: 'flex', flexDirection: 'row' }}>
      <div className="mr-auto">{title}</div>
      <div>{actions}</div>
    </div>
  );
};

const Body = ({ children }: { children: any }) => {
  return <div className="card-body">{children}</div>;
};

export const Card = {
  Container,
  Header,
  Body,
};
