import {  Schema, SpringClient } from 'boaz-bikes-types';
import React, { useContext, useState } from 'react';
import { Table } from '../components/Table';
import { useSpringFetch } from '../firebase';
import { useNavigate } from '../Router';
import { Card } from '../components/Card';
import { PageContainer } from '../components/PageContainer';
import { HomeCityContext } from '../home-city/HomeCityContext';
import { FilterAdminAreaVehicleAlerts, FilterAdminAreaVehicles } from '../utils/FilterAdminAreas';
import { Select } from '../components/Select';
import { Button, styled } from '@material-ui/core';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';
import { FleetContext } from '../vehicles/FleetContext';


const lockAllVehicles = async (selectedHomeCityId:string|undefined, setDisable:(disable:boolean)=>void) => {
  try {
    alert('All available bikes are being locked.')
    setDisable(true)
    await SpringClient.post('/admin/vehicles/iot/lock-multiple-vehicle',{selectedHomeCityId});
    setDisable(false)
} catch (e) {
    console.error('Error locking vehicles', e);
    setDisable(false)
  }

}

const ColorAlert = ({alertType}:any) =>{
    return <label style={{color: alertType=='moved'? 'red' :
    alertType=='fallen'? 'orange' : 
    alertType=='offline scanned'? 'blue': 'green'
    }}>
        {alertType} </label>
}


function VehicleAlertList({ title, currentStatus,currentAlert,selectedHomeCityId,getHomeCities }: 
  {   title?: string | React.ReactNode,
      currentStatus:string,
      currentAlert:string;
      selectedHomeCityId:string|undefined;
      getHomeCities:()=>Schema.HomeCity.Admin[]|null;
    }) {
  const navigate = useNavigate();
  
  const { selectedFleetId } = useContext(FleetContext);

  
  let vehicleAlertsData:any;
  vehicleAlertsData = useSpringFetch<{
    vehicleAlerts: Schema.VehicleAlert.Admin[];
  }>('get', '/admin/vehicles-alerts', { 
    homeCityId: selectedHomeCityId,
    daysAgo: currentStatus,
    currentAlert: currentAlert
  });

  let vehiclesData:any;
  vehiclesData = useSpringFetch<{
    vehicles: Schema.Vehicle.Admin[];
  }>('get', '/admin/vehicles', { 
    homeCityId: selectedHomeCityId,
    fleetId: selectedFleetId 
  });
  
  let vehicles = FilterAdminAreaVehicles(vehiclesData.data?.vehicles) || [];
  const getVehicleByQrCode = (qrCode:string, vehicles:Schema.Vehicle.Admin[]) => {
    let vehicleFind = vehicles.find((vehicle:Schema.Vehicle.Admin)=>(
      vehicle.qrCode == qrCode ))
    return vehicleFind?.id;
  }
  
  const { data, isLoading, refreshData: _refreshData } = vehicleAlertsData;
  let vehicleAlertFiltered = FilterAdminAreaVehicleAlerts(data?.vehicleAlerts) || [];

  if (!isLoading && data == null) {
    return <div className="alert alert-warning">VehicleAlerts not found</div>;
  }

  const vehicleAlert = vehicleAlertFiltered || []; 
  const allHomeCity = getHomeCities();

  const exportVehicleAlert = vehicleAlert.map( (alert) => {     
    let homeCityCurrent = allHomeCity?.find((homecity: any) => homecity.id === alert.homeCityId);
    return {
      'Home City':homeCityCurrent?.name,
      'Qr Code':alert.qrCode,      
      'Type Alert': alert.alertType,
      'Created At': moment.utc(alert.createdAt).fromNow(),
      'User Id':alert.userId
    };
  }
  );

  return (
    <div className="row mb-4">
      <div className="col">
        <Card.Container>
          <Table
            title={title}
            isLoading={isLoading && vehicleAlert.length < 1}
            rowsPerPage={100}
            columnRenderInfos={[
               {
                columnName: 'Home City',
                attribute: 'homeCityId',
                renderFunc: ({ homeCityId }) => {
                    let allHomeCity = getHomeCities();                  
                    let homeCityCurrent = allHomeCity?.find((homecity: any) => homecity.id === homeCityId);
                    return homeCityCurrent?.name || "";
                }
               },
              { columnName: 'QR Code', attribute: 'qrCode',
              renderFunc: ({ qrCode }) => {
                let id = getVehicleByQrCode(qrCode,vehicles)
                return(
                <Link to={`/vehicles/${id}`}>{qrCode}</Link>
              )},
              },
              
              { columnName: 'Alert type', attribute: 'alertType',
              renderFunc: ({alertType}) => <ColorAlert alertType={alertType} />, },
              { columnName: 'Created At', attribute: 'createdAt' },
              { columnName: 'User Id', attribute: 'userId' ,
              renderFunc: ({ userId }) => {
                return(
                <Link to={`/users/${userId}`}>{userId}</Link>
              )},
              }
            ]}
            rows={vehicleAlert}
          />
            <div className="col" style={{marginBottom:15}}>
              <CSVLink
                style={{marginLeft:20}}
                data={exportVehicleAlert}
                filename={"vehicle-alerts.csv"}
                className="btn btn-primary"
                target="_blank"
              >
                Export
              </CSVLink>
            </div>
        </Card.Container>
      </div>
    </div>
  );
}

export function VehicleAlertSection() {

    const status = ['1','3','5','7','12'];
    const alertTypes = ['moved','fallen','offline scanned','Lock Failed','Rental Over 70 min','Vehicle did not lock','All'];
    const { selectedHomeCityId, getHomeCities } = useContext(HomeCityContext);
    const [currentStatus, setCurrentStatus] = useState('1')
    const [ currentAlert, setCurrentAlert ] = useState('All')
    const [disable, setDisable] = useState(false);

    const navigate = useNavigate();
  return (
    <PageContainer>
      
        <div style={{display:'flex',flexDirection:'row', width:'100%',alignItems:'center',marginBottom:5}}>
            <div style ={{ width:'25%'}}>
                <Select 
                    value={currentStatus}
                    options={status.map((status) => ({
                        label: status + ' days ago',
                        value: status,
                    }))}
                    onChange={(value) => setCurrentStatus(value)}
                />
            </div>
            <div style ={{ width:'25%', marginLeft:10}}>
                <Select 
                    value={currentAlert}
                    options={alertTypes.map((status) => ({
                        label: status + ' types alert',
                        value: status,
                    }))}
                    onChange={(value) => setCurrentAlert(value)}
                />
            </div>
            <div style={{display:'flex', alignSelf:'flex-end'}}>
            <Button 
              style={{backgroundColor:'#1382E4', color:'white', marginLeft:10,marginBottom:5}}
              className="btn btn-primary"
              variant="contained"
              onClick={() => navigate('/vehicle-alerts/list-stop-ping')}>
                Scan for Offline Bikes
            </Button>
            <Button 
              style={{backgroundColor:'#1382E4', color:'white', marginLeft:10,marginBottom:5}}
              className="btn btn-primary"
              variant="contained"
              onClick={() => navigate('/vehicle-alerts/list-not-rented')}>
                Scan for Not Rented
            </Button>
          </div>
          {/* <div style={{display:'flex', alignSelf:'flex-end'}}>
            <Button 
              style={{backgroundColor:'#1382E4', color:'white', marginLeft:10,marginBottom:5}}
              className="btn btn-primary"
              variant="contained"
              disabled={disable}
              onClick={() => lockAllVehicles(selectedHomeCityId,setDisable)} >
                Lock all Available Bikes
            </Button>
          </div> */}
        </div>
         
        
      <VehicleAlertList title="Vehicles Alerts"  currentStatus={currentStatus} 
      currentAlert={currentAlert} 
      selectedHomeCityId={selectedHomeCityId} 
      getHomeCities={getHomeCities}
      />
    </PageContainer>
  );
}
