import { displayPrice, Schema, SpringClient } from 'boaz-bikes-types';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CompanyContext } from '../auth/CompanyContext';
import { BadgePill } from '../components/BadgePill';
import { DateInfo } from '../components/DateInfo';
import { MoreDropdown } from '../components/MoreDropdown';
import { IColumnRenderInfo, Table } from '../components/Table';
import { useSpringFetch } from '../firebase';
import { HomeCityContext } from '../home-city/HomeCityContext';
import { useNavigate } from '../Router';
import { FleetContext } from '../vehicles/FleetContext';
import { FilterAdminAreas } from '../utils/FilterAdminAreas';

export const VehicleSummary = ({ vehicle }: { vehicle?: Schema.Vehicle.Admin }) => {
  if (!vehicle) {
    return null;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <div>
        <Link to={`/vehicles/${vehicle.id}`}>{vehicle.qrCode}</Link>
      </div>
    </div>
  );
};

export const VehicleSummaryById = ({ vehicleId }: { vehicleId?: String }) => {
  if (!vehicleId) {
    return null;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <div>
        <Link to={`/vehicles/${vehicleId}`}>{vehicleId}</Link>
      </div>
    </div>
  );
};

export const UserSummary = ({ user }: { user?: Schema.User.Admin }) => {
  if (!user) {
    return null;
  }
  
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      {user.name && <div>{user.name}</div>}
      {user.email && <div>{user.email}</div>}
      <div>
        <Link to={`/users/${user.id}`}>{user?.phoneNumber}</Link>
      </div>
    </div>
  );
};

export const UserSummaryById = ({ userId }: { userId?: string }) => {
  if (!userId) {
    return null;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      {/* {user.name && <div>{user.name}</div>} */}
      {/* {user.email && <div>{user.email}</div>} */}
      <div>
        <Link to={`/users/${userId}`}>{userId}</Link>
      </div>
    </div>
  );
};

export function RentalList({
  title,
  vehicleId,
  userId,
  initialRowsPerPage = 100,
  showReview = false,
  filter:filterTab,
  adminArea,
  adminAreaCity,
  pageSize,
  startAt,
  onGoing,
  setTextSearch,
  textSearch,
}: {
  title?: string;
  vehicleId?: string;
  userId?: string;
  initialRowsPerPage?: number;
  showReview?: boolean;
  filter?(rental: Schema.Rental.Admin): boolean;
  adminArea:boolean;
  adminAreaCity:string;
  pageSize:number;
  startAt?: Date | null;
  onGoing?: boolean | null;
  setTextSearch?:(text:string)=>void;
  textSearch?:string;
}) {
  const { company } = useContext(CompanyContext);
  const { selectedFleetId } = useContext(FleetContext);
  const { selectedHomeCityId, getHomeCities } = useContext(HomeCityContext);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage ] = useState(1)
  const [rowsState, setRowsState] = useState<any>([])
  const [ usersState , setUsersState] = useState<Schema.User.Admin[]>([]);
  const [ vehiclesState , setVehiclesState] = useState<Schema.Vehicle.Admin[]>([]);

  const { data, isLoading,refreshData:refershInitialData } = useSpringFetch<{
    rentals: Schema.Rental.Admin[];
    users: Schema.User.Admin[];
    vehicles:Schema.Vehicle.Admin[];
    rentalsLength:number;
  }>('get', '/admin/rentals', {
    vehicleId,
    userId,
    fleetId: selectedFleetId,
    homeCityId: adminArea? adminAreaCity : selectedHomeCityId,
    pageSize,
    startAt: startAt? startAt : undefined,
    onGoing: onGoing != undefined ? onGoing : undefined,
    page:currentPage-1,
    expand: ['user','vehicle'],
  });
  const { data:dataSearch,isLoading:isLoadingSearch, refreshData:refreshSearchApi } = useSpringFetch<{
    rentals: Schema.Rental.Admin[];
    users:Schema.User.Admin[];
    vehicles:Schema.Vehicle.Admin[];
  }>('get', '/admin/search-rentals', {
    homeCityId: adminArea? adminAreaCity : selectedHomeCityId,
    textSearch,
    expand: ['user','vehicle'],
  });
  const refreshData =()=>{
    refershInitialData();
    refreshSearchApi();
  }
  const setCurrentPageAndData = (page:number) =>{
    setVehiclesState(vehicles);
    setUsersState(users);
    setCurrentPage(page);
  }
  if (!isLoading && data == null) {
    return <div className="alert alert-warning">Rentals not found</div>;
  }

  let rentals:Schema.Rental.Admin[] = []
  let vehicles:Schema.Vehicle.Admin[] = []
  let users: Schema.User.Admin[]=[]
   if (textSearch && textSearch!='' && dataSearch && !isLoadingSearch){
    rentals = dataSearch.rentals;
    vehicles = dataSearch.vehicles;
    users = dataSearch.users
   } else if (adminArea && data){
    rentals = data.rentals.filter((rental)=>rental.homeCityId==adminAreaCity)
    vehicles = vehiclesState.concat(data.vehicles)
    users = usersState.concat(data.users)
   } else if(data){
    rentals = data.rentals;
    vehicles = vehiclesState.concat(data.vehicles)
    users = usersState.concat(data.users)
   }  
  let rentalsLength = data?.rentalsLength;
  const vehicleIdMap = new Map(vehicles?.map((vehicle) => [vehicle.id, vehicle]));
  const userIdMap =  new Map(users?.map((user) => [user.id, user]));
  // mapping homeCities
  const allHomeCity = getHomeCities();
  const homeCityMap = new Map(allHomeCity?.map((homecity: any) => [homecity.id, homecity.name]))
  const filteredRentals = filterTab ? rentals.filter(filterTab) : rentals;
  const columnRenderInfos: IColumnRenderInfo<Schema.Rental.Admin>[] = [
    {
      columnName: 'User',
      attribute: 'userId',
      renderFunc: ({ userId }) => <UserSummary user={userIdMap.get(userId)} />,
    },
    {
      columnName: 'Home City',
      attribute: 'homeCityId',
      renderFunc: ({ homeCityId }) => {
        let homeName = homeCityMap.get(homeCityId);
        return homeName || "";
      }
    },
    {
      columnName: 'Vehicle',
      attribute: 'vehicleId',
      renderFunc: ({ vehicleId }) => <VehicleSummary vehicle={vehicleIdMap.get(vehicleId)} />,
    },
    {
      columnName: 'Start',
      attribute: 'startAt',
      renderFunc: ({ startAt }) => <DateInfo date={startAt} />,
    },
    {
      columnName: 'Duration',
      renderFunc: ({ startAt, endAt }) => {
        if (startAt && endAt) {
          const momentDiff = moment(startAt).diff(moment(endAt));
          return moment.duration(momentDiff).humanize();
        }

        return 'Ongoing';
      },
    },
    {
      columnName: 'Price',
      attribute: 'amountCentsCharged',
      renderFunc: ({ amountCentsCharged }) => {
        return (
          <BadgePill isSuccess={true}>{`${displayPrice({
            amountCents: amountCentsCharged,
            numDigits: 2,
            currency: company.currency,
          })}`}</BadgePill>
        );
      },
    },
    {
      columnName: 'Rating',
      attribute: 'rating',
    },

  ];

  if (showReview) {
    columnRenderInfos.push({
      columnName: 'Review',
      attribute: 'review',
    });
  }    
 

  columnRenderInfos.push({
    columnName: '',
    attribute: 'stripeChargeId',
    renderFunc: ({ stripeChargeId, userId, vehicleId }) => {

      return (
        <MoreDropdown
          options={[
            { label: 'View User', onClick: () => navigate(`/users/${userId}`) },
            { label: 'View Vehicle', onClick: () => navigate(`/vehicles/${vehicleId}`) },
            {
              label: 'View Stripe Charge',
              href: `https://dashboard.stripe.com/payments/${stripeChargeId}`,
            },
            {
              label: 'Refund Stripe Charge',
              onClick: async () => { 
                await SpringClient.post(`/admin/rentals/payment-refund/${stripeChargeId}`) },
            },
          ]}
        />
      );
    },
  });

  columnRenderInfos.push({
    columnName: 'Edit',
    renderFunc: ({ id }) => (
      <Link to={`/rentals/${id}`}>Edit</Link>
    ),
  });
  

  return (
    <Table<Schema.Rental.Admin>
      title={title}
      isLoading={isLoading || isLoadingSearch}
      rowsPerPage={100}
      columnRenderInfos={columnRenderInfos}
      rows={filteredRentals}
      currentPage={currentPage}
      setCurrentPage={setCurrentPageAndData}
      dataLength={rentalsLength}
      rowsState={rowsState}
      setRowsState={setRowsState}
      filterTab={filterTab}
      searchInDb={(text)=>{
        if (setTextSearch) setTextSearch(text)
      }
    }
      // getRowUrl={(rental) => `/rentals/${rental.id}`}
    />
  );
}
