import { Schema, displayPrice, SpringClient, ISubscription } from 'boaz-bikes-types';
import _ from 'lodash';
import React, { useState, useContext } from 'react';
import { useParams } from 'react-router';
import { DateInfo } from '../components/DateInfo';
import { DisplayData } from '../components/DisplayData';
import { LoadingView } from '../components/LoadingSpinner';
import { EventsCard } from '../events/EventsCard';
import { useSpringFetch } from '../firebase';
import { UserPromoCodeList } from '../promo-codes/UserPromoCodeList';
import { RentalList } from '../rentals/RentalList';
import { SupportIssueList } from '../support/SupportHome';
import { LastRentalDisplay } from '../vehicles/VehiclesHome';
import { AdminStatusDropdown } from './AdminStatusDropdown';
import { BlockUserDropdown } from './BlockUserDropdown';
import { SendPushNotificationForm } from './SendPushNotificationForm';
import { UserPingMap } from './UserPingMap';
import { getRentalStats } from '../utils/rentals';
import { Card } from '../components/Card';
import { PageContainer } from '../components/PageContainer';
import { TransactionsList } from './TransactionsList';
import { CompanyContext } from '../auth/CompanyContext';
import { Dialog } from '../components/Dialog';
import { SpringButton } from '../components/SpringButton';
import { useForm, INPUT_TYPE, IFormField, FormField } from 'boaz-bikes-forms';
import { FilterAdminAreas } from '../utils/FilterAdminAreas';
import { HomeCityDropdown } from '../home-city/HomeCityDropdown';
import { Subscriptions } from '@material-ui/icons';
import classNames from 'classnames';

const NUM_PUSH_TOKENS_TO_SHOW = 3;

const UserPushTokenList = ({
  userPushTokens,
}: {
  userPushTokens: Schema.UserPushToken.Admin[];
}) => {
  const [shouldShowAll, setShouldShowAll] = useState(false);

  if (_.isEmpty(userPushTokens)) {
    return <div className="alert alert-warning">No user push tokens registered...</div>;
  }

  const userPushTokensToShow = shouldShowAll
    ? userPushTokens
    : userPushTokens.slice(0, NUM_PUSH_TOKENS_TO_SHOW);

  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <h5>User push tokens</h5>
      <ul className="list-group">
        {userPushTokensToShow.map(({ id, deviceName, devicePlatform, createdAt }) => (
          <li className="list-group-item" key={id}>
            {deviceName} - {devicePlatform} -{' '}
            <DateInfo date={createdAt} containerStyle={{ display: 'inline-block' }} />
          </li>
        ))}
      </ul>

      {userPushTokens.length > NUM_PUSH_TOKENS_TO_SHOW && (
        <div
          style={{ alignSelf: 'flex-start' }}
          className="btn btn-link btn-sm my-2"
          onClick={() => setShouldShowAll(!shouldShowAll)}
        >
          Show {shouldShowAll ? 'Less' : 'More'}
        </div>
      )}
    </div>
  );
};

const UserPushTokensSection = ({
  userPushTokens,
  userId,
}: {
  userPushTokens: Schema.UserPushToken.Admin[];
  userId: string;
}) => {
  return (
    <div className="card mb-4">
      <div className="card-header">Connected Phones</div>
      <div className="card-body">
        <div className="row">
          <div className="col-lg-6 col-xs-12">
            <UserPushTokenList userPushTokens={userPushTokens} />
          </div>
          <div className="col-lg-6 col-xs-12">
            <SendPushNotificationForm userId={userId} />
          </div>
        </div>
      </div>
    </div>
  );
};

const UPDATE_BALANCE_FORM: IFormField[] = [
  {
    label: '',
    name: 'amountCents',
    inputType: INPUT_TYPE.NUMBER,
  },
  {
    label: 'Notes',
    name: 'notes',
    inputType: INPUT_TYPE.TEXTAREA,
  },
];

const EditableUserBalance = ({
  user,
  refreshUser,
}: {
  user: Schema.User.Admin;
  refreshUser: () => void;
}) => {
  const { company } = useContext(CompanyContext);
  const [isOpen, setIsOpen] = useState(false);
  const { formState, updateForm } = useForm(UPDATE_BALANCE_FORM, company);
  const [isUpdating, setIsUpdating] = useState(false);

  const submitFormAsync = async (e: any) => {
    e.preventDefault();
    setIsUpdating(true);

    try {
      await SpringClient.post(`/admin/transactions/${user.id}`, formState);
      await refreshUser();
      setIsOpen(false);
      setIsUpdating(false);
    } catch (e) {
      console.error('Error updating balance', e);
      setIsUpdating(false);
    }
  };

  const valueToAdd = formState.amountCents || 0;

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {displayPrice({
          amountCents: user.balanceAmountCents,
          numDigits: 2,
          currency: company.currency,
        })}
        <SpringButton onClick={() => setIsOpen(true)} className={'btn btn-link'}>
          edit
        </SpringButton>
      </div>

      <Dialog.Container isOpen={isOpen} setIsOpen={setIsOpen}>
        <Dialog.Title title={"Update User's balance"} />

        <form onSubmit={submitFormAsync}>
          <Dialog.Content>
            <div>
              Enter the value (in cents) to add. A positive value increases, and a negative value
              decreases the user's balance.
            </div>
            {UPDATE_BALANCE_FORM.map((formField) => (
              <FormField
                key={formField.name}
                disabled={isUpdating}
                formField={formField}
                updateForm={updateForm}
                formState={formState}
              />
            ))}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                style={{ display: 'flex', flex: 1 }}
                className="alert alert-primary mr-2"
              >{`Current: ${displayPrice({
                amountCents: user.balanceAmountCents,
                numDigits: 2,
                currency: company.currency,
              })}`}</div>
              <div
                style={{ display: 'flex', flex: 1 }}
                className="alert alert-success"
              >{`New: ${displayPrice({
                amountCents: user.balanceAmountCents + valueToAdd,
                numDigits: 2,
                currency: company.currency,
              })}`}</div>
            </div>
          </Dialog.Content>

          <Dialog.Actions>
            <SpringButton isLoading={isUpdating} type="submit" className="btn btn-primary">
              Update
            </SpringButton>
            <SpringButton onClick={() => setIsOpen(false)} type="button" className="btn btn-danger">
              Cancel
            </SpringButton>
          </Dialog.Actions>
        </form>
      </Dialog.Container>
    </>
  );
};

const EndSubscriptionButton = ({subscription,userId,refreshSubData,currentRental}:{subscription:ISubscription,userId:string,refreshSubData:any,currentRental:boolean}) =>{

  const [isLoading, setIsLoading] = useState(false);

  return (
    <SpringButton
      isLoading={isLoading}
      disabled={isLoading || currentRental}
      className={classNames('btn mr-2 cursor-pointer', {
        'btn-danger':true
      })}
      onClick={async () => {
        setIsLoading(true);
        await SpringClient.post(`/admin/subscription-cancel/${userId}`,{
          subscriptionId:subscription.id
        });
        setIsLoading(false);
        refreshSubData()
      }}
    >
      End Subscription Immediately
    </SpringButton>
    )
}

const EndSubscriptionRenewButton = ({subscription,userId,refreshSubData,currentRental}:{subscription:ISubscription,userId:string,refreshSubData:any,currentRental:boolean}) =>{

  const [isLoading, setIsLoading] = useState(false);

  return (
    <SpringButton
      isLoading={isLoading}
      disabled={isLoading || currentRental}
      className={classNames('btn mr-2 cursor-pointer', {
        'btn-danger':true
      })}
      onClick={async () => {
        setIsLoading(true);
        await SpringClient.post(`/admin/subscription-cancel-renew/${userId}`,{
          subscriptionId:subscription.id
        });
        setIsLoading(false);
        refreshSubData()
      }}
    >
      End Automatic renewal
    </SpringButton>
    )
}

const UserDetails = ({
  user,
  refreshUser,
}: {
  user: Schema.User.Admin;
  refreshUser: () => void;
}) => {
  return (
    <div className="col-lg-6 col-xs-12 mb-lg-0 mb-2">
      <Card.Container>
        <Card.Header title="Details" />
        <Card.Body>
          <DisplayData
            data={[
              { label: 'Phone Number', value: user.phoneNumber },
              { label: 'Name', value: user.name },
              { label: 'Email', value: user.email },
              { label: 'ID', value: user.canonicalId },
              {
                label: 'Home City',
                value: <HomeCityDropdown id={user.id} homeCityId={user.homeCityId} refreshData={refreshUser} updateUser={true} />
                ,
              },
              {
                label: 'Balance',
                value: <EditableUserBalance user={user} refreshUser={refreshUser} />,
              },
              {
                label: 'Pass Type',
                value: user.passType ? (user.passType === 'monthly' ? 'Monthly' : (user.passType === 'four-monthly' ? 'Four Monthly' : '')) : '',
              },
              {
                label: 'Rental Count Remain',
                value: user.passType && user.passType !== '' ? user.rentalCountRemain : '',
              },
              {
                label: 'Admin',
                value: (
                  <AdminStatusDropdown
                    id={user.id}
                    isAdmin={user.isAdmin}
                    refreshData={refreshUser}
                  />
                ),
              },
              {
                label: 'Blocked',
                value: (
                  <BlockUserDropdown
                    id={user.id}
                    isBlocked={user.isBlocked}
                    refreshData={refreshUser}
                  />
                ),
              },
              { label: 'Total life Time minutes', value: user.totalLifeTime },
            ]}
          />
        </Card.Body>
      </Card.Container>
    </div>
  );
};

const UserStats = ({
  user,
  rentals,
  subscription,
  refreshSubData
}: {
  user: Schema.User.Admin;
  rentals: Schema.Rental.Admin[];
  subscription:ISubscription | undefined;
  refreshSubData:any
}) => {
  const { dollarsSpent, avgRentalRating } = getRentalStats(rentals);
  console.log(subscription)
  return (
    <div className="col-lg-6 col-xs-12 mb-lg-0 mb-2">
      <Card.Container>
        <Card.Header title="Stats" />
        <Card.Body>
          <DisplayData
            data={[
              { label: 'Joined', value: <DateInfo date={user.createdAt} /> },
              {
                label: 'Last Rental',
                value: (
                  <LastRentalDisplay
                    currentRentalIds={user.currentRentalIds}
                    lastRentalAt={rentals[0]?.startAt}
                  />
                ),
              },
              {
                label: 'Rentals',
                value: `${rentals.length}`,
              },
              {
                label: 'Revenue Generated',
                value: `$${dollarsSpent}`,
              },
              {
                label: 'Average Rating',
                value: avgRentalRating,
              },
              {
                label:"Subscription",
                value: subscription? <EndSubscriptionButton subscription={subscription} userId={user.id} refreshSubData={refreshSubData} currentRental={user.currentRentalIds.length>0}/> : 'Not Active'
              },
              {
                label:"Auto renew",
                value: subscription? (subscription.cancel_at_period_end)? 'Canceled' : <EndSubscriptionRenewButton subscription={subscription} userId={user.id} refreshSubData={refreshSubData} currentRental={user.currentRentalIds.length>0}/> : ''
              }
            ]}
          />
        </Card.Body>
      </Card.Container>
    </div>
  );
};

export function UserProfile() {
  const { userId } = useParams();

  const { data: subData, isLoading: isLoadingSub, refreshData: refreshSubData } = useSpringFetch<{
    subscriptions: ISubscription[];
  }>('get', `/admin/get-current-subscription/${userId}`, {});

  const { data: userData, isLoading: isLoadingUser, refreshData: refreshUser } = useSpringFetch<{
    user: Schema.User.Admin;
    userPushTokens: Schema.UserPushToken.Admin[];
  }>('get', `/admin/users/${userId}`, {});
  const { data: rentalData } = useSpringFetch<{
    rentals: Schema.Rental.Admin[];
  }>('get', '/admin/rentals', {
    userId,
  });
  const { adminArea, adminAreaCity, isLoading: isLoadingAdminArea } = FilterAdminAreas();
  if (isLoadingUser && isLoadingAdminArea && isLoadingSub) {
    return <LoadingView />;
  }

  if (userData == null) {
    return <div className="alert alert-warning">User not found</div>;
  }

  const { user, userPushTokens } = userData;
  const  subscription = subData?.subscriptions[0] || undefined
  const rentals = rentalData?.rentals || [];

  return (
    <PageContainer>
      <div className="row mb-4">
        <UserDetails user={user} refreshUser={refreshUser} />
        <UserStats user={user} rentals={rentals}  subscription={subscription} refreshSubData={refreshSubData}/>
      </div>

      <UserPushTokensSection userPushTokens={userPushTokens} userId={userId as string} />

      <div className="row mb-4">
        <div className="col-12">
          <Card.Container>
            <Card.Header title="Transactions" />
            <TransactionsList userId={user.id} />
          </Card.Container>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12">
          <Card.Container>
            <div className="card-header">Rentals</div>
            <RentalList
              userId={user.id}
              adminArea={adminArea}
              adminAreaCity={adminAreaCity}
              pageSize={100}
            />
          </Card.Container>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12">
          <UserPingMap userId={userId as string} />
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12">
          <EventsCard userId={user.id} />
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12">
          <Card.Container>
            <Card.Header title="Promo Codes" />
            <UserPromoCodeList userId={userId} />
          </Card.Container>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12">
          <Card.Container>
            <Card.Header title="Support Issues" />
            <Card.Body>
              <SupportIssueList userId={userId} />
            </Card.Body>
          </Card.Container>
        </div>
      </div>
    </PageContainer>
  );
}
