import React from 'react';
import MatDialog from '@material-ui/core/Dialog';
import MatDialogActions from '@material-ui/core/DialogActions';
import MatDialogContent from '@material-ui/core/DialogContent';
import MatDialogTitle from '@material-ui/core/DialogTitle';

interface IDialogProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  children: any;
}

const Container = ({ isOpen, setIsOpen, children }: IDialogProps) => {
  return (
    <div>
      <MatDialog open={isOpen} onClose={() => setIsOpen(false)}>
        {children}
      </MatDialog>
    </div>
  );
};

const Title = ({ title }: { title: string }) => {
  return <MatDialogTitle>{title}</MatDialogTitle>;
};

const Content = ({ children }: { children: any }) => {
  return <MatDialogContent>{children}</MatDialogContent>;
};

const Actions = ({ children }: { children: any }) => {
  return <MatDialogActions>{children}</MatDialogActions>;
};

export const Dialog = {
  Container,
  Title,
  Content,
  Actions,
};
