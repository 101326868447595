/*global google*/
import { haversine, Schema, Units } from 'boaz-bikes-types';
import React, { useContext, useState } from 'react';
import { MapComponent } from '../components/MapComponent';
import { FleetContext } from '../vehicles/FleetContext';
import { useServerFetch } from 'boaz-bikes-client-utils';
import { CompanyContext } from '../auth/CompanyContext';
import { HomeCityContext } from '../home-city/HomeCityContext';
import { FilterAdminAreas, FilterAdminAreaVehicles } from '../utils/FilterAdminAreas';
import {Circle, Marker } from "react-google-maps";
import _ from 'lodash';
import { LoadingSpinner, LoadingView } from '../components/LoadingSpinner';
import { DatePeriodProvider } from '../components/DatePeriodProvider';
import { useSpringFetch } from '../firebase';
import { filterByDateAndFleet, filterRentalsByCitySelected } from '../statistics/StatisticsHome';
import   MarkerClusterer  from 'react-google-maps/lib/components/addons/MarkerClusterer';
import moment from 'moment';
import { useNavigate } from '../Router';
import { Select } from '../components/Select';
import { start } from 'repl';
import { VoidExpression } from 'typescript';

export interface IGeoQueryBounds {
  southwestLatitude: number;
  southwestLongitude: number;
  northeastLatitude: number;
  northeastLongitude: number;
}

export const checkInclude = (selectedStatus:string[],status:string ) => {
  let res = false;
  selectedStatus.map((st:string)=>{
     if (st == status) res = true
  })
  return res
}

export const removeStatus = (selectedStatus:string[], setSelectedStatus:any, status:string ) => {
  setSelectedStatus( selectedStatus.filter((stat)=> { 
      return status !== stat 
  }));
}

export const useAdminVehicles = (): Schema.Vehicle.Admin[] => {
  const { selectedFleetId } = useContext(FleetContext);
  const { selectedHomeCityId } = useContext(HomeCityContext);

  const { data } = useServerFetch<{
    vehicles: Schema.Vehicle.Admin[];
  }>('get', '/admin/vehicles', { fleetId: selectedFleetId, homeCityId: selectedHomeCityId },
   {});
   const vehicles = FilterAdminAreaVehicles(data?.vehicles) || [];
  return vehicles;
};

const EVENT_TO_SHOW = [
  'trip_end',
  'trip_started',
  'app_open',
  'vehicles-not-rented',
  'dock'
]
export const useDefaultCenter = () => {
  const { company } = useContext(CompanyContext);
  return {
    lat: company.latitude,
    lng: company.longitude,
  };
};

const filterVehiclesByLastDateRentedAndHomeCity = (vehicles:Schema.Vehicle.Admin[])=>{
  let res:Schema.Vehicle.Admin[] =[];
  const today = new Date();
  vehicles.map((vehicle)=>{
      if (vehicle.eventTime && ((today.valueOf() - new Date(vehicle.eventTime).valueOf()) > (86400 * 1000 * 3))){
        res.push(vehicle)
      }
    }
  )
  return res
}


const filterRentals = (rentals:Schema.Rental.Admin[]) =>{
  let rentalsFiltered = rentals.filter(rental=>{

    if (!(rental.startUserLatitude && rental.startUserLongitude)){
      return false;
    }
    if (!(rental.endUserLatitude && rental.endUserLongitude)){
      return false;
    }
    return true
  })

  return rentalsFiltered;
}

const OpenAppMarker = ({AppOpens}:any) => {
  return (
    <>
      {AppOpens.map((AppOpen: any) => {
        return (
          <Marker
            key={AppOpen.id+'app-open'}
            position={{ lat: AppOpen.latitude, lng: AppOpen.longitude }}
            icon={{
              url: "/assets/app-open.png",
              scaledSize: new google.maps.Size(30, 39),
            }}
          />
        );
      })}
    </>
  );
};

const VehiclesMarkerNotRented = ({vehicles}:any) => {
  const navigate = useNavigate();

  return (
    <>
      {vehicles.map((vehicle: any) => {
        return (
          <Marker
            key={vehicle.id+'vehiclenotrented'}
            position={{ lat: vehicle.latitude, lng: vehicle.longitude }}
            icon={{
              url: "/assets/vehicles-not-rented.png",
              scaledSize: new google.maps.Size(30, 39),
            }}
            onClick={() => navigate(`/vehicles/${vehicle.id}`)}
          />
        );
      })}
    </>
  );
};

const RentalMarkerStartTrip = ({rentals}:any) => {
  return (
    <>
      {rentals.map((rental: any) => {
        return (
          <Marker
            key={rental.id+'start'}
            position={{ lat: rental.startUserLatitude, lng: rental.startUserLongitude }}
            icon={{
              url: "/assets/start-trip.png",
              scaledSize: new google.maps.Size(30, 39),
            }}
          />
        );
      })}
    </>
  );
};

const RentalMarkerEndTrip = ({rentals}:any) => {
  return (
    <>
      {rentals.map((rental: any) => {
        let coordinateCurrent = new google.maps.LatLng(rental.endUserLatitude,rental.endUserLongitude)
        return (
          <Marker
          key={rental.id}
          position={coordinateCurrent}
          icon={{
            url: "/assets/end-trip.png",
            scaledSize: new google.maps.Size(30, 39),
          }}
        />
        );
      })}
    </>
  );
};

const MarkerDock = ({docks}:any) =>{
  const navigate = useNavigate();

  return (
    <>
      {docks.map((dock: any) => {
        let coordinateCurrent = new google.maps.LatLng(dock.latitude,dock.longitude)
        return (
          <Marker
          onClick={()=>navigate(`/docks/${dock.id}`)}
          key={dock.id}
          position={coordinateCurrent}
          icon={{
            url: "/assets/dock.png",
            scaledSize: new google.maps.Size(30, 39),
          }}
        />
        );
      })}
    </>
  );
}

const VEHICLE_STATUS_TO_BOOTSTRAP_TYPE: Record<string,string> =
  {
    "trip_started": "success",
    "trip_end": "danger",
    "app_open": "primary",
    "vehicles-not-rented":"warning",
    "dock":"interesting"
  };

type StatusCount = Record<string, number>;

const ButtonBar = ({
  setSelectedStatus,
  selectedStatus,
  filteredRentals,
  filteredVehicles,
  appOpensFiltered,
  currentCenter,
  setBounds,
  docks,
}: {
  setSelectedStatus: (status: any) => void;
  selectedStatus: string[];
  filteredRentals: Schema.Rental.Admin[];
  filteredVehicles: Schema.Vehicle.Admin[];
  appOpensFiltered: Schema.AppOpen.Admin[];
  currentCenter:any;
  setBounds:(bound:any)=>void;
  docks:Schema.Dock.Admin[];
}) => {
  const dockCount = _.countBy(
    docks,
    docks => "dock"
  ) as StatusCount;
  const appOpenCount = _.countBy(
    appOpensFiltered,
    appOpen => "app_open"
  ) as StatusCount;
  const rentalCountStartTrip = _.countBy(
    filteredRentals,
    rental => "trip_started"
  ) as StatusCount;
  const rentalCountEndTrip = _.countBy(
    filteredRentals,
    rental => "trip_end"
  ) as StatusCount;
  const vehiclesCount = _.countBy(
    filteredVehicles,
    rental => "vehicles-not-rented"
  ) as StatusCount;
  return (
    <div>
      <div
        className="card-body"
        style={{ display: "flex", justifyContent: "space-evenly" }}
      >
        <div className="row" style={{ flex: 1 }}>
          {EVENT_TO_SHOW
            .sort()
            .map((status) => {
              const isSelected = selectedStatus
                ? checkInclude(selectedStatus,status)
                : true;
              const buttonColor = VEHICLE_STATUS_TO_BOOTSTRAP_TYPE[status];
              const buttonStyle = isSelected
                ? `btn-${buttonColor}`
                : `btn-outline-${buttonColor}`;
              return (
                <div className="col-lg-2 col-md-4 mb-2" key={status}>
                  <button
                    type="button"
                    onClick={() =>{ 
                      if (!(checkInclude(selectedStatus,status))){
                        setSelectedStatus((selectedStatus:any) => [...selectedStatus,status])
                      }else {
                        removeStatus(selectedStatus,setSelectedStatus,status)
                      }
                    }}
                    className={`btn ${buttonStyle}`}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    {status == 'dock' && dockCount[status]?
                     dockCount[status]+" ":
                    status == 'trip_started' && rentalCountStartTrip[status]?
                     rentalCountStartTrip[status]+" ":
                     status == 'trip_end' && rentalCountEndTrip[status]?
                     rentalCountEndTrip[status]+" ":
                     status == 'vehicles-not-rented' && vehiclesCount[status]?
                     vehiclesCount[status]+" ":
                     status == 'app_open' && appOpenCount[status]?
                     appOpenCount[status]+" ": 0 + "  "}
                     {status? status  : <></>}
                  </button>
                </div>
              );
            })}
        </div>
       
      </div>
    </div>
  );
};

const getBoundingBox = function (centerPoint:any, distance:number,primera:boolean) {
  var MIN_LAT, MAX_LAT, MIN_LON, MAX_LON, R, radDist, degLat, degLon, radLat, radLon, minLat, maxLat, minLon, maxLon, deltaLon;

  // helper functions (degrees<–>radians)
  const degToRad = function (x:any) {
    return x * (Math.PI / 180);
  };
  const radToDeg = function (x:any) {
    return (180 * x) / Math.PI;
  };
  // coordinate limits
  MIN_LAT = degToRad(-90);
  MAX_LAT = degToRad(90);
  MIN_LON = degToRad(-180);
  MAX_LON = degToRad(180);
  // Earth's radius (km)
  R = 6378.1;
  // angular distance in radians on a great circle
  radDist = distance / R;
  // center point coordinates (deg)
  if (!primera){
    degLat = centerPoint.lat();
    degLon = centerPoint.lng();
  }else {
    degLat = centerPoint.lat;
    degLon = centerPoint.lng;
  }
  
  // center point coordinates (rad)
  radLat = degToRad(degLat);
  radLon = degToRad(degLon);
  // minimum and maximum latitudes for given distance
  minLat = radLat - radDist;
  maxLat = radLat + radDist;
  // minimum and maximum longitudes for given distance
  minLon = undefined;
  maxLon = undefined;
  // define deltaLon to help determine min and max longitudes
  deltaLon = Math.asin(Math.sin(radDist) / Math.cos(radLat));
  if (minLat > MIN_LAT && maxLat < MAX_LAT) {
    minLon = radLon - deltaLon;
    maxLon = radLon + deltaLon;
    if (minLon < MIN_LON) {
      minLon = minLon + 2 * Math.PI;
    }
    if (maxLon > MAX_LON) {
      maxLon = maxLon - 2 * Math.PI;
    }
  }
  // a pole is within the given distance
  else {
    minLat = Math.max(minLat, MIN_LAT);
    maxLat = Math.min(maxLat, MAX_LAT);
    minLon = MIN_LON;
    maxLon = MAX_LON;
  }
  return {
    southwestLatitude: radToDeg(minLat),   
    southwestLongitude: radToDeg(minLon), 
    northeastLatitude: radToDeg(maxLat), 
    northeastLongitude: radToDeg(maxLon),
  }
};

export const HeatMap = ({
  currentStatus,
  currentRadius,
  setCurrentRadius,
  currentCenter,
  setCurrentCenter,
  bounds,
  setBounds,
  startAt: _startAt,
  endAt: _endAt,
  rentalData,
  vehicleData,
  AppOpens,
  dockData,
  cityAdminId,
  loadingAllData,
  defaultCenterCity,
  setDefaultStart,
}: {
  currentStatus?:string;
  currentRadius?:number;
  setCurrentRadius:(radius:number)=>void
  currentCenter:google.maps.LatLng|undefined;
  setCurrentCenter:(center:google.maps.LatLng | undefined)=>void;
  bounds:IGeoQueryBounds|undefined;
  setBounds:(bound:IGeoQueryBounds|undefined)=>void;
  startAt?: Date | null;
  endAt?: Date | null;
  rentalData:{rentals:Schema.Rental.Admin[]}|undefined;
  vehicleData:{vehicles:Schema.Vehicle.Admin[]}|undefined;
  AppOpens:{appOpens:Schema.AppOpen.Admin[]}|undefined;
  dockData:{docks:Schema.Dock.Admin[]}|undefined;
  cityAdminId:string|null|undefined;
  loadingAllData:boolean;
  defaultCenterCity:any;
  setDefaultStart:(param:any)=>void;
}) => {
  const lastDays = moment()
  .utc()
  .subtract(Number(currentStatus), 'days')
  .format();

  const startAt = _startAt? _startAt : moment(lastDays).toDate();
  setDefaultStart(_startAt)
  const endAt = _endAt? _endAt : null
  const [selectedVehicleId, setSelectedVehicleId] = useState<string | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  
 
  const appOpensFiltered = AppOpens? filterByDateAndFleet(AppOpens.appOpens,startAt, endAt) :[]
  const docks = dockData ? dockData.docks : [];
  const vehicles = vehicleData ? vehicleData.vehicles : [];
  const filteredVehicles =  filterVehiclesByLastDateRentedAndHomeCity(vehicles)
  const rentalsWhitoutHomeCity = rentalData
  ? filterByDateAndFleet(rentalData.rentals.slice().reverse(),startAt, endAt) : [];
  const rentals = rentalsWhitoutHomeCity;    //filterRentalsByCitySelected(rentalsWhitoutHomeCity)
  
  const filteredRentals = filterRentals(rentals);

  const RadiousVariableCircle = (radius:any) =>{
    let radiusMiles = radius.radius * 1609.344;
    let center = currentCenter ? currentCenter : defaultCenterCity;
     return(
        <Circle
          center={center}
          radius={radiusMiles}
          options={{
            strokeColor: "#F39860",
            strokeOpacity: 0.8,
            strokeWeight: 3,
            fillColor: "#FCEFE7",
            fillOpacity: 0.35
          }}
       /> 
    )
  }
  let loadingHeatMapData = true;
  const rentalsToHeatMap = () =>{
    let heatmapDataVehiclesLocal:any[] = [];
    filteredRentals.map((rental)=>{
      if (checkInclude(selectedStatus,'trip_end')){
        let coordinateCurrent2 = new google.maps.LatLng(rental.endUserLatitude,rental.endUserLongitude)
        heatmapDataVehiclesLocal.push({location:coordinateCurrent2,weight:20})
      }
      if (checkInclude(selectedStatus,'trip_started')){
        let coordinateCurrent = new google.maps.LatLng(rental.startUserLatitude,rental.startUserLongitude)
        heatmapDataVehiclesLocal.push({location:coordinateCurrent,weight:20})
      }
    })
    filteredVehicles.map((vehicle)=>{
      if (checkInclude(selectedStatus,'vehicles-not-rented')){
        let coordinateCurrent = new google.maps.LatLng(vehicle.latitude,vehicle.longitude)
        heatmapDataVehiclesLocal.push({location:coordinateCurrent,weight:20})
      }
    })
    appOpensFiltered.map((AppOpen:Schema.AppOpen.Admin)=>{
      if ((checkInclude(selectedStatus,'app_open'))){
        let coordinateCurrent = new google.maps.LatLng(AppOpen.latitude,AppOpen.longitude)
        heatmapDataVehiclesLocal.push({location:coordinateCurrent,weight:20})
      }
    })

    docks.map((dock:Schema.Dock.Admin)=>{
      if ((checkInclude(selectedStatus,'dock'))){
          let coordinateCurrent = new google.maps.LatLng(dock.latitude,dock.longitude)
          heatmapDataVehiclesLocal.push({location:coordinateCurrent,weight:20})
        }
    })
    loadingHeatMapData = false;
    return heatmapDataVehiclesLocal
  }


  // let heatmapDataRentals = !loadingHeatMapData? rentalsToHeatMap() : [];
  let heatmapDataRentals = ((!loadingAllData) && (!cityAdminId) && (docks.length>0 || filteredVehicles.length>0 || filteredRentals.length>0) )?  rentalsToHeatMap(): [];
  if ((!cityAdminId)&& ((!loadingAllData))&& (!loadingHeatMapData) && (docks.length>0 || filteredVehicles.length>0 || filteredRentals.length>0) )return<LoadingSpinner/> 
  return (
    <div className="w-100 h-100" style={{ position: 'relative',width:'100%'}}>
      {/* {loading?  <LoadingView/> : <></>} */}
      <MapComponent
        defaultZoom={11}
        defaultCenter={defaultCenterCity}
        containerHeight={'80vh'}
        heatmapDataRentals={heatmapDataRentals}
        onClick={() => setSelectedVehicleId(null)}
        updateDatabyBounds={(center:any)=> {
          const distance =currentCenter?
               haversine({latitude:currentCenter.lat(),longitude:currentCenter.lng()},
                        {latitude:center.lat(),longitude:center.lng()},Units.MILE)
              : 11;
          if (distance > 3 ){
            let newCurrentCenter = new google.maps.LatLng(center.lat(),center.lng())
            let Lat = currentCenter?.lat();
            let Lng = currentCenter?.lng();
            if (Lat && Lng && currentRadius && currentRadius >0){
              let boundCurrent = getBoundingBox(newCurrentCenter,currentRadius,false)
              // setBounds(boundCurrent)
              // updateBoundsCircleMoved(boundCurrent)
            }
            // setCurrentCenter(newCurrentCenter)
          } 
        }}
      >
        <RadiousVariableCircle  radius={currentRadius}/> 
      
        <MarkerClusterer
        averageCenter
        gridSize={60}
        styles={[{
          url:'/assets/dock-cluster.png',
          height:40,
          width:40
        }]}
      >
      {checkInclude(selectedStatus,'dock')? <MarkerDock docks={docks} />
      :<></>}
       </MarkerClusterer>

      <MarkerClusterer
        averageCenter
        gridSize={60}
        styles={[{
          url:'/assets/start-trip-cluster.png',
          height:40,
          width:40
        }]}
      >
      {checkInclude(selectedStatus,'trip_started')? <RentalMarkerStartTrip rentals={filteredRentals} />
      :<></>}
       </MarkerClusterer>
       <MarkerClusterer
        averageCenter
        gridSize={60}
        styles={[{
          url:'/assets/end-trip-cluster.png',
          height:40,
          width:40
        }]}
      >
      {checkInclude(selectedStatus,'trip_end')? <RentalMarkerEndTrip rentals={filteredRentals} />
      :<></>}
       </MarkerClusterer>
       <MarkerClusterer
        averageCenter
        gridSize={60}
        styles={[{
          url:'/assets/vehicles-not-rented-cluster.png',
          height:40,
          width:40
        }]}
      >
      {checkInclude(selectedStatus,'vehicles-not-rented')? <VehiclesMarkerNotRented vehicles={filteredVehicles} />
      :<></>}
      </MarkerClusterer>
      <MarkerClusterer
        averageCenter
        gridSize={60}
        styles={[{
          url:'/assets/app-open-cluster.png',
          height:40,
          width:40
        }]}
      >
      {checkInclude(selectedStatus,'app_open')? <OpenAppMarker AppOpens={appOpensFiltered} />
      :<></>}
      </MarkerClusterer>
    </MapComponent>
    <ButtonBar
          setSelectedStatus={setSelectedStatus}
          selectedStatus={selectedStatus}
          filteredRentals={filteredRentals}
          filteredVehicles={filteredVehicles}
          appOpensFiltered={appOpensFiltered}
          currentCenter={currentCenter}
          setBounds={setBounds}
          docks={docks}
        />
    </div>
  );
};

export const HeatMapSection = () => {

  const status = ['1','3','5','7','12','custom'];
  const [currentStatus, setCurrentStatus] = useState('1');
  const [currentRadius, setCurrentRadius ] = useState(0);
  const [ currentValue, setCurrentValue ] = useState(0);
  const [ loadPressed, setLoadPressed ] = useState(false)
  const [defaultStart, setDefaultStart] = useState(moment().subtract(Number(currentStatus)))
  const [ currentCenter, setCurrentCenter ] = useState<google.maps.LatLng | undefined>(undefined);
  const { selectedHomeCityId,setSelectedHomeCityId, getHomeCities, isLoading:isLoadingCities } = useContext(HomeCityContext);
  const defaultCenter = useDefaultCenter();
  let defaultStartAppOpens = currentStatus;
  let defaultCenterCity:any=defaultCenter;
  const {adminArea, homeCityAdmin:CityAdminId,adminAreaCity, isLoading:isLoadingAreas} = FilterAdminAreas();
  let allHomecities =  getHomeCities();
  if (selectedHomeCityId!= 'All Home City' &&  selectedHomeCityId) {
    allHomecities?.map((city) => {
      if (city.id == selectedHomeCityId) {
        defaultCenterCity = {
          lat: city.latitude,
          lng: city.longitude,
        };
      }
    });
  }else if (adminArea) {
    if (selectedHomeCityId!= 'All Home City') setSelectedHomeCityId(adminAreaCity)
    allHomecities?.map((city) => {
      if (city.id == adminAreaCity) {
        defaultCenterCity = {
          lat: city.latitude,
          lng: city.longitude,
        };
      }
    });
  } else if (CityAdminId) {
    if (selectedHomeCityId!= 'All Home City') setSelectedHomeCityId(CityAdminId)
    
    allHomecities?.map((city) => {
      if (city.id == CityAdminId) {
        defaultCenterCity = {
          lat: city.latitude,
          lng: city.longitude,
        };
      }
    });
  } 
  const [bounds, setBounds] = useState<IGeoQueryBounds|undefined>(undefined);
  let firstBounds:IGeoQueryBounds|undefined = getBoundingBox(defaultCenterCity,currentRadius,true);
 
  const handleChange = (event:any) =>{
    setCurrentValue(event.target.value)
    setCurrentRadius(event.target.value)
  }

  const updateRadius = () =>{
    let Lat = currentCenter?.lat();
    let Lng = currentCenter?.lng();
    if (Lat && Lng && currentRadius >0){
      let boundCurrent = getBoundingBox(currentCenter,currentRadius,false)
      setBounds(boundCurrent)
    }
  }
  
  const refreshAllDataOnMap = () => {
    refreshRentals();
    refreshVehicles();
    refreshAppOpens();
    refreshDocks();
  }
  const { data: rentalData, isLoading: isLoadingRentals, refreshData:refreshRentals } = useSpringFetch<{
    rentals: Schema.Rental.Admin[];
  }>('get', '/admin/rentals',{
    bounds:firstBounds, 
    startAt:defaultStart,
    homeCityId:selectedHomeCityId});

  const { data: vehicleData, isLoading: isLoadingVehicles,  refreshData:refreshVehicles} = useSpringFetch<{
    bounds: IGeoQueryBounds,
    vehicles: Schema.Vehicle.Admin[];
  }>('get', '/admin/vehicles',{
    bounds:firstBounds,
    homeCityId:selectedHomeCityId});

  const { data: AppOpens, isLoading: isLoadingOpenApp,  refreshData:refreshAppOpens } = useSpringFetch<{
    bounds: IGeoQueryBounds,
    appOpens: Schema.AppOpen.Admin[];
  }>('get', '/admin/app-opens',{
    bounds:firstBounds,
    startAt:defaultStartAppOpens,
    homeCityId:selectedHomeCityId});

  const { data: dockData, isLoading: isLoadingDockData,  refreshData:refreshDocks } = useSpringFetch<{
    bounds: IGeoQueryBounds,
    docks: Schema.Dock.Admin[];
  }>('get', '/admin/docks',{bounds:firstBounds,homeCityId:selectedHomeCityId});

  const loadingAllData = isLoadingRentals || isLoadingOpenApp || isLoadingVehicles || isLoadingDockData || isLoadingCities || isLoadingAreas;
  const buttonStyle = loadPressed
                ? `btn-primary`
                : `btn-outline-primary`;
  if (isLoadingCities || isLoadingAreas)return<LoadingSpinner/> 
  return (
    <>
    <div style={{display:'flex',flexDirection:'row'}}>
      <div style ={{ width:'25%', marginBottom:5}}>
        <Select 
            value={currentStatus}
            options={status.map((status) => ({
                label: status + ' days ago',
                value: status,
            }))}
            onChange={(value) => setCurrentStatus(value)}
        />
      </div>
      <div style={{paddingLeft: 10, marginTop:5}}>
          <label style={{marginLeft:2, paddingRight: 5}}>Radius </label>
          <input style ={{paddingLeft: 5, width:100}}type="number" name="radius" value={currentValue} onChange={handleChange}></input>
      </div>
      <button
           type="button"
           disabled={selectedHomeCityId=='All Home City' || !selectedHomeCityId}
           onClick={() =>{ 
            if (!loadPressed){
              updateRadius()
              refreshAllDataOnMap()
            }
            setLoadPressed(!loadPressed)
           }}
           className={`btn-button`}
           style={{
             display: "flex",
             justifyContent: "center",
             height: "100%",
             width: "10%",
             marginLeft:10,
             marginTop:5,
           }}
        >
         {loadPressed? 'Loaded' : 'Load'} 
      </button>
    </div>
    {loadPressed? 
    <div  >
    {currentStatus == 'custom'? 
    <DatePeriodProvider
        render={({ start, end }) => (
          <HeatMap 
          currentStatus={currentStatus}
          currentRadius={currentRadius}
          setCurrentRadius={setCurrentRadius}
          currentCenter={currentCenter}
          setCurrentCenter={setCurrentCenter}
          bounds={bounds}
          setBounds={setBounds}
          startAt={start} endAt={end} 
          rentalData={rentalData}
          vehicleData={vehicleData}
          AppOpens={AppOpens}
          dockData={dockData}
          cityAdminId={CityAdminId}
          loadingAllData={loadingAllData}
          defaultCenterCity={defaultCenterCity}
          setDefaultStart={setDefaultStart}
          />
        )}
      />
      :
      <HeatMap 
      currentStatus={currentStatus}
      currentRadius={currentRadius}
      setCurrentRadius={setCurrentRadius}
      currentCenter={currentCenter}
      setCurrentCenter={setCurrentCenter}
      bounds={bounds}
      setBounds={setBounds}
      rentalData={rentalData}
      vehicleData={vehicleData}
      AppOpens={AppOpens}
      dockData={dockData}
      cityAdminId={CityAdminId}
      loadingAllData={loadingAllData}
      defaultCenterCity={defaultCenterCity}
      setDefaultStart={setDefaultStart}
       />   
       }
      
    </div>
    :<></>}
    </>
  );
};