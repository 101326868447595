import { useEffect, useState } from 'react';
import { usePolling } from 'boaz-bikes-client-utils';
// Adapted from https://usehooks.com/useAnimation/

export const useAnimation = (duration = 500, resetVal = 0) => {
  // The useAnimationTimer hook calls useState every animation frame ...
  // ... giving us elapsed time and causing a rerender as frequently ...
  // ... as possible for a smooth animation.
  const elapsed = useAnimationTimer(duration, resetVal);
  // Amount of specified duration elapsed on a scale from 0 - 1
  return Math.min(1, elapsed / duration);
};

const POLLING_INTERVAL_MS = 100;

const useAnimationTimer = (duration = 1000, resetVal = 0) => {
  const [start, setStart] = useState(new Date());
  const [elapsed, setElapsed] = useState(0);

  usePolling(() => {
    if (resetVal === 0) {
      return;
    }

    setElapsed(Date.now() - start.valueOf());
  }, POLLING_INTERVAL_MS);

  useEffect(() => {
    setStart(new Date());
  }, [resetVal]);

  return elapsed;
};
