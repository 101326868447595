import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Colors } from './Colors';

export const LoadingSpinner = ({
  size,
  color = Colors.PRIMARY,
}: {
  size?: number;
  color?: string | 'inherit';
}) => (
  <div style={{ color: color === 'inherit' ? undefined : color }}>
    <CircularProgress size={size} color={'inherit'} />
  </div>
);

export const LoadingView = () => {
  return (
    <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <LoadingSpinner />
    </div>
  );
};
