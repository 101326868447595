import { Schema, SpringClient, usersToOptions } from 'boaz-bikes-types';
import React, { useContext, useState } from 'react';
import { UserContext } from '../auth/UserContext';
import { DateInfo } from '../components/DateInfo';
import { Select } from '../components/Select';
import { TabRow } from '../components/Tab';
import { Table } from '../components/Table';
import { useSpringFetch } from '../firebase';
import { VehicleSummary } from '../rentals/RentalList';
import { useNavigate } from '../Router';
import { FleetContext } from '../vehicles/FleetContext';
import { Card } from '../components/Card';
import { PageContainer } from '../components/PageContainer';
import { SpringButton } from '../components/SpringButton';
import { Link } from 'react-router-dom';
import { HomeCityContext } from '../home-city/HomeCityContext';
import { FilterAdminAreas } from '../utils/FilterAdminAreas';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { LoadingSpinner } from '../components/LoadingSpinner';

enum Tabs {
  VEHICLE_REPORT = "vehicle report",
  DOCK_REPORT = "dock report"
}

type EventFilter = (event: Schema.Event.Admin) => boolean;

const TAB_TO_FILTER: Partial<Record<Tabs, EventFilter>> = {
  [Tabs.VEHICLE_REPORT]: (event) => event.type == Schema.Event.Type.VEHICLE_REPORT,
  [Tabs.DOCK_REPORT]: (event) => event.type == Schema.Event.Type.DOCK_REPORT,
};

const CompleteButton = ({
  id,
  completedAt,
  refreshData,
}: {
  id: string;
  completedAt: string | null;
  refreshData: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const updateStatusAsync = async () => {
    setIsLoading(true);
    await SpringClient.post(`/admin/tasks/${id}`, {
      completedAt: completedAt ? null : new Date().toISOString(),
    });
    await refreshData();
    setIsLoading(false);
  };

  return (
    <SpringButton
      isLoading={isLoading}
      type="button"
      className={`btn btn-${completedAt ? 'secondary' : 'success'}`}
      onClick={updateStatusAsync}
    >
      {`${completedAt ? 'reopen' : 'complete'}`}
    </SpringButton>
  );
};

// const AssignedSelect = ({
//   signedInUserId,
//   options,
//   assignedId,
//   taskId,
//   refreshData,
// }: {
//   signedInUserId: string;
//   options: Schema.User.Admin[];
//   assignedId: string | null;
//   taskId: string;
//   refreshData: () => void;
// }) => {
//   const [isLoading, setIsLoading] = useState(false);
//   const updateAssignedAsync = async (userId: string) => {
//     setIsLoading(true);
//     await SpringClient.post(`/admin/tasks/${taskId}`, {
//       ownerId: userId || null,
//     });
//     refreshData();
//     setIsLoading(false);
//   };
//   const adminUserOptions = usersToOptions(signedInUserId, options);
//   return (
//     <Select
//       isLoading={isLoading}
//       value={assignedId || ''}
//       options={adminUserOptions}
//       onChange={(value) => updateAssignedAsync(value)}
//     />
//   );
// };

const EventList = ({ 
  filter,
  adminArea,
  adminAreaCity,
  pageSize,
  selectedTab
 }: { 
  filter?: EventFilter;
  adminArea:boolean;
  adminAreaCity:string;
  pageSize:number;
  selectedTab:Tabs
   }) => {
  const { user } = useContext(UserContext);
  const { selectedHomeCityId, getHomeCities } = useContext(HomeCityContext);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage ] = useState(1)
  const [rowsState, setRowsState] = useState<any>([])
  const { selectedFleetId } = useContext(FleetContext);
  const [ vehiclesState , setVehiclesState] = useState<Schema.Vehicle.Admin[]>([]);
  const { homeCityAdmin:CityAdminId}  = FilterAdminAreas();
  const { data, isLoading, refreshData } = useSpringFetch<{
    events: Schema.Event.Admin[];
    vehicles: Schema.Vehicle.Admin[];
    eventsLength:number;
  }>('get', '/admin/events', {
    homeCityId:  adminArea? adminAreaCity : selectedHomeCityId,
    fleetId: selectedFleetId,
    pageSize,
    type:selectedTab,
    page:currentPage-1,
    expand: ['vehicle']
  });
  const { data: userData } = useSpringFetch<{
    users: Schema.User.Admin[];
  }>('get', '/admin/users', { isAdmin: true });

  if (!isLoading && data == null) {
    return <div>No Tasks Available</div>;
  }

  const setCurrentPageAndData = (page:number) =>{
    setVehiclesState(vehicles);
    setCurrentPage(page);

  }

  const vehicles = data ? vehiclesState.concat(data.vehicles) : [] ;
  const events = data && CityAdminId && adminArea ? data.events.filter((event)=> event.homeCityId ==CityAdminId) : data ? data.events : [];
  const vehicleIdMap = new Map(vehicles.map((vehicle) => [vehicle.id, vehicle]));
  // mapping homeCities
  const allHomeCity = getHomeCities();
  const homeCityMap = new Map(allHomeCity?.map((homecity: any) => [homecity.id, homecity.name]))
  const eventsLength = data?.eventsLength;
  // const filteredEvents = filter ? events.filter(filter) : events;
  const filteredEvents = events;
  const exportEvents = filteredEvents.map( (event) => {     
    let homeCityCurrent = allHomeCity?.find((homecity: any) => homecity.id === event.homeCityId);
    let currentUser = userData?.users.find((user:Schema.User.Admin)=>user.id == event.userId);
    let currentVehicle = vehicles.find((vehicle:Schema.Vehicle.Admin)=> vehicle.id == event.vehicleId )
    return {
      'Home City':homeCityCurrent?.name,   
      'Type': event.type,
      'Vehicle Qr':currentVehicle?.qrCode,
      'User':currentUser?.name,
      'Message':event?.message,
      'Created At': moment.utc(event.createdAt).fromNow(),
      'Updated At':moment.utc(event.updatedAt).fromNow(),
    };
  }
);

  return (
    <div className="row">
      <div className="col">
        <Card.Container>
          <Table
            title={`Events`}
            isLoading={isLoading && events.length < 1}
            rows={filteredEvents}
            // onAddItem={() => navigate('/events/create')}
            // getRowUrl={(task) => `/tasks/${task.id}/edit`}
            columnRenderInfos={[
            {
                columnName: 'Home City',
                attribute: 'vehicleId',
                renderFunc: ({ vehicleId }) => {
                    if (vehicleId) {
                    const vehicleCurrent = vehicleIdMap.get(vehicleId);
                    const homeCityName = homeCityMap.get(vehicleCurrent?.homeCityId);
                    return homeCityName || "";
                    }
                    return null;
                }
                },
              { columnName: 'Type', attribute: 'type' },
              {
                columnName: 'Vehicle QR',
                attribute: 'vehicleId',
                renderFunc: ({ vehicleId }) => {
                  if (vehicleId == '8fd494b6-935d-4951-83e8-704b62b74d1c'){ // dock reports has for default vehicle id 8fd494b6-935d-4951-83e8-704b62b74d1c
                      return null;
                  }else 
                  if (vehicleId) {
                    return <VehicleSummary vehicle={vehicleIdMap.get(vehicleId)!} />;
                  }
                  return null;
                },
               },
               {
                columnName: 'User',
                attribute: 'userId',
                renderFunc: ({ userId }) => {
                    if (userData && userId){
                        let user =userData?.users.find((user:Schema.User.Admin)=>user.id == userId);
                        return <div>{user?.name}</div>
                    }
                    return null;
                },
               },
               { columnName: 'Message', attribute: 'message' },
              {
                columnName: 'Created At',
                attribute: 'createdAt',
                renderFunc: ({ createdAt }) => <DateInfo date={createdAt} />,
              },
              {
                columnName: 'Updated At',
                attribute: 'updatedAt',
                renderFunc: ({ updatedAt }) => <DateInfo date={updatedAt} />,
              },
             
            ]}
            currentPage={currentPage}
            setCurrentPage={setCurrentPageAndData}
            rowsState={rowsState}
            setRowsState={setRowsState}
            dataLength={eventsLength}

          />
          <div className="row mb-4">
            <div className="col">
              <CSVLink
                style={{marginLeft:20}}
                data={exportEvents}
                filename={"events.csv"}
                className="btn btn-primary"
                target="_blank"
              >
                Export
              </CSVLink>
            </div>
          </div>
        </Card.Container>
      </div>
    </div>
  );
};

export function EventsHome() {
  const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.VEHICLE_REPORT);
  const {adminArea,adminAreaCity,isLoading:isLoadingAdminArea }  = FilterAdminAreas();
  const {isLoading:isLoadingHomeCities } = useContext(HomeCityContext);
  if (isLoadingAdminArea || isLoadingHomeCities) return <LoadingSpinner></LoadingSpinner>  

  return (
    <PageContainer>
      <TabRow
        tabs={Object.values(Tabs).map((tab) => ({
          title: tab,
          onClick: () => setSelectedTab(tab),
          isSelected: selectedTab === tab,
        }))}
      />
      <EventList selectedTab={selectedTab} pageSize={100} adminArea={adminArea} adminAreaCity={adminAreaCity} filter={TAB_TO_FILTER[selectedTab]} />
    </PageContainer>
  );
}
