import React from 'react';
import { Polygon as MapPolygon } from 'react-google-maps';
import { Schema, GEOFENCE_TYPE_TO_COLOR } from 'boaz-bikes-types';

export enum SupportedGeoJSONType {
  POLYGON = 'Polygon',
  MULTIPOLYGON = 'MultiPolygon',
}

export const isSupportedGeoJSONType = (type: string) => {
  // @ts-ignore ts issue supporting string enums
  return Object.values(SupportedGeoJSONType).includes(type);
};

interface IPolygonProps {
  coordinates: number[][][];
  color: string;
  key?: string;
  onClick?: () => void;
}
const Polygon = ({ coordinates, key, onClick, color }: IPolygonProps) => {
  const paths = coordinates.map((path, index) => {
    return path.map(([lng, lat]) => ({
      lat,
      lng,
    }));
  });
  return (
    <MapPolygon
      key={key}
      onClick={onClick}
      paths={paths}
      options={{ fillColor: color, fillOpacity: 0.5, clickable: onClick != null }}
    />
  );
};

export const Geofence = ({
  geometry,
  type,
  color,
  onClick,
}: {
  geometry: GeoJSON.Geometry;
  type?: Schema.Geofence.Type;
  color?: string;
  onClick?: () => void;
}) => {
  const defaultColor = color || GEOFENCE_TYPE_TO_COLOR[type as Schema.Geofence.Type] || '#ff0000';
  switch (geometry.type) {
    case SupportedGeoJSONType.POLYGON:
      return <Polygon coordinates={geometry.coordinates} color={defaultColor} onClick={onClick} />;
    case SupportedGeoJSONType.MULTIPOLYGON:
      return (
        <>
          {geometry.coordinates.map((polygonCoords, idx) => {
            return (
              <Polygon
                key={idx.toString()}
                coordinates={polygonCoords}
                color={defaultColor}
                onClick={onClick}
              />
            );
          })}
        </>
      );
    default:
      throw new Error('Attempting to render unsupported geoJSON');
  }
};
