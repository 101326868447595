import Checkbox from '@material-ui/core/Checkbox';
import { Schema, SpringClient } from 'boaz-bikes-types';
import querystring from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CompanyContext } from '../auth/CompanyContext';
import { Card } from '../components/Card';
import { DisplayData } from '../components/DisplayData';
import { PageContainer } from '../components/PageContainer';
import { UrlLink } from '../components/UrlLink';
import { PricingConfig } from './PricingConfig';
import { useSpringFetch } from '../firebase';
import { Select } from '../components/Select';
import { HomeCityDropdown } from '../home-city/HomeCityDropdown';
import { LoadingSpinner } from '../components/LoadingSpinner';

enum RedirectValues {
  APP_STORE_CONNECT = 'app-store-connect',
  PLAY_STORE_DASHBOARD = 'play-store-dash',
}

const EditButton = () => {
  return <Link to={'/config/edit'}>Edit</Link>;
};

const CompanyInfo = ({
  company,
  termsConfig,
  playStoreDashUrl,
  appStoreConnectUrl,
}: {
  company: Schema.Company.Admin;
  termsConfig?: Schema.TermsConfig.Client;
  playStoreDashUrl: string;
  appStoreConnectUrl: string;
}) => {
  return (
    <div className="col-lg-6 col-xs-12 mb-lg-0 mb-2">
      <Card.Container>
        <Card.Header title="Company Info" actions={<EditButton />} />
        <Card.Body>
          <DisplayData
            data={[
              { label: 'ID', value: company.id },
              { label: 'Phone Number', value: company.contactPhoneNumber },
              { label: 'Email', value: company.contactEmail },
              { label: 'Country Code', value: company.countryCode },
              { label: 'Unit System', value: company.unitSystem },
              { label: 'Auto Lock Timer', value: company.autoLockTimer },
              { label: 'Latitude', value: company.latitude },
              { label: 'Longitude', value: company.longitude },
              { label: 'Status', value: company.status },
              { label: 'Is Status Visible', value: `${company.isStatusVisible}` },
              {
                label: 'Terms',
                value: (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>{termsConfig?.description}</div>
                    {termsConfig?.links.map(([label, link]) => {
                      return <UrlLink className="mt-1" label={label} href={link} />;
                    })}
                  </div>
                ),
              },
              {
                label: 'Apple App Id',
                value: (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {company.appleAppId}
                    {company.appleAppId && (
                      <>
                        <UrlLink
                          className="mt-1"
                          label={'App Store Listing'}
                          href={`https://apps.apple.com/us/app/id${company.appleAppId}`}
                        />
                        <UrlLink label={'App Store Connect'} href={appStoreConnectUrl} />
                      </>
                    )}
                  </div>
                ),
              },
              {
                label: 'Android Package Name',
                value: (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {company.androidPackageName}
                    {company.androidPackageName && (
                      <>
                        <UrlLink
                          className="mt-1"
                          label={'Play Store Listing'}
                          href={`https://play.google.com/store/apps/details?id=${company.androidPackageName}`}
                        />
                        <UrlLink
                          className="mt-1"
                          label={'Play Store Beta URL'}
                          href={`https://play.google.com/apps/testing/${company.androidPackageName}`}
                        />
                        <UrlLink
                          className="mt-1"
                          label={'Play Store Dashboard'}
                          href={playStoreDashUrl}
                        />
                      </>
                    )}
                  </div>
                ),
              },
            ]}
          />
        </Card.Body>
      </Card.Container>
    </div>
  );
};

const FeatureCheck = ({ enabled }: { enabled: boolean }) => {
  return <Checkbox disabled style={{ margin: 0, padding: 0 }} checked={enabled} />;
};

const FeatureFlags = ({ company }: { company: Schema.Company.Client }) => {
  return (
    <div className="col-lg-6 col-xs-12 mb-lg-0 mb-2">
      <Card.Container>
        <Card.Header title="Feature Flags" />
        <Card.Body>
          <DisplayData
            data={[
              { label: 'Wallet', value: <FeatureCheck enabled={company.minBalanceCents > 0} /> },
              {
                label: 'Group Rentals',
                value: <FeatureCheck enabled={company.enableGroupRentals} />,
              },
              {
                label: 'End At Dock Required',
                value: <FeatureCheck enabled={company.docksRequireNearDock} />,
              },
              {
                label: 'Scan Dock Required',
                value: <FeatureCheck enabled={company.docksRequireScan} />,
              },
            ]}
          />
        </Card.Body>
      </Card.Container>
    </div>
  );
};

const HomeCity = ({ company }: { company: Schema.Company.Client }) => {
  const [homeSelected, setHomeSelected] = useState('0')
  
  const refreshData = (homeCityId: any) =>{
    setHomeSelected(homeCityId);
  }

  return (
    <div className="col-lg-6 col-xs-12 mb-lg-0 mb-2">
      <Card.Container>
        <Card.Header title="Home City" />
        <Card.Body>
          
          <div className="row mb-12">
            <div className="col-lg-6 col-xs-12 mb-lg-0 mb-2">
              <HomeCityDropdown homeCityId={homeSelected} refreshData={refreshData} />   
            </div>
            <div className="col-lg-6 col-xs-12 mb-lg-0 mb-2" >
              <Link to={`/home-city/${homeSelected}/edit`} >Edit</Link>         
            </div>
          </div>

          <div className="row mb-12" style={{ marginTop:20 }}>            
            <Link to={'/home-city/create'} >Add New Home City</Link>         
          </div>
          
        </Card.Body>
      </Card.Container>
    </div>
  );
};

const External = ({ company }: { company: Schema.Company.Client }) => {
  return (
    <div className="col-lg-6 col-xs-12 mb-lg-0 mb-2">
      <Card.Container>
        <Card.Header title="External" />
        <Card.Body>
          <DisplayData
            data={[
              {
                label: 'Resources',
                value: (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <UrlLink
                      label={'Documentation'}
                      href={
                        'https://www.notion.so/springmobility/Spring-Docs-6edecc698e2e45fe8fd0643035da158e'
                      }
                    />
                    <UrlLink
                      className="mt-1"
                      label={'Contents Dashboard'}
                      href={`https://www.contents.io/teams/${company.id}/collections/onboarding-screens`}
                    />
                  </div>
                ),
              },
            ]}
          />
        </Card.Body>
      </Card.Container>
    </div>
  );
};

const CompanyTheme = ({ company }: { company: Schema.Company.Client }) => {
  return (
    <div className="col-lg-6 col-xs-12 mb-lg-0 mb-2">
      <Card.Container>
        <Card.Header title="Company Theme" />
        <Card.Body>
          <DisplayData
            data={[
              { label: 'Primary', value: company.themeColorPrimary },
              { label: 'Accent', value: company.themeColorAccent },
              { label: 'Background', value: company.themeColorBackground },
            ]}
          />
        </Card.Body>
      </Card.Container>
    </div>
  );
};

export const ConfigPage = () => {
  const { company, termsConfig,topUpOptions, refetch } = useContext(CompanyContext);
  const { search } = useLocation();
  const { redirect } = querystring.parse(search);
  const { data, isLoading } = useSpringFetch<{
    company: Schema.Company.Admin;
    topUpOptions: Schema.TopUpOption.Client[];
    priceOptions:Schema.PriceOption.Client[];
  }>('get', `/company-config-admin`, {});
  const priceOptions = data ? data?.priceOptions : [];

  useEffect(() => {
    refetch();
  }, [refetch]);

  const appStoreConnectUrl = `https://appstoreconnect.apple.com/WebObjects/iTunesConnect.woa/ra/ng/app/${company.appleAppId}`;
  const playStoreDashUrl = `https://play.google.com/apps/publish/?account=7797188121055405785#AppDashboardPlace:p=${company.androidPackageName}`;

  useEffect(() => {
    if (redirect === RedirectValues.APP_STORE_CONNECT && company.appleAppId) {
      window.location.href = appStoreConnectUrl;
    }
    if (redirect === RedirectValues.PLAY_STORE_DASHBOARD && company.androidPackageName) {
      window.location.href = playStoreDashUrl;
    }
  }, [
    redirect,
    company.appleAppId,
    company.androidPackageName,
    appStoreConnectUrl,
    playStoreDashUrl,
  ]);
  if (isLoading) return <LoadingSpinner></LoadingSpinner>
  return (
    <PageContainer>
      <div className="row mb-4">
        <CompanyInfo
          company={company}
          termsConfig={termsConfig}
          playStoreDashUrl={playStoreDashUrl}
          appStoreConnectUrl={appStoreConnectUrl}
        />

        <PricingConfig company={company} priceOptions={priceOptions} topUpOptions={topUpOptions} />
      </div>

      <div className="row mb-4">
        <FeatureFlags company={company} />
        <External company={company} />
      </div>

      <div className="row mb-4">
        <CompanyTheme company={company} />
        <HomeCity company={company} />
      </div>
    </PageContainer>
  );
};
