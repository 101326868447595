import React, { createContext, useState } from 'react';
import { useServerFetch } from 'boaz-bikes-client-utils';
import { Schema } from 'boaz-bikes-types';
import { FilterAdminAreas } from '../utils/FilterAdminAreas';

interface IHomeCityContext {
  isLoading: boolean;
  availableHomeCityIds: string[];
  selectedHomeCityId?: string;
  setSelectedHomeCityId(homeCityId: string | undefined): void;
  refreshHomeCities(homeCityObj: any): void;
  getHomeCities(): Schema.HomeCity.Admin[] | null;
}

const INITIAL_HOME_CITY_CONTEXT: IHomeCityContext = {
  isLoading: true,
  availableHomeCityIds: [],
  selectedHomeCityId: undefined,
  setSelectedHomeCityId: () => null,
  refreshHomeCities: () => null,
  getHomeCities: () => null,
};

export const HomeCityContext = createContext<IHomeCityContext>(INITIAL_HOME_CITY_CONTEXT);

export const HomeCityProvider = ({ children }: { children: any }) => {
  const [selectedHomeCityId, setSelectedHomeCityId] = useState();
  const [newData, setNewData] = useState();
  const [isAdminArea, setIsAdminArea ] = useState(false);

  const { data, isLoading:isLoadingCities, refreshData: refreshHomeCities } = useServerFetch<{
    homeCities: Schema.HomeCity.Admin[]
  }>('get', '/admin/homecities');
  let res = FilterAdminAreas();
  const {adminArea, adminAreaCity, isLoading:isLoadingAreas} = res;
  let dataFiltered:Schema.HomeCity.Admin[] | undefined;
  if (!adminArea){
    dataFiltered = data?.homeCities;
  }else{
    dataFiltered = data?.homeCities.filter((homecity) => homecity.id == adminAreaCity);
  }
  const  availableHomeCityIds  =
  dataFiltered?.filter((homecity) => homecity.name).map((homecity) => homecity.name || '') || [];

  const updateHomeCities = (homeCityObj: any) => {
    let listHomeCity = new Array();
    dataFiltered?.forEach(homecity => {
      if (homecity.id === homeCityObj.id) listHomeCity.push(homeCityObj.name);
      else listHomeCity.push(homecity.name)
    })
    setNewData(listHomeCity)
  }

  const getHomeCities = () =>{
    return dataFiltered || [];
  }

  return (
    <HomeCityContext.Provider
      value={{
        isLoading:isLoadingCities || isLoadingAreas,
        availableHomeCityIds: newData || availableHomeCityIds,
        selectedHomeCityId,
        setSelectedHomeCityId: (homeCityId: string) => setSelectedHomeCityId(homeCityId || undefined),
        refreshHomeCities: (homeCityObj: any) => {
          updateHomeCities(homeCityObj);
        },
        getHomeCities
      }}
    >
      {children}
    </HomeCityContext.Provider>
  );
};
