import { FormField, IFormField, INPUT_TYPE, useForm } from 'boaz-bikes-forms';
import { Schema, SpringClient } from 'boaz-bikes-types';
import React, { useState,useContext } from 'react';
import { Colors } from '../components/Colors';
import { Geofence } from '../components/Geofence';
import { LoadingSpinner, LoadingView } from '../components/LoadingSpinner';
import { MapComponent } from '../components/MapComponent';
import { useSpringFetch } from '../firebase';
import { useNavigate } from '../Router';
import { useDefaultCenter } from '../map/MapPage';
import { HomeCityContext } from '../home-city/HomeCityContext';
import { FilterAdminAreas } from '../utils/FilterAdminAreas';

export const useAdminGeofences = () => {
  const { data, isLoading, refreshData } = useSpringFetch<{
    geofences: Schema.Geofence.Admin[];
    todayDateServer:Date;
  }>('get', '/admin/geofences', {});

  const geofences = data ? data.geofences : [];
  
  return { geofences,todayDateServer:data?.todayDateServer, isLoading, refreshData };
};

export const GEOFENCE_FORM_FIELDS: IFormField[] = [
  {
    label: 'Name',
    name: 'name',
    inputType: INPUT_TYPE.TEXT,
  },
  {
    label: 'Type',
    name: 'type',
    inputType: INPUT_TYPE.SELECT,
    options: Object.values(Schema.Geofence.Type),
  },
  {
    label: 'discount',
    name: 'discount',
    inputType: INPUT_TYPE.NUMBER,
  },
  {
    label: 'Is Active',
    name: 'isActive',
    inputType: INPUT_TYPE.SELECT,
    options: [
          {label:"True", value:'true'},
          {label:"False", value:'false'},
          {label:"Custom", value:'custom'}
        ]
  },
  {
    label: 'Active Days',
    name:'daysActive',
    inputType:INPUT_TYPE.TEXT,
    description:'options:sun,mon,tue,wed,thu,fri,sat'
  },
  {
    label: 'Start Active',
    name:'startActive',
    inputType:INPUT_TYPE.SELECT,
    options: [
      {label:"1 am", value:1},
      {label:"2 am", value:2},
      {label:"3 am", value:3},
      {label:"4 am", value:4},
      {label:"5 am", value:5},
      {label:"6 am", value:6},
      {label:"7 am", value:7},
      {label:"8 am", value:8},
      {label:"9 am", value:9},
      {label:"10 am", value:10},
      {label:"11 am", value:11},
      {label:"12 pm", value:12},
      {label:"1 pm", value:13},
      {label:"2 pm", value:14},
      {label:"3 pm", value:15},
      {label:"4 pm", value:16},
      {label:"5 pm", value:17},
      {label:"6 pm", value:18},
      {label:"7 pm", value:19},
      {label:"8 pm", value:20},
      {label:"9 pm", value:21},
      {label:"10 pm", value:22},
      {label:"11 pm", value:23},
      {label:"12 am", value:24},
    ]
  },
  {
    label: 'End Active',
    name:'endActive',
    inputType:INPUT_TYPE.SELECT,
    options: [
      {label:"1 am", value:1},
      {label:"2 am", value:2},
      {label:"3 am", value:3},
      {label:"4 am", value:4},
      {label:"5 am", value:5},
      {label:"6 am", value:6},
      {label:"7 am", value:7},
      {label:"8 am", value:8},
      {label:"9 am", value:9},
      {label:"10 am", value:10},
      {label:"11 am", value:11},
      {label:"12 pm", value:12},
      {label:"1 pm", value:13},
      {label:"2 pm", value:14},
      {label:"3 pm", value:15},
      {label:"4 pm", value:16},
      {label:"5 pm", value:17},
      {label:"6 pm", value:18},
      {label:"7 pm", value:19},
      {label:"8 pm", value:20},
      {label:"9 pm", value:21},
      {label:"10 pm", value:22},
      {label:"11 pm", value:23},
      {label:"12 am", value:24},
    ]
  }
];

export const MapOverlay = ({
  children,
  style = {},
}: {
  children: React.ReactNode;
  style?: React.CSSProperties;
}) => {
  return (
    <div
      className="card"
      style={{
        position: 'absolute',
        top: 8,
        right: 8,
        maxWidth: 400,
        maxHeight: '90vh',
        backgroundColor: Colors.WHITE,
        overflow: 'scroll',
      }}
    >
      <div className="card-body" style={style}>
        {children}
      </div>
    </div>
  );
};

const UnselectedOverlay = () => {
  const navigate = useNavigate();
  return (
    <MapOverlay style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div className="alert alert-info"> Click on geofence to view/edit details </div>
      <div className="btn btn-primary mt-3" onClick={() => navigate('/geofence/create')}>
        {'Create New Geofence'}
      </div>
      <div className="btn btn-primary mt-3" onClick={() => navigate('/geofence/import')}>
        {'Import Geofence'}
      </div>
    </MapOverlay>
  );
};

const UpdateOverlay = ({
  geofence,
  onDismiss,
  onRefresh,
  todayDateServer
}: {
  geofence: Schema.Geofence.Admin;
  onDismiss: () => void;
  onRefresh: () => void;
  todayDateServer:Date|undefined;
}) => {
  const { formState: updateFormState, updateForm: updateUpdateForm } = useForm(
    GEOFENCE_FORM_FIELDS,
    geofence
  );
  const [isUpdatingFence, setIsUpdatingFence] = useState(false);

  const submitUpdateFormAsync = async (e: any) => {
    e.preventDefault();
    setIsUpdatingFence(true);
    const daysWeek = ['mon','tue','wed','thu','fri','sat','sun']
    try {
      if (updateFormState.isActive=='custom'){
        if (!updateFormState.daysActive){
          alert('Active Days field empty')
          throw new Error('Days field empty')
        }
        let index = daysWeek.findIndex((dayWeek)=>(updateFormState.daysActive.includes(`${dayWeek}`)))
        if (index == -1){
          alert('Active Days field empty or invalid.')
          throw new Error('Days field invalid')
        }
      }
      await SpringClient.post(`/admin/geofences/${geofence.id}`, {
        ...updateFormState,
      });
      setIsUpdatingFence(false);
      onRefresh();
    } catch (e) {
      console.error('Error updating geofence', e);
      setIsUpdatingFence(false);
    }
  };

  const deleteSelectedGeofenceAsync = async () => {
    await SpringClient.delete(`/admin/geofences/${geofence.id}`);
    onRefresh();
  };

  return (
    <MapOverlay>
      <form onSubmit={(e) => submitUpdateFormAsync(e)}>
        {GEOFENCE_FORM_FIELDS.map((formField) =>{
           if (formField.name == 'discount' && updateFormState.type != 'parking discount'){
             return;
           }
           if ((formField.name == 'daysActive'|| 
              formField.name == 'startActive' ||
              formField.name == 'endActive'  )&& updateFormState.isActive != 'custom'){
            return;
            }
           return(
          <FormField
            key={formField.name}
            disabled={isUpdatingFence}
            formField={formField}
            updateForm={updateUpdateForm}
            formState={updateFormState}
          />
        )})}

        <button type="submit" className="btn btn-primary" disabled={isUpdatingFence}>
          Update
        </button>
        <button
          className="btn btn-primary ml-2"
          onClick={(e) => {
            e.preventDefault();
            onDismiss();
          }}
        >
          Cancel
        </button>
        <button
          className="btn btn-danger  ml-2"
          onClick={(e) => {
            e.preventDefault();
            deleteSelectedGeofenceAsync();
          }}
        >
          Delete
        </button>
      </form>
      <div>
       <div>Current Server time is:</div>
        {todayDateServer}
      </div>
    </MapOverlay>
  );
};

export const GeofenceMap = () => {
  const defaultCenter = useDefaultCenter();
  const { geofences, refreshData: refreshGeofences,todayDateServer, isLoading } = useAdminGeofences();
  const [selectedGeofenceId, setSelectedGeofenceId] = useState<string>();
  const selectedGeofence = geofences.find(({ id }) => id === selectedGeofenceId);
  const { getHomeCities, selectedHomeCityId,setSelectedHomeCityId } = useContext(HomeCityContext);
  let defaultCenterCity: any;
  const {adminArea, homeCityAdmin:CityAdminId} = FilterAdminAreas();
  if (selectedHomeCityId!= 'All Home City' &&  selectedHomeCityId) {
    let allHomecities = getHomeCities();
    allHomecities?.map((city) => {
      if (city.id == selectedHomeCityId) {
        defaultCenterCity = {
          lat: city.latitude,
          lng: city.longitude,
        };
      }
    });
  } else if (CityAdminId) {
    if (selectedHomeCityId!= 'All Home City') setSelectedHomeCityId(CityAdminId)
    let allHomecities = getHomeCities();
    allHomecities?.map((city) => {
      if (city.id == CityAdminId) {
        defaultCenterCity = {
          lat: city.latitude,
          lng: city.longitude,
        };
      }
    });
  } 

  if (isLoading) {
    return <LoadingView />;
  }
  if (!CityAdminId) return<LoadingSpinner/> 
  return (
    <div className="w-100 h-100" style={{ position: 'relative' }}>
      <MapComponent
        containerHeight={'95vh'}
        defaultZoom={13}
        center={defaultCenterCity}
        clickableIcons={false}
        onClick={() => setSelectedGeofenceId(undefined)}
      >
        {geofences.map(({ id, geometry, type }) => {
          return (
            <Geofence
              key={id}
              geometry={geometry}
              type={type}
              onClick={() => {
                setSelectedGeofenceId(id);
              }}
            />
          );
        })}
      </MapComponent>
      {selectedGeofence ? (
        <UpdateOverlay
          geofence={selectedGeofence}
          onDismiss={() => setSelectedGeofenceId(undefined)}
          onRefresh={() => refreshGeofences()}
          todayDateServer={todayDateServer}
        />
      ) : (
        <UnselectedOverlay />
      )}
    </div>
  );
};
