import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tooltip } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';

export const CopyIcon = ({ text }: { text: string }) => {
  return (
    <CopyToClipboard text={text}>
      <div style={{ cursor: 'pointer' }} className="text-secondary">
        <Tooltip title="copy">
          <FileCopy />
        </Tooltip>
      </div>
    </CopyToClipboard>
  );
};
