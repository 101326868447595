import { SpringClient } from 'boaz-bikes-types';
import React, { useState } from 'react';
import { Select } from '../components/Select';

export const BlockUserDropdown = ({
  id,
  isBlocked,
  refreshData,
}: {
  id: string;
  isBlocked: boolean;
  refreshData: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const updateIsBlocked = async (isBlocked: boolean) => {
    const blockStr = isBlocked ? 'block' : 'unblock';
    if (window.confirm(`Do you want to ${blockStr} this user?`)) {
      setIsLoading(true);
      await SpringClient.post(`/admin/users/${id}/update-blocked`, { isBlocked });
      await refreshData();
      setIsLoading(false);
    }
  };

  return (
    <Select
      isLoading={isLoading}
      value={`${isBlocked}`}
      options={['true', 'false'].map((option) => ({ label: option, value: option }))}
      onChange={(value) => updateIsBlocked(value === 'true')}
    />
  );
};
