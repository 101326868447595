import React, { createContext, useEffect, useReducer } from 'react';
import { firebase } from '../firebase';
import { SpringClient } from 'boaz-bikes-types';

const SIGN_IN_EVENT = 'signIn';
const SIGN_OUT_EVENT = 'signOut';

interface IAUthReducerActionPayload {
  user?: any;
  isAdmin?: boolean;
  type: string;
}

const authReducer = (
  _state: IUserContextState,
  { type, user, isAdmin = false }: IAUthReducerActionPayload
): IUserContextState => {
  switch (type) {
    case SIGN_IN_EVENT:
      return { user, isUserSignedIn: true, isLoadingUser: false, isAdmin };
    case SIGN_OUT_EVENT:
      return { user: null, isUserSignedIn: false, isLoadingUser: false, isAdmin: false };
    default:
      throw new Error('Problem with the auth reducer');
  }
};

const INITIAL_USER_CONTEXT: IUserContext = {
  user: null,
  isAdmin: false,
  isUserSignedIn: false,
  isLoadingUser: true,
  logout: () => null,
};

interface IUserContextState {
  user: any;
  isAdmin: boolean;
  isUserSignedIn: boolean;
  isLoadingUser: boolean;
}

interface IUserContext extends IUserContextState {
  logout: () => void;
}

export const UserContext = createContext<IUserContext>(INITIAL_USER_CONTEXT);

export const UserProvider = ({ children }: { children: any }) => {
  const [state, dispatch] = useReducer(authReducer, INITIAL_USER_CONTEXT);
  const { user, isAdmin, isUserSignedIn, isLoadingUser } = state;

  useEffect(() => {
    // listen for auth state changes
    const unsubscribe = firebase
      .auth()
      .onAuthStateChanged(async (firebaseUser: firebase.User | null) => {
        if (firebaseUser === null) {
          dispatch({
            type: SIGN_OUT_EVENT,
          });
          return;
        }

        try {
          const { isAdmin } = await SpringClient.get('/user/is-admin');

          dispatch({
            type: SIGN_IN_EVENT,
            user: firebaseUser,
            isAdmin,
          });

          const { user: _user } = await SpringClient.get('/user');
          (window as any).analytics.identify(_user.canonicalId, _user);

          // Victor - I use this to get an auth token to test the Cloud Functions API via Paw
          // Ideally their should be a better way of getting those tokens. But this is OK for now
          // since it's not a security vulnerability
          firebaseUser.getIdToken().then(console.log);
        } catch (e) {
          dispatch({
            type: SIGN_OUT_EVENT,
          });
          return;
        }
      });

    return () => unsubscribe();
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        isAdmin,
        isUserSignedIn,
        isLoadingUser,
        logout: () => {
          firebase.auth().signOut();
        },
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
