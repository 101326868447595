import { ICoordinate, Schema } from "boaz-bikes-types";
import moment from "moment";
import React, { useContext } from "react";
import Marker from "react-google-maps/lib/components/Marker";
import Polyline from "react-google-maps/lib/components/Polyline";
import { Colors } from "../components/Colors";
import { MapComponent } from "../components/MapComponent";
import { useSpringFetch } from "../firebase";
import { VehicleAnimator } from "./VehicleAnimator";
import { CompanyContext } from "../auth/CompanyContext";
import { AdminDocks } from "../components/VehicleMarker";

export const STATUS_TO_COLOR: Record<Schema.Vehicle.Status, string> = {
  [Schema.Vehicle.Status.ACTIVE]: Colors.SUCCESS,
  [Schema.Vehicle.Status.RENTED]: Colors.INTERESTING,
  [Schema.Vehicle.Status.LOW_BATTERY]: Colors.DANGER,
  [Schema.Vehicle.Status.OFFLINE]: Colors.PRIMARY,
  [Schema.Vehicle.Status.REPAIR]: Colors.WARNING,
  [Schema.Vehicle.Status.Customer_Bike]: Colors.WARNING,
};

const getMapCenter = (
  startUserCoordinate: ICoordinate | null | undefined,
  endUserCoordinate: ICoordinate | null | undefined
): ICoordinate | null => {
  if (startUserCoordinate) {
    return startUserCoordinate;
  }

  if (endUserCoordinate) {
    return endUserCoordinate;
  }

  return null;
};

const UserPings = ({
  userId,
  startAt,
  endAt,
}: {
  userId: string | null;
  startAt: string | null;
  endAt: string | null;
}) => {
  const { data: userPingsData, isLoading } = useSpringFetch<{
    userPings: Schema.UserPing.Admin[];
  }>("get", `/admin/user-pings/${userId}`, {
    startAt,
    endAt,
  });

  if (isLoading || !userPingsData) {
    return null;
  }

  const { userPings } = userPingsData;

  return (
    <Polyline
      options={{ strokeColor: "blue" }}
      path={userPings.map(({ latitude, longitude }) => ({
        lat: latitude,
        lng: longitude,
      }))}
    />
  );
};

const groupVehiclePingsByStatus = (
  vehiclePings: Schema.VehiclePing.Admin[]
) => {
  const groupedPings: Array<Schema.VehiclePing.Admin[]> = [];

  let currentStatus: string | null = null;

  vehiclePings.forEach((vehiclePing) => {
    const { vehicleStatus } = vehiclePing;
    if (!currentStatus || vehicleStatus !== currentStatus) {
      currentStatus = vehicleStatus;
      groupedPings.push([vehiclePing]);
    } else {
      groupedPings[groupedPings.length - 1].push(vehiclePing);
    }
  });

  return groupedPings;
};

const VehiclePings = ({
  vehicleId,
  startAt,
  endAt,
}: {
  vehicleId: string | null;
  startAt: string | null;
  endAt: string | null;
}) => {
  const { data: vehiclePingsData, isLoading } = useSpringFetch<{
    vehiclePings: Schema.VehiclePing.Admin[];
  }>("get", `/admin/vehicle-pings/${vehicleId}`, {
    startAt,
    endAt,
  });

  if (isLoading || !vehiclePingsData) {
    return null;
  }

  const { vehiclePings } = vehiclePingsData;

  const groupedPings = groupVehiclePingsByStatus(vehiclePings);

  return (
    <>
      {groupedPings.map((vehiclePingsWithStatus, idx) => (
        <Polyline
          key={idx}
          options={{
            strokeColor:
              STATUS_TO_COLOR[vehiclePingsWithStatus[0].vehicleStatus],
          }}
          path={vehiclePingsWithStatus.map(({ latitude, longitude }) => ({
            lat: latitude,
            lng: longitude,
          }))}
        />
      ))}
    </>
  );
};

export const PingsMap = ({
  startAt,
  endAt,
  vehicleId,
  startCoordinate,
  endCoordinate,
  userId,
}: {
  startAt: Date | null;
  endAt: Date | null;
  vehicleId: string;
  startCoordinate?: ICoordinate | null;
  endCoordinate?: ICoordinate | null;
  userId?: string;
}) => {
  startAt = startAt || moment().subtract(4, "hours").toDate();
  endAt = endAt || moment().toDate();

  const endAtString = endAt ? moment(endAt).format() : null;
  const startAtString = startAt ? moment(startAt).format() : null;

  const { company } = useContext(CompanyContext);
  const { latitude: mapCenterLatitude, longitude: mapCenterLongitude } =
    getMapCenter(startCoordinate, endCoordinate) || {
      latitude: company.latitude,
      longitude: company.longitude,
    };

  return (
    <div>
      <VehicleAnimator.Provider
        startAt={startAt}
        endAt={endAt}
        vehicleId={vehicleId}
      >
        <MapComponent
          defaultZoom={13}
          defaultCenter={{ lat: mapCenterLatitude, lng: mapCenterLongitude }}
        >
          <VehicleAnimator.MapMarker />
          {startCoordinate && (
            <Marker
              icon={"http://maps.google.com/mapfiles/ms/icons/red-dot.png"}
              position={{
                lat: startCoordinate.latitude,
                lng: startCoordinate.longitude,
              }}
            />
          )}

          {endCoordinate && (
            <Marker
              icon={"http://maps.google.com/mapfiles/ms/icons/blue-dot.png"}
              position={{
                lat: endCoordinate.latitude,
                lng: endCoordinate.longitude,
              }}
            />
          )}

       {/*   <AdminDocks /> }*/}

          {vehicleId && (
            <VehiclePings
              vehicleId={vehicleId}
              startAt={startAtString}
              endAt={endAtString}
            />
          )}
          {userId && (
            <UserPings
              userId={userId}
              startAt={startAtString}
              endAt={endAtString}
            />
          )}
        </MapComponent>

        <VehicleAnimator.ControlBar />
      </VehicleAnimator.Provider>

      {userId && <div>Blue is user pings.</div>}
      {startCoordinate && endCoordinate && (
        <div>Start = Red Pin. End = Blue Pin.</div>
      )}
    </div>
  );
};
