import React, { useEffect, useState } from 'react';
import { FormField, IFormField, INPUT_TYPE, useForm } from 'boaz-bikes-forms';
import { Spacer } from './VehicleInfo';
import { showMessage } from '../ToastProvider';
import { Message, SpringClient } from 'boaz-bikes-types';
import { SpringButton } from '../components/SpringButton';

export const SEND_COMMAND_FIELDS: IFormField[] = [
  {
    label: 'IOT Device ID',
    name: 'iotDeviceId',
    inputType: INPUT_TYPE.TEXT,
  },
  {
    label: 'Message Type',
    name: 'messageType',
    inputType: INPUT_TYPE.NUMBER,
  },
  {
    label: 'Other Keys',
    name: 'payload',
    inputType: INPUT_TYPE.TEXT,
    description: 'This will be combined with messageType and put in the payload, e.g. {"j": 0}',
  },
];

export const SendFitriderMessage = ({
  vehicleId,
  iotDeviceId,
}: {
  vehicleId: string | null;
  iotDeviceId?: string | null;
}) => {
  const { formState, updateForm } = useForm(SEND_COMMAND_FIELDS);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    updateForm({ name: 'messageType', newValue: 1 });
    updateForm({ name: 'payload', newValue: '{}' });
  }, [updateForm]);

  useEffect(() => {
    updateForm({ name: 'iotDeviceId', newValue: iotDeviceId });
  }, [iotDeviceId, updateForm]);

  const [result, setResult] = useState<object | null>(null);

  const submitFormAsync = async (e: any) => {
    e.preventDefault();
    setResult(null);
    setIsLoading(true);

    try {
      let payload = {
        a: formState.messageType,
      };

      if (formState.payload) {
        try {
          const moreData = JSON.parse(formState.payload);
          payload = Object.assign(payload, moreData);
        } catch {
          showMessage({
            messageText: `Could not parse ${formState.payload}`,
            messageType: Message.Type.Warning,
          });
        }
      }

      const data = {
        topic: formState.iotDeviceId,
        payload,
      };

      const dataToSend = { data };

      const response = await SpringClient.post(`/admin/iot/${vehicleId}/command`, dataToSend);

      setResult(response);
      setIsLoading(false);
    } catch (e) {
      console.error('Error sending command', e);
      showMessage({
        messageText: 'Error sending request.',
        messageType: Message.Type.Warning,
      });
      setIsLoading(false);
    }
  };

  return (
    <div className="row">
      <div className="col-12 col-md-6 mx-auto">
        <div className="my-4">
          <form onSubmit={submitFormAsync}>
            {SEND_COMMAND_FIELDS.map((formField) => (
              <FormField
                key={formField.name}
                disabled={isLoading}
                formField={formField}
                updateForm={updateForm}
                formState={formState}
              />
            ))}

            <SpringButton type="submit" className="btn btn-primary" isLoading={isLoading}>
              Submit
            </SpringButton>
          </form>

          <Spacer />

          {JSON.stringify(result)}
        </div>
      </div>
    </div>
  );
};
