import React from 'react';

interface IProps {
  label: string;
  href: string;
  className?: string;
  style?: any;
}

export const UrlLink = ({ label, href , className, style}: IProps) => {
  return (
    <a target="_blank" rel="noopener noreferrer" href={href} className={className} style={style}>
      {label}
    </a>
  );
};
