import React, { useState } from 'react';
import { IFormField, INPUT_TYPE } from 'boaz-bikes-forms';
import { SimpleForm } from 'boaz-bikes-forms';
import { SpringClient, Schema } from 'boaz-bikes-types';
import { showMessage } from '../ToastProvider';
import { LoadingView } from '../components/LoadingSpinner';

export const CREATE_COMMENT_FIELDS: IFormField[] = [
  {
    label: 'Message',
    name: 'message',
    inputType: INPUT_TYPE.TEXT,
  },
];

interface IProps {
  userId?: string;
  vehicleId?: string;
  rentalId?: string;
  onCommentAdded?(): void;
}

export const SupportComment = ({ userId, vehicleId, rentalId, onCommentAdded }: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const submitFormAsync = async (formState: any) => {
    setIsLoading(true);
    try {
      const { message } = await SpringClient.post('/admin/events', {
        ...formState,
        userId,
        vehicleId,
        rentalId,
        type: Schema.Event.Type.SUPPORT_COMMENT,
      });
      if (onCommentAdded) {
        onCommentAdded();
      }
      showMessage(message);
    } catch (e) {
      console.error('Error adding comment', e);
    }
    setIsLoading(false);
  };
  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <SimpleForm
      title={'Add Comment'}
      formFields={CREATE_COMMENT_FIELDS}
      onSubmit={submitFormAsync}
      autoFocus={false}
    />
  );
};
