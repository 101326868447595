import { getGoogleMapsNavigateUrl, ICoordinate } from 'boaz-bikes-types';
import React from 'react';
import { CopyIcon } from './CopyIcon';

interface IProps {
  coordinate: ICoordinate;
}

export const Location = ({ coordinate }: IProps) => {
  const googleMapsUrl = getGoogleMapsNavigateUrl(coordinate);
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <a className="mr-2" href={googleMapsUrl} target="_blank" rel="noopener noreferrer">
        {`${coordinate.latitude}, ${coordinate.longitude}`}
      </a>
      <CopyIcon text={googleMapsUrl} />
    </div>
  );
};
