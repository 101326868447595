import { Schema } from 'boaz-bikes-types';
import React, { useContext, useState } from 'react';
import { DateInfo } from '../components/DateInfo';
import { TabRow } from '../components/Tab';
import { Table } from '../components/Table';
import { useSpringFetch } from '../firebase';
import { Card } from '../components/Card';
import { PageContainer } from '../components/PageContainer';
import { Link } from 'react-router-dom';
import { HomeCityContext } from '../home-city/HomeCityContext';
import { FilterAdminAreas } from '../utils/FilterAdminAreas';

enum Tabs {
  ALL = 'All',
  PENDING = 'Pending',
  ENROUTE = 'Enroute',
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
}

type DropoffFilter = (dropoff: Schema.Dropoff.Admin) => boolean;

const TAB_TO_FILTER: Partial<Record<Tabs, DropoffFilter>> = {
  [Tabs.ALL]: () => true,
  [Tabs.PENDING]: (dropoff) => dropoff.status == Schema.Dropoff.Status.PENDING,
  [Tabs.ENROUTE]: (dropoff) => dropoff.status == Schema.Dropoff.Status.ENROUTE,
  [Tabs.COMPLETED]: (dropoff) => dropoff.status == Schema.Dropoff.Status.COMPlETED,
  [Tabs.CANCELLED]: (dropoff) => dropoff.status == Schema.Dropoff.Status.CANCELLED,
};

const FilterDateRange = (props: any) => {
  const dateRangeList = [
    { value: 1, label: '1 day' },
    { value: 3, label: '3 days' },
    { value: 7, label: '7 days' },
    { value: 15, label: '15 days' },
    { value: 30, label: '30 days' },
    { value: 90, label: '90 days' },
    { value: 120, label: '120 days' },
    { value: 365, label: '365 days' },
    { value: 0, label: 'All' },
  ];

  const handleChange = (elm: any) => {
    props.setDateRange(elm.target.value);
  }

  return (
    <div>
      Date Range: {' '}
      <select
        // className="form-control"
        value={props.dateRange}
        onChange={handleChange}
      >
        {dateRangeList.map((range) => (
          <option key={range.value} value={range.value}>{range.label}</option>
        ))}
      </select>
    </div>
  )
}

const getDataByDateRange = (data: any[], range: number) => {
  if (range > 0) {
    let dateCompare = new Date();
    dateCompare.setHours(0, 0, 0, 0);
    dateCompare.setDate(dateCompare.getDate() - range + 1);
    data = data.filter(item => {
      let dateOfItem = new Date(item.createdAt);
      if (dateOfItem.getTime() > dateCompare.getTime()) return true;
      return false;
    })
  }
  return data;
}

const DropOffList = ({ filter }: { filter?: DropoffFilter }) => {
  const { selectedHomeCityId, getHomeCities } = useContext(HomeCityContext);
  const [dateRange, setDateRange] = useState(0);
  const {adminArea, homeCityAdmin:CityAdminId} = FilterAdminAreas()
  const { data, isLoading, refreshData } = useSpringFetch<{
    dropOffs: Schema.Dropoff.Admin[];
    users: Schema.User.Admin[];
    drivers: Schema.User.Admin[];
  }>('get', '/admin/dropoffs', {
    homeCityId: selectedHomeCityId,
    expand: ['user', 'driver'],
  });

  if (!isLoading && data == null) {
    return <div>No Drop Offs Available</div>;
  }

  // filter dropOffs
  const dropOffs = data && CityAdminId ? data.dropOffs.filter((dropoff)=>dropoff.homeCityId==CityAdminId)
   : data? data.dropOffs : [];
  const filteredDropOffs = filter ? dropOffs.filter(filter) : dropOffs;
  const dropOffsFilterDate = getDataByDateRange(filteredDropOffs, dateRange);

  // mapping users
  const userIdMap = new Map(data?.users?.map((user) => [user.id, user]));

  // mapping drivers
  const driverIdMap = new Map(data?.drivers?.map((driver)=> [driver.id, driver]));

  // mapping homeCities
  const allHomeCity = getHomeCities();
  const homeCityMap = new Map(allHomeCity?.map((homecity: any) => [homecity.id, homecity.name]))

  const refreshDateRange = (range: number) => {
    setDateRange(Number(range));
  }

  return (
    <div className="row">
      <div className="col">
        <Card.Container>
          <Card.Header
            title={`Drop Offs`}
            actions={<FilterDateRange dateRange={dateRange} setDateRange={refreshDateRange} />}
          />
          <Table
            // title={`Drop Offs`}
            // onAddItem={()=>{console.log("hello")}}
            // getRowUrl={(dropOff) => `/dropoffs/${dropOff?.id}`}
            isLoading={isLoading}
            rows={dropOffsFilterDate}
            columnRenderInfos={[
              {
                columnName: 'Home City',
                attribute: 'homeCityId',
                renderFunc: ({ homeCityId }) => {
                  let homeName = homeCityMap.get(homeCityId);
                  return homeName || "";
                }
              },
              {
                columnName: 'Order ID',
                attribute: 'id'
              },
              {
                columnName: 'User ID',
                attribute: 'userId',
                renderFunc: ({ userId }) => {
                  let user = userIdMap.get(userId);
                  return <Link to={`/users/${user?.id}`}>{user?.phoneNumber}</Link>
                }
              },
              {
                columnName: 'Drop Off Address',
                attribute: 'dropOffAddress'
              },
              {
                columnName: 'Notes',
                attribute: 'notes'
              },
              {
                columnName: 'Bikes Requested',
                attribute: 'bikesRequested'
              },
              {
                columnName: 'Driver ID',
                attribute: 'driverId',
                renderFunc: ({ driverId }) => {
                  let driver = driverIdMap.get(driverId);
                  return <Link to={`/users/${driver?.id}`}>{driver?.phoneNumber}</Link>
                }
              },
              {
                columnName: 'Status',
                attribute: 'status'
              },
              {
                columnName: 'Order Created',
                attribute: 'createdAt',
                renderFunc: ({ createdAt }) => <DateInfo date={createdAt} />,
              },
              {
                columnName: 'Order Completed',
                attribute: 'updatedAt',
                renderFunc: ({ updatedAt }) => <DateInfo date={updatedAt} />,
              },

              {
                columnName: 'Rating',
                attribute: 'rating',
              },

              {
                columnName: 'Edit',
                renderFunc: ({ id }) => (
                  <Link to={`/dropoffs/${id}`}>Edit</Link>
                ),
              },
            ]}
          />
        </Card.Container>
      </div>
    </div>
  );
};

export function DropOffHome() {
  const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.ALL);
  return (
    <PageContainer>
      <TabRow
        tabs={Object.values(Tabs).map((tab) => ({
          title: tab,
          onClick: () => setSelectedTab(tab),
          isSelected: selectedTab === tab,
        }))}
      />
      <DropOffList filter={TAB_TO_FILTER[selectedTab]} />
    </PageContainer>
  );
}