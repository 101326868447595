import React, { useState } from 'react';
import { SpringClient } from 'boaz-bikes-types/dist/SpringClient';
import { Select } from '../components/Select';

export const DeliveryStatusDropdown = ({
  id,
  isDelivery,
  refreshData,
}: {
  id: string;
  isDelivery: boolean;
  refreshData: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const updateStatusAsync = async (isDelivery: boolean) => {    
    if (window.confirm("Do you want to change this person's delivery status?")) {
      setIsLoading(true);
      await SpringClient.post(`/admin/users/${id}/update-delivery`, { isDelivery });
      await refreshData();
      setIsLoading(false);
    }
  };  
  return (
    <Select
      isLoading={isLoading}
      value={`${isDelivery}`}
      options={['true', 'false'].map((option) => ({ label: option, value: option }))}
      onChange={(value) => updateStatusAsync(value === 'true')}
    />
  );
};
