import { Schema } from "boaz-bikes-types";
import _ from "lodash";
import moment from "moment";
import React, { useContext, useState } from "react";
import { Card } from "../components/Card";
import { DatePeriodProvider } from "../components/DatePeriodProvider";
import { Geofence } from "../components/Geofence";
import { IDataPoint, LineGraph } from "../components/LineGraph";
import { MapComponent } from "../components/MapComponent";
import { AdminDocks, VehicleMarker } from "../components/VehicleMarker";
import { useSpringFetch } from "../firebase";
import { useAdminGeofences } from "../geofence/GeofenceHome";
import { useAdminVehicles, useDefaultCenter } from "../map/MapPage";
import { useNavigate } from "../Router";
import {
  filterByDateAndFleet,
  getNewUserCountByDay,
  getRevenueByDay,
  getRidesByDay,
} from "../statistics/StatisticsHome";
import { getRentalStats } from "../utils/rentals";
import { FleetContext } from "../vehicles/FleetContext";
import { PageContainer } from "../components/PageContainer";
import { HomeCityContext } from "../home-city/HomeCityContext";
import { FilterAdminAreas } from "../utils/FilterAdminAreas";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { checkInclude, removeStatus } from "../heat-map/HeatMapSection";

const VEHICLE_STATUS_TO_BOOTSTRAP_TYPE: Record<string, string> =
  {
    [Schema.Vehicle.Status.ACTIVE]: "success",
    [Schema.Vehicle.Status.LOW_BATTERY]: "danger",
    [Schema.Vehicle.Status.OFFLINE]: "primary",
    [Schema.Vehicle.Status.REPAIR]: "warning",
    [Schema.Vehicle.Status.RENTED]: "interesting",
    [Schema.Vehicle.Status.Customer_Bike]: "interesting",
    '30-50%-battery':"warning",

  };

  const STATUS_BUTTONS: any =
  [
    [Schema.Vehicle.Status.ACTIVE],
    [Schema.Vehicle.Status.LOW_BATTERY],
    [Schema.Vehicle.Status.OFFLINE],
    [Schema.Vehicle.Status.REPAIR],
    [Schema.Vehicle.Status.RENTED],
    [Schema.Vehicle.Status.Customer_Bike],
    '30-50%-battery',
  ];

type StatusCount = Record<string, number>;

const StatusCountBar = ({
  statusCount,
  onClick,
}: {
  statusCount: Record<Schema.Vehicle.Status, number>;
  onClick: () => void;
}) => {
  const total = _.sum(Object.values(statusCount));
  return (
    <div
      onClick={onClick}
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        cursor: "pointer",
      }}
    >
      <div
        style={{ display: "flex", justifyContent: "center" }}
      >{`${total} vehicles`}</div>
      <div className="progress">
        {Object.keys(statusCount)
          .sort()
          .map((_status: any) => {
            const status = _status as Schema.Vehicle.Status;
            return (
              <div
                key={_status}
                className={`progress-bar bg-${VEHICLE_STATUS_TO_BOOTSTRAP_TYPE[status]}`}
                role="progressbar"
                style={{
                  width: `${(statusCount[status] || 0 / total) * 100}%`,
                }}
              />
            );
          })}
      </div>
    </div>
  );
};

const ButtonBar = ({
  setSelectedStatus,
  selectedStatus,
  setShowDocks,
  showDocks,
}: {
  setSelectedStatus: (status?: any) => void;
  selectedStatus: string[];
  setShowDocks:(showDocks:boolean) => void;
  showDocks:boolean
}) => {
  const vehicles = useAdminVehicles();
  const statusCount = _.countBy(
    vehicles,
    (vehicle) =>{ 
      if (vehicle && vehicle.batteryPercent && vehicle.batteryPercent<50 && vehicle.batteryPercent>30){
        return '30-50%-battery' 
      }else  return vehicle.status 
    }
  ) as StatusCount;
  const buttonStyleDocks = showDocks
  ? `btn-interesting`
  : `btn-outline-interesting`;
  return (
    <div>
      <div style={{ width: "100%", padding: 20 }}>
        <StatusCountBar
          statusCount={statusCount}
          onClick={() => setSelectedStatus(undefined)}
        />
      </div>
      <div
        className="card-body"
        style={{ display: "flex", justifyContent: "space-evenly" }}
      >
        <div className="row" style={{ flex: 1 }}>
          {STATUS_BUTTONS
            .sort()
            .map((status:any) => {
              const isSelected = selectedStatus
                ? checkInclude(selectedStatus,status)
                : true;
              const buttonColor = VEHICLE_STATUS_TO_BOOTSTRAP_TYPE[status];
              const buttonStyle = isSelected
                ? `btn-${buttonColor}`
                : `btn-outline-${buttonColor}`;
              return (
                <div className="col-lg-2 col-md-4 mb-2" key={status}>
                  <button
                    type="button"
                    onClick={() =>{ 
                      if (!(checkInclude(selectedStatus,status))){
                        setSelectedStatus((selectedStatus:any) => [...selectedStatus,status])
                      }else {
                        removeStatus(selectedStatus,setSelectedStatus,status)
                      }
                    }}
                    className={`btn ${buttonStyle}`}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                      {`${statusCount[status] || 0} ${status}`}
                  </button>
                </div>
              );
            })}
        </div>
      </div>
      <div style={{flex:0.10, width:'15%', marginBottom:10, marginLeft:20}}>
        <button
            type="button"
            onClick={() => setShowDocks(!showDocks)}
            className={`btn ${buttonStyleDocks}`}
            style={{
              display: "flex",
              justifyContent: "center",
              height: "100%",
              width: "100%",
            }}
          >Docks</button>
        </div>
    </div>
  );
};

const VehicleMarkers = ({ selectedStatus }: any) => {
  const vehicles = useAdminVehicles();
  const navigate = useNavigate();
  const filteredVehicles = vehicles.filter((vehicle: any) =>
    selectedStatus &&  selectedStatus == '30-50%-battery' && vehicle.status != Schema.Vehicle.Status.OFFLINE? 
         vehicle.batteryPercent && vehicle.batteryPercent<=50 && vehicle.batteryPercent>=30 && 
         vehicle.status == Schema.Vehicle.Status.ACTIVE :
    selectedStatus ? vehicle.status == selectedStatus
       : true
  );

  return (
    <>
      {filteredVehicles.map((vehicle: Schema.Vehicle.Admin) => {
        return (
          <VehicleMarker
            key={vehicle.id}
            coordinate={vehicle}
            status={
              vehicle.status == Schema.Vehicle.Status.ACTIVE && vehicle.isUnlocked == true ? 'available-unlocked':
              vehicle.status == Schema.Vehicle.Status.ACTIVE && vehicle.batteryPercent && vehicle.batteryPercent>30 && vehicle.batteryPercent <51 ? 'batttery-porcentage-30-50': 
              vehicle.status}
            onClick={() => navigate(`/vehicles/${vehicle.id}`)}
          />
        );
      })}
    </>
  );
};

const AdminVehicleMap = ({defaultCenterCity}:any) => {
  const { geofences } = useAdminGeofences();
  const [ showDocks, setShowDocks ] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
 
  return (
    <div className={"row mb-4"}>
      <div className={"col"}>
        <Card.Container>
          <MapComponent center={defaultCenterCity} defaultZoom={13}>
            {selectedStatus.map((statusCurrent)=>{
              return <VehicleMarkers selectedStatus={statusCurrent} />
            })
            }
            {showDocks? <AdminDocks /> : <></>}
            {geofences.map((geofence) => {
              return (
                <Geofence
                  key={geofence.id}
                  geometry={geofence.geometry}
                  type={geofence.type}
                />
              );
            })}
          </MapComponent>
          <ButtonBar
            setSelectedStatus={setSelectedStatus}
            selectedStatus={selectedStatus}
            setShowDocks={setShowDocks}
            showDocks={showDocks}
          />
        </Card.Container>
      </div>
    </div>
  );
};

const AnalyticsRow = ({
  summaryLabel,
  summaryValue,
  data,
}: {
  summaryLabel: string;
  summaryValue: string;
  data: IDataPoint[];
}) => {
  return (
    <div className="row mb-2 border-bottom">
      <div className="col-lg-2 col-12">
        <div className="text-muted">{summaryLabel}</div>
        <h4 className="text-primary">{summaryValue}</h4>
      </div>
      <div className="col-lg-10 col-12" style={{ overflow: "visible" }}>
        <LineGraph
          data={data}
          height={80}
          includeXAxis={false}
          includeYAxis={false}
          includeXTicks={false}
          includeYTicks={false}
        />
      </div>
    </div>
  );
};

const RentalAnalyticsRows = ({
  startAt,
  endAt,
  adminArea,
  adminAreaCity,
  selectedHomeCityId
}: {
  startAt: Date;
  endAt: Date;
  adminArea:boolean;
  adminAreaCity:string;
  selectedHomeCityId:string|undefined;
}) => {
  const { selectedFleetId } = useContext(FleetContext);

  const { data: rentalData } = useSpringFetch<{
    rentals: Schema.Rental.Admin[];
  }>("get", "/admin/rentals", {
    homeCityId: adminArea? adminAreaCity : selectedHomeCityId,
    fleetId:selectedFleetId,
    startAt:startAt,
    endAt:endAt,
  });
  const rentals = rentalData? rentalData.rentals : [];
  const { data: userData } = useSpringFetch<{
    users: Schema.User.Admin[];
  }>("get", "/admin/users",{
    startAt:startAt,
    endAt:endAt,
  });

  const users = userData
    ? filterByDateAndFleet(
        userData.users,
        startAt,
        endAt,
        selectedFleetId,
        selectedHomeCityId
      )
    : [];
  const { dollarsSpent } = getRentalStats(rentals);

  const newUsersByDay = getNewUserCountByDay(users, startAt, endAt);
  const newUserCount = _.sumBy(newUsersByDay, (dataPoint) => dataPoint.y);

  return (
    <>
      <AnalyticsRow
        summaryLabel="Total Rentals"
        summaryValue={`${rentals.length}`}
        data={getRidesByDay(rentals, startAt, endAt)}
      />
      <AnalyticsRow
        summaryLabel="Net Revenue"
        summaryValue={`$${dollarsSpent}`}
        data={getRevenueByDay(rentals, startAt, endAt)}
      />
      <AnalyticsRow
        summaryLabel="New Customers"
        summaryValue={`${newUserCount}`}
        data={getNewUserCountByDay(users, startAt, endAt)}
      />
    </>
  );
};

const RentalAnalyticsCard = () => {
  const defaultStart = moment().subtract(7, "day").toDate();
  const defaultEnd = moment().toDate();
  const { selectedHomeCityId, isLoading:isLoadingHomeCity } = useContext(HomeCityContext);
  const {adminArea,adminAreaCity,isLoading:isLoadingAdminArea }  = FilterAdminAreas();
  if (isLoadingAdminArea || isLoadingHomeCity) return <LoadingSpinner></LoadingSpinner>  
  return (
    <div className="row mb-4">
      <div className="col">
        <Card.Container>
          <Card.Header title="Rental Analytics" />
          <div className="card-body">
            <DatePeriodProvider
              initialStart={defaultStart.toISOString()}
              initialEnd={defaultEnd.toISOString()}
              render={({ start, end }) => {
                return (
                  <RentalAnalyticsRows
                    startAt={start || defaultStart}
                    endAt={end || defaultEnd}
                    selectedHomeCityId={selectedHomeCityId}
                    adminAreaCity={adminAreaCity}
                    adminArea={adminArea}
                  />
                );
              }}
            />
          </div>
        </Card.Container>
      </div>
    </div>
  );
};

export const HomePage = () => {
  
  const {isLoading } = useContext(HomeCityContext);
  const {homeCityAdmin:CityAdminId, isLoading:isLoadingAdminArea,adminArea,adminAreaCity} = FilterAdminAreas();
  const { selectedHomeCityId,setSelectedHomeCityId, getHomeCities } = useContext(HomeCityContext);
  let allHomecities = getHomeCities();
  let defaultCenterCity: any = null;
  if (selectedHomeCityId!= 'All Home City' &&  selectedHomeCityId) {
    allHomecities?.map((city) => {
      if (city.id == selectedHomeCityId) {
        defaultCenterCity = {
          lat: city.latitude,
          lng: city.longitude,
        };
      }
    });
  }else if (adminArea) {
    if (selectedHomeCityId!= 'All Home City') setSelectedHomeCityId(adminAreaCity)
    allHomecities?.map((city) => {
      if (city.id == adminAreaCity) {
        defaultCenterCity = {
          lat: city.latitude,
          lng: city.longitude,
        };
      }
    });
  } else if (CityAdminId) {
    if (selectedHomeCityId!= 'All Home City') setSelectedHomeCityId(CityAdminId)
    allHomecities?.map((city) => {
      if (city.id == CityAdminId) {
        defaultCenterCity = {
          lat: city.latitude,
          lng: city.longitude,
        };
      }
    });
  } 
  if (!defaultCenterCity|| !CityAdminId || isLoading || isLoadingAdminArea) return <LoadingSpinner/>

  return (
    <PageContainer>
      <AdminVehicleMap  defaultCenterCity={defaultCenterCity}/>

   {/* <RentalAnalyticsCard /> */} 
{/* 
      <div className="row mb-4">
        <div className="col">
          <Card.Container>
            <RentalList title={"Recent Rentals"} limit={5}
            adminArea={adminArea} 
            adminAreaCity={adminAreaCity}
           />
          </Card.Container>
        </div>
      </div>*/} 
    </PageContainer>
  );
};
