export enum Colors {
  PRIMARY = '#007bff',
  SECONDARY = '#6c757d',
  SUCCESS = '#28a745',
  DANGER = '#dc3545',
  WARNING = '#ffc107',
  INFO = '#17a2b8',
  LIGHT = '#f8f9fa',
  DARK = '#343a40',
  WHITE = '#fff',
  INTERESTING = '#9b51e0',
}
