import React from 'react';
import QRCode from 'qrcode.react';

interface IProps {
  qrCode: string;
}

export const SpringQRCode = ({ qrCode }: IProps) => {
  return (
    <div
      style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}
    >
      <div className="mr-2">{qrCode}</div>
      <div>
        <QRCode value={qrCode} size={64} />
      </div>
    </div>
  );
};
