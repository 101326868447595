import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { Card } from '../components/Card';
import { Schema } from 'boaz-bikes-types';
import { DisplayData } from '../components/DisplayData';
import { Link } from 'react-router-dom';
import { PageContainer } from '../components/PageContainer';
import { useSpringFetch } from '../firebase';
import { LoadingView } from '../components/LoadingSpinner';
import { HomeCityContext } from '../home-city/HomeCityContext';
import { Marker } from 'react-google-maps';
import { MapComponent } from '../components/MapComponent';
import { Location } from '../components/Location';

export function DropOffDetail() {
    const { dropOffId } = useParams();
    const { getHomeCities } = useContext(HomeCityContext);

    const { data, isLoading } = useSpringFetch<{
        dropOff: Schema.Dropoff.Admin,
        user: Schema.User.Admin,
        driver: Schema.User.Admin
    }>(
        'get',
        `/admin/dropoff/${dropOffId}`,
        {}
    );

    if (isLoading) {
        return <LoadingView />;
    }

    // mapping homeCities
    const allHomeCity = getHomeCities();
    const homeCityMap = new Map(allHomeCity?.map((homecity: any) => [homecity.id, homecity.name]))

    // location of dropOff
    const defaultCenter = { lat: data?.dropOff?.latitude || 0, lng: data?.dropOff?.longitude || 0 };
    const coordinate = {
        latitude: data?.dropOff?.latitude || 0,
        longitude: data?.dropOff?.longitude || 0,
    }

    return (
        <PageContainer>
            <Card.Container>
                <Card.Header
                    title={'DropOffs Details'}
                    actions={
                        <Link to={`/dropoffs/${dropOffId}/edit`}>
                            <button className={'btn btn-sm btn-secondary'} >Edit</button>
                        </Link>
                    }
                />
                <Card.Body>
                    <div className="row">
                        <div className="col">
                            <DisplayData
                                data={[
                                    {
                                        label: 'Order ID',
                                        value: data?.dropOff?.id,
                                    },
                                    {
                                        label: 'Created On',
                                        value: data?.dropOff?.createdAt,
                                    },
                                    {
                                        label: 'User',
                                        value: <Link to={`/users/${data?.user?.id}`}>{data?.user?.phoneNumber}</Link>
                                    },
                                    {
                                        label: 'First Name',
                                        value: data?.user?.name
                                    },
                                    {
                                        label: 'Last Name',
                                        value: data?.user?.name
                                    },
                                    {
                                        label: '# Bikes Requested',
                                        value: data?.dropOff?.bikesRequested
                                    },
                                    {
                                        label: 'Home City',
                                        value: homeCityMap.get(data?.dropOff?.homeCityId)
                                    },
                                    {
                                        label: 'Address',
                                        value: data?.dropOff?.dropOffAddress
                                    },
                                    {
                                        label: 'Location',
                                        value: <Location coordinate={coordinate} />
                                    },
                                    {
                                        label: 'Notes',
                                        value: data?.dropOff?.notes
                                    },
                                    {
                                        label: 'Status',
                                        value: data?.dropOff?.status
                                    },
                                    {
                                        label: 'Bike ID(s) Drop Off',
                                        value: ""
                                    },
                                    {
                                        label: 'Delivery ID',
                                        value: <Link to={`/users/${data?.driver?.id}`}>{data?.driver?.phoneNumber}</Link>
                                    },
                                    {
                                        label: 'Rating',
                                        value: data?.dropOff?.rating
                                    },
                                    {
                                        label: 'Review',
                                        value: data?.dropOff?.review
                                    },
                                ]}
                            />
                        </div>
                        <div className="col">
                            <Card.Container>
                                <Card.Header
                                    title={'Cancelled Note from Customer'}
                                />
                                <Card.Body>
                                    {data?.dropOff?.status === Schema.Dropoff.Status.CANCELLED
                                        &&
                                        data?.dropOff?.notes
                                    }
                                </Card.Body>
                            </Card.Container>
                            <br />
                            <div>
                                <MapComponent defaultZoom={15} defaultCenter={defaultCenter}>
                                    <Marker
                                        position={defaultCenter}
                                        icon={`http://maps.google.com/mapfiles/ms/icons/red-dot.png`}
                                    />
                                </MapComponent>
                            </div>
                        </div>
                    </div>

                </Card.Body>
            </Card.Container>
        </PageContainer>
    );
};