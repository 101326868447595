import { Schema, SpringClient, usersToOptions } from 'boaz-bikes-types';
import React, { useContext, useState } from 'react';
import { UserContext } from '../auth/UserContext';
import { DateInfo } from '../components/DateInfo';
import { Select } from '../components/Select';
import { TabRow } from '../components/Tab';
import { Table } from '../components/Table';
import { useSpringFetch } from '../firebase';
import { VehicleSummary } from '../rentals/RentalList';
import { useNavigate } from '../Router';
import { FleetContext } from '../vehicles/FleetContext';
import { Card } from '../components/Card';
import { PageContainer } from '../components/PageContainer';
import { SpringButton } from '../components/SpringButton';
import { Link } from 'react-router-dom';
import { HomeCityContext } from '../home-city/HomeCityContext';
import { FilterAdminAreas } from '../utils/FilterAdminAreas';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { LoadingSpinner } from '../components/LoadingSpinner';

enum Tabs {
  OPEN = 'Open',
  UNASSIGNED = 'Unassigned',
  COMPLETE = 'Complete',
}

type TaskFilter = (task: Schema.Task.Admin) => boolean;

const TAB_TO_FILTER: Partial<Record<Tabs, TaskFilter>> = {
  [Tabs.OPEN]: (task) => !task.completedAt,
  [Tabs.UNASSIGNED]: (task) => task.ownerId == null && !task.completedAt,
  [Tabs.COMPLETE]: (task) => task.completedAt != null,
};

const CompleteButton = ({
  id,
  completedAt,
  refreshData,
}: {
  id: string;
  completedAt: string | null;
  refreshData: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const updateStatusAsync = async () => {
    setIsLoading(true);
    await SpringClient.post(`/admin/tasks/${id}`, {
      completedAt: completedAt ? null : new Date().toISOString(),
    });
    await refreshData();
    setIsLoading(false);
  };

  return (
    <SpringButton
      isLoading={isLoading}
      type="button"
      className={`btn btn-${completedAt ? 'secondary' : 'success'}`}
      onClick={updateStatusAsync}
    >
      {`${completedAt ? 'reopen' : 'complete'}`}
    </SpringButton>
  );
};

const AssignedSelect = ({
  signedInUserId,
  options,
  assignedId,
  taskId,
  refreshData,
}: {
  signedInUserId: string;
  options: Schema.User.Admin[];
  assignedId: string | null;
  taskId: string;
  refreshData: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const updateAssignedAsync = async (userId: string) => {
    setIsLoading(true);
    await SpringClient.post(`/admin/tasks/${taskId}`, {
      ownerId: userId || null,
    });
    refreshData();
    setIsLoading(false);
  };
  const adminUserOptions = usersToOptions(signedInUserId, options);
  return (
    <Select
      isLoading={isLoading}
      value={assignedId || ''}
      options={adminUserOptions}
      onChange={(value) => updateAssignedAsync(value)}
    />
  );
};

const TaskList = ({ 
  filter,
  adminArea,
  adminAreaCity,
  pageSize,
 }: { 
   filter?: TaskFilter,
   adminArea:boolean,
   adminAreaCity:string,
   pageSize:number,
  
  }) => {
  const { user } = useContext(UserContext);
  const { selectedHomeCityId, getHomeCities } = useContext(HomeCityContext);
  const navigate = useNavigate();
  const { selectedFleetId } = useContext(FleetContext);
  const [currentPage, setCurrentPage ] = useState(1)
  const [rowsState, setRowsState] = useState<any>([]);
  const [ exportAll, setExportAll ] = useState(false);
  const [ vehiclesState , setVehiclesState] = useState<Schema.Vehicle.Admin[]>([]);
  const [ usersState , setUsersState] = useState<Schema.User.Admin[]>([]);
  const [ textSearch, setTextSearch ] = useState('');
  const { data, isLoading, refreshData:refreshDataInital } = useSpringFetch<{
    tasks: Schema.Task.Admin[];
    vehicles: Schema.Vehicle.Admin[];
    users:Schema.User.Admin[];
    taskLength:number;
  }>('get', '/admin/tasks', {
    homeCityId: adminArea? adminAreaCity : selectedHomeCityId,
    fleetId: selectedFleetId,
    pageSize:!exportAll? pageSize : undefined,
    page:!exportAll? currentPage-1 : undefined,
    expand: ['user','vehicle']
  });
  const { data:dataSearch, isLoading:isLoadingSearch, refreshData:refreshDataSearch } = useSpringFetch<{
    tasks: Schema.Task.Admin[];
    vehicles: Schema.Vehicle.Admin[];
    users:Schema.User.Admin[];
    taskLength:number;
  }>('get', '/admin/tasks-search', {
    homeCityId: adminArea? adminAreaCity : selectedHomeCityId,
    textSearch,
    expand: ['user','vehicle']
  });
  const refreshData =()=>{
    refreshDataSearch()
    refreshDataInital()
  }
  // const { data: userData } = useSpringFetch<{
  //   users: Schema.User.Admin[];
  // }>('get', '/admin/users', { isAdmin: true });

  if (!isLoading && data == null) {
    return <div>No Tasks Available</div>;
  }
  const setCurrentPageAndData = (page:number) =>{
    setVehiclesState(vehicles);
    setUsersState(users);
    setCurrentPage(page);
  }
  const exportAllFunction = () =>{
    setExportAll(true)
  }

  let vehicles =  data ? vehiclesState.concat(data.vehicles) : [];
  let users =   data? usersState.concat(data.users) : [];
  const tasksLength = data?.taskLength;
  const tasks = textSearch!='' && dataSearch?.tasks?  dataSearch.tasks : data? data?.tasks : [];
  const vehicleIdMap = new Map(vehicles.map((vehicle) => [vehicle.id, vehicle]));
  // mapping homeCities
  const allHomeCity = getHomeCities();
  const homeCityMap = new Map(allHomeCity?.map((homecity: any) => [homecity.id, homecity.name]))

  const filteredTasks = filter ? tasks.filter(filter) : tasks;
  const exportTasks = tasks.map( (task) => {     
    let homeCityCurrent = allHomeCity?.find((homecity: any) => homecity.id === task.homeCityId);
    let currentAssigned = data?.users.find((user:Schema.User.Admin)=>user.id == task.ownerId);
    let currentVehicle = vehicles.find((vehicle:Schema.Vehicle.Admin)=> vehicle.id == task.vehicleId )
    return {
      'Home City':homeCityCurrent?.name,
      'Assigned':currentAssigned?.name,      
      'Type': task.type,
      'Notes': task.notes,
      'Vehicle Qr':currentVehicle?.qrCode,
      'Creation Reason':task.creationReason,
      'Created At': moment.utc(task.createdAt).fromNow(),
      'Completed At':moment.utc(task.completedAt).fromNow(),
    };
  }
);

  return (
    <div className="row">
      <div className="col">
        <Card.Container>
          <Table
            title={`Tasks`}
            isLoading={isLoading ||isLoadingSearch}
            rows={filteredTasks}
            onAddItem={() => navigate('/tasks/create')}
            // getRowUrl={(task) => `/tasks/${task.id}/edit`}
            columnRenderInfos={[
              {
                columnName: '',
                attribute: 'completedAt',
                renderFunc: ({ id, completedAt }) => (
                  <CompleteButton id={id} completedAt={completedAt} refreshData={refreshData} />
                ),
              },
              {
                columnName: 'Assigned',
                attribute: 'ownerId',
                renderFunc: ({ id, ownerId }) => (
                  <AssignedSelect
                    taskId={id}
                    signedInUserId={user.uid}
                    assignedId={ownerId}
                    options={(data || { users: [] }).users}
                    refreshData={refreshData}
                  />
                ),
              },
              { columnName: 'Type', attribute: 'type' },
              { columnName: 'Notes', attribute: 'notes' },
              {
                columnName: 'Vehicle QR',
                attribute: 'vehicleId',
                renderFunc: ({ vehicleId }) => {
                  if (vehicleId) {
                    return <VehicleSummary vehicle={vehicleIdMap.get(vehicleId)!} />;
                  }
                  return null;
                },
              },
              {
                columnName: 'Home City',
                attribute: 'vehicleId',
                renderFunc: ({ vehicleId }) => {
                  if (vehicleId) {
                    const vehicleCurrent = vehicleIdMap.get(vehicleId);
                    const homeCityName = homeCityMap.get(vehicleCurrent?.homeCityId);
                    return homeCityName || "";
                  }
                  return null;
                }
              },
              { columnName: 'Creation Reason', attribute: 'creationReason' },
              {
                columnName: 'Created At',
                attribute: 'createdAt',
                renderFunc: ({ createdAt }) => <DateInfo date={createdAt} />,
              },
              {
                columnName: 'Edit',
                renderFunc: ({ id }) => (
                  <Link to={`/tasks/${id}/edit`}>Edit</Link>
                ),
              },
            ]}
            currentPage={currentPage}
            setCurrentPage={setCurrentPageAndData}
            dataLength={tasksLength}
            rowsState={rowsState}
            setRowsState={setRowsState}
            searchInDb={(text)=>{
              setTextSearch(text)
            }
          }
          />
          <div className="row mb-4">
            <div className="col">
              <CSVLink
                style={{marginLeft:20}}
                data={exportTasks}
                filename={"tasks.csv"}
                className="btn btn-primary"
                target="_blank"
              >
                Export
              </CSVLink>
            </div>
          </div>
          <button
            type="button"
            onClick={exportAllFunction}
            className="btn btn-primary"
            style={{marginBottom:20, marginLeft:20, display: 'flex', flexDirection: 'row', width:"15%"}}
            >Load All Tasks
          </button>
        </Card.Container>
      </div>
    </div>
  );
};

export function TasksHome() {
  const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.OPEN);
  const {adminArea,adminAreaCity,isLoading:isLoadingAdminArea }  = FilterAdminAreas();
  const {isLoading:isLoadingHomeCities } = useContext(HomeCityContext);

  if (isLoadingAdminArea || isLoadingHomeCities) return <LoadingSpinner></LoadingSpinner>  
  return (
    <PageContainer>
      <TabRow
        tabs={Object.values(Tabs).map((tab) => ({
          title: tab,
          onClick: () => setSelectedTab(tab),
          isSelected: selectedTab === tab,
        }))}
      />
      <TaskList filter={TAB_TO_FILTER[selectedTab]}  adminArea={adminArea} adminAreaCity={adminAreaCity} pageSize={100}/>
    </PageContainer>
  );
}
