import React from 'react';
import { useSpringFetch } from '../firebase';
import { Schema, SpringClient } from 'boaz-bikes-types';

import { showMessage } from '../ToastProvider';
import { CREATE_PROMO_CODE_FIELDS } from './CreatePromoCode';
import { LoadingView } from '../components/LoadingSpinner';
import { SimpleForm } from 'boaz-bikes-forms';
import { useNavigate } from '../Router';
import { PageContainer } from '../components/PageContainer';

const UpdatePromoCodeForm = ({ promoCode }: { promoCode: Schema.PromoCode.Admin }) => {
  const navigate = useNavigate();

  const submitFormAsync = async (formState: any) => {
    try {
      const { message } = await SpringClient.post(`/admin/promos/${promoCode.id}`, formState);
      navigate(`/promos/${promoCode.id}`);
      showMessage(message);
    } catch (e) {
      console.error('Error creating promoCode', e);
    }
  };

  return (
    <SimpleForm
      title={'Update Promo Code'}
      formFields={CREATE_PROMO_CODE_FIELDS}
      initialState={promoCode}
      onSubmit={submitFormAsync}
    />
  );
};

export const UpdatePromoCode = ({ match }: { match: any }) => {
  const { promoCodeId } = match.params;

  const { data, isLoading } = useSpringFetch<{ promoCode: Schema.PromoCode.Admin }>(
    'get',
    `/admin/promos/${promoCodeId}`
  );

  if (isLoading) {
    return <LoadingView />;
  }

  if (data == null) {
    return <div>Promo Code not found</div>;
  }

  const { promoCode } = data;

  return (
    <PageContainer>
      <div className="row">
        <div className="col-md-6 col-12 mx-auto">
          <UpdatePromoCodeForm promoCode={promoCode} />
        </div>
      </div>
    </PageContainer>
  );
};
