import * as firebase from "firebase/app";
import { COMPANY, ISpringClientCallOptions, Message } from "boaz-bikes-types";
import { COMPANY_ID } from "./utils/ApiClient";
import { useServer, useServerFetch } from "boaz-bikes-client-utils";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/storage";

const COMPANY_ID_TO_FIREBASE_CONFIG: Partial<Record<COMPANY, any>> = {
  [COMPANY.SPRING]: {
    apiKey: "AIzaSyAEzGSpvi-NbzAuit9n7ifPN04bETSB8_U",
    authDomain: "spring-prod.firebaseapp.com",
    databaseURL: "https://spring-prod.firebaseio.com",
    projectId: "spring-prod",
    storageBucket: "spring-prod.appspot.com",
    messagingSenderId: "439540966195",
    appId: "1:439540966195:web:fb6b0fa85e6f8e01",
  },
  [COMPANY.BOAZ_BIKES]: {
    apiKey: "AIzaSyB3zdM4BvYBCMScb6IUZfR0vlIuFjjEGKM",
    authDomain: "root-monolith-282303.firebaseapp.com",
    databaseURL: "https://root-monolith-282303.firebaseio.com",
    projectId: "root-monolith-282303",
    storageBucket: "root-monolith-282303.appspot.com",
    messagingSenderId: "995704404036",
    appId: "1:995704404036:web:8dbb77f67efb87e26d6411",
  },

  // ADD_WEB_APP_CONFIG  (used by script to add value above this line)
};

//console.log("========", COMPANY_ID);
//console.log(COMPANY_ID_TO_FIREBASE_CONFIG[COMPANY_ID]);
const firebaseConfig =
  COMPANY_ID_TO_FIREBASE_CONFIG[COMPANY_ID] ||
  COMPANY_ID_TO_FIREBASE_CONFIG[COMPANY.SPRING];

firebase.initializeApp(firebaseConfig);

export function useSpring<
  Req extends object,
  Resp extends {
    message?: Message.IMessage;
    [key: string]: any;
  }
>(
  method: "get" | "post",
  path: string,
  onSuccess?: (responseData: Resp) => void,
  onFailure?: () => void,
  options?: ISpringClientCallOptions
): { isLoading: boolean; callFunction: (req: Req) => void } {
  return useServer<Req, Resp>(method, path, onSuccess, onFailure, options);
}

export function useSpringFetch<T extends {}>(
  method: "get" | "post",
  path: string,
  functionArguments?: object,
  options?: any
): { data?: T; isLoading: boolean; refreshData: () => void } {
  return useServerFetch<T>(method, path, functionArguments, options);
}

export { firebase };
