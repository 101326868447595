import React, { createContext, useContext } from 'react';
import { Schema } from 'boaz-bikes-types';
import { useSpringFetch } from '../firebase';
import { getPingsBeforeDate, TimeAnimator } from './TimeAnimator';
import { VehicleMarker } from '../components/VehicleMarker';

const VehicleAnimatorContext = createContext<{
  vehiclePings: Schema.VehiclePing.Admin[];
}>({
  vehiclePings: [],
});

const MapMarker = () => {
  const { snapshotDate } = useContext(TimeAnimator.Context);
  const { vehiclePings } = useContext(VehicleAnimatorContext);
  const [activePing] = getPingsBeforeDate(snapshotDate, vehiclePings, 'recordedAt', 1);

  if (!activePing) {
    return null;
  }

  return (
    <VehicleMarker
      coordinate={{ latitude: activePing.latitude, longitude: activePing.longitude }}
      status={activePing.vehicleStatus}
    />
  );
};

const Provider = ({
  startAt,
  endAt,
  vehicleId,
  children,
}: {
  startAt: Date;
  endAt: Date;
  vehicleId: string;
  children: React.ReactNode;
}) => {
  const { data: vehiclePingsData } = useSpringFetch<{
    vehiclePings: Schema.VehiclePing.Admin[];
  }>('get', `/admin/vehicle-pings/${vehicleId}`, {
    startAt,
    endAt,
  });

  const vehiclePings = vehiclePingsData ? vehiclePingsData.vehiclePings.slice() : [];

  return (
    <TimeAnimator.Provider startAt={startAt} endAt={endAt}>
      <VehicleAnimatorContext.Provider value={{ vehiclePings }}>
        {children}
      </VehicleAnimatorContext.Provider>
    </TimeAnimator.Provider>
  );
};

const ControlBar = () => {
  return <TimeAnimator.ControlBar />;
};

export const VehicleAnimator = {
  Provider,
  MapMarker,
  ControlBar,
};
