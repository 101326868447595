import React from 'react';
import { firebase } from '../firebase';

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

const uiConfig = {
  signInFlow: 'popup',
  callbacks: {
    signInSuccessWithAuthResult: (authResult: any, redirectUrl?: string) => {
      return false;
    },
  },
  signInOptions: [firebase.auth.PhoneAuthProvider.PROVIDER_ID],
};

export const SignInForm = () => {
  return (
    <div>
      <p>Please sign-in:</p>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
    </div>
  );
};
