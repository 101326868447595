import { Schema, SpringClient } from 'boaz-bikes-types';
import React, { useContext, useEffect, useState } from 'react';
import { DateInfo } from '../components/DateInfo';
import { TabRow } from '../components/Tab';
import { Table } from '../components/Table';
import { firebase, useSpringFetch } from '../firebase';

import { Card } from '../components/Card';
import { PageContainer } from '../components/PageContainer';
import { HomeCityContext } from '../home-city/HomeCityContext';
import moment from 'moment';
import { FilterAdminAreas, FilterAdminAreaUsers } from '../utils/FilterAdminAreas';
import { useNavigate } from '../Router';
import { LoadingSpinner, LoadingView } from '../components/LoadingSpinner';
import { Select } from '../components/Select';
import { FirebaseStorageImage } from '../support/SupportHome';

enum UserTab {
  ALL = 'Verification',
  APPROVED = 'Approved',
  NOT_APPROVED = 'Not Approved',
}

const TAB_TO_FILTER: Partial<Record<UserTab, any>> = {
  [UserTab.ALL]: (user:any) => user.verification == 'pending',
  [UserTab.APPROVED]: (user:any) => user.verification == 'approved',
  [UserTab.NOT_APPROVED]: (user:any) => user.verification == 'not-approved',
};


export const VerificationDropdown = ({
    valueDb,
    refreshData,
    userId
  }: {
    valueDb: string | null;
    refreshData: () => void;
    userId:string
  }) => {
    const [isLoading, setIsLoading] = useState(false);
    const updateStatusAsync = async (value: string,userId:string) => {
      if (window.confirm("Do you want to change this person's verification status?")) {
        setIsLoading(true);
        console.log(value)
        await SpringClient.post(`/admin/profile-validation-admin`, { verification:value,userId:userId });
        await refreshData();
        setIsLoading(false);
      }
    };
    return (
      <Select
        isLoading={isLoading}
        value={`${valueDb}`}
        options={[
          { label: 'pending', value: Schema.User.verificationIdStatus.PENDING },
          { label: 'approved', value: Schema.User.verificationIdStatus.APPROVED},
          { label: 'incorrect document uploaded', value: Schema.User.verificationIdStatus.SEND_AGAIN1 },
          { label: 'Images not clear enough to make a decision', value: Schema.User.verificationIdStatus.SEND_AGAIN2 },
          { label: 'not-approved', value: Schema.User.verificationIdStatus.NOT_APPROVED },
        ]}
        onChange={(value) => updateStatusAsync(value,userId)}
      />
    );
  };

  export const FirebaseStorageImageUser = ({
    firebaseUrlDocument,
    firebaseUrlProfile,
    className,
    style,
  }: {
    firebaseUrlDocument: string | null;
    firebaseUrlProfile: string | null;
    className?: string;
    style?: any;
  }) => {
    const [downloadUrlProfile, setDownloadUrlProfile] = useState<string | null>(null);
    const [downloadUrlDocument, setDownloadUrlDocument] = useState<string | null>(null);
    const [isDownloading, setIsDownloading] = useState(false);
    useEffect(() => {
      const getDownloadUrlAsync = async () => {
        setIsDownloading(true);
        if (!firebaseUrlDocument || !firebaseUrlProfile) {
          setIsDownloading(false);
          return;
        }
        const storageRefDocument = firebase.storage().ref(firebaseUrlDocument);
        const storageRefProfile = firebase.storage().ref(firebaseUrlProfile);

        const _downloadUrlDocument = await storageRefDocument.getDownloadURL();
        const _downloadUrlProfile = await storageRefProfile.getDownloadURL();
        setDownloadUrlDocument(_downloadUrlDocument);
        setDownloadUrlProfile(_downloadUrlProfile);
        setIsDownloading(false);
      };
  
      getDownloadUrlAsync();
    }, [firebaseUrlDocument,firebaseUrlProfile]);
  
    if (isDownloading) {
      return <LoadingView />;
    }
  
    if (downloadUrlDocument == null || downloadUrlProfile == null) {
      return <div>No photo</div>;
    }
  
    return <>
          <img alt="Alt Tag" src={downloadUrlDocument} className={className} style={style} />
          <img alt="Alt Tag" src={downloadUrlProfile} className={className} style={style} />
          </> 
  };

    

export const UserList = ({
  title,
  filter,
  limit,
  pageSize,
  adminArea,
  adminAreaCity
}: {
  title?: string;
  filter?: any;
  limit?: number;
  pageSize:number;
  adminArea:boolean;
  adminAreaCity:string;
}) => {
  const {selectedHomeCityId, getHomeCities} = useContext(HomeCityContext);
  const [currentPage, setCurrentPage ] = useState(1);
  const [ textSearch, setTextSearch ] = useState('');
  const [rowsState, setRowsState] = useState<any>([]);
  const navigate = useNavigate();
  const [ exportAll, setExportAll ] = useState(false);
  const { data, isLoading, refreshData:refershInitialData } = useSpringFetch<{
    users: Schema.User.Admin[];
    usersLength:number;
  }>('get', '/admin/users', {
    homeCityId: adminArea? adminAreaCity : selectedHomeCityId,
    limit,
    pageSize:!exportAll?  pageSize: undefined,
    page:!exportAll?  currentPage-1: undefined,
  });
  
  const users =  data?.users || [];
  const usersLength = data?.usersLength || 0;
  if (!isLoading && data == null) {
    return <div className="alert alert-warning">Users not found</div>;
  }

  const filteredUsers = filter ? users.filter(filter) : users;
  let allHomeCity = getHomeCities();    
  const homeCityMap = new Map(allHomeCity?.map((homecity: any) => [homecity.id, homecity.name]))
  const exportUsers = filteredUsers.length >0 ? filteredUsers.map( (user) => {      
      let homeCityCurrent = allHomeCity?.find((homecity: any) => homecity.id === user.homeCityId);
      return {        
        'Phone Number': user.phoneNumber,
        'Name': user.name,
        'Email': user.email,
        'Signed Up': moment.utc(user.createdAt).fromNow(),
        'Home City': homeCityCurrent?.name,
        'Admin': user.isAdmin ? 'true' : 'false',
        'AreaAdmin': user.areaAdmin,  
        'Delivery': user.isDelivery ? 'true' : 'false',
        'Blocked': user.isBlocked ? 'true' : 'false',
        'Referral': user.referral,
      };
    }
  )  : [] ;
  const exportAllFunction = () =>{
    setExportAll(true)
  }

  return (
    <>
    <Table
      title={title}
      isLoading={isLoading}
      autoFocusSearch={true}
      rowsPerPage={100}
      columnRenderInfos={[
        {
          columnName: 'Phone Number',
          attribute: 'phoneNumber',
        },
        {
          columnName: 'Name',
          attribute: 'name',
        },
        {
          columnName: 'Email',
          attribute: 'email',
        },
        {
          columnName: 'Image',
          attribute: 'verification',
          renderFunc: ({ id }) => (
            <FirebaseStorageImageUser
              style={{ maxHeight: 200, maxWidth: 100 }}
              firebaseUrlDocument={'companies/boaz-bikes/verification/'+id+'/document.jpg'}
              firebaseUrlProfile={'companies/boaz-bikes/verification/'+id+'/profile.jpg'}
              className="zoom"
            />
          ),
        },
        {
        columnName: 'Verification state',
        attribute: 'verification',
        renderFunc: ({ verification,id }) => <VerificationDropdown userId={id} valueDb={verification} refreshData={refershInitialData} />,
          },
        {
          columnName: 'Signed Up',
          attribute: 'createdAt',
          renderFunc: ({ createdAt }) => <DateInfo date={createdAt} />,
        },
        {
          columnName: 'Home City',
          attribute: 'homeCityId',
          renderFunc: ({ homeCityId }) => {
            let homeName = homeCityMap.get(homeCityId);
            return homeName || "";
          }
        },
      ]}
      rows={filteredUsers}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      rowsState={rowsState}
      setRowsState={setRowsState}
      dataLength={usersLength}
      searchInDb={(text)=>{
          setTextSearch(text)
        }
      }
    />

    {/* <div className="row mb-4">
      <div className="col">
        <CSVLink
          style={{marginLeft:20}}
          data={exportUsers}
          filename={"users.csv"}
          className="btn btn-primary"
          target="_blank"
        >
          Export
        </CSVLink>
      </div>
    </div>
    <button
        type="button"
        onClick={exportAllFunction}
        className="btn btn-primary"
        style={{marginBottom:20, marginLeft:20, display: 'flex', flexDirection: 'row', width:"15%"}}
        >Load All Users
      </button> */}
      
    </>
  );
};

export const UsersVerificationHome = () => {
  const [selectedTab, setSelectedTab] = useState<UserTab>(UserTab.ALL);
  const {adminArea,adminAreaCity,isLoading:isLoadingAdminArea }  = FilterAdminAreas();
  const {isLoading:isLoadingHomeCities } = useContext(HomeCityContext);

  if (isLoadingAdminArea || isLoadingHomeCities) return <LoadingSpinner></LoadingSpinner>  

  return (
    <PageContainer>
      <TabRow
        tabs={Object.values(UserTab).map((tab) => ({
          title: tab,
          onClick: () => setSelectedTab(tab),
          isSelected: selectedTab === tab,
        }))}
      />
      <div className="row mb-4">
        <div className="col">
          <Card.Container>
            <UserList adminArea={adminArea} adminAreaCity={adminAreaCity} pageSize={100} title={''} filter={TAB_TO_FILTER[selectedTab]} />
          </Card.Container>
        </div>
      </div>
    </PageContainer>
  );
};
