import React, { useState, useContext } from 'react';
import { IFormField, INPUT_TYPE } from 'boaz-bikes-forms';
import { SpringClient, Schema, usersToOptions } from 'boaz-bikes-types';
import { useNavigate } from '../Router';
import { SimpleForm } from 'boaz-bikes-forms';
import { LoadingView } from '../components/LoadingSpinner';
import { useSpringFetch } from '../firebase';
import { UserContext } from '../auth/UserContext';
import { PageContainer } from '../components/PageContainer';

export const TaskForm = ({ existingTask }: { existingTask?: Schema.Task.Admin }) => {
  const navigate = useNavigate();
  const [isCreating, setIsCreating] = useState(false);
  const { user } = useContext(UserContext);
  const { data: userData, isLoading: isLoadingUsers } = useSpringFetch<{
    users: Schema.User.Admin[];
  }>('get', '/admin/users', { isAdmin: true });
  const adminUserOptions = usersToOptions(user.uid, (userData || { users: [] }).users);

  const CREATE_TASK_FIELDS: IFormField[] = [
    {
      label: 'Type',
      name: 'type',
      inputType: INPUT_TYPE.SELECT,
      options: Object.values(Schema.Task.Type),
    },
    {
      label: 'Notes',
      name: 'notes',
      inputType: INPUT_TYPE.TEXTAREA,
    },
    {
      label: 'Assigned',
      name: 'ownerId',
      inputType: INPUT_TYPE.SELECT,
      options: adminUserOptions,
    },
    {
      label: 'Vehicle Qr Code',
      name: 'vehicleQRCode',
      inputType: INPUT_TYPE.TEXT,
    },
  ];

  const submitFormAsync = async (formState: any) => {
    setIsCreating(true);

    const endpoint = existingTask ? `/admin/tasks/${existingTask.id}` : '/admin/tasks';

    const submissionData = {
      ...formState,
      vehicleId: formState.vehicleId || null,
      ownerId: formState.ownerId || null,
      completedAt: existingTask ? existingTask.completedAt : null,
    };
    try {
      await SpringClient.post(endpoint, submissionData);
      navigate(`/tasks`);
    } catch (e) {
      console.error('Error creating task', e);
      setIsCreating(false);
    }
  };

  if (isCreating || isLoadingUsers) {
    return <LoadingView />;
  }

  return (
    <PageContainer>
      <div className="row">
        <div className="col-md-6 col-12 mx-auto">
          <SimpleForm
            title={`${existingTask ? 'Update' : 'Create'} Task`}
            initialState={existingTask}
            formFields={CREATE_TASK_FIELDS}
            onSubmit={submitFormAsync}
          />
        </div>
      </div>
    </PageContainer>
  );
};
