import { Schema } from 'boaz-bikes-types';
import _ from 'lodash';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { DateInfo } from '../components/DateInfo';
import { MoreDropdown } from '../components/MoreDropdown';
import { TabRow } from '../components/Tab';
import { Table } from '../components/Table';
import { useSpringFetch } from '../firebase';
import { UserSummary, VehicleSummary } from '../rentals/RentalList';
import { useNavigate } from '../Router';
import { SupportComment } from './SupportComment';
import { Card } from '../components/Card';
import { LoadingView } from '../components/LoadingSpinner';
import { HomeCityContext } from '../home-city/HomeCityContext';

interface IProps {
  userId?: string;
  vehicleId?: string;
  rentalId?: string;
  limit?: number;
}

enum Tab {
  ALL = 'All',
  COMMENTS = 'Comments',
  ALERTS = 'Alerts',
}

type EventFilter = (event: Schema.Event.Admin) => boolean;

const TAB_TO_FILTER: Partial<Record<Tab, EventFilter>> = {
  [Tab.COMMENTS]: (event) => event.type === Schema.Event.Type.SUPPORT_COMMENT,
  [Tab.ALERTS]: (event) => event.type === Schema.Event.Type.VEHICLE_ALERT,
};

export function EventsCard({ userId, vehicleId, rentalId, limit }: IProps) {
  const navigate = useNavigate();
  const { data, isLoading, refreshData } = useSpringFetch<{
    events: Schema.Event.Admin[];
    vehicles: Schema.Vehicle.Admin[];
    users: Schema.User.Admin[];
  }>('get', '/admin/events', { userId, vehicleId, rentalId, limit, expand: ['vehicle', 'user'] });
  const [selectedTab, setSelectedTab] = useState<Tab>(Tab.ALL);
  const { getHomeCities } = useContext(HomeCityContext);

  if (isLoading && data == null) {
    return <LoadingView />;
  }

  if (data == null) {
    return <div className="alert alert-warning">Events not found</div>;
  }

  const events = data?.events || [];
  const vehicles = data?.vehicles || [];
  const users = data?.users || [];
  const vehiceIdMap = new Map(vehicles.map((vehicle) => [vehicle.id, vehicle]));
  const userIdMap = new Map(users.map((user) => [user.id, user]));

  const filter = TAB_TO_FILTER[selectedTab];
  const filteredEvents = filter ? events.filter(filter) : events;
  return (
    <Card.Container>
      <Card.Header title="Events" />
      <TabRow
        tabs={Object.values(Tab).map((tab) => ({
          title: tab,
          onClick: () => setSelectedTab(tab),
          isSelected: selectedTab === tab,
        }))}
        includeShadow={false}
        includeBorder={false}
      />
      <Table<Schema.Event.Admin>
        columnRenderInfos={[
          {
            columnName: 'CreatedAt',
            attribute: 'createdAt',
            renderFunc: ({ createdAt }) => <DateInfo date={createdAt} />,
          },
          {
            columnName: 'Type',
            attribute: 'type',
          },
          {
            columnName: 'Message',
            attribute: 'message',
          },
          {
            columnName: 'Raw Payload',
            attribute: 'payload',
            shouldJsonifyValue: true,
          },
          {
            columnName: 'Vehicle',
            attribute: 'vehicleId',
            renderFunc: ({ vehicleId }) => {
              if (!vehicleId) {
                return null;
              }
              return <VehicleSummary vehicle={vehiceIdMap.get(vehicleId)!} />;
            },
          },
          {
            columnName: 'Home City',
            attribute: 'homeCityId',
            renderFunc: ({ homeCityId }) => {
              let allHomeCity = getHomeCities();                  
              let homeCityCurrent = allHomeCity?.find((homecity: any) => homecity.id === homeCityId);
              return homeCityCurrent?.name || "";
            }
          },
          {
            columnName: 'User',
            attribute: 'userId',
            renderFunc: ({ userId }) => {
              if (!userId) {
                return null;
              }
              return <UserSummary user={userIdMap.get(userId)!} />;
            },
          },
          {
            columnName: 'Rental',
            attribute: 'rentalId',
            renderFunc: ({ rentalId }) =>
              rentalId ? (
                <Link to={`/rentals/${rentalId}`}>{_.truncate(rentalId, { length: 10 })}</Link>
              ) : null,
          },
          {
            columnName: 'Author',
            attribute: 'authorId',
            renderFunc: ({ authorId }) =>
              authorId ? (
                <Link to={`/users/${authorId}`}>{_.truncate(authorId, { length: 10 })}</Link>
              ) : null,
          },
          {
            columnName: '',
            attribute: 'userId',
            renderFunc: ({ userId, rentalId, vehicleId }) => {
              const options = [];
              if (userId) {
                options.push({ label: 'View User', onClick: () => navigate(`/users/${userId}`) });
              }
              if (rentalId) {
                options.push({
                  label: 'View Rental',
                  onClick: () => navigate(`/rentals/${rentalId}`),
                });
              }
              if (vehicleId) {
                options.push({
                  label: 'View Vehicle',
                  onClick: () => navigate(`/vehicles/${vehicleId}`),
                });
              }
              return <MoreDropdown options={options} />;
            },
          },
        ]}
        rows={filteredEvents}
      />
      <div className="card-body border-top">
        <SupportComment
          vehicleId={vehicleId}
          rentalId={rentalId}
          userId={userId}
          onCommentAdded={refreshData}
        />
      </div>
    </Card.Container>
  );
}
