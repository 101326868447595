import React, { useEffect, useState } from 'react';
import { FormField, IFormField, INPUT_TYPE, useForm } from 'boaz-bikes-forms';
import { Spacer } from './VehicleInfo';
import { showMessage } from '../ToastProvider';
import { Message, SpringClient } from 'boaz-bikes-types';
import ReactJson from 'react-json-view';
import { SpringButton } from '../components/SpringButton';

enum MESSAGE_TYPE {
  SIGN_IN = 'Q0',
  HEARTBEAT = 'H0',
  BEGIN_LOCK_OR_UNLOCK = 'R0',
  UNLOCK = 'L0',
  LOCK = 'L1',
  GET_IOT_SETTINGS_INSTRUCTION = 'S5',
  S6 = 'S6',
  S7 = 'S7',
  S4 = 'S4',
  REPORT_ALARM = 'W0',
  V0 = 'V0',
  REPORT_POSITION = 'D0',
  D1 = 'D1',
  GET_FIRMWARE_VERSION = 'G0',
  REPORT_ERROR_FROM_SCOOTER = 'E0',
  U0 = 'U0',
  U1 = 'U1',
  U2 = 'U2',
  K0 = 'K0',
  S1 = 'S1',
}

export const SEND_COMMAND_FIELDS: IFormField[] = [
  {
    label: 'IOT Device ID',
    name: 'iotDeviceId',
    inputType: INPUT_TYPE.TEXT,
  },
  {
    label: 'Message Type',
    name: 'messageType',
    inputType: INPUT_TYPE.SELECT,
    options: Object.values(MESSAGE_TYPE),
  },
  {
    label: 'Message Payload (Separate by Commas)',
    name: 'messagePayload',
    inputType: INPUT_TYPE.TEXT,
  },
  {
    label: 'Wait for Response',
    name: 'waitForResponse',
    inputType: INPUT_TYPE.BOOLEAN,
  },
];

export const SendOmniMessage = ({
  vehicleId,
  iotDeviceId,
}: {
  vehicleId: string | null;
  iotDeviceId?: string | null;
}) => {
  const { formState, updateForm } = useForm(SEND_COMMAND_FIELDS);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    updateForm({ name: 'iotDeviceId', newValue: iotDeviceId });
  }, [iotDeviceId, updateForm]);

  const [result, setResult] = useState<object | null>(null);

  const submitFormAsync = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const data = {
        iotDeviceId: formState.iotDeviceId,
        messageType: formState.messageType,
        messagePayload: formState.messagePayload ? formState.messagePayload.split(',') : [],
        waitForResponse: formState.waitForResponse,
      };
      const dataToSend = {
        vehicleId,
        data,
      };

      const response = await SpringClient.post(`/admin/iot/${vehicleId}/command`, dataToSend);

      setResult(response);
      setIsLoading(false);
    } catch (e) {
      console.error('Error sending command', e);
      showMessage({
        messageText: 'Error sending request.',
        messageType: Message.Type.Warning,
      });
      setIsLoading(false);
    }
  };

  return (
    <div className="row">
      <div className="col-12 col-md-6 mx-auto">
        <div className="my-4">
          <form onSubmit={submitFormAsync}>
            {SEND_COMMAND_FIELDS.map((formField) => (
              <FormField
                key={formField.name}
                disabled={isLoading}
                formField={formField}
                updateForm={updateForm}
                formState={formState}
              />
            ))}

            <SpringButton type="submit" className="btn btn-primary" isLoading={isLoading}>
              Submit
            </SpringButton>
          </form>

          <Spacer />

          {result && <ReactJson theme="apathy:inverted" src={result} />}
        </div>
      </div>
    </div>
  );
};
