import React, { useState, useContext, useEffect } from 'react';
import XLSX from 'xlsx';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import MaterialTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { LoadingView } from '../components/LoadingSpinner';
import {Schema, SpringClient } from 'boaz-bikes-types';
import { useNavigate } from '../Router';
import { v4 as uuid } from 'uuid';
import { HomeCityContext } from '../home-city/HomeCityContext';
import { Message } from 'boaz-types';
import { showMessage } from '../ToastProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      overflowX: 'auto',
    },
    table: {
      minWidth: 650,
    },
    row: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
      '&:hover': {
        textDecoration: 'none',
      },
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    toolbar: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    spacer: {
      flex: '1 1 100%',
    },
    actions: {
      color: theme.palette.text.secondary,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    title: {
      flex: '0 0 auto',
    },
  })
);


export const ImportDock = () => {
    const [ newDocks, setNewDocks ] = useState<Schema.Dock.Admin[]>([])
    // const dockFields = ["Display Name","latitude","longitude"]
    const dockFields = ['Display_Name',	'Home_Cty',	'Manufacturer','Manufacturer_ID','QR_Code','Latitude',	'Longitude','Is_Active','Is_Operator','Is High Prriority',	'User_Parking'];		
    const [isEmpty, setIsEmpty] = useState(true);
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();
    const { getHomeCities, isLoading:isLoadingHomeCities } = useContext(HomeCityContext);
    const availableHomeCityIds = getHomeCities();
    const checkHomeCitiesId = (homeCityId:string) =>{
      if  (availableHomeCityIds?.some((elem)=> elem.id == homeCityId)){
         return true
      }else  {
       return false
      }  
     }
    const getIdFromName =(name:string) =>{
      let res ='';
      availableHomeCityIds?.map((city)=>{
        if (city.name.toLowerCase() == name.toLowerCase()){
          res = city.id
        }
      })
      return res;
    }
   
    const validateFields = (currentFields:any)=> {
      let valid = true;
      valid = checkHomeCitiesId(getIdFromName(currentFields[1]))
      if (!valid) return "City id invalid"
      for (let i = 0; i < 12;i++){
        valid = (currentFields[i] != '' && currentFields[i] != null)
        if (!valid) return 'invalid field'
      }
      valid = (currentFields[10] == 'true' ||  currentFields[10] == 'false')
      if (!valid) return "invalid Boolean field";
      valid = (currentFields[11] == 'true' ||  currentFields[11] == 'false')
      if (!valid) return "invalid Boolean field";
      valid = (currentFields[12] == 'true' ||  currentFields[12] == 'false')
      if (!valid) return "invalid Boolean field";
      valid = (currentFields[13] == 'true' ||  currentFields[13] == 'false')
      if (!valid) return "invalid Boolean field";
      return valid
    }
  
    const getCSVData= async (file: any)=>{
      setIsEmpty(true);
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = e => {
        /* Parse data */
        const bstr = e.target!.result;
        const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array", raw: true });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, { header: 1});
        const d = new Date();
        let date = d.getHours() + ":" + d.getMinutes() + ", " + d.toDateString();
        let today = date.toString()
        try{
          const jsonParser = (data : string) => JSON.parse(data);
          let CSVReady = jsonParser(JSON.stringify(data));
          let newDock: Schema.Dock.Admin;
          let ok;
        for (let i=1; i< CSVReady.length; i++){
          ok = validateFields(CSVReady[i]);
          if (ok == true){ 
            newDock = {
              id:'',
              companyId:'',
              createdAt:today,
              updatedAt:today,
              displayName:CSVReady[i][0],
              manufacturerId:CSVReady[i][3],
              manufacturer:CSVReady[i][2],
              numTotalBays:CSVReady[i][5],
              numAvailableBays:CSVReady[i][6],
              numAvailableVehicles:CSVReady[i][7],
              latitude:CSVReady[i][8],
              longitude:CSVReady[i][9],
              isActive:CSVReady[i][10],
              isOperator:CSVReady[i][11],
              qrCode:CSVReady[i][4],
              homeCityId:getIdFromName(CSVReady[i][1]),
              highPriority:CSVReady[i][12],
              userParkingAllowed:CSVReady[i][13], 
              isInspected:true
            }
          }else{
            setIsEmpty(true)
            console.error(ok)
            const err = ok.toString();
            showMessage(Message.create(err, Message.Type.Danger));
          }
          setNewDocks(newDocks => [...newDocks,newDock])
      }
        }catch(e){
          console.log(e)
        }
      };
      if (rABS) reader.readAsBinaryString(file[0]);
      else reader.readAsArrayBuffer(file[0]);
      setIsEmpty(false)
    }
  
    const postDocks = async () => {
        setIsLoading(true);
    try {
          await SpringClient.post('/admin/create-multiple-docks', newDocks);
          navigate('/docks');
    } catch (e) {
          console.error('Error creating users', e);
          setIsLoading(false);
        }
    }
  
    
    if (isLoading || isLoadingHomeCities) return <LoadingView/>
  
  
    return (
      <div>
            <div>
                  <input 
                    type="file" 
                    onChange={(e)=>getCSVData(e.target.files)} />
          </div>
         {!isEmpty ? <div className={classes.tableContainer}>
              <MaterialTable className={classes.table}>
                <TableHead>
                  <TableRow>
                   {dockFields.map((field)=>(
                      <TableCell key={field} >{field}</TableCell>
                     
                   )) }
                  </TableRow>
                </TableHead>
  
                <TableBody>
                  {(newDocks && newDocks[0] != undefined) ? newDocks.map((currentDock,index)=>(
                    <TableRow key={index}>
                        <TableCell>{currentDock.displayName}</TableCell>
                        <TableCell>{currentDock.homeCityId}</TableCell>
                        <TableCell>{currentDock.manufacturer}</TableCell>
                        <TableCell>{currentDock.manufacturerId}</TableCell>
                        <TableCell>{currentDock.qrCode}</TableCell>
                        <TableCell>{currentDock.latitude}</TableCell>
                        <TableCell>{currentDock.longitude}</TableCell>
                        <TableCell>{currentDock.isActive}</TableCell>
                        <TableCell>{currentDock.isOperator}</TableCell>
                        <TableCell>{currentDock.highPriority}</TableCell>
                        <TableCell>{currentDock.userParkingAllowed}</TableCell>
                    </TableRow>
                  ))
                   : <></>}
                </TableBody>
              </MaterialTable>

            </div>
             : <div></div> } 
            {newDocks.length>0 && !isEmpty? 
            <div style={{flexDirection:"row", width:600} }>
            <button
              type="button"
              className="btn btn-primary"
              style={{  flexDirection: 'row', marginRight: 24, marginTop:10 }}
              onClick={()=>{
                  postDocks()
              }}
            > 
            Confirm
            </button>
                <button
                type="button"
                className="btn btn-primary"
                style={{  flexDirection: 'row', marginRight: 24, marginTop:10 }}
                onClick={()=>{
                  setNewDocks([])
                  setIsEmpty(true)
                }}
              > 
              Remove
              </button>
              </div>
              : <div></div>}
              
      </div>
    );
  };

