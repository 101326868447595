import { Schema } from 'boaz-bikes-types';
import React, { useEffect, useState, useContext } from 'react';
import { DateInfo } from '../components/DateInfo';
import { LoadingSpinner, LoadingView } from '../components/LoadingSpinner';
import { MoreDropdown } from '../components/MoreDropdown';
import { Table } from '../components/Table';
import { firebase, useSpringFetch } from '../firebase';
import { UserSummary } from '../rentals/RentalList';
import { useNavigate } from '../Router';
import { Card } from '../components/Card';
import { PageContainer } from '../components/PageContainer';
import { HomeCityContext } from '../home-city/HomeCityContext';
import { FilterAdminAreas, FilterAdminAreaVehicles } from '../utils/FilterAdminAreas';
import { FleetContext } from '../vehicles/FleetContext';
import { Link } from 'react-router-dom';

export const FirebaseStorageImage = ({
  firebaseUrl,
  className,
  style,
}: {
  firebaseUrl: string | null;
  className?: string;
  style?: any;
}) => {
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    const getDownloadUrlAsync = async () => {
      setIsDownloading(true);
      if (!firebaseUrl) {
        setIsDownloading(false);
        return;
      }

      const storageRef = firebase.storage().ref(firebaseUrl);
      const _downloadUrl = await storageRef.getDownloadURL();
      setDownloadUrl(_downloadUrl);
      setIsDownloading(false);
    };

    getDownloadUrlAsync();
  }, [firebaseUrl]);

  if (isDownloading) {
    return <LoadingView />;
  }

  if (downloadUrl == null) {
    return <div>No photo</div>;
  }

  return <img alt="Alt Tag" src={downloadUrl} className={className} style={style} />;
};

export const SupportIssueList = ({ 
  userId,
  adminArea,
  adminAreaCity,
  pageSize,
 }: { 
   userId?: string
   adminArea?:boolean;
   adminAreaCity?:string;
   pageSize?:number;
}) => {
  const navigate = useNavigate();
  const { selectedHomeCityId, getHomeCities } = useContext(HomeCityContext);
  const { selectedFleetId } = useContext(FleetContext);
  const [currentPage, setCurrentPage ] = useState(1)
  const [rowsState, setRowsState] = useState<any>([])
  const [ vehiclesState , setVehiclesState] = useState<Schema.Vehicle.Admin[]>([]);
  const [ usersState , setUsersState] = useState<Schema.User.Admin[]>([]);
  const { data, isLoading } = useSpringFetch<{
    supportIssues: Schema.SupportIssue.Admin[];
    users: Schema.User.Admin[];
    vehicles:Schema.Vehicle.Admin[];
    supportIssuesLength:number;
  }>('get',userId? '/admin/support-issues-user' :'/admin/support-issues' , { 
    homeCityId: adminArea? adminAreaCity : selectedHomeCityId, 
    userId, 
    pageSize,
    page:currentPage-1,
    expand: ['user','VEHICLE'] 
  });
  // let vehiclesData:any;
  // vehiclesData = useSpringFetch<{
  //   vehicles: Schema.Vehicle.Admin[];
  // }>('get', '/admin/vehicles', { 
  //   homeCityId: selectedHomeCityId,
  //   fleetId: selectedFleetId 
  // });
  let vehicles = data?.vehicles ? vehiclesState.concat(data.vehicles) : [];
  const getVehicleByQrCode = (qrCode:string, vehicles:Schema.Vehicle.Admin[]) => {
    let vehicleFind = vehicles.find((vehicle:Schema.Vehicle.Admin)=>(
      vehicle.qrCode == qrCode ))
    return vehicleFind?.id;
  }
  const setCurrentPageAndData = (page:number) =>{
    setVehiclesState(vehicles);
    setUsersState(users);
    setCurrentPage(page);
  }
  const supportIssuesLength =  data?.supportIssuesLength;
  const supportIssues = data?.supportIssues || [];
  const users = data? usersState.concat(data.users) : [];
  const userIdMap = new Map(users.map((user) => [user.id, user]));
  // mapping homeCities
  const allHomeCity = getHomeCities();
  const homeCityMap = new Map(allHomeCity?.map((homecity: any) => [homecity.id, homecity.name]))

  return (
    <Table
      title={'All Support Issues'}
      isLoading={isLoading}
      columnRenderInfos={[
        {
          columnName: 'User',
          attribute: 'userId',
          renderFunc: ({ userId }) => <UserSummary user={userIdMap.get(userId)} />,
        },
        {
          columnName: 'Image',
          attribute: 'photoGcloudUrl',
          renderFunc: ({ photoGcloudUrl }) => (
            <FirebaseStorageImage
              style={{ maxHeight: 200, maxWidth: 100 }}
              firebaseUrl={photoGcloudUrl}
              className="zoom"
            />
          ),
        },
        {
          columnName: 'Comments',
          attribute: 'comments'
        },
        {
          columnName: 'Vehicle Id',
          attribute: 'vehicleIdEnteredByUser',
          renderFunc: ({ vehicleIdEnteredByUser }) => {
            let id = getVehicleByQrCode(vehicleIdEnteredByUser,vehicles)
            return(
            <Link to={`/vehicles/${id}`}>{vehicleIdEnteredByUser}</Link>
          )},
        },
        {
          columnName: 'Home City',
          attribute: 'homeCityId',
          renderFunc: ({ homeCityId }) => {
            const homeCityName = homeCityMap.get(homeCityId);
            return homeCityName || "";
          }
        },
        {
          columnName: 'Created At',
          attribute: 'createdAt',
          renderFunc: ({ createdAt }) => <DateInfo date={createdAt} />,
        },
        {
          columnName: '',
          attribute: 'userId',
          renderFunc: ({ userId }) => {
            return (
              <MoreDropdown
                options={[{ label: 'View User', onClick: () => navigate(`/users/${userId}`) }]}
              />
            );
          },
        },
      ]}
      rows={supportIssues}
      currentPage={currentPage}
      setCurrentPage={setCurrentPageAndData}
      dataLength={supportIssuesLength}
      rowsState={rowsState}
      setRowsState={setRowsState}
    />
  );
};

// TODO: add the type for support issues
export function SupportHome() {

  const {adminArea,adminAreaCity,isLoading:isLoadingAdminArea }  = FilterAdminAreas();
  const {isLoading:isLoadingHomeCities } = useContext(HomeCityContext);

  if (isLoadingAdminArea || isLoadingHomeCities) return <LoadingSpinner></LoadingSpinner>  
  return (
    <PageContainer>
      <div className="row mb-4">
        <div className="col">
          <Card.Container>
            <SupportIssueList pageSize={100} adminArea={adminArea} adminAreaCity={adminAreaCity} />
          </Card.Container>
        </div>
      </div>
    </PageContainer>
  );
}
