import React, { useContext, useState } from 'react';
import { FormField, IFormField, INPUT_TYPE, useForm } from 'boaz-bikes-forms';
import { SpringClient } from 'boaz-bikes-types';
import { SpringButton } from '../components/SpringButton';
import { HomeCityContext } from '../home-city/HomeCityContext';

const ALL_HOME_CITY = 'All Home City';

const SEND_PUSH_NOTIFICATION_FIELDS: IFormField[] = [
  {
    label: 'Title',
    name: 'title',
    inputType: INPUT_TYPE.TEXT,
  },
  {
    label: 'Body',
    name: 'body',
    inputType: INPUT_TYPE.TEXT,
  },
];

export const SendPushNotificationForm = ({
  userId,
  sendToAllUsers = false,
}: {
  userId?: string;
  sendToAllUsers?: boolean;
}) => {
  const { formState, updateForm, clearForm } = useForm(SEND_PUSH_NOTIFICATION_FIELDS);
  const { getHomeCities } = useContext(HomeCityContext);
  const availableHomeCityIds = getHomeCities();
  const [isLoading, setIsLoading] = useState(false);
  const [ selectedhomeCity,setSelectedHomeCityId] = useState(ALL_HOME_CITY);

  const submitFormAsync = async (e: any) => {
    e.preventDefault();
    if (window.confirm('Are you sure you want to send this push notification?')) {
      setIsLoading(true);

      try {
        if (selectedhomeCity == ALL_HOME_CITY){
        await SpringClient.post('/admin/send-push-notification', {
          userId,
          sendToAllUsers,
          ...formState,
        });
      }else {
        await SpringClient.post('/admin/send-push-notification-by-city',{
          userId,
          sendToAllUsers:false,
          ...formState,
          homeCityId:selectedhomeCity
        })
      }
      } catch (e) {
        console.error('Error sending message', e);
      }

      clearForm();
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h5>Send push notification</h5>
      <select
          className="form-control col-2"
          value={selectedhomeCity || 'All cities'}
          onChange={(e) => setSelectedHomeCityId(e.target.value)}
        >
        <option key={'all'} value={ALL_HOME_CITY}>
        {ALL_HOME_CITY}
      </option>
      {availableHomeCityIds?.map((homecity) => (
        <option key={homecity.id} value={homecity.id}>{homecity.name}</option>
      ))}
      </select>
      <form onSubmit={submitFormAsync}>
        {SEND_PUSH_NOTIFICATION_FIELDS.map((formField) => (
          <FormField
            key={formField.name}
            disabled={isLoading}
            formField={formField}
            updateForm={updateForm}
            formState={formState}
          />
        ))}

        <SpringButton type="submit" className="btn btn-primary" isLoading={isLoading}>
          Submit
        </SpringButton>
      </form>
    </div>
  );
};
