import React, { useContext, useState } from 'react';
import { SpringClient } from 'boaz-bikes-types/dist/SpringClient';
import { Select } from '../components/Select';
import { HomeCityContext } from '../home-city/HomeCityContext';
import { HomeCity } from 'boaz-bikes-types/dist/schemas/HomeCity';

export const AdminAreaStatusDropdown = ({
  id,
  areaAdmin,
  refreshData,
}: {
  id: string;
  areaAdmin: string;
  refreshData: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { selectedHomeCityId, getHomeCities } = useContext(HomeCityContext);
  let cities:HomeCity.Admin[] | null = getHomeCities();
  let nameCity:string[] =[];
  nameCity.push('ALL')
  cities?.forEach((city)=>{
    nameCity.push(city.name)
  })
  const updateStatusAsync = async (areaAdmin: string) => {
    if (window.confirm("Do you want to change this person's admin area?")) {
      setIsLoading(true);
      await SpringClient.post(`/admin/users/${id}/update-admin`, { areaAdmin });
      await refreshData();
      setIsLoading(false);
    }
  };
  return (
    <Select
      isLoading={isLoading}
      value={`${areaAdmin?.toLowerCase()}`}
      options={nameCity.map((option:any) => ({ label: option, value: option.toLowerCase() }))}
      onChange={(value) => updateStatusAsync(value)}
    />
  );
};
