import React, { createContext } from 'react';
import { useServerFetch } from 'boaz-bikes-client-utils';
import { Schema } from 'boaz-bikes-types';
import { VerifiedUser } from '@material-ui/icons';

type ICompanyContext = {
  company: Schema.Company.Client;
  priceOptions: Schema.PriceOption.Client[];
  topUpOptions: Schema.TopUpOption.Client[];
  termsConfig?: Schema.TermsConfig.Client;
  refetch: () => void;
};

export const CompanyContext = createContext<ICompanyContext>({
  company: {} as Schema.Company.Client,
  priceOptions: [],
  topUpOptions: [],
  refetch: () => null,
});

export const CompanyProvider = ({ children }: { children: any }) => {
  const { data, refreshData } = useServerFetch<{
    company: Schema.Company.Client;
    priceOptions: Schema.PriceOption.Client[];
    topUpOptions: Schema.TopUpOption.Client[];
    termsConfig: Schema.TermsConfig.Client;
  }>('get', '/company-config');

  if (!data) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: 20,
        }}
      >
        <VerifiedUser fontSize={'large'} />
        Loading company info...
      </div>
    );
  }

  return (
    <CompanyContext.Provider
      value={{
        company: data.company,
        priceOptions: data.priceOptions,
        termsConfig: data.termsConfig,
        topUpOptions: data.topUpOptions,
        refetch: refreshData,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};
