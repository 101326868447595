import React from 'react';
import { IFormField, INPUT_TYPE } from 'boaz-bikes-forms';
import { SpringClient, Schema } from 'boaz-bikes-types';
import { useNavigate } from '../Router';
import { showMessage } from '../ToastProvider';
import { SimpleForm } from 'boaz-bikes-forms';
import { LoadingView } from '../components/LoadingSpinner';
import { PageContainer } from '../components/PageContainer';
import { useParams } from 'react-router';
import { useSpringFetch } from '../firebase';

export const DELIVERY_FIELDS: IFormField[] = [
  {
    label: 'Active',
    name: 'isActive',
    inputType: INPUT_TYPE.SELECT,
    options: [
      { label: 'No', value: false },
      { label: 'Yes', value: true }
    ]
  },
];


export function DeliveryEdit() {
  const navigate = useNavigate();
  const { deliveryId } = useParams();

  const { data, isLoading } = useSpringFetch<{ delivery: Schema.Delivery.Admin }>(
    'get',
    `/admin/delivery/${deliveryId}`,
    {}
  );

  if (isLoading) {
    return <LoadingView />;
  }

  if (data == null) {
    return <div className="alert alert-warning">Delivery not found</div>;
  }

  const submitFormAsync = async (formState: any) => {
    try {
      const { message, delivery } = await SpringClient.post(`/admin/delivery/${deliveryId}`, formState);
      showMessage(message);
      navigate(`/delivery/${deliveryId}`);
    } catch (e) {
      console.error('Error update Delivery', e);
    }
  };

  return (
    <PageContainer>
      <div className="row">
        <div className="col-md-6 col-12 mx-auto">
          <SimpleForm
            title={'Update Delivery'}
            formFields={DELIVERY_FIELDS}
            initialState={data?.delivery}
            onSubmit={submitFormAsync}
          />
        </div>
      </div>
    </PageContainer>
  )
};