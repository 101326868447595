import { Home } from "@material-ui/icons";
import { useServerFetch } from "boaz-bikes-client-utils";
import { Schema } from "boaz-bikes-types";
import { analytics } from "firebase";
import { useContext } from "react";
import { UserContext } from "../auth/UserContext";
import { useSpringFetch } from "../firebase";

export const FilterAdminAreas = () =>{
    let adminArea = false
    const {data:dataUsers, isLoading} = useSpringFetch<{
         homeCityAdmin:string |null| undefined;
         adminAreaRes:boolean;
         adminAreaCity:any;
         
    }>('get', '/admin/users-filter-admin', { 
        isAdmin:true, 
        company_id:'boaz-bikes',
    });
    adminArea = dataUsers?.adminAreaRes? dataUsers?.adminAreaRes : false;
    return {
        adminArea:adminArea,
        homeCityAdmin:dataUsers?.homeCityAdmin,
        adminAreaCity:dataUsers?.adminAreaCity,
        isLoading,
       };
}

// export const FilterAdminAreas = () =>{
//     const { data, isLoading:isLoadingCities, refreshData: refreshHomeCities } = useServerFetch<{
//         homeCities: Schema.HomeCity.Admin[]
//       }>('get', '/admin/homecities');
//     let allHomecities = data?.homeCities;
//     const { user } = useContext(UserContext);
//     const dataUsers = useSpringFetch<{
//         users: Schema.User.Admin[];
//     }>('get', '/admin/users', { filter: "Admin" });
//     let homeCityAdmin:string |null| undefined;
//     let adminAreaRes:boolean = false;
//     let adminAreaCity:any = null;
//     let userLogged:Schema.User.Admin;
//     let isLoading = true;
//     dataUsers.data?.users.map((userCurrent)=>{
//         if (userCurrent.phoneNumber == user.phoneNumber){
//             userLogged = userCurrent;
//             if (userLogged.areaAdmin.toLowerCase() != 'all'){
//                 allHomecities?.map((city)=>{
//                 let area:string =  userCurrent.areaAdmin ? userCurrent.areaAdmin : 'undefine';
//                 if (city.name.toLowerCase() == area.toLowerCase()){
//                     adminAreaCity = city.id;
//                     homeCityAdmin = userCurrent? userCurrent.homeCityId : undefined;
//                     adminAreaRes = true
//                     isLoading = false;
//                     return;
//                 }
//                 })
//             }else {
//                 homeCityAdmin = userCurrent? userCurrent.homeCityId : undefined;
//                 adminAreaCity = null;
//                 adminAreaRes = false;
//                 isLoading = false;
//                 return;
//             }
//         }
//     });
//     return {
//          adminArea:adminAreaRes,
//          homeCityAdmin:homeCityAdmin,
//          adminAreaCity:adminAreaCity,
//          isLoading,
//         };
// }
export const FilterAdminAreaVehicleAlerts = (vehicleAlerts:Schema.VehicleAlert.Admin[]|undefined) =>{
    const {adminArea, adminAreaCity:CityAdminId}  = FilterAdminAreas()
    let filteredVehicles;
    if (adminArea){
        filteredVehicles = vehicleAlerts?.filter((vehicleAlerts)=>vehicleAlerts.homeCityId == CityAdminId);
    }else {
        filteredVehicles = vehicleAlerts;
    }
    return filteredVehicles;
}


export const FilterAdminAreaVehicles = (vehicles:Schema.Vehicle.Admin[]|undefined) =>{
    const {adminArea, adminAreaCity}  = FilterAdminAreas()
    let filteredVehicles;
    if (adminArea){
        filteredVehicles = vehicles?.filter((vehicle)=>vehicle.homeCityId == adminAreaCity);
    }else {
        filteredVehicles = vehicles;
    }
    return filteredVehicles;
}

export const FilterAdminAreaUsers = (users: Schema.User.Admin[]|undefined) =>{
    const {adminArea, adminAreaCity}  = FilterAdminAreas()
    let filteredUsers;
    if (adminArea){
        filteredUsers = users?.filter((user)=>user.homeCityId == adminAreaCity);
    }else {
        filteredUsers = users;
    }
    return filteredUsers;
}
