import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';

import App from './App';

import './index.scss';
import './utils/ApiClient';

Sentry.init({
  dsn: 'https://305500266bad445a8cf687cd705b28c4@o566944.ingest.sentry.io/5710360',
  environment: process.env.NODE_ENV,
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
