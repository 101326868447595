import React from "react";
import { ICoordinate, Schema } from "boaz-bikes-types";
import { Marker } from "react-google-maps";
import { useAdminDocks } from "../map/MapPage";
import { useNavigate } from "../Router";

export const STATUS_TO_ICON: Record<
  any,
  { selected: string; unselected: string }
> = {
  [Schema.Vehicle.Status.ACTIVE]: {
    selected: "/assets/active-selected.png",
    unselected: "/assets/active-unselected.png",
  },
  [Schema.Vehicle.Status.RENTED]: {
    selected: "/assets/rented-selected.png",
    unselected: "/assets/rented-unselected.png",
  },
  [Schema.Vehicle.Status.LOW_BATTERY]: {
    selected: "/assets/low-battery-selected.png",
    unselected: "/assets/low-battery-unselected.png",
  },
  [Schema.Vehicle.Status.OFFLINE]: {
    selected: "/assets/offline-selected.png",
    unselected: "/assets/offline-unselected.png",
  },
  [Schema.Vehicle.Status.REPAIR]: {
    selected: "/assets/repair-selected.png",
    unselected: "/assets/repair-unselected.png",
  },
  [Schema.Vehicle.Status.Customer_Bike]: {
    selected: "/assets/repair-selected.png",
    unselected: "/assets/repair-unselected.png",
  },
  ['batttery-porcentage-30-50']:{
    selected: "/assets/medium-battery-selected.png",
    unselected: "/assets/medium-battery-unselected.png",
  },
  ['available-unlocked']:{
    selected: "/assets/active-unlock-selected.png",
    unselected: "/assets/active-unlock-unselected.png",
  }
};

export const VehicleMarker = ({
  coordinate,
  status,
  isSelected,
  onClick,
}: {
  coordinate: ICoordinate;
  status: any;
  isSelected?: boolean;
  onClick?: () => void;
}) => {
  const icon =
    STATUS_TO_ICON[status] || STATUS_TO_ICON[Schema.Vehicle.Status.ACTIVE];
  return (
    <Marker
      position={{ lat: coordinate.latitude, lng: coordinate.longitude }}
      icon={{
        url: isSelected ? icon.selected : icon.unselected,
        scaledSize: new google.maps.Size(30, 39),
      }}
      onClick={onClick}
    />
  );
};

export const DockMarker = ({ coordinate,onClick,highPriority }: { coordinate: ICoordinate,onClick?:()=>void,highPriority:boolean }) => {
  return (
    <Marker
      position={{ lat: coordinate.latitude, lng: coordinate.longitude }}
      icon={{
        url: highPriority? "/assets/dock-selected.png" :  "/assets/dock.png",
        scaledSize: new google.maps.Size(30, 39),
      }}
      onClick={onClick}
    />
  );
};

export const AdminDocks = () => {
  const docks = useAdminDocks();
  const navigate = useNavigate();

  return (
    <>
      {docks.map((dock) => (
        <DockMarker onClick={() => navigate(`/docks/${dock.id}`)} key={dock.id} highPriority={dock.highPriority? dock.highPriority : false} coordinate={dock} />
      ))}
    </>
  );
};
