import { faArrowLeft, faBicycle } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IIotLog, Schema } from 'boaz-bikes-types';
import _ from 'lodash';
import React, { useState } from 'react';
import { DateInfo } from '../components/DateInfo';
import { DatePeriodProvider } from '../components/DatePeriodProvider';
import { DisplayJson } from '../components/DisplayData';
import { OmniScooter } from 'boaz-bikes-omni';
import { Link } from 'react-router-dom';
import { LoadingSpinner } from '../components/LoadingSpinner';
import { Table } from '../components/Table';
import { useSpringFetch } from '../firebase';
import { FeedFilters } from './FeedFilters';
import { Card } from '../components/Card';
import { IconButton } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';

const VEHICLE_PING_CATEGORY = 'Vehicle Ping';
const EVENT_CATEGORY = 'Event';
const IOT_LOG_CATEGORY = 'Iot Log';
export const FEED_ITEM_CATEGORIES = [VEHICLE_PING_CATEGORY, EVENT_CATEGORY, IOT_LOG_CATEGORY];

interface FeedItem {
  timestamp: string;
  type: string;
  message: React.ReactNode | null;
  extra?: React.ReactNode;
}

const VehicleFeedPresenter = ({ feedItems }: { feedItems: FeedItem[] }) => {
  return (
    <Table
      rows={feedItems}
      columnRenderInfos={[
        {
          columnName: 'Timestamp',
          renderFunc: ({ timestamp }) => (
            <DateInfo date={timestamp} shouldStartWithRelative={false} />
          ),
        },
        { columnName: 'Type', attribute: 'type' },
        { columnName: 'Message', attribute: 'message' },
        {
          columnName: 'Extra',
          renderFunc: ({ extra }) => extra,
        },
      ]}
    />
  );
};

const parseOmniMessageToComponents = (message: string) => {
  const omniMessage = JSON.parse(message).msg;
  const messageComponents = omniMessage.split(',');
  const direction = messageComponents[0];
  const type = messageComponents[3];

  return { direction, type, omniMessage };
};

const IotLogMessage = ({ iotController, message }: { iotController: string; message: string }) => {
  const { isExpanded, expandCollapseButton } = useExpand();

  if (iotController !== 'omni') {
    return <span>{message}</span>;
  }

  try {
    const rawMessage = JSON.parse(message).msg;
    const omniMessage = OmniScooter.parseOmniMessageFromString(rawMessage);
    if (!omniMessage) {
      return null;
    }

    const { messageDirection, messageType } = omniMessage;

    // TODO: extend to more types of messages
    const heartbeatInfo = OmniScooter.parseHeartbeat(omniMessage);

    let arrow = null;
    if (messageDirection === '*SCOR') {
      arrow = <FontAwesomeIcon icon={faArrowRight} color={'green'} />;
    } else if (messageDirection === '*SCOS') {
      arrow = <FontAwesomeIcon icon={faArrowLeft} color={'blue'} />;
    }

    return (
      <span>
        <FontAwesomeIcon icon={faBicycle} /> {arrow} {messageType} {rawMessage}
        {heartbeatInfo && (
          <div>
            {isExpanded && <DisplayJson data={heartbeatInfo} />}
            {expandCollapseButton}
          </div>
        )}
      </span>
    );
  } catch (e) {
    return <span>''</span>;
  }
};

const useExpand = (
  label?: string
): { isExpanded: boolean; expandCollapseButton: React.ReactNode } => {
  const [isExpanded, setIsExpanded] = useState(false);
  const expandCollapseButton = (
    <button className="btn btn-small btn-link" onClick={() => setIsExpanded(!isExpanded)}>
      {isExpanded ? 'Collapse' : 'Expand'} {label}
    </button>
  );
  return { isExpanded, expandCollapseButton };
};

const IotSummarySection = ({ logs, iotController }: { logs: IIotLog[]; iotController: string }) => {
  const { isExpanded, expandCollapseButton } = useExpand('IOT Logs Summary');

  if (iotController !== Schema.Vehicle.IotController.OMNI) {
    return null;
  }

  const logTypeToCount: { [key: string]: number } = {};
  logs.forEach(({ message }) => {
    try {
      const { direction, type } = parseOmniMessageToComponents(message);
      const key = `${direction}_${type}`;

      if (logTypeToCount[key]) {
        logTypeToCount[key] += 1;
      } else {
        logTypeToCount[key] = 1;
      }
    } catch (e) {
      console.log(`couldn't parse this message: ${message}`);
    }
  });

  return (
    <div>
      {isExpanded && <DisplayJson data={logTypeToCount} />}

      {expandCollapseButton}
    </div>
  );
};

const useIotLogs = ({
  iotController,
  iotDeviceId,
  startAt,
  endAt,
}: {
  iotController: string;
  iotDeviceId: string;
  startAt?: string | null;
  endAt?: string | null;
}): { iotLogs: FeedItem[]; iotSummarySection: React.ReactNode; isLoading: boolean } => {
  const { data, isLoading } = useSpringFetch<{
    logs: IIotLog[];
  }>('get', '/admin/iot/logs', { iotController, query: iotDeviceId, limit: 200, startAt, endAt });
  if (!data) {
    return { iotLogs: [], iotSummarySection: null, isLoading };
  }

  const { logs } = data;
  return {
    iotLogs: logs.map((log) => ({
      timestamp: log.recordedAt,
      type: IOT_LOG_CATEGORY,
      message: <IotLogMessage iotController={iotController} message={log.message} />,
    })),
    iotSummarySection: <IotSummarySection logs={logs} iotController={iotController} />,
    isLoading,
  };
};

const useEvents = ({
  vehicleId,
  startAt,
  endAt,
}: {
  vehicleId: string;
  startAt?: string | null;
  endAt?: string | null;
}): { events: FeedItem[]; isLoading: boolean } => {
  const { data, isLoading } = useSpringFetch<{
    events: Schema.Event.Admin[];
  }>('get', '/admin/events', { vehicleId, startAt, endAt });

  if (!data) {
    return { events: [], isLoading };
  }

  const { events } = data;

  const feedItems = events.map((event) => ({
    timestamp: event.createdAt,
    type: `${EVENT_CATEGORY} - ${event.type}`,
    message: event.message,
    extra: (
      <>
        {event.userId && (
          <div>
            <Link to={`/users/${event.userId}`}>{`user: ${event.userId}`}</Link>
          </div>
        )}
        <div>
          {event.rentalId && (
            <Link to={`/rentals/${event.rentalId}`}>{`rental: ${event.rentalId}`}</Link>
          )}
        </div>
      </>
    ),
  }));

  return {
    events: feedItems,
    isLoading,
  };
};

const VehiclePingExtra = ({ vehiclePing }: { vehiclePing: Schema.VehiclePing.Admin }) => {
  const { isExpanded, expandCollapseButton } = useExpand();
  const { isUnlocked, isCharging, batteryPercent } = vehiclePing;
  return (
    <div>
      Unlocked - {JSON.stringify(isUnlocked)}
      {' // '}
      Charging - {JSON.stringify(isCharging)}
      {' // '}
      {batteryPercent}%
      {isExpanded && (
        <div>
          <DisplayJson data={vehiclePing} />
        </div>
      )}
      {expandCollapseButton}
    </div>
  );
};

const useVehiclePings = ({
  vehicleId,
  startAt,
  endAt,
}: {
  vehicleId: string;
  startAt?: string | null;
  endAt?: string | null;
}): { vehiclePings: FeedItem[]; isLoading: boolean } => {
  const { data: vehiclePingsData, isLoading } = useSpringFetch<{
    vehiclePings: Schema.VehiclePing.Admin[];
  }>('get', `/admin/vehicle-pings/${vehicleId}`, {
    startAt,
    endAt,
  });

  if (!vehiclePingsData) {
    return { vehiclePings: [], isLoading };
  }

  const { vehiclePings } = vehiclePingsData;

  return {
    vehiclePings: vehiclePings.map((vehiclePing) => {
      const { recordedAt, vehicleStatus } = vehiclePing;
      return {
        timestamp: recordedAt,
        type: VEHICLE_PING_CATEGORY,
        message: vehicleStatus,
        extra: <VehiclePingExtra vehiclePing={vehiclePing} />,
      };
    }),
    isLoading,
  };
};

const FeedDataProvider = ({
  vehicle,
  start,
  end,
  render,
}: {
  vehicle: Schema.Vehicle.Admin;
  start: Date | null;
  end: Date | null;
  render: ({ feedItems }: { feedItems: FeedItem[] }) => React.ReactElement;
}) => {
  const startAt = start && start.toISOString();
  const endAt = end && end.toISOString();
  const { iotLogs: iotFeedItems, iotSummarySection, isLoading: isLoadingIotLogs } = useIotLogs({
    iotController: vehicle.iotController,
    iotDeviceId: vehicle.iotDeviceId as string,
    startAt,
    endAt,
  });
  const { events: eventFeedItems, isLoading: isLoadingEvents } = useEvents({
    vehicleId: vehicle.id,
    startAt,
    endAt,
  });
  // console.log(eventFeedItems)
  const { vehiclePings: vehiclePingFeedItems, isLoading: isLoadingVehiclePings } = useVehiclePings({
    vehicleId: vehicle.id,
    startAt,
    endAt,
  });
  // console.log(vehiclePingFeedItems)
  const [activeCategories, setActiveCategories] = useState(FEED_ITEM_CATEGORIES);

  const allFeedItems: FeedItem[] = _.orderBy(
    [...iotFeedItems, ...eventFeedItems, ...vehiclePingFeedItems],
    ({ timestamp }) => timestamp,
    'desc'
  );

  const categoryToCount: Record<string, number> = {
    [VEHICLE_PING_CATEGORY]: vehiclePingFeedItems.length,
    [EVENT_CATEGORY]: eventFeedItems.length,
    [IOT_LOG_CATEGORY]: iotFeedItems.length,
  };

  const categoriesToExclude: string[] = _.difference(FEED_ITEM_CATEGORIES, activeCategories);

  const feedItems = allFeedItems.filter(({ type }) => {
    for (const categoryToExclude of categoriesToExclude) {
      if (type.startsWith(categoryToExclude)) {
        return false;
      }
    }

    return true;
  });

  const isLoading = isLoadingIotLogs || isLoadingEvents || isLoadingVehiclePings;

  return (
    <div>
      {isLoading && <LoadingSpinner />}

      <FeedFilters
        activeCategories={activeCategories}
        setActiveCategories={setActiveCategories}
        categoryToCount={categoryToCount}
      />

      {iotSummarySection}

      {render({ feedItems })}
    </div>
  );
};

export const VehicleFeed = ({
  vehicle,
  initialStart,
  initialEnd,
}: {
  vehicle: Schema.Vehicle.Admin;
  initialStart?: string | null;
  initialEnd?: string | null;
}) => {
  const [refreshKey, setRefreshKey] = useState(0);

  return (
    <div className="row mb-4">
      <div className="col">
        <Card.Container>
          <Card.Header title="Feed" />
          <Card.Body>
            <IconButton aria-label="refresh" onClick={() => setRefreshKey(refreshKey + 1)}>
              <Refresh />
            </IconButton>
            <DatePeriodProvider
              initialStart={initialStart}
              initialEnd={initialEnd}
              render={({ start, end }) => (
                <FeedDataProvider
                  key={refreshKey}
                  vehicle={vehicle}
                  start={start}
                  end={end}
                  render={({ feedItems }) => <VehicleFeedPresenter feedItems={feedItems} />}
                />
              )}
            />
          </Card.Body>
        </Card.Container>
      </div>
    </div>
  );
};
