import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { Card } from '../components/Card';
import { Schema } from 'boaz-bikes-types';
import { DisplayData } from '../components/DisplayData';
import { Link } from 'react-router-dom';
import { PageContainer } from '../components/PageContainer';
import { useSpringFetch } from '../firebase';
import { LoadingView } from '../components/LoadingSpinner';
import { HomeCityContext } from '../home-city/HomeCityContext';
import { Marker } from 'react-google-maps';
import { MapComponent } from '../components/MapComponent';
import { RenderDropOffIds } from './DeliveryHome';
import { Location } from '../components/Location';

const RenderBikeInventory = ({ vehicles }: { vehicles: Schema.Vehicle.Admin[] | undefined}) => {
  return (
    <>
      {vehicles?.map(vehicle =>
        <>
          <Link to={`/vehicles/${vehicle?.id}`}>{vehicle?.qrCode}</Link>,{' '}
        </>
      )}
    </>
  )
}

export function DeliveryDetail() {
  const { deliveryId } = useParams();
  const { selectedHomeCityId, getHomeCities } = useContext(HomeCityContext);

  const { data, isLoading } = useSpringFetch<{
    delivery: Schema.Delivery.Admin,
    user: Schema.User.Admin,
    dropOff: Schema.Dropoff.Admin,
    vehicles: Schema.Vehicle.Admin[],
  }>(
    'get',
    `/admin/delivery/${deliveryId}`,
    {}
  );

  if (isLoading) {
    return <LoadingView />;
  }

  // mapping homeCities
  const allHomeCity = getHomeCities();
  const homeCityMap = new Map(allHomeCity?.map((homecity: any) => [homecity.id, homecity.name]))

  // location of delivery
  const defaultCenter = { lat: data?.delivery?.latitude || 0, lng: data?.delivery?.longitude || 0 };
  const dropOffIds = data?.delivery?.dropOffIds || [];

  const coordinate = {
    latitude: data?.delivery?.latitude || 0,
    longitude: data?.delivery?.longitude || 0,
  }
  const availableBikeIds = data?.user?.availableBikeIds || [];

  return (
    <PageContainer>
      <Card.Container>
        <Card.Header
          title={'Delivery Details'}
          actions={
            <Link to={`/delivery/${deliveryId}/edit`}>
              <button className={'btn btn-sm btn-secondary'} >Edit</button>
            </Link>
          }
        />
        <Card.Body>
          <div className="row">
            <div className="col">
              <DisplayData
                data={[
                  {
                    label: 'User',
                    value: <Link to={`/users/${data?.user?.id}`}>{data?.user?.phoneNumber}</Link>
                  },
                  {
                    label: 'Home City',
                    value: homeCityMap.get(data?.delivery?.homeCityId)
                  },
                  {
                    label: 'Name',
                    value: data?.user?.name
                  },
                  {
                    label: 'Bike Inventory',
                    value: <RenderBikeInventory vehicles={data?.vehicles} />,
                  },
                  {
                    label: 'Pending DropOffs IDs',
                    value: <RenderDropOffIds dropOffIds={dropOffIds} />,
                  },
                  {
                    label: 'Bikes Available For Rent',
                    value: data?.delivery?.availableBikes
                  },
                  {
                    label: 'Active',
                    value: data?.delivery?.isActive ? "Yes" : "No"
                  },
                  {
                    label: 'Location',
                    value: <Location coordinate={coordinate} />,
                  },
                  {
                    label: 'Last Ping',
                    value: ""
                  },
                  {
                    label: 'Last Ping Time',
                    value: data?.delivery?.updatedAt
                  },
                ]}
              />
            </div>
            <div className="col">
              <div>
                <MapComponent defaultZoom={15} defaultCenter={defaultCenter}>
                  <Marker
                    position={defaultCenter}
                    icon={`http://maps.google.com/mapfiles/ms/icons/red-dot.png`}
                  />
                </MapComponent>
              </div>
            </div>
          </div>

        </Card.Body>
      </Card.Container>
    </PageContainer>
  );
};