import React from 'react';
import classNames from 'classnames';

export const PageContainer = ({
  fullWidth,
  includeTopPadding = true,
  children,
}: {
  children: React.ReactNode;
  includeTopPadding?: boolean;
  fullWidth?: boolean;
}) => {
  return (
    <div
      className={classNames({
        container: !fullWidth,
        'container-fluid': fullWidth,
        'pt-4': includeTopPadding,
      })}
    >
      {children}
    </div>
  );
};
