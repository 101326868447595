import { displayPrice, Schema } from 'boaz-bikes-types';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { DateInfo } from '../components/DateInfo';
import { DisplayData } from '../components/DisplayData';
import { LoadingView } from '../components/LoadingSpinner';
import { useSpringFetch } from '../firebase';
import { UserPromoCodeList } from './UserPromoCodeList';
import { Card } from '../components/Card';
import { PageContainer } from '../components/PageContainer';
import { CompanyContext } from '../auth/CompanyContext';

export function PromoCodeInfo({ match }: any) {
  const { company } = useContext(CompanyContext);
  const { promoCodeId } = match.params;

  const { data, isLoading } = useSpringFetch<{
    promoCode: Schema.PromoCode.Admin;
  }>('get', `/admin/promos/${promoCodeId}`);

  if (isLoading) {
    return <LoadingView />;
  }

  if (data == null) {
    return <div className="alert alert-warning">Promo Code not found</div>;
  }

  const { promoCode } = data;
  const discountAmount = displayPrice({
    amountCents: promoCode.discountCents,
    numDigits: 2,
    currency: company.currency,
  });

  return (
    <PageContainer>
      <div className="row mb-4">
        <div className="col-lg-6 col-xs-12">
          <Card.Container>
            <Card.Header
              title="Details"
              actions={<Link to={`/promos/${promoCodeId}/edit`}>Edit</Link>}
            />
            <Card.Body>
              <DisplayData
                data={[
                  { label: 'Code', value: promoCode.promoCode },
                  { label: 'Initial Use Count', value: promoCode.initialUseCount },
                  { label: 'Active', value: `${promoCode.isActive}` },
                  { label: 'Expiration Date', value: `${promoCode.expirationDate?.split('T')[0]}` },
                  {  label: 'Discount Amount',value: discountAmount, },
                  { label: 'Created', value: <DateInfo date={promoCode.createdAt} /> },
                ]}
              />
            </Card.Body>
          </Card.Container>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12">
          <Card.Container>
            <Card.Header title="Users with Promo Code" />
            <UserPromoCodeList promoCodeId={promoCodeId} />
          </Card.Container>
        </div>
      </div>
    </PageContainer>
  );
}
