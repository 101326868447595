import React from 'react';
import { GoogleMap, withGoogleMap, withScriptjs,  } from 'react-google-maps';
import HeatmapLayer from 'react-google-maps/lib/components/visualization/HeatmapLayer';

const WrappedMap = withScriptjs(
  withGoogleMap(({ children, ...otherProps }: any) => {
    
   let heatMapData = otherProps.heatmapDataRentals;
   const optionsHeatMap = {
    data:heatMapData,
    radius:150,
   }
   let ref:any;
    return (
      <GoogleMap
      ref={(mapRef) => ref = mapRef} // bind the ref
        options={{
          zoomControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
        }}
        onBoundsChanged={()=>heatMapData? otherProps.updateDatabyBounds(ref.getCenter()) : []}
        {...otherProps}
      > 
       {children}
      {heatMapData? 
       <HeatmapLayer 
            data={heatMapData}
            options={optionsHeatMap}
        /> : <></>}
        
      </GoogleMap>
    );
  })
);

export const MapComponent = ({ containerHeight, ...props }: any) => {
  const mergedProps = Object.assign({}, props, {
    // googleMapURL:
    //   'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyB3zdM4BvYBCMScb6IUZfR0vlIuFjjEGKM',
    googleMapURL:
    'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=visualization,geometry,drawing,places&key=AIzaSyB3zdM4BvYBCMScb6IUZfR0vlIuFjjEGKM',

    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: containerHeight || 400 }} />,
    mapElement: <div style={{ height: `100%` }} />,
  });

  return <WrappedMap {...mergedProps} />;
};
