import * as Sentry from "@sentry/browser";

import {
  COMPANY,
  ISpringClientConfigurationOptions,
  SpringClient,
} from "boaz-bikes-types";

import { firebase } from "../firebase";
import { showMessage } from "../ToastProvider";
import { useEffect } from "react";

const DEFAULT_COMPANY_ID: COMPANY = COMPANY.BOAZ_BIKES;

const getAuthToken = async (): Promise<any> => {
  const { currentUser } = firebase.auth();
  if (!currentUser) {
    return null;
  }

  return await currentUser.getIdToken();
};

export const COMPANY_ID = DEFAULT_COMPANY_ID;
// const baseUrl = 'http://104.154.62.48:3000';
//baseUrl: "https://localhost:3335";
const springClientConfig: ISpringClientConfigurationOptions = {
  //Staging
  //baseUrl: "https://boazappapi.boazbikes.info:4445",

  //Cloud Run
  // baseUrl: "https://boaz-server-6mjjcuc6bq-uc.a.run.app",

  //local Testing
  // baseUrl: "http://192.168.1.58:4444",
  // 
  //App Engine
  // baseUrl: "https://root-monolith-282303.uc.r.appspot.com",

  // New server dashboard
  baseUrl: "https://boaz-server-dashboard-6mjjcuc6bq-uc.a.run.app",

  getAuthToken,
  showMessage,
  captureException: (error, metadata) => {
    Sentry.withScope((scope) => {
      if (metadata && metadata.url) {
        scope.setTag("url", metadata.url);
      }
      if (metadata && metadata.extraData) {
        scope.setExtra("extra_data", metadata.extraData);
      }
      Sentry.captureException(error);
    });
  },
};

const ADMIN_BASE_URL_ENV_KEY = "REACT_APP_ADMIN_BASE_URL";
if (process.env[ADMIN_BASE_URL_ENV_KEY]) {
  springClientConfig.baseUrl = process.env[ADMIN_BASE_URL_ENV_KEY];
}

SpringClient.configureClient(COMPANY_ID, springClientConfig);

const useDocumentTitle = (title?: string | null) => {
  const baseTitle = ` ${COMPANY_ID} - Admin Dashboard`;
  const htmlTitle = title ? `${title} - ${baseTitle}` : baseTitle;

  useEffect(() => {
    document.title = htmlTitle;
  }, [htmlTitle]);
};

export const DocumentTitle = ({ title }: { title?: string | null }) => {
  useDocumentTitle(title);
  return null;
};
