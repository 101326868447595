import { MapComponent } from '../components/MapComponent';
import React, { useCallback, useEffect, useState } from 'react';
import { DatePeriodPicker } from '../components/DatePeriodProvider';
import { useServer } from 'boaz-bikes-client-utils';
import _ from 'lodash';
import { getPingsBeforeDate, TimeAnimator } from './TimeAnimator';
import Polyline from 'react-google-maps/lib/components/Polyline';
import { Schema } from 'boaz-bikes-types';
import { LoadingSpinner } from '../components/LoadingSpinner';
import { VehicleMarker } from '../components/VehicleMarker';
import { useDefaultCenter } from './MapPage';

const usePingsInPeriod = (
  start: Date | null,
  end: Date | null
): {
  vehicleIdToPings: { [vehicleId: string]: Partial<Schema.VehiclePing.Admin>[] };
  isLoading: boolean;
} => {
  const [vehicleIdToPings, setVehicleIdToPings] = useState<{ [vehicleId: string]: any[] }>({});

  const { callFunction, isLoading } = useServer<{ startAt: string; endAt: string }, any>(
    'get',
    '/admin/vehicle-pings',
    ({ vehicleIdToPings: _vehicleIdToPings }: { vehicleIdToPings: any }) => {
      setVehicleIdToPings(_vehicleIdToPings);
    }
  );

  useEffect(() => {
    if (start && end) {
      callFunction({ startAt: start.toISOString(), endAt: end.toISOString() });
    }
  }, [start, end, callFunction]);

  return {
    vehicleIdToPings,
    isLoading,
  };
};

export const HistoricalMap = () => {
  const defaultCenter = useDefaultCenter();
  const [start, setStart] = useState<Date>(new Date('2019-11-09T13:00:00Z'));
  const [end, setEnd] = useState<Date>(new Date('2019-11-09T13:15:00Z'));

  const setDates = useCallback(({ start: _start, end: _end }) => {
    if (_start && _end) {
      setStart(_start);
      setEnd(_end);
    }
  }, []);

  const { vehicleIdToPings, isLoading } = usePingsInPeriod(start, end);

  return (
    <div style={{ height: '100%' }}>
      <DatePeriodPicker
        initialStart={start && start.toISOString()}
        initialEnd={end && end.toISOString()}
        setDates={setDates}
      />

      <TimeAnimator.Provider startAt={start} endAt={end}>
        <TimeAnimator.ControlBar />

        {isLoading && <LoadingSpinner />}

        <MapComponent defaultZoom={13} defaultCenter={defaultCenter} containerHeight={'80vh'}>
          <TimeAnimator.Consumer
            render={({ snapshotDate }) => {
              return _.map(vehicleIdToPings, (pings, vehicleId) => {
                const recentPings: Schema.VehiclePing.Admin[] = getPingsBeforeDate(
                  snapshotDate,
                  pings,
                  'recordedAt',
                  5
                );

                if (recentPings.length === 0) {
                  return null;
                }

                const lastPing = recentPings[recentPings.length - 1];

                return (
                  <React.Fragment key={vehicleId}>
                    <Polyline
                      key={`line-${vehicleId}`}
                      path={recentPings.map(({ latitude, longitude }) => ({
                        lat: latitude,
                        lng: longitude,
                      }))}
                    />
                    <VehicleMarker
                      key={`marker-${vehicleId}`}
                      status={lastPing.vehicleStatus}
                      coordinate={lastPing}
                    />
                  </React.Fragment>
                );
              });
            }}
          />
        </MapComponent>
      </TimeAnimator.Provider>
    </div>
  );
};
