import { Schema, SpringClient } from 'boaz-bikes-types';
import React, { useContext, useState } from 'react';
import { DateInfo } from '../components/DateInfo';
import { TabRow } from '../components/Tab';
import { Table } from '../components/Table';
import { useSpringFetch } from '../firebase';
import { Card } from '../components/Card';
import { PageContainer } from '../components/PageContainer';
import { HomeCityContext } from '../home-city/HomeCityContext';
import { LoadingSpinner } from '../components/LoadingSpinner';
import { UserSummary, UserSummaryById } from '../rentals/RentalList';
import moment from 'moment';

enum UserTab {
  ALL = 'All',
  ACTIVE = 'Active',
  NOACTIVE = 'No active'
}


const currentDate = new Date();
const TODAYTIMESTAMP = Math.floor(currentDate.getTime() / 1000);

const TAB_TO_FILTER: Partial<Record<UserTab, any>> = {
  [UserTab.ALL]: (user: any) => true,
  [UserTab.ACTIVE]: (user: any) => user.current_period_end > TODAYTIMESTAMP,
  [UserTab.NOACTIVE]: (user: any) => user.current_period_end < TODAYTIMESTAMP,
};

type UserFilter = (user: Schema.User.Admin) => boolean;


export const UserPassList = ({
  title,
  filter,
  limit,
  pageSize,
}: {
  title?: string;
  filter?: UserFilter;
  limit?: number;
  pageSize: number;
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsState, setRowsState] = useState<any>([]);
  const [startingAfter, setStartingAfter] = useState(null);
  const [endingBefore, setEndingBefore] = useState(null);
  const { selectedHomeCityId } = useContext(HomeCityContext);

  const { data, isLoading } = useSpringFetch<{
    userPasses: any[];
  }>('get', '/admin/get-users-passes', {
    homeCityId: selectedHomeCityId,
    limit: pageSize,
    startingAfter: startingAfter ? startingAfter : undefined,
    endingBefore: endingBefore ? endingBefore : undefined,
  });
  if (!isLoading && data == null) {
    return <div className="alert alert-warning">No passes found</div>;
  }

  const passes = data?.userPasses || [];

  //const start: number = (currentPage - 1);
  //const end: number = Math.min(start + 100, passes.length);

  const filteredData = filter ? passes.filter(filter) : passes;

  return (
    <>
      <Table
        title={title}
        isLoading={isLoading}
        autoFocusSearch={true}
        rowsPerPage={pageSize}
        columnRenderInfos={[
          {
            columnName: 'User',
            attribute: 'userId',
            renderFunc: ({ userId }) => <UserSummaryById userId={userId} />,
          },
          {
            columnName: 'Start',
            attribute: 'current_period_start',
            renderFunc: ({ current_period_start }) => {
              const date = new Date(current_period_start * 1000); // Convert timestamp to milliseconds
              // const formattedDate = date.toLocaleDateString(); // Convert to a human-readable date format
              const relativeDate = moment.utc(date).fromNow();

              return (
                <div>
                  <p>{relativeDate}</p>
                </div>
              );
            },

          },
          {
            columnName: 'End',
            attribute: 'current_period_end',
            renderFunc: ({ current_period_end }) => {
              const date = new Date(current_period_end * 1000); // Convert timestamp to milliseconds
              const formattedDate = date.toLocaleDateString(); // Convert to a human-readable date format

              return (
                <div>
                  <p>{formattedDate}</p>
                </div>
              );
            }
          },
          {
            columnName: 'Type',
            attribute: 'passType',
            renderFunc: ({ passType }) => {
              if (passType) {
                return (
                  <div>
                    <p>{passType}</p>
                  </div>
                );
              }
              return '';
            }
          },
        ]}
        rows={filteredData}
        setCurrentPage={(page) => {
          if (page > currentPage) {
            const lastId = passes.length > 0 ? passes[passes.length - 1].id : null;
            setStartingAfter(lastId);
          } else {
            const firstId = passes.length > 0 ? passes[0].id : null;
            setEndingBefore(firstId);
          }
          setCurrentPage(page)
        }
        }
        currentPage={currentPage}
        rowsState={rowsState}
        setRowsState={setRowsState}
        dataLength={-1}
        showCount={true}
      />
    </>
  );
};

export const UsersPassHome = () => {
  const { isLoading: isLoadingHomeCities } = useContext(HomeCityContext);

  if (isLoadingHomeCities) return <LoadingSpinner></LoadingSpinner>

  return (
    <PageContainer>
      <Card.Container>
        <UserPassList pageSize={100} title={'Active Passes'} />
      </Card.Container>
    </PageContainer>
  );
};
