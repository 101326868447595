import { Message, Schema, SpringClient } from 'boaz-bikes-types';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { DateInfo } from '../components/DateInfo';
import { Select } from '../components/Select';
import { Table } from '../components/Table';
import { useSpringFetch } from '../firebase';
import { useNavigate } from '../Router';
import { showMessage } from '../ToastProvider';
import { FleetContext } from './FleetContext';
import { Card } from '../components/Card';
import { PageContainer } from '../components/PageContainer';
import { HomeCityContext } from '../home-city/HomeCityContext';
import { UserContext } from '../auth/UserContext';
import { FilterAdminAreas, FilterAdminAreaVehicles } from '../utils/FilterAdminAreas';
import { LoadingSpinner } from '../components/LoadingSpinner';
import { CSVLink } from 'react-csv';


export const ShowBatteryPercent = ({batteryPercent}:{batteryPercent:number}) =>{

  return <div>{batteryPercent.toFixed(0)}</div>
}

export const VehicleStatusDropdown = ({
  ids,
  status,
  refreshData,
}: {
  ids: string[];
  status: Schema.Vehicle.Status;
  refreshData: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const updateStatusAsync = async (status: Schema.Vehicle.Status) => {
    setIsLoading(true);
    await Promise.all(
      ids.map(async (id) => {
        await SpringClient.post(`/admin/vehicles/${id}`, { status }, { showGoodMessages: false });
      })
    );
    showMessage(Message.create('Vehicles Updated', Message.Type.Success));
    await refreshData();
    setIsLoading(false);
  };

  return (
    <Select
      isLoading={isLoading}
      value={status}
      options={Object.values(Schema.Vehicle.Status).map((status) => ({
        label: status,
        value: status,
      }))}
      onChange={(value) => updateStatusAsync(value as Schema.Vehicle.Status)}
    />
  );
};

export const LastRentalDisplay = ({
  lastRentalAt,
  currentRentalIds,
}: {
  lastRentalAt?: string | null;
  currentRentalIds?: string | string[] | null;
}) => {
  const _currentRentalIds =
    typeof currentRentalIds === 'string' ? [currentRentalIds] : currentRentalIds;
  if (_currentRentalIds && _currentRentalIds.length) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {_currentRentalIds.map((rentalId) => (
          <Link key={rentalId} to={`/rentals/${rentalId}`}>
            Current Rental
          </Link>
        ))}
      </div>
    );
  } else if (lastRentalAt) {
    return <DateInfo date={lastRentalAt} />;
  }
  return null;
};


function VehicleList({ 
  title,
  adminArea,
  adminAreaCity
 }: {
    title?: string | React.ReactNode,
    adminArea:boolean;
    adminAreaCity:string;
 }) {
  const navigate = useNavigate();
  const { selectedFleetId } = useContext(FleetContext);
  const { selectedHomeCityId, getHomeCities } = useContext(HomeCityContext);
  
  let vehiclesData:any;
  vehiclesData = useSpringFetch<{
    vehicles: Schema.Vehicle.Admin[];
  }>('get', '/admin/vehicles', { 
    homeCityId: adminArea? adminAreaCity : selectedHomeCityId,
    fleetId: selectedFleetId 
  });
  const { data, isLoading, refreshData: _refreshData } = vehiclesData;
  
  const [selectedVehicles, setSelectedVehicles] = useState<Schema.Vehicle.Admin[]>([]);

  const refreshData = () => {
    setSelectedVehicles([]);
    _refreshData();
  };

  if (!isLoading && data == null) {
    return <div className="alert alert-warning">Vehicles not found</div>;
  }

  const vehicles = data?.vehicles || [];
  const allHomeCity = getHomeCities();
const exportVehicleAlert = vehicles.map( (vehicle:Schema.Vehicle.Admin) => {     
  let homeCityCurrent = allHomeCity?.find((homecity: any) => homecity.id === vehicle.homeCityId);
  return {
    'Status':vehicle.status,
    'Qr Code':vehicle.qrCode,
    "Battery":vehicle.batteryPercent,      
    'Last Ping': vehicle.lastPingedAt,
    'Last Rented': vehicle.lastRentalAt,
    'Home City':homeCityCurrent?.name,
    'Type':vehicle.vehicleTypes,
    "Is unlocked":vehicle.isUnlocked,
    'Fleet': vehicle.fleetId,
  };
}
);
  if (isLoading) return <LoadingSpinner></LoadingSpinner>
  return (
    <div className="row mb-4">
      <div className="col">
        <Card.Container>
          <Table
            title={title}
            isLoading={isLoading && vehicles.length < 1}
            rowsPerPage={100}
            onAddItem={() => navigate('/vehicles/create')}
            onAddCsv={() => navigate('/vehicles/multiple-create')}
            setSelectedItems={(vehicles) => setSelectedVehicles(vehicles)}
            selectedItems={selectedVehicles}
            selectionTools={[
              <VehicleStatusDropdown
                ids={selectedVehicles.map((v) => v.id)}
                status={Schema.Vehicle.Status.ACTIVE}
                refreshData={refreshData}
              />,
            ]}
            // getRowUrl={(vehicle) => `/vehicles/${vehicle.id}`}
            columnRenderInfos={[
              {
                columnName: 'Status',
                attribute: 'status',
                renderFunc: ({ id, status }) => (
                  <VehicleStatusDropdown ids={[id]} status={status} refreshData={refreshData} />
                ),
              },
              { columnName: 'QR Code', attribute: 'qrCode' },
              { columnName: 'IOT Device ID', attribute: 'iotDeviceId' },
              {
                columnName: 'Battery',
                attribute: 'batteryPercent',
                renderFunc: ({ batteryPercent }) =>
                batteryPercent? <ShowBatteryPercent batteryPercent={batteryPercent} />:
                <></>
              },
              {
                columnName: 'Last Ping',
                attribute: 'lastPingedAt',
                renderFunc: ({ lastPingedAt }) => <DateInfo date={lastPingedAt} />,
              },
              {
                columnName: 'Last Rented',
                attribute: 'lastRentalAt',
                renderFunc: ({ lastRentalAt, currentRentalId }) => (
                  <LastRentalDisplay
                    lastRentalAt={lastRentalAt}
                    currentRentalIds={currentRentalId}
                  />
                ),
              },
              {
                columnName: 'Home City',
                attribute: 'homeCityId',
                renderFunc: ({ homeCityId }) => {
                  let allHomeCity = getHomeCities();                  
                  let homeCityCurrent = allHomeCity?.find((homecity: any) => homecity.id === homeCityId);
                  return homeCityCurrent?.name || "";
                }
              },
              {
                columnName: 'Type',
                attribute: 'vehicleTypes'
              },
              { columnName: 'Charging', attribute: 'isCharging', shouldJsonifyValue: true },
              { columnName: 'Unlocked', attribute: 'isUnlocked', shouldJsonifyValue: true },
              { columnName: 'Fleet', attribute: 'fleetId' },
              {
                columnName: 'Edit',
                renderFunc: ({ id }) => (
                  <Link to={`/vehicles/${id}`}>Edit</Link>
                ),
              },
            ]}
            rows={vehicles}
          />
        </Card.Container>
        <div className="col" style={{marginBottom:15}}>
              <CSVLink
                style={{marginLeft:20}}
                data={exportVehicleAlert}
                filename={"vehicles.csv"}
                className="btn btn-primary"
                target="_blank"
              >
                Export
              </CSVLink>
            </div>
      </div>
    </div>
  );
}

export function VehiclesHome() {

  const {adminArea,adminAreaCity,isLoading:isLoadingAdminArea }  = FilterAdminAreas();

  if (isLoadingAdminArea) return <LoadingSpinner></LoadingSpinner>  
  return (
    <PageContainer>
      <VehicleList title="All Vehicles" 
         adminArea={adminArea} 
         adminAreaCity={adminAreaCity}
         />
    </PageContainer>
  );
}
