import { Schema } from 'boaz-bikes-types';
import React from 'react';
import { Table } from '../components/Table';
import { useSpringFetch } from '../firebase';
import { UserSummary } from '../rentals/RentalList';
import { LoadingView } from '../components/LoadingSpinner';

export function UserPromoCodeList({
  promoCodeId,
  userId,
  title,
}: {
  promoCodeId?: string;
  userId?: string;
  title?: string;
}) {
  const { data, isLoading } = useSpringFetch<{
    userPromoCodes: Schema.UserPromoCode.Admin[];
    users: Schema.User.Admin[];
  }>('get', `/admin/user-promos`, { promoCodeId, userId, expand: ['user'] });

  if (isLoading) {
    return <LoadingView />;
  }

  if (data == null) {
    return <div>Promo code has not been applied</div>;
  }

  const userPromoCodes = data?.userPromoCodes || [];
  const users = data?.users || [];
  const userIdMap = new Map(users.map((user) => [user.id, user]));

  return (
    <Table<Schema.UserPromoCode.Admin>
      title={title}
      getRowUrl={(userPromoCode) => `/promos/${userPromoCode.promoCodeId}`}
      columnRenderInfos={[
        {
          columnName: 'User',
          attribute: 'userId',
          renderFunc: ({ userId }) => <UserSummary user={userIdMap.get(userId)} />,
        },
        {
          columnName: 'Promo Code',
          attribute: 'promoCode',
        },
        {
          columnName: 'Discount Amount Cents',
          attribute: 'discountCents',
        },
        {
          columnName: 'Initial Use Count',
          attribute: 'initialUseCount',
        },
        {
          columnName: 'Remaining Uses',
          attribute: 'remainingUseCount',
        },
      ]}
      rows={userPromoCodes}
    />
  );
}
