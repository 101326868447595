import { Schema } from 'boaz-bikes-types';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Colors } from '../components/Colors';
import { DateInfo } from '../components/DateInfo';
import { DisplayData } from '../components/DisplayData';
import { Geofence } from '../components/Geofence';
import { Location } from '../components/Location';
import { MapComponent } from '../components/MapComponent';
import { SpringQRCode } from '../components/SpringQRCode';
import { AdminDocks, VehicleMarker } from '../components/VehicleMarker';
import { useAdminGeofences } from '../geofence/GeofenceHome';
import { FleetContext } from '../vehicles/FleetContext';
import { VehicleControlButtons } from '../vehicles/VehicleInfo';
import { LastRentalDisplay, VehicleStatusDropdown } from '../vehicles/VehiclesHome';
import { useServerFetch } from 'boaz-bikes-client-utils';
import { CompanyContext } from '../auth/CompanyContext';
import { HomeCityContext } from '../home-city/HomeCityContext';
import { FilterAdminAreas, FilterAdminAreaVehicles } from '../utils/FilterAdminAreas';
import { useNavigate } from '../Router';
import { LoadingSpinner } from '../components/LoadingSpinner';

export const useAdminVehicles = (): Schema.Vehicle.Admin[] => {
  const { selectedFleetId } = useContext(FleetContext);
  const { selectedHomeCityId, setSelectedHomeCityId } = useContext(HomeCityContext);

  const { data } = useServerFetch<{
    vehicles: Schema.Vehicle.Admin[];
  }>('get', '/admin/vehicles', { fleetId: selectedFleetId, homeCityId: selectedHomeCityId },
   { pollingInterval: 10_000 });
   const vehicles = FilterAdminAreaVehicles(data?.vehicles) || [];
  return vehicles;
};

export const useAdminDocks = (): Schema.Dock.Admin[] => {
  const { data } = useServerFetch<{
    docks: Schema.Dock.Admin[];
  }>('get', '/admin/docks', {}, {});

  const docks = data?.docks || [];
  return docks;
};

const AdminVehicleOverlay = ({ selectedVehicleId }: { selectedVehicleId: string }) => {


  const vehicles = useAdminVehicles();
  const vehicle: Schema.Vehicle.Admin | null =
    vehicles.find(({ id }) => id === selectedVehicleId) || null;
  
    return (
    <div
      className="card"
      style={{
        position: 'absolute',
        top: 8,
        right: 8,
        maxWidth: 400,
        backgroundColor: Colors.WHITE,
        overflow: 'scroll',
      }}
    >
      <div className="card-body">
        {vehicle? <DisplayData
          data={[
            {
              label: 'QR Code',
              value: vehicle?.qrCode ? <SpringQRCode qrCode={vehicle.qrCode} /> : null,
            },
            {
              label: 'Status',
              value: (
                <VehicleStatusDropdown
                  ids={[vehicle?.id]}
                  status={vehicle?.status}
                  refreshData={() => null}
                />
              ),
            },
            { label: 'Last Ping', value: <DateInfo date={vehicle?.lastPingedAt} /> },
            {
              label: 'Last Rental',
              value: (
                <LastRentalDisplay
                  currentRentalIds={vehicle?.currentRentalId}
                  lastRentalAt={vehicle?.lastRentalAt}
                />
              ),
            },
            { label: 'Locked', value: `${!vehicle?.isUnlocked}` },
            { label: 'Battery', value: `${vehicle?.batteryPercent}%` },
            { label: 'IOT Controller', value: vehicle?.iotController },
            { label: 'Location', value: <Location coordinate={vehicle} /> },
            { label: 'ID', value: <Link to={`/vehicles/${vehicle?.id}`}>{vehicle?.id}</Link> },
            { label: 'Actions', value: <VehicleControlButtons vehicleId={vehicle?.id} /> },
          ]}
        />:<></>}
      </div>
    </div>
  );
};

export const useDefaultCenter = () => {
  const { company } = useContext(CompanyContext);
  return {
    lat: company.latitude,
    lng: company.longitude,
  };
};
const VehicleMarkers = ({selectedVehicleId,setSelectedVehicleId}:any) => {
  const vehicles = useAdminVehicles();
  return (
    <>
   {vehicles.map((vehicle) => (
          <VehicleMarker
            key={vehicle.id}
            coordinate={vehicle}
            status={vehicle.status == Schema.Vehicle.Status.ACTIVE && vehicle.batteryPercent && vehicle.batteryPercent<30 ? Schema.Vehicle.Status.LOW_BATTERY : vehicle.status}
            isSelected={vehicle.id === selectedVehicleId}
            onClick={() => setSelectedVehicleId(vehicle.id)}
          />
      ))}
  </>
  )
}

export const MapPage = () => {
  const defaultCenter = useDefaultCenter();
  const [selectedVehicleId, setSelectedVehicleId] = useState<string | null>(null);

    const { getHomeCities, selectedHomeCityId, setSelectedHomeCityId } = useContext(HomeCityContext);
  const { geofences } = useAdminGeofences();

  let defaultCenterCity:any=defaultCenter;
  const {adminArea, homeCityAdmin:CityAdminId}  = FilterAdminAreas()
  if (selectedHomeCityId!= 'All Home City' &&  selectedHomeCityId) {
    let allHomecities = getHomeCities();
    allHomecities?.map((city) => {
      if (city.id == selectedHomeCityId) {
        defaultCenterCity = {
          lat: city.latitude,
          lng: city.longitude,
        };
      }
    });
  } else if (CityAdminId) {
    if (selectedHomeCityId!= 'All Home City') setSelectedHomeCityId(CityAdminId)
    let allHomecities = getHomeCities();
    allHomecities?.map((city) => {
      if (city.id == CityAdminId) {
        defaultCenterCity = {
          lat: city.latitude,
          lng: city.longitude,
        };
      }
    });
  } 
  if (!CityAdminId) return<LoadingSpinner/> 
  return (
    <div className="w-100 h-100" style={{ position: 'relative' }}>
      <MapComponent
        defaultZoom={13}
        // defaultCenter={defaultCenterCity}
        center={defaultCenterCity}
        containerHeight={'95vh'}
        onClick={() => setSelectedVehicleId(null)}
      >
        <VehicleMarkers setSelectedVehicleId={setSelectedVehicleId} selectedVehicleId={selectedVehicleId} />
        <AdminDocks/>
        {geofences.map((geofence) => (
          <Geofence geometry={geofence.geometry} type={geofence.type} />
        ))}
      </MapComponent>

      {selectedVehicleId != undefined ? <AdminVehicleOverlay selectedVehicleId={selectedVehicleId} /> : <></>}
    </div>
  );
};
