import _ from 'lodash';
import { Schema } from 'boaz-bikes-types';

export const getRentalStats = (rentals: any[]) => {
  const dollarsSpent = _.sumBy(rentals, (rental) => rental.durationInMonths && rental.durationInMonths == 4 ? 100 : rental.durationInMonths && rental.durationInMonths == 1 ? 30 : rental.amountCentsCharged/100 || 0);
  const avgDollarsPerRental = rentals.length ? dollarsSpent / rentals.length : 0;
  const rentalsWithRatings = rentals.filter((rental) => rental.rating != null);
  const avgRentalRating = rentals.length
    ? _.sumBy(rentalsWithRatings, (rental) => rental.rating || 0) / rentalsWithRatings.length
    : 0;
 const dollarsSpentAux = dollarsSpent.toFixed(2)
  return { dollarsSpent:dollarsSpentAux, avgDollarsPerRental, avgRentalRating: avgRentalRating.toFixed(1) };
};

//rentals.reduce((totalRev:any, pass:any) => totalRev + (pass.durationInMonths == 4 ? 100 : pass.durationInMonths == 1 ? 30 : pass.amountCentsCharged/100 || 0), 0) ,